@use '../abstracts/variables' as *;

.file-upload {

  &__label {
    
  }

  &__field {
    display: none;
  }

  &__group {

  }

  &__drag-drop {
    margin-top: 22px;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed $primary-color;
    border-radius: 4px;
    text-align: center;
    padding: 20px 30px;
    cursor: pointer;

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: $primary-color;

      i {
        font-size: 1.5rem;
      }
    }

    &--over {
      background-color: rgba($primary-color, 0.12);
      border-width: 4px;
    }
  }

  &__name {
    padding: 10px 0;
  }

  &__error {
    color: $secondary-color;
    margin-top: 15px;
    font-size: 0.875rem;
  }
}