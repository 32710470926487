@use '../abstracts/variables' as *;

.share-attachments-wrapper {

  &__label {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 7px;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__box {
    border: 1px solid $form-el-border-color-1;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &_item {
      display: flex;
      align-items: center;
      margin-right: 26px;
      border-radius: 3px;
      margin-top: 3px;
      margin-bottom: 3px;

      .checkbox {
        margin-right: 6px;

        label {
          height: auto;
        }

        &__btn {
          width: auto;
          height: auto;

          svg {
            font-size: 1.25rem;
          }
        }
      }

      span {
        font-size: 0.875rem;
        color: $nonary-text-color;
        line-height: 16px;
      }
    }

    p {
      font-size: 0.875rem;
    }
  }

  .attachments-disabled-msg,
  .attachments-size-msg {
    font-size: 0.875rem;
    padding: 15px 0;
    color: $secondary-bg-color;
  }

  .attachments-size-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .attachments-size-msg {
    padding: 5px 0;
    margin-right: 15px;

    strong {
      color: #000;
    }
  }

  .attachments-upload-trigger {
    margin-top: 10px;

    input {
      display: none;
    }

    &__uploading {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba($primary-light-color, 0.5);
      padding: 15px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .progress {
        width: 200px;
        height: 10px;
        background-color: #ccc;
        border-radius: 4px;
        margin-bottom: 20px;
        position: relative;
    
        div {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: $primary-color;
          border-radius: 4px;
          transition: width .3s ease;
        }
    
        p {
          position: absolute;
          bottom: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
  }
}