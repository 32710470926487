@use '../abstracts/variables' as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  font-family: $ff-primary;
  font-weight: 500;
  font-size: 0.875rem;
  min-height: 32px;
  padding: 0 13px;
  border-radius: 3px;
  cursor: pointer;
  background-color: $tertiary-bg-color;
  color: $quinary-text-color;
  transition: background-color .3s ease;

  &:hover {
    background-color: $quinary-bg-color;
  }

  &:active {
    background-color: darken($quinary-bg-color, 10%);
  }

  .icon {
    display: block;
    margin-right: 11px;

    svg {
      display: block;
    }
  }

  .icon-right {
    display: block;
    margin-left: 11px;

    svg {
      display: block;
    }
  }

  .arrow {

    svg {
      display: block;
    }
  }

  &--drop-down {
    padding-right: 7px;
  }

  &--bold {
    font-weight: 700;
  }

  &--regular {
    font-weight: 400;
  }

  &--small {
    // height: 24px !important;
    min-height: 24px !important;
    padding: 0 10px;
    font-size: 0.75rem;
  }

  &--x-small {
    min-height: 20px;
    padding: 0 8px;
    font-size: 0.6875rem;
  }

  &--medium {
    min-height: 40px !important;
    padding: 0 20px;
  }

  &--large {
    min-height: 50px !important;
  }

  &--with-padding-y {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
  }

  &--primary {
    background-color: $primary-color;
    color: $primary-light-color;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba($primary-color, 0.5);
    }

    &:active {
      background-color: darken($primary-color, 20%);
    }
  }

  &--secondary-light {
    background-color: $quinary-color-light;
    color: $septenary-color;

    &:hover {
      background-color: darken($quinary-color-light, 10%);
    }
    &:disabled {
      background-color: $quinary-color-light;
    }
    &:active {
      background-color: darken($quinary-color-light, 20%);
    }
  }

  &--outline-primary {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: 500;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: $primary-color;
      color: $primary-light-color;
    }
    &:active {
      background-color: darken($primary-color, 10%);
    }
  }

  &--outline-primary-light {
    background-color: $primary-light-color;
    border: 1px solid rgba($primary-color, 0.43);
    color: $primary-color;
    font-weight: 500;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: rgba($primary-color, 0.43);
      color: $primary-light-color;
      border-color: rgba($primary-color, 0.43);
    }
    &:active {
      color: $primary-light-color;
      background-color: $primary-color;
    }
  }

  &--outline-light {
    background-color: transparent;
    border: 1px solid $senary-border-color;
    color: $secondary-text-color;
    font-size: 0.8125rem;
    font-weight: 400;
    min-height: 28px;
    padding: 0 10px 0 5px;
    transition: box-shadow .3s ease, background-color .3s ease;

    .icon {
      color: $senary-border-color;
      margin-right: 5px;
      transition: color .3s ease;
      
      svg {
        font-size: 1.25rem;
      }
    }

    &:hover {
      background-color: transparent;
      box-shadow: 0 0 5px rgba($shadow-color-4, 0.2);
    }

    &:active {
      background-color: lighten($senary-border-color, 10);
    }

    &.active {
      background-color: $primary-bg-color;

      .icon {
        color: $primary-color;
      }
    }
  }

  &--outline-pink {
    background-color: transparent;
    border: 1px solid $bg-color-28;
    color: $bg-color-28;
    font-weight: 400;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: $bg-color-28;
      color: $primary-light-color;
      font-weight: 500;
    }
    &:active {
      background-color: darken($bg-color-28, 10%);
    }
  }

  &--white {
    background-color: $primary-light-color;
    color: $primary-color;
    box-shadow: 0 2px 4px 0 rgba($shadow-color-4, 0.5);

    &:active {
      background-color: darken($primary-light-color, 10%);
    }
  }

  &--dull {
    background-color: transparent;
    color: $octonary-text-color;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $tertiary-bg-color !important;
    color: $quinary-text-color !important;
  }

  &--primary-light {
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
    transition: background-color .3s ease;

    &:hover {
      background-color: rgba($primary-color, 0.3);
    }
    &:active {
      background-color: rgba($primary-color, 0.5);
    }
  }

  &--primary-light-2 {
    border: 1px solid $tags-border-color;
    background-color: $tags-bg-color;
    color: $primary-color;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken($tags-bg-color, 5);
    }
    &:active {
      background-color: darken($tags-bg-color, 10);
    }
  }

  &--danger {
    background-color: $danger-color;
    color: $primary-light-color;
    transition: background-color .3s ease;
    
    &:hover {
      background-color: darken($danger-color, 10%);
      color: $primary-light-color;
    }
    &:active {
      background-color: darken($danger-color, 20%);
    }
  }

  &--transparent {
    background-color: transparent;
    color: $secondary-text-color;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--smaller-icon-margin {

    .icon {
      margin-right: 5px;
    }
  }

  &--txt-small {
    font-size: 0.6875rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 8px;
    
    span {
      display: block;
    }

    .icon {
      margin-right: 6px;
    }
  }

  &--tertiary {
    background-color: $bg-color-12;
    color: $text-color-14;

    &:hover {
      background-color: darken($bg-color-12, 10%);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba($bg-color-12, 0.5);
    }

    &:active {
      background-color: darken($bg-color-12, 20%);
    }
  }

  &--round {
    border-radius: 16px;
  }

  &--text-small {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.3px;
    font-weight: 700;
  }

  &.landing-gray {
    background-color: $senary-light-color;
    color: $primary-dark-color;
    border-radius: 6px;
    min-height: 38px;
    font-size: 0.8125rem;
    font-family: $ff-tertiary-bold;
    letter-spacing: 0.93px;
    padding: 0 16px 0 9px;

    .icon {
      margin-right: 14px;
    }

    &:hover {
      background-color: darken($senary-light-color, 5);
    }
    &:active {
      background-color: darken($senary-light-color, 10);
    }

    &--lg {
      font-size: 1rem;
      font-family: $ff-tertiary-medium;
      letter-spacing: 0.34px;
      min-height: 56px;
      padding: 0 16px 0 13px;
      justify-content: flex-start;

      .icon {
        margin-right: 30px;
      }
    }
  }

  &.landing-primary {
    font-family: $ff-tertiary-bold;
    font-size: 0.8125rem;
    letter-spacing: 0.2px;
    min-height: 38px;
    padding: 0 24px 0 8px;

    .icon {
      margin-right: 5px;
    }

    &--lg {
      font-size: 1rem;
      letter-spacing: 0px;
      min-height: 56px;
      padding: 0 10px;
      justify-content: flex-start;

      .icon {
        margin-right: 6px;
      }
    }
  }

  &--light-green {
    background-color: rgba($bg-color-22, 0.1);
    color: $bg-color-22;

    &:hover {
      background-color: rgba($bg-color-22, 0.2);
    }
    &:active {
      background-color: rgba($bg-color-22, 0.3);
    }
  }
}