@use '../abstracts/variables' as *;

.download-wrapper {
  background-color: $primary-bg-color;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.download {
  background-color: $primary-light-color;
  max-width: 372px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgba($primary-dark-color, 0.14);
  padding: 40px;
  text-align: center;

  @media (max-width: $break-sm-max) {
    padding: 40px 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 29px;

    .logo {
      width: 41px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background-color: $primary-color;
      color: $primary-light-color;
      font-weight: 900;
      margin-right: 14px;
      font-size: 1.25rem;
    }
  }

  h3 {
    font-weight: 400;
    color: $primary-dark-color;
    margin-bottom: 31px;
  }

  p {
    font-size: 1rem;
    line-height: 1.1875;
    color: $nonary-text-color;

    &:not(:last-child) {
      margin-bottom: 29px;
    }

    &.download__restart {
      color: $primary-dark-color;

      a {
        color: $primary-color;
      }
    }
  }
}