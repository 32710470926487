@use '../abstracts/variables' as *;

.dropdown-el {
  z-index: 100;
  backface-visibility: hidden;

  &.animate {
    opacity: 0;
    transform: scale(0);
    transition: opacity .2s ease, transform .2s ease;
  }

  &.animate.active {
    opacity: 1;
    transform: scale(1);
  }

  &.default {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    min-width: 100%;
    background-color: $primary-light-color;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba($shadow-color-5, 0.5);
    padding: 5px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background-color: $primary-light-color;
      border-radius: 3px;
    }

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: $primary-light-color;
      box-shadow: 0 0 1px rgba($shadow-color-8, 0.5);
      position: absolute;
      top: -6px;
      left: calc(50% - 6px);
      z-index: -1;
    }
  }

  &.no-arrow {

    &::before,
    &::after {
      display: none;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  &--contract-picker {
    background-color: $primary-light-color;
    box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
    border-radius: 3px;
    position: absolute;
    bottom: calc(100% + 15px);
    left: -16px;
    width: 330px;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: $primary-light-color;
      content: '';
      z-index: 1;
    }

    &::after {
      position: absolute;
      bottom: -5px;
      left: 50px;
      width: 10px;
      height: 10px;
      background-color: $primary-light-color;
      box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
      transform: rotate(-45deg);
      content: '';
    }

    &_head {
      border-bottom: 1px solid $primary-bg-color;
      padding: 7px 11px 6px;

      .form-input {
        margin-bottom: 0;
      }
    }

    &_body {
      height: 150px;
      overflow-y: auto;
      padding: 3px 0;

      ul {

        li {
          font-size: 0.8125rem;
          font-weight: 400;
          color: $primary-dark-color;
          padding: 10px 11px 9px 15px;
          cursor: pointer;
          position: relative;
          z-index: 2;

          &:hover {
            color: $primary-color;
            background-color: $primary-bg-color;
          }

          &.selected {
            background-color: $primary-bg-color;
            padding-right: 36px;

            &:hover {
              color: inherit;
            }
          }

          svg {
            position: absolute;
            top: 5px;
            right: 12px;
            color: $primary-color;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}