.detail-view__section_icon {
  position: absolute;
  top: 1px;
  left: 0;

  i {
    font-size: 1.5rem;
  }
}

.detail-view__section_title {
  margin-bottom: 10px;

  h4, 
  input {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    color: #162A4E;
  }

  input {
    background-color: transparent;
    padding: 4px;
    margin-left: -6px;
    border: 2px solid transparent;

    &:focus {
      background-color: #fff;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      color: #162A4E;
    }
  }

  .edit {

    input {
      margin-left: 0;
    }

    .btn-wrapper {
      display: flex;
      align-items: center;

      .close {
        color: #162A4E;
        margin-left: 10px;
      }
    }
  }
}

.detail-view__section_list {
  display: flex;
  align-items: center;

  .label {
    margin-right: 10px;
  }

  .list-wrapper {
    position: relative;

    &__selected {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: lighten(#162A4E, 10);
      }
    }
  }
}

.list-wrapper__dropdown {

  &_position {
    display: flex;
    justify-content: space-between;

    &-inner {
      position: relative;
      
      > div {
        background-color: #F5F6F8;
        border-radius: 3px;
        padding: 6px 12px;
        cursor: pointer;
        transition: background-color .3s ease;
      }

      &:hover {

        > div {
          background-color: darken(#F5F6F8, 5);
        }
      }

      &:nth-child(1) {
        flex: 1;
        margin-right: 8px;
      }

      &:nth-child(2) {
        width: 80px;
      }

      p {

        &.select-label {
          font-size: 0.75rem;
          margin-bottom: 4px;
        }

        &.selected {
          font-size: 0.875rem;
        }
      }

      select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .button {
    margin-top: 15px;
  }
}