@use '../abstracts/variables_new.scss' as *;

.buy-credits {

  &__subtitle {
    margin-bottom: 15px;
  }

  &__payment-method {

    .card {
      border: 1px solid $border-color-2;
      border-radius: 6px;
      height: 38px;
      padding: 10px 16px;
    }

    .save-for-later {
      padding: 15px 0;
      margin-left: -2px;
    }
  }

  &__use-new-payment {
    display: flex;
    align-items: center;

    p {
      margin-right: 10px;
    }
  }

  .payment-error {
    background-color: $danger-color;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 16px;
  }
}