.color-select {

  &__label {
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  &__colors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
      width: 46px;
      height: 32px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      color: #fff;
      transition: opacity .3s ease;

      &:hover {
        opacity: 0.8;
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }
}