@use '../abstracts/variables' as *;

.send-by-email-modal,
.signature-modal {

  .modal__content {
    padding: 40px 0 0;
    border-radius: 8px;
    max-width: 846px;
  }
}

.send-by-email,
.signature-modal-inner {

  h2 {
    font-size: 1.4375rem;
    font-weight: 500;
    letter-spacing: 0.29px;
    color: $secondary-text-color;
    text-align: center;
    margin-bottom: 42px;
    padding: 0 10px;
  }

  &__head {
    padding: 0 40px;
    width: calc(100% + 80px);
    margin-left: -40px;

    @media (max-width: $break-sm-max) {
      padding: 0 20px;
      width: calc(100% + 40px);
      margin-left: -20px;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid $nonary-border-color;

      li {
        color: rgba($tertiary-text-color, 0.69);
        font-size: 0.8125rem;
        font-weight: 500;
        padding-bottom: 13px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0 25px;
        position: relative;

        &.no-cursor {
          cursor: default;
        }

        &.active {
          color: $primary-color;
          
          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
          }
        }
      }
    }
  }

  &__body {
    padding: 30px 40px 0;

    @media (max-width: $break-sm-max) {
      padding: 30px 20px 0;
    }

    .form {
      padding-top: 16px;

      &__group {
        margin-bottom: 22px;
      }

      .form-input {
        margin-bottom: 0;

        &__field {
          height: 38px;
        }
      }

      &__subtitle {
        font-size: 1.125rem;
        color: $secondary-bg-color;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 711px;

        &--2 {
          margin-bottom: 15px;
        }

        &--3 {
          margin-bottom: 10px;
        }

        .icon-button {
          width: 50px;
          height: 38px;
          align-self: flex-end;
        }
      }

      .recipients {
        max-width: 406px;

        .icon-button {
          width: 50px;
          height: 38px;
          align-self: flex-end;
          margin-left: 16px;
        }
      }

      .editor {
        margin-bottom: 32px;

        p {
          font-size: 0.9375rem;
          font-weight: 500;
          color: $secondary-text-color;
          margin-bottom: 10px;
        }

        .tox.tox-tinymce {
          border: 1px solid $form-el-border-color-1;
          
          .tox-sidebar-wrap .tox-edit-area iframe{
            background-color: $form-el-bg-color-1;
          }
        }
      }

      .share-attachments-wrapper {
        margin-bottom: 42px;

        .attachments-disabled-msg {
          color: $secondary-color;
          padding: 10px 0 0;
          font-size: 0.875rem;
        }
      }

      &__actions {
        padding-top: 0;
        padding-bottom: 48px;

        .button {
          margin: 0 17px;

          &--primary {
            min-width: 142px;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.3px;
          }

          &--transparent {
            font-size: 0.9375rem;
            font-weight: 400;
            color: $nonary-text-color;
          }
        }
      }
    }

    &_history {
      padding: 23px 40px 20px;
      width: calc(100% + 80px);
      margin-left: -40px;
      background-color: $form-el-bg-color-1;
      border-radius: 0 0 8px 8px;

      @media (max-width: $break-sm-max) {
        padding: 23px 20px 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
      }

      h4 {
        font-size: 0.875rem;
        margin-bottom: 15px;
        color: $nonary-text-color;
      }

      ul {
        border-top: 1px solid $border-color-15;
        padding-top: 21px;

        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 16px;
          color: $nonary-text-color;
          max-width: 416px;

          @media (max-width: $break-xs-max) {
            display: block;
          }

          .recipients {

            p {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              font-weight: 500;

              &:last-child {
                margin-bottom: 0;
              }

              .thumb {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: $senary-bg-color;
                margin-right: 11px;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                }
              }
            }
          }

          .date {
            font-size: 0.75rem;
            font-weight: 400;

            @media (max-width: $break-xs-max) {
              padding-left: 31px;
              margin-top: 5px;
            }
          }
        }
      }
    }

    .qr-code-section {
      padding-bottom: 70px;
      padding-top: 23px;

      
      &__inner {
        display: flex;
        align-items: center;
        
        @media (max-width: $break-md-max) {
          display: block;
        }
      }

      &__left {
        width: 100%;
        max-width: 315px;
        margin-right: 88px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-self: stretch;

        @media (max-width: $break-md-max) {
          margin: 0 auto 60px;

          &_text {
            min-height: auto !important;
            margin-bottom: 30px;
          }
        }

        &_text {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 102px;
        }

        p {
          font-size: 0.875rem;
          color: $nonary-text-color;
          word-break: break-all;
        }

        .button {
          height: 45px;
          font-size: 0.75rem;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          padding: 0 20px;
          width: 100%;
          margin-top: auto;

          i {
            font-size: 1.5rem;
          }
        }
      }

      &__right {
        width: 100%;
        max-width: 271px;
        display: flex;
        flex-direction: column;

        @media (max-width: $break-md-max) {
          margin: 0 auto;
        }

        &_text {
          background-color: $tertiary-bg-color;
          border-radius: 3px;
          padding: 8px 12px 8px 15px;
          display: flex;
          align-items: center;
          margin-top: auto;

          i {
            font-size: 1.5rem;
            margin-right: 5px;
            color: $primary-dark-color;
          }

          p {
            font-size: 0.8125rem;
            line-height: 15px;
            color: $secondary-text-color;
            flex: 1;
            text-align: center;
          }
        }


        canvas {
          display: block;
          margin: 0 auto 34px;
        }
      }
    }
  }

  .checkbox-add-watermark {
    margin-top: 30px;
    margin-bottom: 30px;

    label {
      display: flex;
      align-items: center;
    }
  }
}