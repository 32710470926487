@use '../abstracts/variables' as *;

body {
  font-family: $ff-primary;
}

h1 {
  font-size: 2.25rem;
  font-weight: 500;

  &.document-title {
    font-size: 1.0625rem;
    font-weight: 700;
  }
}

h2 {
  font-size: 1.375rem;
  font-weight: 700;

  &.landing-h2 {
    font-family: $ff-tertiary-bold;
    font-size: 2.8125rem;

    @media (max-width: $break-xs-max) {
      font-size: 2.25rem;
    }
  }
}

h3 {
  font-weight: 700;
  font-size: 1.25rem;
  color: $tertiary-text-color;
}

h4 {
  font-size: 1.125rem;
  color: $tertiary-text-color;

  &.with-border {
    font-size: 1.0625rem;
    font-weight: 500;
    color: $primary-dark-color;
    line-height: 1.18;
    padding-bottom: 7px;
    border-bottom: 1px solid $senary-border-color;
  }
}

h5 {
  font-size: 1rem;
  font-weight: 700;
}

h6 {
  font-size: 0.75rem;
  color: $nonary-text-color;
  text-transform: uppercase;
}

a.text-link {
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {

  &.landing-p {
    font-family: $ff-tertiary;
    font-size: 1rem;
    line-height: 1.3125;
    color: $quaternary-dark-color;
  }
}

select, button, input, textarea {
  font-family: $ff-primary;
}

.customized-icon {
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
}

.customized-icon-2 {
  font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 20;
}

.material-symbols-outlined {
  font-size: 1.25rem;
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 20;

  &.filled {
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 20;
  }

  &.size-24 {
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    font-size: 1.5rem;
  }

  &.weight-300 {
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 20;
  }
}

h1.heading-1 {
  font-size: 1.3125rem;
  color: #172B4D;
  line-height: 1.238;
}

h2.heading-2 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  color: #162A4E;
}

h3.heading-3 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25;
  color: #162A4E;
}

h4.heading-4 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875;
  color: #162A4E;
}

h5.heading-5 {
  font-size: 0.6875rem;
  line-height: 1.09;
  font-weight: 400;
  color: #162A4E;
  letter-spacing: 0.93px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

h6.heading-6 {
  font-size: 0.625rem;
  line-height: 1.2;
  font-weight: 500;
  color: #95A9C9;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.text-style-1 {
  font-size: 0.875rem;
  line-height: 1.285;
  letter-spacing: 0.2px;
  color: #162A4E;
  white-space: pre-wrap;

  strong {
    font-weight: 700;
  }
}

.text-style-2 {
  font-size: 0.875rem;
  line-height: 1.21;
  color: #162A4E;
}

.text-style-3 {
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.23;
  color: #162A4E;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-style-4 {
  font-size: 0.75rem;
  line-height: 1.16;
  color: #162A4E;
}

.text-style-5 {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.19px;
  color: #162A4E;
}

.date-style-1 {
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.17px;
  color: #162A4E;

  span {
    color: #6E83A3;
  }
}

.date-style-2 {
  font-size: 0.6875rem;
  line-height: 1.63;
  letter-spacing: 0.16px;
  color: #99ACCB;
}