@use '../abstracts/variables' as *;

.authentication {
  padding: 60px 15px;
  position: relative;
  height: 100%;
  min-height: 760px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
    text-align: center;
    color: $tertiary-text-color;
    cursor: pointer;

    svg {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.75rem;
      margin-top: 6px;
    }
  }

  &__language {
    position: absolute;
    top: 20px;
    right: 25px;
    
    .language-selector {
      text-transform: uppercase;
    }
  }
  
  &__inner {
    max-width: 385px;
    width: 100%;
    margin: 0 auto;
  }

  &__head {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 30px;
    
    img {
      display: block;
      margin-right: 7px;
    }

    p {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: $ff-tertiary-bold;
      line-height: 34px;
    }
  }
}