@use '../abstracts/variables_new.scss' as *;

.alert-widget-content {

  .fc-header-toolbar {
    padding: 8px 16px 0;
    margin-bottom: 11px;

    .fc-toolbar-chunk {

      &:last-child {
        display: flex;
        align-items: center;
      }

      .fc-toolbar-title {
        color: $text-color-12;
        font-size: 0.875rem;
        font-weight: 500;
        text-transform: capitalize;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 28px;
        background-color: #fff;
        border: 1px solid $border-color-1;
        border-radius: 4px;
        color: $text-color-6;
        font-size: 0.75rem;
        cursor: pointer;
        margin-left: 5px;
        outline: 0;

        &:hover {
          border-color: $primary-color;
        }

        &:focus {
          background-color: #fff;
          box-shadow: none;
        }

        &:active {
          border-color: $border-color-1;
          background-color: #fff;
          color: $text-color-6;
          box-shadow: none;
        }

        &:active:focus {
          box-shadow: none;
        }

        &.fc-today-button {
          width: auto;
          padding: 0 9px;
        }
      }
    }
  }
  
  .fc-daygrid {
    padding: 0 16px;

    > table {
      border: 0;

      th, td {
        border: 0;
      }
    }

    .fc-col-header {

      .fc-col-header-cell {
        background-color: $bg-color-1;
        border: 0;
        font-size: 0.875rem;
        height: 32px;

        a {
          color: $text-color-12;
          text-transform: capitalize;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .fc-daygrid-body {

      > table {

        > tbody {

          > tr {
            height: 40px !important;

            > td {

              .fc-daygrid-day-frame {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              }

              .fc-daygrid-day-events {
                display: none;
              }

              .fc-daygrid-day-bg {

                > div {
                  display: none;

                  &:first-child {
                    display: block;
                  }
                }

                .fc-bg-event {
                  background-color: rgba($primary-color, 0.3);
                  cursor: pointer;

                  .fc-event-title {
                    display: none;
                  }
                }
              }

              &.fc-day-disabled {
                background-color: transparent !important;
              }

              .fc-daygrid-day-top {
                justify-content: center;
                height: 100%;

                a {
                  color: $text-color-14;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 0.875rem;
                  padding: 0;
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                }
              }

              &.fc-day-past {

                .fc-daygrid-day-top {

                  a {
                    color: $text-color-15;
                  }
                }
              }

              &.fc-day-today {
                background-color: transparent;

                .fc-daygrid-day-top {

                  a {
                    background-color: $danger-color;
                    color: #fff;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      .fc-day {
        border: 0;
      }
    }
  }


}