@use '../abstracts/variables.scss' as *;

.page-or-template-share-modal {

  &__head {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    h2 {
      font-weight: 400;
    }
  }

  &__body {
    display: flex;
    padding: 20px 0;

    .page-share-body {
      flex: 1;
      display: flex;
      flex-direction: column;

      .template-select {
        align-self: flex-start;
        margin-bottom: 20px;

        .select-v2 {
          min-width: 120px;
        }

        .select-v2__body_dropdown {
          white-space: nowrap;
        }
      }
    }

    .page-share {
      border: 2px solid #ddd;
      padding: 15px;
      flex: 1;
      border-radius: 2px;
    }
    
    .page-share {
      
      &__info-msg {
        margin-bottom: 15px;
      }

      .custom-select {
        margin-bottom: 15px;
      }

      &__btn-wrapper {
        margin-bottom: 20px;
      }
    }

    .or {
      width: 60px;
      text-align: center;
      padding: 15px;
    }

    .template-share {
      flex: 1;
      align-self: flex-start;
    }

    @media (max-width: $break-sm-max) {
      flex-direction: column;
      align-items: center;

      .template-share {
        align-self: center;
      }
    }
  }
}