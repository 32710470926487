.dashboard {
  padding-left: 48px;
  min-height: 100%;
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 10;
  }

  .line-loader {
    top: 0;
  }
}