@use '../abstracts/variables' as *;

.mobile-menu {

  &__list {

    li {
      margin-bottom: 10px;

      &.show-on-mobile {
        display: none;

        @media (max-width: $break-xs-max) {
          display: block;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        border-radius: 4px;
        background-color: #F5F6F8;
        color: #172B4D;
        font-size: 0.875rem;
        font-weight: 400;
        transition: background-color .3s ease;
        height: 44px;

        &:hover,
        &.active {
          background-color: #ced3da;
        }

        i, svg {
          font-size: 1.5rem;
          color: inherit;
          margin-right: 5px;
          display: block;

          &.custom-icon-t {

            &::before,
            &::after {
              background-color: #172B4D;
            }
          }

          &.custom-icon-upload {
            font-size: 1rem;
          }
        }
      }

      .notification-button-wrapper {

        .icon-button {
          width: 100%;
          height: 44px;
          background-color: #F5F6F8;
          color: #172B4D;

          &:hover {
            background-color: #ced3da;
          }
        }
      }
    }

  }
}