@use '../abstracts/variables_new.scss' as *;

.filters-list {

  &__item {
    padding: 0 21px 0 26px;
    position: relative;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .item-inner {
      display: flex;
      align-items: center;
      position: relative;

      p {
        font-size: 0.8125rem;
        line-height: 1.23;
        color: $text-color-4;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-left {
          display: block;
          margin-right: 9px;

          span {
            font-size: 1.5rem;
          }
        }
      }

      .icon-right {
        margin-left: auto;
        cursor: pointer;

        span {
          font-size: 1.5rem;
          color: $text-color-4;
          display: block;
        }
      }

      .icon-arrow {
        position: absolute;
        left: -24px;
        top: 0;
        width: 24px;
        height: 24px;

        span {
          font-size: 1.5rem;

          &.drop-down-icon {
            display: none;
          }
        }

        &.active {

          span {

            &.right-icon {
              display: none;
            }

            &.drop-down-icon {
              display: block;
            }
          }
        }
      }

      .dropdown {
        border: 1px solid $border-color-1;
        border-radius: 4px;
        padding: 6px 0;
        box-shadow: 0 5px 10px $shadow-color-2;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;
        background-color: #fff;

        p {
          color: $text-color-6;
          font-size: 0.75rem;
          line-height: 1.142;
          display: flex;
          align-items: center;
          padding: 0 10px;
          height: 30px;
          transition: background-color .3s ease, color .3s ease;
          cursor: pointer;

          span {
            font-size: 1.25rem;
            margin-right: 6px;
            width: 20px;
            
            svg {
              color: $text-color-1;
              fill: $text-color-1;
            }
          }

          &:hover {
            background-color: $bg-color-1;
          }

          &.delete {

            &:hover {
              background-color: $highlighted-color-5-light;
              color: $highlighted-color-5;
            }
          }
        }
      }

      &:hover {

        > p {
          color: $text-color-1;
        }
      }
    }

    &.active {

      &::before {
        width: 2px;
        height: 21px;
        position: absolute;
        top: 2px;
        left: 0;
        background-color: $primary-color;
        content: '';
      }

      > .item-inner {

        > p {
          font-weight: 500;
          color: $text-color-1;
        }
      }
    }
  }

  .subfolder {

    .filters-list__item {
      margin-bottom: 0;
      padding: 0 0 0 24px;
    }
  }

  .subfolder {

    .filters-list__item {

      &.active {

        &::before {
          left: -26px;
        }
      }

      .subfolder {

        .filters-list__item {

          &.active {

            &::before {
              left: -50px;
            }
          }

          .subfolder {

            .filters-list__item {
    
              &.active {
    
                &::before {
                  left: -74px;
                }
              }
            }
          }
        }
      }
    }
  }
}