@use '../../abstracts/variables' as *;

.detail-view__section_members-and-tags {
  display: flex;

  @media (max-width: $break-xs-max) {
    flex-direction: column;

    .members-and-tags:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .members-and-tags {
    margin-right: 29px;

    &:last-child {
      margin-right: 0;
    }

    h5 {
      font-size: 0.8175rem;
      font-weight: 500;
      color: #5B74A2;
      text-transform: uppercase;
      margin-bottom: 9px;
    }

    &__list {
      display: flex;

      .member {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-color: #EBEDF0;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .tag {
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        border-radius: 3px;
        background-color: #EBEDF0;
        font-size: 0.875rem;
        font-weight: 700;
        color: #fff;
        margin-right: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .add {
        position: relative;

        &__icon {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #EBEDF0;
          color: #53617D;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: background-color .3s ease;

          &:hover {
            background-color: darken(#EBEDF0, 5);
          }
        }

        &--tag {

          .add__icon {
            height: 32px;
            border-radius: 3px;
          }
        }
      }

      .actions {
        position: relative;
      }
    }
  }
}

.add__dropdown {

  .members-list {
    max-height: 235px;
    overflow-y: auto;
    padding-right: 2px;

    li {
      position: relative;
      padding: 8px 20px 8px 44px;
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 4px;
      transition: background-color .3s ease;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: #EBEDF0;
      }

      .user-thumb {
        width: 32px;
        height: 32px;
        background-color: #ccc;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 4px;
        left: 4px;

        .letter-circle {
          width: 32px;
          height: 32px;
        }
      }

      .user-name {
        font-size: 0.875rem;
        line-height: 1.43;
        padding: 2px 0;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        font-size: 1rem;
      }
    }
  }

  .tags-list {
    margin-bottom: 15px;
    max-height: 235px;
    overflow-y: auto;

    &--hidden {
      height: 1px;
      overflow: hidden;
    }

    li {
      display: flex;
      justify-content: space-between;

      p {
        flex: 1;
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 6px 20px 6px 6px;
        color: #fff;
        border-radius: 3px;
        margin-right: 8px;
        cursor: pointer;
        transition: opacity .3s ease;
        position: relative;

        &:hover {
          opacity: 0.8;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 4px;
          font-size: 1rem;
          transform: translateY(-50%);
        }
      }

      a {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        border-radius: 3px;
        transition: background-color .3s ease;

        &:hover {
          background-color: #EBEDF0;
        }

        svg {
          font-size: 1rem;
        }
      }
    }
  }

  .create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: #EBEDF0;
    color: inherit;
    font-size: 0.875rem;
    border-radius: 3px;
    padding: 0 8px;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken(#EBEDF0, 10);
    }
  }
}

.create-edit-tag-dropdown {

  .form-input {

    &__label {
      color: #162A4E;
      font-size: 0.875rem;
      margin-bottom: 5px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.remove-member-from-document,
.remove-tag-from-document {

  p {
    font-size: 0.9375rem;
    margin-bottom: 5px;
    color: #162A4E;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
  }
}

.remove-tag-from-document {

  p {

    &.tag {
      font-size: 0.875rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      border-radius: 3px;
      color: #fff;
    }
  }
}