@use '../abstracts/variables.scss' as *;

.auth-modal {

  .modal__content {
    max-width: 521px;

    .auth {

      h2 {
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1.22;
        text-align: center;
        margin-bottom: 40px;
      }

      form {
        width: 100%;
      }
    }
  }
}