.kanban-view {
  height: 100%;
  
  &__columns {
    display: flex;
    align-items: flex-start;
    overflow: auto;
    height: 100%;
    position: relative;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  &__column {
    background-color: #EBECF0;
    border-radius: 5px;
    min-width: 270px;
    width: 270px;
    padding: 16px 10px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 15px);

    &_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;
      position: relative;
      z-index: 2;

      > p {
        font-size: 1rem;
        font-weight: 500;
        color: #2E2F35;
        max-width: calc(100% - 30px);
      }

      .list-actions-dropdown {
        position: relative;

        &__trigger {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 20px;
          cursor: pointer;
          border-radius: 3px;
          transition: background-color .3s ease;

          &:hover {
            background-color: darken(#EBECF0, 10);
          }

          span {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(#162A4E, 0.38);
            display: block;
            margin-right: 2px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &__menu {
          position: absolute;
          top: calc(100% + 5px);
          background-color: #fff;
          box-shadow: 0 2px 4px rgba(#494949, 0.5);
          padding: 10px;
          border-radius: 3px;
          white-space: nowrap;
          width: auto;

          ul li .link {
            justify-content: center;
          }
        }
      }
    }

    &_cards {
      min-height: 100px;
      position: relative;
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      // overflow: hidden;
      padding-right: 4px;
      width: calc(100% + 4px);
      padding-bottom: 2px;
    } 

    &_foot {
      margin-top: 14px;
      position: relative;

      a.add-card {
        color: #5D6C85;
        font-size: 0.9375rem;
        font-weight: 500;
        display: flex;
        align-items: center;

        svg {
          display: block;
          margin-right: 3px;
        }
      }
    }
  }

  &__card {
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(#494949, 0.5);
    border-radius: 3px;
    padding: 10px 15px 7px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    // min-height: 200px;

    &:last-child {
      margin-bottom: 0;
    }

    &--dragging {
      box-shadow: 10px 11px 50px 0 rgba(#807E7E, 0.5);
    }

    &_labels {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .card-label {
        min-width: 45px;
        padding: 4px;
        border-radius: 4px;
        background-color: #EBECF0;
        margin-right: 10px;
        margin-bottom: 6px;
        position: relative;
        z-index: 3;

        span {
          display: none;
          text-align: center;
          font-size: 0.6875rem;
          font-weight: 500;
          color: #fff;
        }

        &:hover {
          cursor: pointer;
        }
        
        &.active {
          padding: 2px 4px;
  
          span {
            display: block;
          }
        }

        &[data-color="#2C80FA"]:hover {
          background-color: darken(#2C80FA, 10) !important;
        }
        &[data-color="#17BFFF"]:hover {
          background-color: darken(#17BFFF, 10) !important;
        }
        &[data-color="#1FDAD2"]:hover {
          background-color: darken(#1FDAD2, 10) !important;
        }
        &[data-color="#FF07C3"]:hover {
          background-color: darken(#FF07C3, 10) !important;
        }
        &[data-color="#F92A60"]:hover {
          background-color: darken(#F92A60, 10) !important;
        }
        &[data-color="#FF702B"]:hover {
          background-color: darken(#FF702B, 10) !important;
        }
        &[data-color="#FDB400"]:hover {
          background-color: darken(#FDB400, 10) !important;
        }
        &[data-color="#1FC933"]:hover {
          background-color: darken(#1FC933, 10) !important;
        }
        &[data-color="#8C46FF"]:hover {
          background-color: darken(#8C46FF, 10) !important;
        }
        &[data-color="#666666"]:hover {
          background-color: darken(#666666, 10) !important;
        }
      }

    }

    &_clickable {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &_title {
      margin-bottom: 6px;

      p {
        font-size: 0.9375rem;
        line-height: 1.2;
        color: #162A4E;
      }
    }

    &_info {
      display: flex;
      align-items: center;

      .info {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        span {
          color: #000;
          opacity: 0.2;
          font-size: 0.875rem;
          margin-right: 2px;
        }

        i,
        svg {
          font-size: 1.25rem;
          color: #DCDCDC;
        }
      }
    }

    &_users {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -9px;

      .card-user {
        width: 	36px;
        height: 36px;
        border-radius: 50%;
        background-color: #EBECF0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: -14px;
      }
    }
  }

  .rbd-custom-placeholder {
    position: absolute;
    background-color: #DFE0E5;
    border-radius: 6px;
  }

  .custom-scrollbar > div{
    display: flex;
    align-items: flex-start;
  }

  &__new-list {
    min-width: 270px;
    width: 270px;
    position: relative;

    a:not(.close) {
      display: flex;
      align-items: center;
      padding: 0 27px 0 9px;
      background-color: #EBECF0;
      color: #5D6C85;
      font-size: 0.9375rem;
      font-weight: 500;
      border-radius: 5px;
      white-space: nowrap;
      height: 40px;
      transition: background-color .3s ease;

      &:hover {
        background-color: darken(#EBECF0, 5);
      }

      svg {
        display: block;
        margin-right: 8px;
      }
    }

    .add-new-list {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #EBECF0;
      padding: 10px;
      border-radius: 5px;
      width: 100%;

      &__actions {
        display: flex;
        align-items: center;

        .close {
          margin-left: 10px;
          color: #162A4E;
  
          svg {
            display: block;
          }
        }
      }
    }
  }
}