.detail-view__section_description {

  p {
    cursor: pointer;
  }

  p.hidden-el {
    font-size: 0.9375rem;
    padding: 7px 14px;
    border: 2px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;

    span {
      display: block;
    }
  }

  .description-text {

    p {
      min-height: 19px;
    }
  }

  .description-edit {
    position: relative;

    textarea {
      margin-bottom: 10px;
      background-color: #fff;
      min-height: 120px;
    }

    &__actions {
      display: flex;
      align-items: center;

      .close {
        margin-left: 10px;
        color: #162A4E;

        svg {
          display: block;
        }
      }
    }
  }
}