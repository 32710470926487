@use '../abstracts/variables' as *;

.history-box {
  background-color: $primary-light-color;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.21);
  padding: 26px 42px 20px 44px;
  border-radius: 3px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  header {
    display: flex;
    align-items: center;

    @media (max-width: $break-md-max) {
      display: block;

      .history-box__time {
        margin-bottom: 20px;
      }

      .history-box__progress {
        margin-bottom: 20px;
      }
    }
  }

  &__time {
    font-size: 0.8125rem;
    color: rgba($tertiary-text-color, 0.69);
    width: 86px;
    padding-right: 10px;
    // padding-top: 9px;
  }

  &__progress,
  &__info_bottom {

    p {

      &.main {
        font-size: 0.9375rem;
        color: $octonary-text-color;
        margin-bottom: 4px;
      }

      &.number-of-entries {
        font-size: 0.8125rem;
        font-weight: 500;
      }
    }

    .progress-bar {
      width: 190px;
      max-width: 100%;
      height: 4px;
      border-radius: 3px;
      position: relative;
      background-color: rgba($primary-color, 0.2);
      margin-bottom: 12px;

      div {
        background-color: $primary-color;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  &__actions {
    margin-left: auto;
    padding-top: 9px;

    .button {
      margin-bottom: 10px;

      &:first-child {
        margin-right: 13px;
      }
    }
  }

  &__body {
    padding-top: 44px;
    padding-bottom: 20px;

    .group-wrapper {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &__title {
        font-size: 1rem;
        font-weight: 700;
        color: $septenary-text-color;
        margin-bottom: 10px;
      }
    }

    .group {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: $break-md-max) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {

        &.label {
          width: 220px;
          margin-right: 15px;
          font-weight: 400;
          font-size: 0.875rem;
          color: $septenary-text-color;
        }

        &.input {
          font-size: 1rem;
          font-weight: 700;
          color: $septenary-text-color;
          padding: 2px 0 1px;
          border-bottom: 1px solid $senary-border-color;
          flex: 1;
          padding: 8px 0;
          min-height: 36px;
        }
      }
    }
  }

  &--v2 {
    
    header {
      align-items: flex-start;
    }

    .history-box {

      &__time {
        width: 62px;
        min-width: 62px;
        padding-top: 9px;
      }

      &__info {

        &_top {
          display: flex;
          align-items: center;
          
          .circle {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: $senary-bg-color;
            border-radius: 50%;
            position: relative;
            margin-right: 14px;

            .icon {
              width: 22px;
              height: 22px;
              position: absolute;
              bottom: -4px;
              right: -4px;
              border-radius: 50%;
              background: rgb(0,167,255);
              background: linear-gradient(143deg, rgba(0,167,255,1) 0%, rgba(0,110,255,1) 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              color: $primary-light-color;

              svg {
                display: block;
                font-size: 1rem;
              }

              &.alert {
                background: rgb(255,112,158);
                background: linear-gradient(145deg, rgba(255,112,158,1) 0%, rgba(255,61,101,1) 100%);
              }
              &.dates {
                background: rgb(247,190,253);
                background: linear-gradient(147deg, rgba(247,190,253,1) 0%, rgba(237,138,250,1) 100%);

                svg {
                  font-size: 0.875rem;
                }
              }
              &.tasks {
                background: rgb(212,167,253);
                background: linear-gradient(153deg, rgba(212,167,253,1) 0%, rgba(169,110,251,1) 100%);
              }
              &.info {
                background: rgb(255,208,131);
                background: linear-gradient(151deg, rgba(255,208,131,1) 0%, rgba(254,163,76,1) 100%);
              }
              &.contacts {
                background: rgb(2,231,250);
                background: linear-gradient(142deg, rgba(2,231,250,1) 0%, rgba(1,203,242,1) 100%);
              }
              &.attachments {
                background: rgb(112,214,255);
                background: linear-gradient(147deg, rgba(112,214,255,1) 0%, rgba(61,173,255,1) 100%);
              }
            }
          }

          p {
            font-size: 0.9375rem;
            line-height: 1.2;
            color: $secondary-bg-color;
          }
        }

        &_bottom {
          margin-top: 18px;

          p {
            font-size: 0.8125rem;
            line-height: 1.385;
            margin-bottom: 4px;
            color: rgba($text-color-30, 0.53);
          }

          .progress-bar {
            max-width: 144px;
          }
        }
      }

      &__actions {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .button {

          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 15px;
          }
        }

        @media (max-width: $break-md-max) {
          justify-content: flex-start;
          padding-top: 15px;

          .button {

            &:first-child {
              margin-right: 15px;
            }
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }

      &__body {
        padding-top: 27px;
        
        &.values {
          padding-left: 62px;
        }

        .group-wrapper {

          &__title {
            display: none;
          }
        }

        .group {

          p {
            font-size: 0.9375rem;
            line-height: 1.2;
          }
        }

        @media (max-width: $break-md-max) {
          padding-top: 15px;
          padding-left: 0 !important;

          .group {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &--template {

    header {
      align-items: center;
    }

    .history-box__time {
      padding-top: 0;
    }

    .history-box__progress {

      p {
        margin-bottom: 0;
      }
    }
  }
}