@use '../abstracts/variables.scss' as *;

.notary-box {
  position: relative;
  padding: 15px;
  padding-right: 80px;
  margin-bottom: 10px;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .address {  
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin-bottom: 5px;

    svg {
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  .phone-email {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      margin-right: 15px;
      word-break: break-all;

      svg {
        font-size: 1rem;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .edit-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
    color: $primary-color;
    transition: background-color .3s ease, color .3s ease;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
}