@use '../abstracts/variables_new.scss' as *;

.move-to-folder-v2 {

  &__body {
    padding: 20px 0;

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .breadcrumbs {
        font-size: 0.875rem;

        &__item {

          &.active {
            font-weight: 700;
          }
        }
      }

      .icon-btn {

        .svg-icon svg {
          width: 13px;
        }
      }
    }

    &_main {
      padding: 10px 0;

      .folder-inner {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        padding: 5px;
        transition: background-color .3s ease;
        cursor: pointer;

        &:hover {
          background-color: $bg-color-1;
        }

        span.material-symbols-outlined {
          margin-right: 10px;
        }
      }

      .no-folders {
        text-align: center;
        padding: 50px 0;

        h3 {
          font-size: 1rem;
          font-weight: 700;
          color: $primary-color;
        }

        p {
          font-size: 0.75rem;
        }
      }

      ul.templates-list {

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          margin-bottom: 10px;
          padding: 5px;
          transition: background-color .3s ease;

          &:hover {
            background-color: $bg-color-1;
          }

          .material-symbols-outlined {
            margin-right: 5px;
          }

          .delete {
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}