@use '../abstracts/variables_new.scss' as *;

.profile-form {
  padding-top: 23px;
  max-width: 688px;
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }

  .text-style-1 {
    margin-bottom: 20px;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;

    @media (max-width: $break-sm-max) { 
      flex-direction: column;
      align-items: flex-start;
    }

    &--image {
      margin-bottom: 39px;

      .image-box {
        width: 92px;
        height: 92px;
        background-color: $bg-color-9;
        border: 1px solid $border-color-6;
        border-radius: 2px;
        margin-right: 14px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .actions {
        display: flex;
        align-items: center;

        .btn {
          margin-right: 10px;
          border-radius: 4px;
          font-size: 0.8125rem;

          &--outline {
            border-color: $border-color-5;
          }
        }

        @media (max-width: $break-sm-max) {
          flex-direction: column;
          align-items: flex-start;
  
          .btn:nth-child(2) {
            margin-bottom: 10px;
          }
        }
      }

      @media (max-width: $break-sm-max) {
        flex-direction: column;
        align-items: flex-start;

        .image-box {
          margin-bottom: 14px;
        }
      }
    }

    .input-v2 {
      
      @media (max-width: $break-sm-max) { 
        max-width: 322px;
        width: 100%;
      }
      
      &:first-child {
        margin-right: 44px;
      }
      
      &__label {
        font-size: 0.9375rem;
      }
      
      &__field {
        height: 40px;
        width: 322px;

        @media (max-width: $break-sm-max) { 
          width: 100%;
        }
      }
    }

    .select-v2.select-v2--new {
      
      .select-v2__label {
        font-size: 0.9375rem;
      }
      
      .select-v2__body {
        width: 62px;
        
        &_selected {
          height: 40px;

          p {
            font-size: 0.9375rem;
            color: $text-color-6;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 14px;
    }
  }

  .switch-wrapper {
    margin-bottom: 30px;

    > div {
      display: inline-block;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .security-block {
    margin-bottom: 20px;

    .input-v2 {
      margin-bottom: 0;
    }

    .hint {
      margin-top: 15px;
      font-size: 12px;
    }
  }

  .send-later-link {
    min-width: auto !important;
    margin-top: 10px;
  }
}