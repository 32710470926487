@use '../abstracts/variables' as *;

.document-alerts-modal {

  h2 {
    font-size: 1.4375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 31px;
    text-align: center;
  }

  &__notification {
    position: relative;
    padding: 11px 29px 10px 24px;
    background-color: rgba($tertiary-color, 0.15);
    border-radius: 7px;
    margin-bottom: 30px;

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $text-color-27;
      cursor: pointer;
      
      svg {
        font-size: 1rem;
      }
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
      font-style: italic;
      color: $text-color-27;
      text-align: center;
    }
  }

  .custom-select {
    max-width: 220px;
  }

  label, .custom-select__label {
    font-weight: 400 !important;
  }

  .date_picker {
    margin-bottom: 22px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .button {
      font-size: 0.8125rem;
      text-transform: uppercase;
      padding-left: 30px;
      padding-right: 30px;
    }

    .button--danger {
      margin-right: auto;
    }
    .button--transparent {
      margin-right: 23px;
      color: $nonary-text-color;
      font-weight: 400;
    }
  }
}