@use '../abstracts/variables.scss' as *;

.share-modal {
  color: $secondary-text-color;

  &__head {
    padding: 20px;

    h2 {
      text-align: center;
      font-weight: 400;
    }
  }

  &__body {
    padding: 20px 40px;

    .tags-input {

      &__wrapper {
        background-color: transparent;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        min-width: 120px;
      }
    }

    .search-users {
      background-color: $primary-bg-color;
      border-radius: 4px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      padding: 5px 10px;

      svg {
        display: block;
        margin-right: 5px;
      }

      input {
        flex: 1;
        padding: 10px 2px;
        border: 0;
        outline: 0;
        background-color: transparent;
        font-size: 0.875rem;
        color: $secondary-text-color;
      }
    }

    .list-of-users {
      max-height: 240px;
      overflow-y: auto;
      margin-bottom: 40px;

      .user-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $primary-border-color;
        padding: 20px 0 20px 48px;
        min-height: 76px;

        &--added {
          background-color: rgba($primary-color, 0.1);
        }

        &__thumb {
          position: absolute;
          top: 20px;
          left: 5px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary-bg-color;
          text-transform: uppercase;
        }

        &__info {

          p {

            &.name {
              color: $secondary-text-color;
            }

            &.email {
              font-size: 0.875rem;
              color: $quaternary-text-color;
            }
          }
        }

        &__action {
          padding-right: 10px;

          .button {
            min-width: 80px;
            background-color: #fff;
          }
        }
      }

      .no-users {
        text-align: center;
        color: $secondary-text-color;

        svg {
          font-size: 2rem;
          color: $primary-color;
        }

        p {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &::after {
      content: '';
      width: calc(100% + 40px);
      height: 1px;
      left: -20px;
      top: 100%;
      background-color: $primary-border-color;
      position: absolute;
    }

    li {
      margin: 0 10px;
      border-bottom: 2px solid transparent;

      a {
        color: $secondary-text-color;
        padding: 0 5px 5px;
        display: block;
      }

      &.active {
        border-bottom-color: $primary-color;

        a {
          color: $primary-color;
        }
      }
    }
  }

  &__tabs-content {
    padding: 15px 0;

    &_options {

      li {
        border-bottom: 1px solid $primary-border-color;
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: 10px 0;
      }
    }

    .content-wrapper {
      margin-top: 40px;

      p {
        font-size: 0.875rem;
        margin-bottom: 10px;

        a {
          color: $primary-color;
        }
      }

      &--2 {
        margin-top: 0;
      }

      .action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 0.9375rem;
        }
      }

      &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .form-input {
          flex: 1;
          margin-bottom: 0;

          input {
            padding-left: 8px;
            padding-right: 8px;
            height: 40px;
          }
        }

        .button {
          width: 120px;
          margin-left: 20px;
        }
      }

      .textarea {

        textarea {
          resize: none;
          min-height: 80px;
          max-height: 80px;
          padding: 8px;
        }
      }
    }

    .qr-wrapper {
      text-align: center;

      p {
        font-size: 0.875rem;
        margin-bottom: 20px;
      }
    }
  }

  &__foot {
    padding: 20px;
    background-color: $primary-bg-color;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    border-radius: 0 0 3px 3px;

    &_left {

      p {
        display: flex;
        align-items: center;

        svg {
          color: $secondary-text-color;
          margin-right: 5px;
        }

        a {
          text-decoration: underline;
          color: $secondary-text-color;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &_right {
      margin-left: auto;

      a {
        color: $primary-color;
      }
    }
  }
}