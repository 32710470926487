@use '../abstracts/variables_new.scss' as *;

.custom-datepicker-v2 {
  position: relative;

  &__selected {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 4px;

    p {
      font-size: 0.75rem;
      margin-left: 4px;
    }
  }

  &__dropdown {
    padding: 14px 11px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(#000, 0.23);
    border-radius: 3px;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;

    &_head {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @media (max-width: $break-sm-max) {
        display: block;
      }

      .datepicker-wrapper {
        position: relative;

        &__icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $text-color-6;
        }
      }

      .react-datepicker__input-container {

        input {
          font-size: 0.875rem;
          font-weight: 400;
          border: 1px solid $border-color-1;
          border-radius: 4px;
          outline: 0;
          padding: 7px 30px 6px 8px;
          margin-right: 10px;
          width: 137px;

          @media (max-width: $break-sm-max) {
            margin-right: 0;
            margin-bottom: 10px;
          }

          &:focus {
            border-color: $primary-color;
          }

          &.not-empty {
            background-image: none;
          }
        }

        .react-datepicker__close-icon {
          right: 10px;

          &::after {
            background-color: $primary-color;
            font-size: unset;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 16px;
            padding: 0;
          }
        }
      }

      .btn {
        height: 34px;
      }
    }

    &_body {

      ul {

        li {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.785;
          cursor: pointer;
          position: relative;

          &::after {
            content: '';
            width: 3px;
            height: 20px;
            background-color: $primary-color;
            position: absolute;
            left: -11px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
          }

          &.active {

            &::after {
              opacity: 1;
            }
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}