@use '../../abstracts/variables' as *;

.edit-variable-modal {

  .modal__content {
    max-width: 830px;
    padding: 0;
  }
}

.edit-variable {
  display: flex;

  @media (max-width: $break-md-max) {
    flex-direction: column;

    .edit-variable__left {
      min-height: 300px;
    }

    .edit-variable__right {
      width: 100%;

      &_close {
        display: none;
      }
    }
  }

  &__left {
    flex: 1;
    padding: 20px 28px 20px 37px;
    min-height: 500px;
    position: relative;

    &.sidebar-closed {
      padding-right: 37px;
    }

    &_open {
      position: absolute;
      top: 60px;
      right: 15px;
      font-size: 1.5rem;
      transform: rotate(180deg);
      color: #006EFF;
    }

    .document-detail-variables {
      padding: 0;

      .variables__body {
        margin-left: 0;
      }
    }
  }

  &__right {
    width: 331px;
    background-color: #F1F5F9;
    border-radius: 0 3px 3px 0;
    padding: 31px 22px 31px 18px;

    &_close {
      font-size: 1.5rem;
      margin-bottom: 18px;
      display: inline-block;
      color: #006EFF;
    }

    .document-detail-sp-variables {
      padding: 0;

      &__head-search-wrapper {
        background-color: #fff;
        border-radius: 3px;
      }

      &__body_section--active h2 {
        background-color: #E5ECF3;
      }

      &__body_section h2 {

        &:hover,
        &.active {
          background-color: #E5ECF3;
        }
      }

      &__body ul {
        background-color: #FAFBFC;
      }
    }
  }
}