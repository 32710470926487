@use '../abstracts/variables_new.scss' as *;

.contacts-import-modal-v2 {

  .csv-upload-section,
  .xls-upload  {

    .upload-icon {

      span {
        font-size: 3rem;
        color: $text-color-2;
      }
    }

    p {

      &.para-1 {
        color: $text-color-6;
      }

      &.para-2 {
        color: $text-color-2;
      }
    }
  }

  .contacts-import-modal__body {

    .settings-row {
      background-color: $bg-color-2;
    }

    .contact-import-section {

      &__body {

        .group-btn {
          background-color: $bg-color-2;
        }
      }
    }

    &_right-head {

      .check-all {

        span {
          color: $text-color-2;

          &.filled {
            color: $primary-color;
          }
        }
      }
    }
  }

  .contacts-import-modal__actions {

    &::after {
      background-color: rgba($text-color-6, 0.12);
      width: calc(100% + 64px);
      left: -32px;
    }

    .buttons {
      display: flex;
      align-items: center;
    }
  }
}