@use '../abstracts/variables.scss' as *;

.credits {

  &__left {
    padding: 10px;
    border: 1px solid $primary-color;
    font-size: 0.875rem;
    margin-bottom: 10px;
    text-align: center;
    color: #172B4D;
  }
}

.credits-modal {

  &__head {
    margin-bottom: 30px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
    }
  }

  &__body {

  }
}