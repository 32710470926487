@use '../abstracts/variables_new.scss' as *;

.breadcrumbs-v2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    position: relative;
    margin-right: 26px;
    font-size: 0.875rem;
    line-height: 1.642;

    &::after {
      content: '/';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(50%);
      right: -13px;
      color: $text-color-9;
    }

    &:last-child {

      &::after {
        display: none;
      }
    }

    a {
      display: block;
      color: $primary-color;
    }

    span {
      display: block;
      color: $text-color-8;
    }
  }
}