@use '../abstracts/variables_new.scss' as *;

.create-task {

  &__notification {
    position: relative;
    padding: 11px 29px 10px 24px;
    background-color: $highlighted-color-1-light;
    border-radius: 7px;
    margin-bottom: 30px;

    &_close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $highlighted-color-1;
      cursor: pointer;
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
      font-style: italic;
      color: $highlighted-color-1;
      text-align: center;
    }
  }

  &__field {
    margin-bottom: 31px;
  }

  &__field-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    
    .member-select {
      width: 58%;
    }

    .date-input {
      width: 37%;
    }

    &--2 {
      
      > div {
        width: calc(50% - 10px);
        
        &.date-input {
          width: calc(50% - 10px);
        }
      }
    }

    @media (max-width: $break-xs-max) {
      display: block;
      margin-bottom: 0;

      .member-select,
      .date-input {
        width: 100%;
        margin-bottom: 31px;
      }
    }
  }
}