@use '../abstracts/variables.scss' as *;

.collection-grid-view {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__head {
    padding: 0 20px;
    border-bottom: 1px solid #dcdcdc;
    height: 42px;
    display: flex;
    align-items: center;

    @media (max-width: $break-lg-max) {
      display: none;
    }

    .actions {
      display: flex;
      align-items: center;
      flex: 1;

      .checkbox {
        margin-right: 4px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 300;
        margin-right: auto;
      }

      a {
        display: flex;
        align-items: center;
        margin-left: 2px;
        font-size: 0.8125rem;
        font-weight: 700;
        color: $nonary-text-color;
        transition: background-color .3s ease;
        padding: 5px 11px;
        border-radius: 5px;
  
        &:hover {
          background-color: $tertiary-bg-color;
        }
  
        i, 
        svg {
          color: inherit;
          font-size: 1.125rem;
        }
  
        span:not(.path1):not(.path2) {
          margin-left: 5px;
        }
  
        &.rotate-180 {
  
          svg {
            transform: rotate(180deg);
          }
        }
  
        &.delete {
  
          &:hover {
            background-color: #FDEAE6;
            color: #ED5534;
          }
        }
      }
    }

    .sorting {
      display: flex;
      align-items: center;
      flex: 1;

      .checkbox {
        margin-right: auto;
      }

      .sort-by {
        display: flex;
        align-items: center;

        > p {
          font-size: 0.75rem;
          line-height: 1.41;
          margin-right: 8px;
        }
      }

      .order {
        margin-left: 8px;
        cursor: pointer;
        
        svg {
          font-size: 1.25rem;
        }

        &.asc {
          color: $primary-color;
        }
      }
    }
  }

  &__body {
    padding: 20px 20px 42px;
    flex: 1;
    height: calc(100% - 66px - 42px);
    
    &_boxes {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow-y: auto;
    }
  }
}