@use '../abstracts/mixins.scss' as *;

.list-view {
  height: 100%;

  &__table {
    display: table;
    white-space: nowrap;
    table-layout: fixed;
    min-width: 100%;
    border-top: 2px solid #DCDCDC;

    &--documents {
      min-width: none;
      width: 100%;
    }

    &_row {
      display: table-row;

      &--head {
        background-color: #F5F5F5;

        .list-view__table_cell {
          color: #45536A;
          padding: 6px 8px;
        }

        &-documents {
          display: table-header-group;

          > div {
            display: table-row;
          }
        }
      }

      &--body {

        .list-view__table_cell {

          &:nth-child(1) {
            width: 50px;

            .checkbox {
              display: none;
            }
          }

          &--date {
            width: 100px;
          }
        }

        &:hover {

          .list-view__table_cell {

            &:nth-child(1) {
  
              span.number {
                display: none;
              }

              .checkbox {
                display: block;
              }
            }
          }
        }
      }

      &:hover {
        background-color: #F5F5F5;

        .list-view__table_cell .actions {
          display: flex;
        }
      }

      &--active {
        background-color: #F0F7FF;

        &:hover {
          background-color: #F0F7FF;
        }

        .list-view__table_cell {

          &:nth-child(1) {

            span.number {
              display: none;
            }
            
            .checkbox {
              display: block;
            }
          }

          .template {
            display: none;
          }
          .hover {
            display: block;
          }
        }
      }
    }

    &_cell {
      display: table-cell;
      font-size: 0.8125rem;
      color: #1B1D24;
      border-right: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      padding: 8px 8px 6px;
      text-align: center;
      vertical-align: middle;
      position: relative;
      user-select: none;

      .text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:last-child {
        border-right: 0;
      }

      &:nth-child(1) {
        min-width: 53px;
        // max-width: 53px;
        padding-left: 23px;
        padding-right: 0;
        border-right: 0;
        text-align: left;
        color: #838383;
        font-size: 0.875rem;
        line-height: 1.286;

        span {
          min-width: 12px;
          text-align: center;
          display: inline-block;
        }
      }

      &:nth-child(2) {
        min-width: 250px;
        text-align: left;
        padding-right: 13px;
      }

      &.with-actions {
        position: relative;

        .actions {
          position: absolute;
          top: 50%;
          right: 13px;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          display: none;

          .create {
            display: flex;
            align-items: center;
            height: 24px;
            background-color: #d7dbe3;
            color: #162A4E;
            transition: background-color .3s ease, color .3s ease;
            padding: 0 10px;
            border-radius: 3px;
            margin-right: 5px;

            &:hover {
              background-color: #45536A;
              color: #fff;
            }
          }
        }
      }

      .checkbox {

        &__btn {
          width: 12px;
          height: 12px;

          svg {
            font-size: 1rem;
          }
        }
      }

      .sort-wrapper {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        cursor: pointer;
        display: block;
        max-width: 100%;

        &.centered {
          justify-content: center;
        }

        .sort {
          display: none;

          &--active {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -1px;
          }
        }

        svg {
          font-size: 1rem;
          display: block;
          margin-left: 2px;
        }

        &:active {

          svg {
            color: #ccc;
          }
        }
      }

      .date {

        span {
          display: block;

          &.time {
            color: #949BA7;
          }
        }
      }

      @include status {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        display: inline-block;
      }

      .num {
        display: inline-flex;
        width: 18px;
        height: 18px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 0.75rem;
        font-weight: 700;
        color: #fff;
      }

      &.attachments-cell {

        .num {
          background-color: #43AFFD;
        }
      }

      &.tasks-cell,
      &.dates-cell {

        .num {
          background-color: #A664FC;
        }
      }

      &.alerts-cell {

        .num {
          background-color: #FE6084;
        }
      }

      .members {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        .member {
          width: 22px;
          height: 22px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 50%;
          margin: 0 2px;
          background-color: #ccc;
          display: inline-block;
        }
      }

      .tags {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .tag {
          // display: inline-flex;
          // align-items: center;
          height: 20px;
          border-radius: 10px;
          padding: 0 9px;
          color: #fff;
          align-self: center;
          margin-right: 4px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .hover {
        display: none;

        svg {
          font-size: 1.125rem;
          color: #0acb93;

          &.off {
            color: #F47458;
          }
        }
      }

      .edit {
        color: #45536A;
        display: inline-flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        
        svg {
          font-size: 1rem;
          display: inline-block;
        }

        &:hover {
          background-color: #45536A;
          color: #fff;
        }
      }

      .flex-centered {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          margin: 0 2px;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      .handle {
        width: 8px;
        height: 100%;
        cursor: col-resize;
        position: absolute;
        top: 0;
        right: -4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: '';
          display: none;
          width: 1px;
          height: 100%;
          background-color: #2663EB;
        }

        &--before {
          right: auto;
          left: -5px;
        }

        &:hover {

          &::after {
            display: block;
          }
        }
      }

      &.filtered-out {

        .sort-wrapper {
          text-align: left;
        }
        
        .handle {
          display: none;
        }
      }
    }
  }
}