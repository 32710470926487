@use '../abstracts/variables' as *;

.create-task-modal {

  form {
    padding-right: 20px;
  }

  .form-input {
    margin-bottom: 26px;
  }

  .task-member-and-date {
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    > div {
      min-width: 0;

      &:first-child {
        max-width: 80%;
      }
    }

    .assign-btn {
      min-width: 0;
      max-width: 100%;
  
      svg {
        display: block;
        margin-left: 10px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        display: block;
      }
    }

    .date-btn {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #162a4e;
      border-radius: 2px;
      margin-left: 5px;
      transition: background-color .3s ease;
      padding: 0 5px;
      font-size: 0.75rem;
      text-decoration: underline;
      min-width: 0;
      max-width: 100%;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        display: block;
      }

      svg {
        margin-right: 5px;
        font-size: 1.25rem;
      }

      &:hover {
        background-color: #dadada;
      }
    }

    @media (max-width: $break-sm-max) {
      flex-direction: column;
      align-items: flex-start;

      .assign-btn {
        margin-bottom: 20px;
      }

      .date-btn {
        margin-left: 0;
      }
    }
  }

  .contract-picker {
    margin-bottom: 33px;
    
    &__label {
      font-size: 0.9375rem;
      font-weight: 400;
      color: $senary-color;
      margin-bottom: 8px;
    }
    
    &__field {
      display: inline-flex;
      border-color: $tertiary-bg-color;
      border-width: 2px;
      background-color: $primary-light-color;

      .button {
        background-color: $dashboard-bg;
        border-color: $senary-border-color;
        color: $senary-color;
        transition: background-color .3s ease, color .3s ease, border .3s ease;

        &:hover {
          border: 1px solid $tags-border-color;
          background-color: $tags-bg-color;
          color: $primary-color;
        }
      }
    }

    &.active {

      .contract-picker__field button {
        border: 1px solid $tags-border-color;
        background-color: $tags-bg-color;
        color: $primary-color;
      }
    }
  }

  &__actions {
    display: flex;

    .button {

      &:first-child {
        font-weight: 700;
        margin-right: 17px;
      }

      &:last-child {
        color: $nonary-text-color;
        font-weight: 400;
      }
    }
  }
}