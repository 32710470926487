.dashboard-boxes-wrapper {
  overflow: hidden;

  &__inner {
    width: calc(100% + 15px);
    display: flex;
    flex-wrap: wrap;

    .dashboard-box {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}