@use '../abstracts/variables_new.scss' as *;

.private-notepad-widget-content {
  padding: 18px 20px 0;
  height: 100%;

  .custom-editor {

    span {
      font-size: 0.875rem !important;
      line-height: 1.357 !important;
      color: $text-color-4 !important;
    }
  }

  .custom-editor-toolbar {
  
    .editor-btn {
  
      &:hover {
        background-color: $bg-color-5;
      }
    }
  }
}