@use '../abstracts/variables' as *;

.date-picker-field {
  margin-bottom: 22px;
  width: 100%;

  &__label {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 8px;
  }

  .react-datepicker-wrapper {
    max-width: 100%;

    input {
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      color: $primary-dark-color;
      border-radius: 2px;
      height: 36px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 0 14px;
      width: 100%;
      outline: 0;
      transition: border .3s ease;

      &:focus {
        border-color: $primary-color;
      }
    }
  }

  &--small {
    margin-bottom: 0;

    .react-datepicker-wrapper {

      input {
        height: 28px;
        font-size: 0.8125rem;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  &--thick-border {

    .react-datepicker-wrapper {

      input {
        border-width: 2px;
      }
    }
  }
}