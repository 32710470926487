.alert {

  &__head {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 10px;
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {

      &:first-child {
        margin-right: 15px;
      }
    }
  }
}