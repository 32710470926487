@use '../abstracts/variables' as *;

.search-box-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__body {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 7px 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px;

    @media (max-width: 800px) {
      left: 50% !important;
      transform: translateX(-50%);
    }

    .search-wrap {
      margin-bottom: 15px;

      form {
        display: flex;
        align-items: center;

        .form-input {
          margin-bottom: 0;
          flex: 1;

          input {
            border-radius: 3px 0 0 3px;
          }
        }

        .close {
          background-color: #f4f4f4;
          height: 33px;
          border-radius: 0 3px 3px 0;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          cursor: pointer;
          
          svg {
            font-size: 1.125rem;
            color: #767676;
            display: block;
          }
        }
      }
    }

    .select-categories {
      margin-bottom: 20px;

      p {
        font-size: 0.875rem;
        color: #767676;
        margin-bottom: 10px;
      }

      &__body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .button {
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .search-history {

      p {
        font-size: 0.875rem;
        color: #767676;
        margin-bottom: 10px;

        a {
          color: $primary-color;
          text-decoration: underline;
        }
      }

      &__list {
        padding: 5px 0;

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          margin-bottom: 10px;

          svg {
            font-size: 1.25rem;
            margin-right: 5px;
          }

          a {
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}