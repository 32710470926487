@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.view-filters {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 12px;

  &--variables {

    @media (max-width: 900px) {
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
    }

    @media (max-width: $break-md-max) {
      flex-direction: column;
      align-items: flex-start;

      .divider {
        display: none;
      }

      .view-filters__dropdown,
      .search,
      .view-filters__icon-button {
        margin-bottom: 10px;
      }

      .pagination {
        margin-left: 0 !important;
      }
    }

    .view-filters__dropdown {

      &_selected {
        font-size: 0.875rem;
        color: #172B4D;
        height: 32px;
        padding: 0 12px;
      }

      &_list {

        li {
          height: 32px;
          
          p {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  &--categories {
    justify-content: flex-end;
  }

  &__dropdown {
    margin-right: 15px;
    position: relative;

    &_selected {
      display: flex;
      align-items: center;
      background-color: #F5F6F8;
      border-radius: 3px;
      height: 24px;
      padding: 0 8px;
      font-size: 0.75rem;
      font-weight: 500;
      color: #53617D;
      cursor: pointer;
      transition: background-color .3s ease;

      &:hover {
        background-color: darken(#F5F6F8, 10);
      }

      span {
        font-weight: 500;
      }

      svg {
        display: block;
        margin-left: 5px;
      }

      .total {
        display: flex;
        align-items: center;
        border-radius: 9px;
        background-color: #fff;
        font-size: 0.75rem;
        font-weight: 700;
        color: #07A97A;
        padding: 0 5px;
        height: 19px;
        margin-left: 5px;
      }
    }

    &_list {
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      white-space: nowrap;
      background-color: $primary-light-color;
      box-shadow: 0 2px 6px rgba($shadow-color-7, 0.5);
      border-radius: 3px;
      min-width: 100%;
      z-index: 5;

      li {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 24px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          background-color: #d7dbe3;
        }

        p {
          font-size: 0.75rem;
          font-weight: 400;
          color: #53617D;
          // margin: 0 1px 0 5px;
        }
      }
    }

    &:last-child {
      margin-right: 0;

      .view-filters__dropdown_list {
        left: auto;
        right: 0;
      }
    }
  }

  .search {

    .form-input {
      margin-bottom: 0;

      input {
        padding-bottom: 7px;
        padding-left: 8px;
        padding-right: 8px;
        color:#172B4D;
        font-weight: 400;
        background-color: #F5F6F8;

        @include placeholder {
          color: #bbb;
        }
      }
    }
  }

  .total {

    p {
      font-size: 0.75rem;
      color: #172B4D;
    }
  }

  .divider {
    height: 23px;
    width: 1px;
    background-color: #E7E7E7;
    margin: 0 15px;
  }

  &__icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #F5F6F8;
    border-radius: 3px;
    color: #172B4D;
    transition: background-color .3s ease;
    margin-right: 15px;
    font-size: 1.25rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: darken(#F5F6F8, 10);
    }

    i.custom-icon-upload {
      font-size: 0.875rem;
    }

    svg {
      font-size: inherit;
    }
  }

  .pagination {
    margin-left: auto;
    display: flex;
    align-items: center;

    &__page-info {
      font-size: 0.875rem;
      color: #162A4E;
      margin-right: 10px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: #F5F6F8;
      border-radius: 3px;
      color: #172B4D;
      transition: background-color .3s ease;
      margin-left: 8px;
      cursor: pointer;
      border: 0;
      outline: 0;

      &:hover {
        background-color: darken(#F5F6F8, 10);
      }

      &:disabled {
        opacity: 0.7;

        &:hover {
          background-color: #F5F6F8;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .divider {
      width: 1px;
      height: 17px;
      background-color: #DBDBDB;
      margin-right: 12px;
      margin-left: 0;
    }
  }

  &__button {
    background-color: #F5F6F8;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    height: 32px;
    padding: 0 7px;
    font-size: 0.8125rem;
    color: #172B4D;
    transition: background-color .3s ease;
    margin-right: 8px;

    &:hover {
      background-color: darken(#F5F6F8, 10);
    }

    svg, i {
      font-size: 1.25rem;
      margin-right: 4px;
    }
  }

  &--list {
    justify-content: flex-end;
  }
}