@use '../abstracts/variables' as *;

.document-detail {
  min-height: 100%;
  background-color: $primary-bg-color;
  display: flex;
  flex-direction: column;
  // padding-top: 94px;

  @media (max-width: $break-md-max) {
    // padding-top: 144px;
  }

  &__head {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    box-shadow: 0 2px 4px 0 rgba($shadow-color-2, 0.5);
    padding: 12px 64px;
    margin-bottom: 26px;
    background-color: $primary-light-color;

    @media (max-width: $break-lg-max) {
      padding: 12px 15px;
    }

    .container {
      display: flex;
      align-items: center;

      @media (max-width: $break-md-max) {
        flex-wrap: wrap;
      }
    }

    &_left {
      display: flex;
      align-items: center;
      margin-right: auto;
    }

    &_back {
      font-size: 1.5rem;
      color: rgba($primary-text-color, 0.2);
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 10px;
      transition: color 0.3s ease;
      width: 24px;
      min-width: 24px;
      height: 100%;
      position: relative;
      transition: width 0.3s ease, color 0.3s ease;
      overflow: hidden;

      @media (max-width: $break-xs-max) {
        margin-right: 10px;
      }

      svg {
        display: block;
        text-align: center;
      }

      &:hover {
        color: $primary-color;
        // width: 100px;

        span {
          transform: translateY(-50%);
          opacity: 1;
        }

        @media (max-width: $break-xs-max) {
          width: 24px;

          span {
            display: none;
          }
        }
      }

      span {
        font-size: 0.875rem;
        color: $primary-text-color;
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(calc(-50% - 20px));
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    }

    &_main {
      &-top {
        display: flex;
        align-items: center;
        // margin-bottom: 9px;

        .title {
          margin-bottom: 0;
          margin-right: 23px;
          max-width: 500px;
        }

        .status-wrap {
          margin-right: 6px;

          &__left {
            margin-right: 0 !important;
          }

          .custom-select {
            &__selected {
              span {
                width: 12px;
                height: 12px;
                border: 0;
                margin-right: 0;

                &::after {
                  width: 12px;
                  height: 12px;
                }
              }

              .text {
                display: none;
              }
            }
          }
        }

        .action-link {
          color: #999;
          margin-right: 6px;

          svg.active {
            color: $primary-color;
          }

          svg {
            display: block;
            font-size: 1.125rem;
          }
        }
      }

      &-actions {
        margin-top: 9px;
      }

      .title {
        font-size: 1.0625rem;
        font-weight: 700;
        margin-bottom: 6px;
        position: relative;
        display: inline-flex;

        .transparent {
          opacity: 0;
          padding: 0 5px;
          display: block;
          height: 19px;
          z-index: -1;
        }

        .input-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          input {
            width: 100%;
            min-width: 1px;
            padding-left: 0;
            font-size: 1.0625rem;
          }
        }
      }

      .status-wrap {
        display: flex;
        align-items: center;

        &__left {
          margin-right: 15px;
          display: flex;

          @media (max-width: $break-xs-max) {
            margin-right: 15px;
          }
        }

        &__right {
          font-size: 0.8125rem;
          color: $quinary-text-color;
          margin-right: 15px;
        }

        .template-history-trigger {
          height: 17px;

          &.link-wrapper {
            margin-bottom: 0;

            a {
              font-size: 0.8125rem;
              color: #bbb;
              white-space: nowrap;
              flex-wrap: nowrap;

              &:hover {
                color: $primary-color;
              }

              svg {
                display: block;
                margin-right: 5px;
              }
            }
          }
        }
      }

      &-actions {
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          li {
            margin-right: 15px;

            a,
            > span {
              display: block;
              color: #1b1d24;
              font-size: 0.875rem;
              line-height: 1.142;
              margin-bottom: 4px;

              &.disabled {
                color: #ccc;
                cursor: not-allowed;
              }
            }

            > span {
              color: #ccc;
              cursor: not-allowed;
            }

            a {
              position: relative;

              span.comments-badge {
                position: absolute;
                top: -5px;
                right: -9px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background-color: #ff3d65;
                color: #fff;
                font-size: 8px;
                font-weight: 500;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    &_actions {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 15px;

      @media (max-width: $break-md-max) {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;

        .icon-button {
          margin-left: auto;
        }
      }

      @media (max-width: 420px) {
        .icon-button {
          width: 36px;
          min-width: 36px;

          svg {
            font-size: 1.25rem;
          }
        }
      }

      .tooltip-wrapper {
        height: 30px;
      }

      .btn-wrapper {
        margin-right: 4px;
        position: relative;
      }

      .more-actions {
        height: 30px;
      }

      .button {
        margin-right: 4px;
        height: 30px;

        &.save-btn {
          min-width: 124px;

          @media (max-width: $break-xs-max) {
            min-width: 50px;
            .text {
              display: none;
            }
            .icon {
              margin-right: 0;
            }
          }
          @media (max-width: 420px) {
            min-width: 36px;

            svg {
              font-size: 1.25rem;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.share-btn {
          height: 28px;
          font-size: 0.6875rem;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.4px;
        }
      }

      .dropdown-actions {
        position: absolute;
        top: calc(100% + 9px);
        right: 0;
        width: 152px;
        border-radius: 5px;
        background-color: $primary-light-color;
        box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
        padding: 15px 0;
        // opacity: 0;
        // transition: opacity .3s ease, transform .3s ease;
        // transform: scale(0.6);
        // backface-visibility: hidden;

        // &.active {
        //   opacity: 1;
        //   transform: scale(1);
        // }

        ul {
          li {
            display: flex;
            align-items: center;
            min-height: 30px;
            padding-left: 20px;
            font-size: 0.875rem;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }

            svg,
            i {
              color: $octonary-text-color;
              display: block;
              margin-right: 8px;
              font-size: 1.5rem;
              transition: color 0.3s ease;
            }

            &:hover {
              background-color: $primary-bg-color;
              color: $primary-color;

              svg,
              i {
                color: $primary-color;
              }
            }

            &.remove {
              &:hover {
                background-color: $bg-color-20;
                color: $text-color-22;

                svg {
                  color: $text-color-22;
                }
              }
            }
          }
        }
      }

      .action-btn {
        color: #767676;
        font-size: 1rem;
        margin-right: 15px;
        transition: color 0.3s ease;

        &:hover {
          color: $primary-color;
        }

        svg {
          display: block;
        }
      }
    }

    .scrolling-indicator {
      position: absolute;
      top: 100%;
      left: 0;
      height: 2px;
      background-color: $primary-color;
    }
  }

  &__body {
    flex-grow: 1;
    position: relative;
    padding: 0 64px;
    transition: width 0.3s ease;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: $break-lg-max) {
      padding: 0 0 0 15px;
    }

    &_inner {
      margin: 0 auto;
      max-width: 1151px;
      width: 100%;
      position: relative;
      padding-right: 64px;
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      /* SCROLLING TASK */
      // padding-bottom: 500px;
      /* SCROLLING TASK */

      &-title {
        border: 1px solid $denary-border-color;
        border-radius: 3px;
        margin-bottom: 12px;
        padding: 12px 19px;
        display: flex;
        align-items: center;

        p {
          margin-right: 7px;
          color: $secondary-text-color;
        }

        svg {
          font-size: 1.125rem;
          color: $octonary-color;
          display: block;
          cursor: pointer;
        }

        .help {
          position: relative;

          &--active {
            .help__tooltip {
              opacity: 1;
              width: 250px;
              transform: translate(calc(-50% + 10px), calc(-100% - 15px))
                scale(1);
              white-space: normal;
            }
          }
        }
      }

      .wrap {
        background-color: $primary-light-color;
        box-shadow: 1px 3px 4px rgba($shadow-color-2, 0.5);
        flex-grow: 1;
        // padding-bottom: 80vh;

        &--uploaded-doc {
          padding: 16px;
        }

        .preview-doc {
          // border: 1px solid $denary-border-color;
          // border-radius: 3px;

          .react-pdf__Page__canvas {
            width: 100% !important;
            height: auto !important;
          }

          .react-pdf__Page__textContent {
            display: none !important;
          }

          .react-pdf__Document {
            .react-pdf__Page {
              border: 1px solid $denary-border-color;
              border-radius: 3px;
              margin-bottom: 12px;
            }
          }
        }

        &--align-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .preview-icon {
            background-color: $bg-color-18;
            border: 1px solid $denary-border-color;
            box-shadow: 0 2px 3px 0 rgba($primary-dark-color, 0.1);
            width: 136px;
            min-height: 174px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            margin-bottom: 26px;
            // cursor: pointer;
            padding: 2px;

            .pdf {
              background-color: $secondary-color-light;
              width: 50px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 900;
              font-size: 0.9375rem;
              color: $primary-light-color;
              border-radius: 3px;
              text-transform: uppercase;
            }

            // svg {
            //   font-size: 3rem;
            //   display: block;
            //   color: rgba($primary-dark-color, 0.2);
            // }
          }

          p.uploaded-doc-name {
            font-size: 0.9375rem;
            line-height: 1.2;
            color: $secondary-text-color;
            max-width: 306px;
          }
        }

        input[type='text']:focus,
        input[type='string']:focus,
        input[type='number']:focus,
        textarea:focus {
          border: 1px solid $primary-color;
        }
      }
    }

    .side-panel--2 {
      position: absolute;
      right: 55px;
      top: 0;
      padding-top: 0;
      z-index: 9;
      opacity: 1;
      transition: opacity 0.3s ease;

      .side-panel__menu {
        padding-top: 0;
        position: fixed;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: none;
      }
    }

    &_side-panel {
      width: 406px;
      position: fixed;
      right: -406px;
      background-color: $primary-light-color;
      height: calc(100% - 104px);
      top: 104px;
      transition: right 0.3s ease;

      &.side-panel--shared-template {
        top: 74px;
        height: calc(100% - 74px);

        @media (max-width: $break-md-max) {
          height: calc(100% - 116px);
          top: 116px;
        }
      }

      &.side-panel--shared-template-2 {
        top: 80px;
        height: calc(100% - 80px);

        @media (max-width: $break-md-max) {
          height: calc(100% - 122px);
          top: 122px;
        }
      }

      &.side-panel--prod {
        // top: 92px;
        height: calc(100% - 92px);

        @media (max-width: $break-md-max) {
          height: calc(100% - 142px);
          top: 142px;
        }
      }

      @media (max-width: $break-md-max) {
        height: calc(100% - 152px);
        top: 152px;
      }

      @media (max-width: $break-xs-max) {
        width: 320px;
        right: -320px;
      }
    }

    &.opened {
      width: calc(100% - 342px);

      @media (max-width: $break-lg-max) {
        width: 100%;

        &::after {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          content: '';
        }

        .document-detail__body_side-panel {
          box-shadow: 0 4px 8px 0 rgba($shadow-color, 0.5);
        }
      }

      .side-panel--2 {
        opacity: 0;
        z-index: -1;

        &::after {
          display: block;
        }
      }

      .document-detail__body_side-panel {
        right: 0;

        .side-panel__menu .trigger {
          transform: rotate(0deg);
        }
      }
    }
  }
}
