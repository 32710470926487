@use '../abstracts/variables.scss' as *;
@use '../abstracts/mixins.scss' as *;

@mixin groupBtn() {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
  padding: 0 10px;
  background-color: #f2f2f2;
  color: #333;
  border: 0;
  outline: 0;
  border-radius: 3px;
  text-align: left;
  user-select: none;
  font-size: 0.8125rem;

  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    svg {
      display: block;
      color: #aaa;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  @content
}

@mixin switchBtn() {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 3px;
  min-height: 32px;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  position: relative;

  p {
    margin-left: 8px;
    font-size: 0.8125rem;
    color: #333;
  }

  &:hover {
    opacity: 0.8;
  }

  .switch-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.contacts-import-modal {

  .csv-upload-section {
    padding-top: 10px;

    .upload-icon {

      svg {
        font-size: 3rem;
        color: #53617d;
      }
    }

    p {

      &.para-1 {
        font-size: 1.5rem;
        color: #333;
      }

      &.para-2 {
        font-size: 0.875rem;
        color: #aaa;
      }
    }

    &--csv-added {

      > p {
        background-color: #FA6D6D;
        border-radius: 4px;
        padding: 10px;
        text-align: center;
        font-size: 0.875rem;
        color: #fff;
        margin-bottom: 15px;
      }

      > div {
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }
    }
  }

  &__body {
    display: flex;
    padding-top: 10px;

    &_left {
      width: 400px;
      height: 400px;
      overflow-y: auto;
      padding-right: 15px;

      p.added-from-csv {
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: #333;
      }
    }

    &_right {
      flex: 1;
      height: 400px;
      overflow-y: auto;
      padding-left: 15px;
      display: flex;
      flex-direction: column;

      &-head {

        .action-message {
          margin-bottom: 5px;
        }

        .check-all {
          display: flex;
          align-items: center;
          padding: 0 13px;
          cursor: pointer;
          margin-bottom: 5px;
          user-select: none;

          svg {
            color: #f2f2f2;
          }

          p {
            font-size: 0.8125rem;
            margin-left: 5px;
            color: #333;
          }

          &.active {

            svg {
              color: #006eff;
            }
          }
        }
      }
      
      &-inner {
        flex: 1;
        overflow-y: auto;
        padding-right: 5px;
      }

      .not-mapped {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #333;

        h3 {
          font-weight: 500;
          margin-bottom: 10px;
          text-align: center;
        }

        p {
          text-align: center;
          font-size: 0.875rem;
        }
      }

      .action-message {
        margin-bottom: 10px;

        p {
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }

    .contact-import-section {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &__head {
        border-bottom: 2px solid rgba(0,0,0,0.05);
        padding-bottom: 5px;
        display: flex;
        align-items: center;

        h3 {
          font-size: 0.875rem;
          font-weight: 500;
          color: #53617d;
        }

        a {
          color: #006EFF;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          margin-left: auto;

          svg {
            display: block;
            font-size: 1rem;
            margin-right: 4px;
            color: inherit;
          }
        }
      } 
      
      &__body {
        padding: 15px 0;

        button {
          cursor: pointer;

          &.group-btn {
            @include groupBtn();  
          }
        }
      }
    }

    .field-mapping {
      display: flex;
      border-bottom: 1px solid rgba(0,0,0,0.05);
      padding-bottom: 10px;
      margin-bottom: 15px;
      align-items: flex-start;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }

      &__main {
        width: 150px;
        margin-right: 5px;

        &_body {
          @include switchBtn();

          p {
            width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .edit-custom-field {
            position: absolute;
            top: 50%;
            right: -8px;
            transform: translateY(-50%);
            z-index: 11;

            button {
              width: 16px;
              height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              border-radius: 3px;
              outline: 0;
              background-color: #006EFF;
              color: #fff;

              svg {
                display: block;
                font-size: 0.75rem;
              }
            }
          }
        }

        &_link-variable {
          padding-top: 5px;

          a {
            color: #006EFF;
            text-decoration: none;
            font-size: 0.8125rem;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &__csv {
        flex: 1;
        display: flex;
        align-items: center;

        .arrow {
          margin-right: 5px;

          svg {
            display: block;
            color: #666;
            transform: rotate(180deg);
          }
        }

        > div:not(.arrow) {
          flex: 1;
        }
      }
    }

    .settings-row {
      @include switchBtn();
      margin-bottom: 10px;

      &--last {
        margin-bottom: 0;
      }
    }

    .settings-group {

      p.info {
        font-size: 0.75rem;
        color: #333;
        margin-bottom: 5px;
      }

      .group-btn {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .contact-box {
      border: 3px solid #f2f2f2;
      border-radius: 3px;
      padding: 10px 10px 10px 40px;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      position: relative;
      display: flex;
      flex-wrap: wrap;

      .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);

        svg {
          color: #f2f2f2;
          display: block;
        }
      }

      p {
        font-size: 0.875rem;
        color: #333;
        margin-right: 10px;

        &::after {
          content: ','
        }

        &:last-child {

          &::after {
            display: none;
          }
        }
      }

      &.active {
        border-color: #006EFF;

        .icon {

          svg {
            color: #006eff;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding-top: 30px;
    position: relative;

    .action-message {

      p {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .buttons {
      margin-left: auto;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 2px;
      background-color: rgba(0,0,0,0.05);
    }

    .cancel-btn {
      margin-right: 15px;
    }
  }

  .xls-upload {
    padding-top: 10px;

    &__area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 300px;
      border: 2px dashed rgb(229,229,229);
      border-radius: 10px;
      padding: 20px;
      cursor: pointer;

      .upload-icon {

        svg {
          font-size: 3rem;
          color: #53617d;
        }
      }

      p {

        &.para-1 {
          font-size: 1.5rem;
          color: #333;
        }

        &.para-2 {
          font-size: 0.875rem;
          color: #aaa;
        }
      }
    }

    &__info {
      height: 300px;
      border: 2px dashed rgb(229,229,229);
      border-radius: 10px;
      padding: 20px;

      &_file {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-left {

          p {
            font-size: 1rem;
            color: #333;
            line-height: 1.3;

            &.size {
              font-size: 0.75rem;
              color: #aaa;
              line-height: 1.2;
            }
          }
        }

        &-right {
          width: 24px;
          height: 24px;
          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }

          svg {
            font-size: 1.5rem;
            color: #fa4040;
          }
        }
      }
    }
  }
}

/* Inside actions dropdown */
.actions-dropdown {

  .link-variable-section {

    .trigger-btn {
      @include groupBtn();

      span.text {
        display: block;
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  
    &__body {
      margin-bottom: 15px;

      .edit-custom-field-wrapper {

        input {
          display: block;
          width: 100%;
          padding: 4px 5px;
          background-color: #f2f2f2;
          border: 1px solid rgba(#53617d, 0.5);
          border-radius: 3px;
          outline: 0;
          margin-bottom: 10px;
          font-size: 0.75rem;

          &:focus {
            border-color: #333;
          }
        }
      }
    }
  
    &__foot {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .cancel-btn {
        padding: 0 10px;
      }

      .remove-btn {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .link-variable-search {
    margin-bottom: 10px;

    input {
      border: 2px solid #f2f2f2;
      background-color: #fff;
      color: #333;
      font-size: 0.8125rem;
      padding: 5px 10px;
      border-radius: 3px;
      outline: 0;
      display: block;
      width: 100%;
    }
  }

  .link-variable-list {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;

    .link {

      a {
        font-size: 0.75rem;
        word-break: break-all;
      }
    }
  }

  &.edit-custom-field-dropdown {

    .actions-dropdown__head {
      padding: 0 5px 5px;

      p {
        font-size: 0.8125rem;
      }

      svg {
        top: -8px;
        right: -8px;
      }
    }

    .actions-dropdown__body {

      input[type="text"] {
        display: block;
        width: 100%;
        padding: 4px 5px;
        background-color: #f2f2f2;
        border: 1px solid rgba(#53617d, 0.5);
        border-radius: 3px;
        outline: 0;
        margin-bottom: 10px;
        font-size: 0.75rem;

        &:focus {
          border-color: #333;
        }
      }
    }
  }
}