@use '../abstracts/variables' as *;

.suggestions {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 100%;
  background-color: $primary-light-color;
  border: 1px solid $nonary-border-color;
  box-shadow: 0 1px 4px rgba($shadow-color-5, 0.5);
  z-index: 99;

  &__list {
    padding: 5px 0;

    &_item {
      cursor: pointer;
      padding: 5px;
      font-size: 0.875rem;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        background-color: $primary-bg-color;
        color: $primary-color;
      }
    }
  }
}