@use '../abstracts/variables_new.scss' as *;

.date-input {
  margin-bottom: 20px;

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  } 

  &__field {
    position: relative;

    &_icon {
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      z-index: 1;
      color: $text-color-2;
    }

    .react-datepicker-wrapper {
      width: 100%;
      display: block;
    }

    .react-datepicker__input-container {

      input {
        display: flex;
        align-items: center;
        height: 38px;
        padding: 0 24px 0 36px;
        background-color: #fff;
        border: 1px solid $border-color-2;
        outline: 0;
        border-radius: 6px;
        font-size: 0.75rem;
        color: $text-color-4;
        letter-spacing: 0.17px;
        line-height: 1.5;
        width: 100%;

        &:focus {
          border-color: $primary-color;
        }
      }

      .react-datepicker__close-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          background-color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: unset;
        }
      }
    }
  }

  &.error {

    .date-input__field {
      
      input {
        border-color: $danger-color;
      }
    }
  }
}