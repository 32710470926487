@use '../abstracts/variables_new.scss' as *;

.folder-breadcrumbs {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    color: $text-color-1;

    p {
      cursor: pointer;
      max-width: 100px;
    }

    &:last-child {
      
      p {
        font-weight: 500;
        cursor: auto;
      }
    }

    .material-symbols-outlined {
      font-size: 1.5rem;
      color: $text-color-2;
      display: block;
      user-select: none;

      &.arrow-down {
        cursor: pointer;
      }

      &.more {
        cursor: pointer;
      }
    }
  }
}