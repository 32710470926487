@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-view {
  background-color: $primary-light-color;

  &__head {
    // box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    // padding-bottom: 10px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 282px;
    width: calc(100% - 282px);
    z-index: 7;
    background-color: $primary-light-color;
    transition: width .3s ease, left .3s ease;
    
    &.not-fixed {
      position: sticky;
      top: 0;
      width: 100%;
    }

    &.static {
      position: static;
      width: 100%;
    }

    @media (max-width: $break-lg-max) {
      left: 48px;
      width: calc(100% - 48px);
    }

    @media (max-width: $break-md-max) {
      padding-bottom: 0;
    }

    &_top-wrap {
      height: 59px;
      opacity: 1;
      transition: height .3s ease, opacity .3s ease;

      &.shrink {
        height: 0;
        opacity: 0;
        z-index: -1;
      }
    }
    
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 15px 13px 20px;
      margin-bottom: 12px;
      position: relative;

      h4 {
        display: flex;
        align-items: center;

        span {
          display: none;
          margin-right: 10px;
          cursor: pointer;

          svg {
            display: block;
          }

          @media (max-width: $break-lg-max) {
            display: block;
          }
        }
      }

      .search-trigger-wrapper {
        position: relative;
  
        .close {
          position: absolute;
          left: 100%;
          top: 0;
          width: 20px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          
          svg {
            display: block;
            font-size: 1.25rem;
          }
        }
      }

      @include searchTrigger {
        width: 143px;
      }

      @media (max-width: $break-md-max) {
        margin-bottom: 0;
      }

      @media (max-width: $break-sm-max) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &_actions {
      padding: 6px 15px 6px 20px;
      border-bottom: 1px solid #dcdcdc;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 6;

      @media (max-width: $break-md-max) {
        top: 59px;
        z-index: 5;
      }

      .add-btn {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: 0;
        height: 24px;
        padding: 0 0 0 10px;
        font-size: 0.75rem;
        cursor: pointer;
        margin-right: 15px;

        span {
          height: 100%;
          display: block;
          border-left: 1px solid #ccc;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            display: block;
          }
        }
      }
    }

    &_bottom {
      display: flex;
      height: 36px;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      background-color: #f5f5f5;

      @media (max-width: $break-md-max) {
        display: none;
      }

      .dashboard-column {
        justify-content: center;
        // height: 34px;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          text-align: right;
          margin-right: 0;
          justify-content: flex-end;
        }

        &.col-1 {
          justify-content: flex-start;
          text-align: left;
          margin-right: 15px;
        }

        &__inner {

          &:hover {
            background-color: #F9F8F8;
          }
        }
      }
    }
  }

  &__body {
    padding-top: 95px;

    &--documents-v2,
    &--contacts-v2,
    &--variables-v2,
    &--templates-v2,
    &--signatures-v2,
    &--shared_documents {
      padding-top: 59px;
      height: calc(100% - 37px);

      &.no-results {
        height: 150px;
      }

      &.grid-view {
        height: calc(100vh - 70px);
      }
    }

    // &--contacts {
    //   padding-top: 152px;
    // }

    &.no-padding-top {
      padding-top: 0;
    }

    @media (max-width: $break-md-max) {
      padding-top: 59px;
    }

    .dashboard-row {

      .dashboard-column {

        &.col-1 {

          .checkbox {
            display: none;
          }
        }

        &.col-font-small {
          font-size: 0.8125rem;

          span {
            font-weight: 300;

            &.normal {
              font-weight: 400;
            }
          }
        }
      }

      &:hover,
      &.active {

        .dashboard-column {

          &.col-1 {

            .checkbox {
              display: block;
            }
            .icon {
              display: none;
            }
          }

          &__main {

            p.title {
              color: $primary-color;
            }
          }
        }
      }

      &.template-row,
      &.signatures-row {

        &:hover {

          .checkbox-col {

            .checkbox {
              display: block;
            }
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }

  &--documents,
  &--contacts,
  &--variables,
  &--templates,
  &--signatures,
  &--shared_documents {
    display: flex;
    flex-direction: column;
    height: calc(100% - 66px);

    .loader-wrapper {
      padding-top: 100px;
    }

    .dashboard-view__head {
      padding-bottom: 0;
      box-shadow: none;
      // position: relative;
      // width: 100%;
      // left: 0;

      &.shadow {
        box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      }

      @media (max-width: $break-md-max) {
        box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      }
    }

    .dashboard-view__head_bottom {
      padding-bottom: 10px;
      position: sticky !important;
      top: 0;
      background-color: #f5f5f5;
      z-index: 6;
      display: table-header-group !important;
      transition: box-shadow .3s ease;

      > div {
        display: table-row;
      }
    }

    .dashboard-view__body_table {
      display: table;
      table-layout: fixed;
      width: 100%;

      &-main {
        display: table-row-group;
      }

      &.resizable {

        @media (max-width: $break-md-max) {
          min-width: 100% !important;
        }

        .dashboard-view__head_bottom {
          
          .dashboard-column {
      
            &.col-1 {
              max-width: none;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .dashboard-row {

          .dashboard-column {

            &__inner {
              display: block;

              p.title,
              .text-overflow,
              .tags {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.user-col {

                  .user-thumb,
                  .letter-circle {
                    margin: 0 4px 0 0;
                    height: 21px;
                    width: 21px;
                    font-size: 0.675rem;
                  }

                  .user-thumb {
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border-radius: 50%;
                  }
                }
              }

              .tags {
                display: block;

                .tag {
                  margin-right: 4px;
                }
              }
            }

            &.actions-col {

              .dashboard-column__inner {
                display: flex;
              }
            }
          }
        }

      }

      &.non-resizable {

        .dashboard-view__head_bottom {
          
          .dashboard-column {
      
            &.col-1 {
              max-width: 430px;
              width: 430px !important;
            }
          }
        }

      }

      .dashboard-view__head_bottom {

        &.with-shadow {
          box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
        }

        .document-checked {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          max-width: calc(100vw - 282px);
          height: calc(100% - 1px);
          background-color: #fff;
          padding: 0 15px 0 10px;
          display: flex;

          @media (max-width: $break-lg-max) {
            max-width: calc(100vw - 48px);
          }
        }

        @media (max-width: $break-md-max) {
          display: none !important;
        }
      }

      .dashboard-view__head_bottom,
      .dashboard-row {
        display: table-row;

        &.footer-row {

          .dashboard-column__inner {
            min-height: 65px;
          }
        }

        @media (max-width: $break-md-max) {
          display: block;

          &.folder-row {

            .col-status_label {
              // display: none;
            }
          }
        }

        .dashboard-column {
          display: table-cell;
          vertical-align: middle;
          border-bottom: 1px solid #DDE1E3;

          &.checkbox_col-col {
            border-right: 1px solid #dcdcdc;

            .dashboard-column__inner {
              padding-left: 11px !important;
            }

            .checkbox {

              &__btn {
                width: 18px;
                height: 18px;

                svg {
                  font-size: 1.125rem;
                }
              }
            }
          }

          &.actions-col,
          &.col-actions {
            border-right: 0;

            .dashboard-column__inner {
              padding: 0 11px;
            }
          }

          &.helper-col {

            .dashboard-column__inner:hover {
              background-color: #fff;
            }
          }

          &.col-1,
          &.col-2,
          &.col-3,
          &.col-4 {
            width: auto;
            
            @media (max-width: $break-md-max) {
              clear: both;
              min-height: 18px;
            }
          }

          &.thumbs-col {

            .dashboard-column__inner {
              padding-top: 7px;
              padding-bottom: 7px;

              @media (max-width: $break-md-max) {
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }

          &--statuses {

            .dashboard-column__inner {
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }

          &.helper-col {

            .dashboard-column__inner {
              padding: 0;
            }
          }

          &__inner-wrapper {
            // min-height: 65px;
            display: flex;
            align-items: center;
          }

          &__inner {
            display: flex;
            padding: 9px 11px;
            justify-content: flex-start;
            text-align: left;
            flex: 1;
            font-size: 0.8125rem;
            line-height: 18px;
            color: #1b1d24;

            .icon-mobile {
              display: none;
              position: absolute;
              top: 0;
              left: 10px;
              color: #bbb;
              
              svg {
                font-size: 1.125rem;  
              }

              @media (max-width: $break-md-max) {
                display: block;
              }
            }
          }

          &.col-1 {

            .dashboard-column__inner {
              justify-content: flex-start;
              text-align: left;
            }
          }

          &.custom-col {
            font-size: 0.75rem;
            color: #172B4D;
          }

          &:first-child {

            .dashboard-column__inner {
              padding-left: 44px;

              .checkbox,
              .icon {
                left: 10px;
              }
            }
          }

          @media (max-width: $break-md-max) {
            display: block;
            border-bottom: 0;

            &__inner-wrapper {
              display: block;
              min-height: 1px;
            }
            
            &__inner {
              padding: 0;
              justify-content: flex-start;
              text-align: left;
            }

            &:first-child {

              .dashboard-column__inner {
                padding-left: 0;
  
                .checkbox,
                .icon {
                  left: 10px;
                }
              }
            }
          }
        }

        .more .dropdown {
          top: calc(50% + 24px);
        }
      }
    }
  }

  &--contacts {

    .dashboard-view__body_table .dashboard-view__head_bottom .dashboard-column__inner {
      padding: 9px 34px 9px 11px;
    }

    .dashboard-view__body_table .dashboard-view__head_bottom .helper-col .dashboard-column__inner {
      padding: 0;
    }
  }

  &--templates {

    .dashboard-view__body--templates-v2 {

      .dashboard-view__body_table {
  
        .template-row {
    
          @media (max-width: $break-md-max) {

            .dashboard-column__inner {
              padding-right: 120px;
            }

            .dashboard-column__show-on-hover {
              display: flex;
              position: absolute;
              top: 0;
              right: 10px;
              flex-direction: column;
              width: 100px;
              height: auto;
              background-color: transparent;


              .button--white {
                font-size: 0.75rem;
                min-height: 28px;
                margin-bottom: 5px;
              }

              .dashboard-column__template-more-wrapper {
                margin-left: auto;
              }
            }
          }
        }
      }
    }

  }

  &--signatures {

    // .dashboard-view__head_bottom {

    //   .dashboard-column {
    //     margin-right: 0;
    //     border-right: 1px solid #f3f3f3;
    //     padding: 9px 11px;

    //     .svg-icon {
    //       margin-right: 4px;
  
    //       svg {
    //         fill: #949494;
    //         margin-left: 0;
    //       }
    //     }

    //     &:first-child {
    //       padding-left: 44px;

    //       .checkbox {
    //         left: 10px;
    //       }
    //     }

    //     &:last-child {
    //       border-right: 0;
    //     }

    //     &:hover {
    //       background-color: #F9F8F8;
    //     }
    //   }
    // }

    .dashboard-row {

      .dashboard-column {
        // height: 36px;
        // margin-right: 0;
        // text-align: left !important;
        // justify-content: flex-start !important;

        // @media (max-width: $break-md-max) {
        //   padding: 0;
        //   height: auto;
        // }

        // &:last-child {
        //   border-right: 0;
        // }

        // &.col-1 {
        //   padding-left: 44px;

        //   .icon,
        //   .checkbox {
        //     left: 10px;
        //   }

        //   @media (max-width: $break-md-max) {
        //     padding-left: 33px;
        //   }
        // }

        // &.show-on-hover {
        //   padding-top: 3px;
        //   padding-bottom: 3px;

        //   button {
        //     height: 24px;
        //     font-size: 0.75rem;
        //   }
        // }

        &__inner {
          height: 36px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          display: flex !important;
          align-items: center;
          min-width: 0;
          max-width: 100%;

          @media (max-width: $break-md-max) {
            height: auto;
          }
        }

        .letter-circle {
          margin: 0 4px 0 0;
          height: 21px;
          width: 21px;
          font-size: 0.675rem;
        }
      }

      &.signatures-row {

        &:hover:not(.signatures-row) {

          .dashboard-column.col-1 {
            border-right: 0;
          }
        }

        .dashboard-column {

          &:nth-last-child(2) {
            border-right: 0;
          }
        }
      }
    }
  }

  &--shared_documents {

    .dashboard-view__body_table {

      .dashboard-view__head_bottom { 

        .document-checked {
          max-width: 100%;
        }
      }
    }
  }
}

.collection-hidden-fields {

  .field-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .checkbox {
      margin-bottom: 0;
    }

    .drag-handle {
      margin-left: auto;
      display: block;
      cursor: move;
      
      svg {
        font-size: 1rem;
        display: block;
      }
    }
  }

  > div {

    .checkbox {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        align-items: center;
        cursor: pointer;
      }

      &--disabled {

        .checkbox__btn,
        .checkbox__label {
          color: #c3c2c2;
        }
      }
    }
  }
}