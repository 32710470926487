@use '../abstracts/variables' as *;

.progress-bar {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &__head {
    margin-bottom: 4px;
    font-size: 0.875rem;
    line-height: 24px;
    color: $senary-dark-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_name {
      
      a {
        color: inherit;
      }
    }

    &_value {

    }
  }

  &__body {
    background-color: $nonary-color;
    height: 6px;
    border-radius: 6px;
    position: relative;

    div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 6px;
    }
  }

  &--color-1 {

    .progress-bar__body div { background-color: $chart-color-1; }
  }

  &--color-2 {

    .progress-bar__body div { background-color: $chart-color-2; }
  }

  &--color-3 {

    .progress-bar__body div { background-color: $chart-color-3; }
  }

  &--color-4 {

    .progress-bar__body div { background-color: $chart-color-4; }
  }
}