@use '../abstracts/variables_new.scss' as *;

.side-panel-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
  &.active {

    .side-panel-v2__overlay {
      opacity: 1;
    }

    .side-panel-v2__inner {
      right: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.6);
    opacity: 0;
    transition: opacity .3s ease;
  }

  &__inner {
    position: fixed;
    top: 0;
    right: -336px;
    width: 336px;
    height: 100%;
    background-color: #fff;
    padding: 15px;
    overflow-y: auto;
    transition: right .3s ease;

    &_head {
      background-color: $bg-color-2;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-bottom: 24px;

      h5 {
        margin-bottom: 0;
      }

      .material-symbols-outlined {
        display: block;
        font-size: 1rem;
        margin-right: 5px;
      }
    }

    &_body {

      p {
        margin-bottom: 20px;
      }

      ul {

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 0.875rem;
          margin-bottom: 10px;
          border-bottom: 1px solid $border-color-1;
          padding-bottom: 10px;

          &:last-child {
            border-bottom: 0;
          }

          span {
            margin-right: 10px;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}