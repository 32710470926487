@use '../abstracts/variables' as *;

.kpi {

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 51px 63px;

    @media (max-width: $break-sm-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__boxes {
    display: flex;
    flex-wrap: wrap;
  }

  &__box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(#989898, 0.5);
    padding: 22px;
    max-width: 504px;
    width: 100%;
    margin: 0 15px 15px 0;

    &.dragging {
      background-color: #f4f4f4;
    }

    &_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      h4 {
        font-size: 0.9375rem;
        font-weight: 700;
        color: #172B4D;
        cursor: grab;
      }

      a {
        color: rgba(#162A4E, 0.38);
        width: 32px;
        display: flex;
        justify-content: center;
        border-radius: 3px;
        transition: background-color .3s ease;

        svg {
          display: block;
        }

        &:hover {
          background-color: #ced0da;
        }
      }
    }

    &_body {
      min-height: 300px;
    }
  }
}