@use '../abstracts/variables' as *;

.paper {
  width: 338px;
  height: 434px;
  background-color: $primary-light-color;
  padding: 88px 46px 0;
  box-shadow: 0 20px 33px 0 rgba($shadow-color-9, 0.5);
  border: 1px solid $denary-border-color;
  border-radius: 3px;
  position: relative;

  @media (max-width: $break-md-max) {
    margin: 0 auto;
    max-width: 338px;
    width: 90%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    > :first-child {
      width: 51.2%;
      margin-bottom: 0 !important;
    }
    > :last-child {
      width: 46.3%;
      margin-bottom: 0 !important;
    }
  }

  &__line {
    height: 6px;
    background-color: $quinary-light-color;
    border-radius: 2px;
    margin-bottom: 16px;

    &:nth-child(2) {
      margin-bottom: 26px;
    }

    &--sm {
      width: 36.88%;
    }

    &--md {
      width: 67.2%;
    }

    &--md-2 {
      width: 62%;
    }

    &--md-3 {
      width: 51.2%;
    }

    &--lg {
      width: 100%;
    }

    &--red {
      background-color: #FFB6A7;
    }

    &--blue {
      background-color: #ABD1E6;
    }

    &--green {
      background-color: #40D3A2;
    }
  }

  &__signature {
    margin-top: 68px;
    text-align: right;
    font-size: 1.5rem;
    padding-right: 23px;
    color: $secondary-light-color;
  }
}