@use '../abstracts/variables' as *;

.document-detail-sp-appendices {
  padding: 50px 38px 50px 24px;

  @media (max-width: $break-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  h4 {
    font-weight: 500;
    font-size: 1.0625rem;
    margin-bottom: 12px;
  }

  .file-upload button {
    width: 100%;
  }

  .button-wrapper {
    margin-top: 20px;
  }
}