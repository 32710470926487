.dashboard-small-box {
  background-color: #F8F9FA;
  border: 1px solid #DFE1E6;
  padding: 15px 11px 10px 17px;
  border-radius: 3px;
  margin-bottom: 24px;

  h4 {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.2;
    color: #34353B;
    margin-bottom: 10px;
  }

  &__body {
    
    p.price {
      border-radius: 2px;
      background-color: #fff;
      color: #006EFF;
      padding: 7px 13px 6px 9px;
      font-size: 1.125rem;
      line-height: 1;
      font-weight: 500;
      display: inline-block;
    }

    .tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tag {
        background-color: #fff;
        height: 19px;
        display: flex;
        align-items: center;
        border-radius: 9px;
        padding: 0 3px 0 5px;
        font-size: 0.8125rem;
        color: #34353B;
        margin-right: 6px;
        margin-bottom: 7px;

        span {
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #DBDBDB;
          font-size: 0.75rem;
          font-weight: 500;
          border-radius: 50%;
          margin-left: 4px;
        }
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 2px;

    button {
      height: 24px;
      border: 1px solid #BBBBBB;
      border-radius: 3px;
      padding: 5px 7px;
      background-color: transparent;
      outline: 0;
      font-size: 0.75rem;
      line-height: 1.166666667;
      color: #767676;
      cursor: pointer;

      &:hover {
        color: #34353B;
      }
    }
  }
}