@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.tags-input-v2 {
  margin-bottom: 20px;

  &.active {

    .tags-input__wrapper {
      // border-color: $primary-text-color;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 3px 8px;
    background-color: #fff;
    border: 1px solid $border-color-2;
    outline: 0;
    border-radius: 6px;
    font-size: 0.75rem;
    color: $text-color-4;
    letter-spacing: 0.17px;
    line-height: 1.5;
    width: 100%;

    &.active {
      border-color: $primary-color;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    .tag {
      background-color: rgba($primary-color, 0.05);
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 3px;
      padding: 3px 26px 3px 8px;
      font-size: 0.75rem;
      margin-right: 5px;
      margin-bottom: 2px;
      margin-top: 2px;
      transition: background-color .3s ease;
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        color: $primary-color;
      }

      &:hover {
        background-color: rgba($primary-color, 0.1);
      }
    }

    input {
      border: 0;
      outline: 0;
      margin-bottom: 2px;
      margin-top: 2px;
      padding: 3px 0;
      flex: 1;
      min-width: 80px;
      font-size: 0.875rem;
      color: $text-color-6;
      background-color: transparent;
      font-weight: 400;

      @include placeholder {
        color: $text-color-6;
      }
    }
  }

  &--error {

    .tags-input-v2__wrapper {
      border-color: $danger-color;
    }
  }
}