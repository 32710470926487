@use '../abstracts/variables_new.scss' as *;

.checkbox-v2 {

  label{
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }

  &__btn {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.unchecked {
      color: $border-color-2;
    }
    
    &.checked {
      color: $primary-color;
    }
    
    svg {
      display: block;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 5px;
    color: $text-color-7;
  }

  &__error {
    font-size: 0.75rem;
    // color: $secondary-color;
    padding: 5px 0;
  }

  &--small {

    .checkbox-v2__btn {
      width: 18px;
      height: 18px;
      border-radius: 4px;

      svg {
        font-size: 1.125rem;
      }
    }
  }
}

.checkbox-inline {
  display: inline-block;
  transform: translateY(6px);
}