@use '../abstracts/variables' as *;

.footer {
  background-color: $secondary-bg-color;
  // position: fixed;
  // right: 0;
  // left: 52px;
  // bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 3rem;
  margin-top: auto;

  @media (max-width: $break-md-max) {
    flex-direction: column;
    justify-content: center;
    padding: 5px 15px;
  }
  
  &--label {
    color: #BBBBBB;
    font-size: 11px;
    font-weight: normal;

    @media (max-width: $break-md-max) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &:nth-child(2) {
        margin: 5px 0;
      }
    }
  }

  &--link {
    color: #BBBBBB;
  }

  &--link:hover {
    text-decoration: underline;
    color: white;
  }
  
}