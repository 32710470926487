@use '../abstracts/variables' as *;
@use '../abstracts/animations.scss' as *;

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $primary-light-color;
  z-index: 99999;
  transition: opacity .3s ease;

  &.hidden {
    opacity: 0;
    z-index: -99;
  }

  &__loader {
    width: 120px;
    height: 2px;
    position: relative;
    overflow: hidden;
    background: #EBEDF0;
    margin-top: 22px;
    transition: width .3s ease;

    &::before{
      content: "";
      width: 60px;
      height: 2px;
      position: absolute;
      left: -34px;
      background: #2C7BE5;  
      animation: loading2 1.5s infinite ease;
    }
  }

  &.fading {

    .page-loader__loader {
      width: 0;
    }

    .logo-wrapper {
      top: 30px;
      opacity: 0;
    }
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  position: relative;
  top: 0;
  transition: top .5s ease, opacity .5s ease;

  p {
    font-size: 1.5625rem;
    font-family: $ff-tertiary-bold;
    letter-spacing: 0.42px;
    margin-left: 7px;
    color: #10203e;
  }
}