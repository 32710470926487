@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.form-radio {

  &__choices {

    > div {
      position: relative;
  
      input {
        display: none;
      }
  
      input:checked ~ div {
        border-color: $primary-dark-color;
  
        &::after {
          opacity: 1; 
        }
      }

      &:hover {
        
        .radio__choices_btn::after {
          opacity: 1;
        }
      }
    }

    &_label {
      display: flex;
      cursor: pointer;
      align-items: center;
      color: $primary-text-color;

      p {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    &_btn {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border: 1px solid $primary-dark-color;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        background-color: $primary-dark-color;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .3s ease;
      }
    }
  }

  &--2 {

    .form-radio__choices {
      display: flex;
      align-items: center;

      &_label {
        margin-right: 18px;

        p {
          margin-left: 0;
          color: $quinary-text-color;
          font-weight: 400;
        }
      }

      &_btn {
        margin-right: 5px;
        border: 2px solid $arrow-color;

        &::after {
          display: none;
        }
      }
    }

    .form-radio__choices > div input:checked ~ div {
      border-color: $secondary-bg-color;
    }

    .form-radio__choices > div input:checked ~ p {
      color: $secondary-bg-color;
    }
  }
}