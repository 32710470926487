@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.my-profile {
  display: flex;
  // min-height: 100%;

  &__left {
    width: 234px;
    position: fixed;
    top: 0;
    left: 48px;
    height: 100%;
    overflow-y: auto;
    transition: left .3s ease;

    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary-bg-color, 0.5);
      display: none;
    }

    @media (max-width: $break-md-max) {
      position: fixed;
      left: -234px;
      top: 0;
      height: 100%;
      z-index: 1004;
      padding-bottom: 0;

      &.opened {
        left: 0;

        .my-profile__left_overlay {
          display: block;
        }
      }
    }
  }

  &__right {
    flex: 1;
    padding-left: 234px;
    transition: padding-left .3s ease;

    @media (max-width: $break-md-max) {
      padding-left: 0;
    }
    
    &_head {
      display: flex;
      align-items: center;
      background-color: $primary-light-color;
      // box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
      padding: 15px 18px 15px 34px;
      position: fixed;
      top: 0;
      left: 282px;
      width: calc(100% - 282px);
      z-index: 1;
      transition: left .3s ease, width .3s ease;
      border-bottom: 1px solid #e5e5e5;

      @media (max-width: $break-md-max) {
        padding-left: 15px;
        width: calc(100% - 48px);
        left: 48px;
      }

      h1 {
        font-size: 1.25rem;
        font-weight: 700;
        color: $secondary-text-color;
        display: flex;
        align-items: center;
        margin-right: auto;

        span {
          display: none;
          margin-right: 10px;

          svg {
            display: block;
          }

          @media (max-width: $break-md-max) {
            display: block;
          }
        }
      }

      .support-menu-btn {
        width: 36px;
        margin-left: 10px;
        color: $nonary-text-color;
        background-color: #f3f3f3;
    
        &:hover {
          background-color: #e6e6e6;
        }
        &:active {
          background-color: #cdcdcd;
        }
      }

      @include username;

      .notifications-btn {
        position: relative;
        width: 35px;
        color: $nonary-text-color;
      }

      .dashboard-btn-wrapper {
        margin-left: 8px;

        .icon-button,
        .username {
          margin-left: 0;
        }

        button:hover {
          color: $primary-color;
          background-color: darken(rgba(#EBECF0, 0.5), 10) !important;
        }
      }
    }

    &_body {
      padding: 98px 34px 44px;
      position: relative;

      @media (max-width: $break-md-max) {
        padding: 98px 15px 44px;
      }

      h3 {
        font-weight: 400;
        color: $secondary-text-color;
        margin-bottom: 13px;
      }

      .form {
        // max-width: 448px;

        .form-input {
          min-width: 320px;

          @media (max-width: $break-xs-max) {
            min-width: 1px;
            width: 100%;
            margin-right: 0;
          }
        }

        h3 {

          &.space-above {
            margin-top: 60px;
          }
        }

        .button {

          &.w-140 {
            min-width: 140px;
          }
        }

        &__actions {
          justify-content: flex-start;

          .button {

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  } 

  .security-block {
    // margin-bottom: 10px;
  }

  .switch-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 100px;
  }
}