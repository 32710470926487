@use '../abstracts/variables' as *;

.help-category {

  &__head {
    margin-bottom: 20px;
    position: relative;

    h2 {
      font-size: 1.875rem;
      margin-bottom: 15px;
      font-weight: 500;
      padding-right: 150px;
    }

    .sort-select {
      position: absolute;
      top: 3px;
      right: 0;
      width: 140px;
      margin-bottom: 0;

      .custom-select__selected {
        height: 28px;
        padding: 0 20px 0 5px;

        .text {
          font-size: 0.75rem;
        }
        .icon {
          
          svg {
            font-size: 1rem;
          }
        }
      }

      .select-dropdown {
        left: auto;
        right: 0;
      }
    }

    &_desc {
      border: 1px solid #EBECF0;
      background-color: #F5F6F8;
      padding: 10px;

      p {
        font-size: 0.875rem;
      }
    }
  }

  &__body {

    ul {

      li {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        @media (max-width: $break-xs-max) {
          display: block;
        }

        a {
          display: inline-flex;
          align-items: center;
          color: #5D6C85;
  
          svg {
            display: block;
            margin-right: 5px;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          margin-left: auto;
          font-size: 0.75rem;
          color: #aaa;

          @media (max-width: $break-xs-max) {
            display: block;
            margin-top: 5px;
            padding-left: 29px;
          }
        }
      }
    }
  }
}