@use '../abstracts/variables_new.scss' as *;

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 6px;
  background-color: $primary-color;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.21;
  font-weight: 700;
  padding: 0 12px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color .3s ease;

  &:hover {
    background-color: darken($primary-color, 5);
  }

  &:active {
    background-color: darken($primary-color, 10);
  }

  &:disabled {
    background-color: $bg-color-3;
    cursor: not-allowed;
  }

  &--with-icon {
    padding-right: 6px;

    svg {
      font-size: 1.25rem;
      margin-left: 4px;
    }
  }

  &--with-icon-left {
    padding-left: 6px;

    svg {
      font-size: 1.5rem;
      margin-right: 8px;
      fill: #fff;
      display: block;
    }

    .material-symbols-outlined {
      font-size: 1.5rem;
      margin-right: 8px;
      fill: #fff;
      color: #fff;
    }

    i {
      color: #fff;
      margin-right: 14px;
      font-size: 1.25rem;
    }
  }

  &--xs {
    height: 20px;
    font-size: 0.625rem;
  }

  &--small {
    border-radius: 3px;
    padding: 0 8px;
    height: 28px;
    font-weight: 400;

    &.btn--with-icon-left {

      .material-symbols-outlined {
        font-size: 1.25rem;
      }
    }
  }

  &--small-2 {
    border-radius: 3px;
    padding: 0 16px;
    height: 30px;
    font-weight: 500;
  }

  &--small-3 {
    font-size: 0.75rem;
    font-weight: 400;
    border-radius: 4px;
    height: 28px;
  }

  &--large {
    
  }

  &--outline {
    background-color: #fff;
    color: $text-color-6;
    border: 1px solid $border-color-1;

    &.btn--with-icon-left {

      svg {
        fill: $text-color-11;
      }

      .material-symbols-outlined {
        fill: $text-color-11;
        color: $text-color-11;
      }
    }

    &:hover {
      background-color: $bg-color-1;
    }

    &:active {
      background-color: darken($bg-color-1, 5);
    }
  }

  &--outline-2 {
    background-color: #fff;
    color: $text-color-6;
    border: 1px solid $border-color-5;

    &:hover {
      background-color: $bg-color-1;
    }

    &:active {
      background-color: darken($bg-color-1, 5);
    }
  }

  &--gray {
    background-color: $bg-color-4;
    color: $text-color-6;

    &:hover {
      background-color: darken($bg-color-4, 5);
    }
  
    &:active {
      background-color: darken($bg-color-4, 10);
    }
  }

  &--primary-light {
    background-color: $bg-color-1;
    color: $primary-color;
    letter-spacing: 0.3px;
    font-size: 0.8125rem;
    font-weight: 500;

    &:hover {
      background-color: darken($bg-color-1, 5);
    }
  }

  &--light-green {
    background-color: $highlighted-color-1-light;
    color: $highlighted-color-1;

    &:hover {
      background-color: darken($highlighted-color-1-light, 5);
    }
  }

  &--danger {
    background-color: $danger-color;
    color: #fff;

    &:hover {
      background-color: darken($danger-color, 5);
    }
    &:active {
      background-color: darken($danger-color, 10);
    }
  }

  &--danger-light {
    background-color: rgba($danger-color, 0.04);
    color: $danger-color;

    &:hover {
      background-color: rgba($danger-color, 0.15);
    }
    &:active {
      background-color: rgba($danger-color, 0.25);
    }
  }

  &--with-letter-spacing {
    letter-spacing: 0.43px;
  }

  &--thick-border {
    border-width: 2px;
  }

  &--full {
    width: 100%;
  }

  &--fw-500 {
    font-weight: 500;
  }

  &--fw-400 {
    font-weight: 400;
  }
}