@use '../abstracts/variables' as *;

@mixin statusDot {
  span {
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    position: relative;
    border: 1px solid $senary-border-color;
    border-radius: 50%;
    margin-right: 6px;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-color: $quaternary-border-color;
      content: '';
      border-radius: 50%;
    }
  }

  &.ready {

    span::after {
      background-color: $tertiary-color;
    }
  }

  &.empty {

    span::after {
      background-color: #fff;
    }
  }

  &.draft {

    span {

      &::after {
        background-color: $senary-color;
      }
    }
  }

  &.in-progress {

    span::after {
      background-color: $quaternary-color;
    }
  }

  &.closed {

    span::after {
      background-color: $quinary-color;
    }
  }

  &.expired {

    span::after {
      background-color: $primary-color;
    }
  }

  &.canceled {

    span::after {
      background-color: $secondary-color;
    }
  }

  &.validated {

    span::after {
      background-color: $quinary-color;
    }
  }

  &.to_validate {

    span::after {
      background-color: $status-dot-color-5;
    }
  }
}

.custom-select {
  position: relative;
  user-select: none;

  &__selected {
    font-size: 0.8125rem;
    line-height: 1.23;
    color: $quinary-text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: border .3s ease;
    
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }

    @include statusDot();

    &--thick-border {
      border-width: 2px !important;
    }

    &--error {
      border-color: $secondary-color !important;
    }
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% + 21px);
    left: 0;
    min-width: 152px;
    border-radius: 5px;
    background-color: $primary-light-color;
    box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
    padding: 11px 0;

    ul {
      max-height: 290px;
      overflow-y: auto;

      li {
        padding: 7px 12px;
        font-size: 0.875rem;
        line-height: 1.142;
        transition: background-color .3s ease, color .3s ease;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        .icon {
          margin-right: 5px;

          svg, i {
            font-size: 1rem;
          }
        }

        &:hover,
        &.active {
          background-color: $tertiary-bg-color;
          color: $primary-color;
        }

        &.default {
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

        &.with-border {
          border-bottom: 1px solid $primary-color;
        }

        @include statusDot();
      }
    }
  }

  &__label {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 8px;
  }

  &--form {
    margin-bottom: 22px;

    .custom-select__selected {
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      color: $primary-dark-color;
      border-radius: 2px;
      height: 36px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 0 30px 0 14px;
      position: relative;
      transition: border .3s ease;

      &.opened {
        border-color: $primary-color;
      }

      &.not-selected {
        color: $form-el-text-color-1;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        color: $primary-color;

        svg {
          display: block;
        }
      }
    }

    .select-dropdown {
      top: calc(100% + 5px);
      min-width: 100%;
    }
  }

  &--disabled {
    cursor: not-allowed;
    
    .custom-select__selected {
      cursor: not-allowed;

      .icon {
        color: $quaternary-border-color;
      }
    }
  }

  &.status-dropdown {
    
    .custom-select__selected {
      color: $secondary-text-color;
      font-weight: 500;
      font-size: 0.75rem;

      &_arrow {
        color: $arrow-color;
      }
    }

    .select-dropdown {
      top: calc(100% + 10px);
      left: 50%;
      // width: 113px;
      min-width: 113px;
      box-shadow: 0 1px 3px 0 rgba($shadow-color-8, 0.5);
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background-color: $primary-light-color;
        border-radius: 3px;
      }

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: #fff;
        box-shadow: 0 0 1px rgba($shadow-color-8, 0.5);
        position: absolute;
        top: -6px;
        left: calc(50% - 6px);
        z-index: -1;
      }
      
      &.animate {
        transform: translateX(-50%) scale(0);
      }
      &.animate.active {
        transform: translateX(-50%) scale(1);
      }

      ul {
        z-index: 1;
        position: relative;

        li {
          font-size: 0.8125rem;
          padding: 6px 12px;
          white-space: nowrap;

          span {
            width: 10px;
            height: 10px;
            min-width: 10px;
            border: 0;
            border-radius: 5px;
            margin-right: 9px;

            &::after {
              display: none;
            }
          }

          &.draft {

            span {
              background-color: $quaternary-border-color; 
            }
          }

          &.empty {

            span {
              background-color: #fff;
            }
          }

          &.ready {

            span {
              background-color: $tertiary-color;
            }
          }

          &.pending {

            span {
              background-color: $status-dot-color-1;
            }
          }
          &.in-progress {

            span {
              background-color: $status-dot-color-2;
            }
          }
          &.to_validate {

            span {
              background-color: $status-dot-color-5;
            }
          }
          &.validated {

            span {
              background-color: $status-dot-color-4;
            }
          }
        }
      }
    }
  }

  &--full-width {
    width: 100%;
    max-width: 100%;
  }

  &.error {

    .custom-select__label {
      color: $secondary-color;
    }
  }
}