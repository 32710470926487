@use '../abstracts/variables' as *;

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
};

@mixin documentsDropdownWrapper {
  .documents-dropdown-wrapper {
    position: relative;

    .add-documents-dropdown {
      top: calc(100% + 5px);

      ul {

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 400;
          padding: 5px;
          cursor: pointer;
          transition: background-color .3s ease, color .3s ease;

          &:hover {
            background-color: $primary-bg-color;
            color: $primary-color;

            svg, i {
              color: $primary-color;
            }
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg, i {
            display: block;
            font-size: 1.25rem;
            color: $quinary-text-color;
            transition: color .3s ease;
          }

          i {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

@mixin username {
  .username {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $senary-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    
    svg {
      font-size: 1.125rem;
      color: $primary-light-color;
    }

    span {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
    }

    .letter-circle {
      width: 32px;
      height: 32px;
    }

    @content
  }
}

@mixin searchTrigger {

  .search-trigger {
    width: 244px;
    height: 32px;
    background-color: rgba(#EBECF0, 0.35);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      font-size: 1.125rem;
      color: #767676;
    }

    p {
      font-size: 0.875rem;
      margin-left: 2px;
      color: #767676;
    }

    @media (max-width: $break-md-max) {
      width: 36px;

      p {
        display: none;
      }
    }

    @content
  } 
}

@mixin status {
  .status {
    font-size: 0.8125rem;
    font-weight: 400;
    letter-spacing: 0.58px;
    color: #1A1919;
    text-transform: none;
    height: 20px;
    border-radius: 10px;
    padding: 0 8px;
    align-items: center;

    &--draft {
      background-color: #E6E2E2;
    }

    &--to_validate {
      background-color: #fdb402;
    }

    &--validated {
      background-color: #38b648;
    }

    &--ready {
      background-color: #31ddfe;
    }

    &--empty {
      display: none;
    }

    @content
  }
}

@mixin moreActionsDropdown {
  .more-actions {
    position: absolute;
    left: calc(100% - 24px);
    top: calc(100% + 10px);
    white-space: nowrap;
    background-color: $primary-light-color;
    min-width: 122px;
    border-radius: 3px;
    padding: 10px 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba($shadow-color-6, 0.5);

    p {
      display: flex;
      align-items: center;
      color: $nonary-text-color;
      cursor: pointer;
      font-size: 0.8125rem;
      font-weight: 700;
      padding: 5px 11px;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        color: $primary-color;
        background-color: $tertiary-bg-color;

        svg {
          color: $primary-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        width: 24px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        font-size: 1.25rem;
        color: $nonary-text-color;
        transition: color .3s ease;
      }

      &.delete {

        &:hover {
          background-color: $bg-color-20;
          color: $text-color-22;

          svg {
            color: $text-color-22;
          }
        }
      }

    }
  }

  @content
}