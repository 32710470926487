@use '../abstracts/variables_new.scss' as *;

.actions-dropdown-v2 {
  border: 1px solid $border-color-1;
  border-radius: 4px;
  padding: 12px 0;
  box-shadow: 0 5px 10px $shadow-color-2;

  .actions-dropdown__body {

    .search-wrapper {
      padding: 0 10px;

      input {
        height: 30px;
      }
    }

    ul.default-list {
      max-height: 230px;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        margin-bottom: 0;

        div {
          div {
            display: flex;
            justify-content: space-around;
            padding: 0;
            width: 100%;
            button:hover, button:focus {
              text-decoration: underline;
            }
          }
        }

        a, div {
          color: $text-color-6;
          font-size: 0.875rem;
          line-height: 1.142;
          display: flex;
          align-items: center;
          padding: 0 10px;
          height: 46px;
          transition: background-color .3s ease, color .3s ease;

          button {
            cursor: pointer;
            background: transparent;
            border: none;
            padding: 0;
          }

          span.icon {
            font-size: 1.5rem;
            margin-right: 10px;
            width: 24px;
            
            .material-symbols-outlined,
            svg {
              color: $text-color-1;
              fill: $text-color-1;
            }
          }

          &:hover {
            background-color: $bg-color-1;
          }

          &.delete {

            &:hover {
              background-color: $highlighted-color-5-light;
              color: $highlighted-color-5;
            }
          }
        }
      }

      &--small {

        li {

          a,div {
            font-size: 0.75rem;
            height: 32px;

            span.icon {

              span.material-symbols-outlined {
                color: $text-color-2;
              }
            }

            &.delete {

              &:hover {

                span.icon {

                  span.material-symbols-outlined {
                    color: $danger-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .actions-dropdown__body {
    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}