@use '../base/fonts' as *;

// Colors
$primary-color: #006EFF;
$secondary-color: #FA6D6D;
$tertiary-color: #31DDFE;
$quaternary-color: #EDAA16;
$quinary-color: #38B648;
$senary-color: #BBBBBB;
$septenary-color: #2CB23C;
$octonary-color: #CCC;
$nonary-color: #f5f6f8;
$denary-color: #FDF8D1;
$danger-color: #FA4040;
$color-12: #172B4D;
$color-13: #949BA7;
$color-14: #F47458;
$color-15: #2AA5FE;
$color-16: #A663FC;
$color-17: #ED6AFF;
$color-18: #02D4F5;
$color-19: #FF466F;
$color-20: #42526E;
$color-21: #DEC27B;
$color-22: #FF7D98;
$color-23: #ebecf0;
$color-24: #6C788C;
$color-25: #0459D9;
$color-26: #6C99DC;
$color-27: #074AAF;
$color-28: #FF9731;

$primary-color-light: #E4EDF9;
$primary-color-light-2: #4895FC;
$secondary-color-light: #ed5534;
$tertiary-color-light: #bdf4ff;
$quaternary-color-light: #ffb000;
$quinary-color-light: #E3F7E7;

$primary-light-color: #fff;
$secondary-light-color: #BFC4CC;
$tertiary-light-color: #C3C6D0;
$quaternary-light-color: #b2b2b2;
$quinary-light-color: #e3e3e3;
$senary-light-color: #f6f6f6;
$primary-dark-color: #000;
$secondary-dark-color: #38393f;
$tertiary-dark-color: #10203E;
$quaternary-dark-color: #5D606B;
$quinary-dark-color: #1B1725;
$senary-dark-color: #505D6F;
$septenary-dark-color: #0F1F3F;
$octonary-dark-color: #667083;
$nonary-dark-color: #0D1D41;
$denary-dark-color: #1e1e1e;

$primary-bg-color: #f4f4f4;
$secondary-bg-color: #1B1D24;
$tertiary-bg-color: #f3f3f3;
$quaternary-bg-color: #23252D;
$quinary-bg-color: #e0e0e0;
$senary-bg-color: #c3c2c2;
$septenary-bg-color: #fffbfb;
$octonary-bg-color: #fafafa;
$nonary-bg-color: #f9f9fb;
$denary-bg-color: #e8e8e8;
$bg-color-11: #304FFE;
$bg-color-12: #01D5FF;
$bg-color-13: #312E71;
$bg-color-14: #554FAE;
$bg-color-15: #746ED5;
$bg-color-16: #091937;
$bg-color-17: #88F5A0;
$bg-color-18: #fbfbfb;
$bg-color-19: #FBFAFA;
$bg-color-20: #FDEAE6;
$bg-color-21: #4E5057;
$bg-color-22: #07A97A;
$bg-color-23: #E7E9EE;
$bg-color-24: #FFECCA;
$bg-color-25: #D5AD65;
$bg-color-26: #5561FE;
$bg-color-27: #0012FF;
$bg-color-28: #FF3D65;
$bg-color-29: #0ACB93;
$bg-color-30: #08BD89;
$bg-color-31: #EFEFF0;

$primary-text-color: #000;
$secondary-text-color: #34353B;
$tertiary-text-color: #2E2F35;
$quaternary-text-color: #7e7e7e;
$quinary-text-color: #bbb;
$senary-text-color: #c1c1c1;
$septenary-text-color: #48494D;
$octonary-text-color: #8D8D8D;
$nonary-text-color: #767676;
$denary-text-color: #cdcdcd;
$text-color-11: #1B1626;
$text-color-12: #884F37;
$text-color-13: #FF7E47;
$text-color-14: #0A2540;
$text-color-15: #0C1C42;
$text-color-16: #030A4E;
$text-color-17: #009CFF;
$text-color-18: #56759E;
$text-color-19: #928F8F;
$text-color-20: #A3A1A1;
$text-color-21: #006B18;
$text-color-22: #ED5534;
$text-color-23: #97989A;
$text-color-24: #B2B6BD;
$text-color-25: #0E1E40;
$text-color-26: #FF3D65;
$text-color-27: #0C8096;
$text-color-28: #454855;
$text-color-29: #07A97A;
$text-color-30: #1F2534;

$primary-border-color: #e5e5e5;
$secondary-border-color: #f3f3f3;
$tertiary-border-color: #ddd;
$quaternary-border-color: #bbb;
$quinary-border-color: #f0eded;
$senary-border-color: #dbdbdb;
$septenary-border-color: #f0f0f0;
$octonary-border-color: #979797;
$nonary-border-color: #DADCE0;
$denary-border-color: #f1f1f1;
$border-color-11: #eaeaea;
$border-color-12: #ebebeb;
$border-color-13: #3A3684;
$border-color-14: #e1e1e1;
$border-color-15: #F3EEEE;

$status-color: #139AE8;
$status-color-2: #2BB23A;
$status-color-2-bg: #E3F7E7;
$status-color-3: #ED5423;
$status-color-3-bg: #FBDACF;

$shadow-color: #b2b1b1;
$shadow-color-2: #b9b9b9;
$shadow-color-3: #969696;
$shadow-color-4: #a7a7a7;
$shadow-color-5: #707070;
$shadow-color-6: #878787;
$shadow-color-7: #8c8c8c;
$shadow-color-8: #818181;
$shadow-color-9: #c7c7c7;
$shadow-color-10: #c2c2c2;
$shadow-color-11: #b4b4b4;
$shadow-color-12: #a1a1a1;

$modal-overlay: #898989;

$form-el-bg-color-1: #FAFBFC;

$form-el-border-color-1: #DFE1E6;

$form-el-text-color-1: #999;

$att-color-1: #f000ff;
$att-color-2: #21C2AA;
$att-color-3: #2D73DA;

$chart-color-1: #18BFFF;
$chart-color-2: #20DAD2;
$chart-color-3: #FF09C3;
$chart-color-4: #20C933;
$chart-color-5: #8C46FF;

$tags-bg-color: #E8F2FF;
$tags-border-color: #CCE2FF;

$arrow-color: #ccc;

$status-dot-color-1: #f88639;
$status-dot-color-2: #2E8EC1;
$status-dot-color-3: #F4C712;
$status-dot-color-4: #00A553;
$status-dot-color-5: #FDB402;

$dashboard-bg: #F8F9FA;

$check-color: #38DE0E;

$chart-bg-color: #B4F3D7;
$chart-bg-gradient-color-1: #43E695;
$chart-bg-gradient-color-2: #3BB2B8;

$icon-box-circle-bg: #25EA52;
$icon-box-circle-text: #07D636;

$notification-success-bg: #EDF7EE;
$notification-success-border: #5DB661;
$notification-success-text: #457C46;
$notification-success-icon: #65BB6A;
$notification-danger-bg: #FDECEB;
$notification-danger-border: #F77A72;
$notification-danger-text: #C0362D;
$notification-danger-icon: #F6554A;
$notification-warning-bg: #FFF4E5;
$notification-warning-border: #DCAD6C;
$notification-warning-text: #B57726;
$notification-warning-icon: #FFA229;
$notification-info-bg: #E9F4FD;
$notification-info-border: #389FF4;
$notification-info-text: #2A75B2;
$notification-info-icon: #389FF4;

$action-alert-bg: #ff5321;
$action-alert-text: #ff3900;
$action-task-bg: #A96EFB;
$action-contact-bg: #00D6FF;
$action-contact-text: #01CBF2;
$action-template-bg: #6B789B;

$switch-bg: #C5D0DE;

// Fonts
// $ff-primary: 'Roboto', sans-serif;
$ff-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
$ff-secondary: 'Lato', sans-serif;
$ff-tertiary: 'Helvetica Neue';
$ff-tertiary-medium: 'Helvetica Neue Medium';
$ff-tertiary-bold: 'Helvetica Neue Bold';
$ff-quaternary: 'Roboto Mono', monospace;

// Breakpoints
$break-xxs-max: 375px;
$break-xxs-min: 376px;
$break-xs-max: 480px;
$break-xs-min: 481px;
$break-sm-max: 575px;
$break-sm-min: 576px;
$break-md-max: 767px;
$break-md-min: 768px;
$break-lg-max: 991px;
$break-lg-min: 992px;
$break-xl-max: 1199px;
$break-xl-min: 1200px;