@use '../abstracts/variables.scss' as *;

.step-members-modal {

  &__head {
    margin-bottom: 20px;

    h2 {
      font-weight: 500;
    }
  }

  &__body {

    .selected-users {
      margin-bottom: 11px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .button,
      .btn {
        margin-right: 4px;
        margin-bottom: 4px;

        .icon-right {
          margin-left: 5px;

          svg {
            font-size: 1rem;
          }
        }
      }
    }

    .list-of-members-wrapper {

      &__head {
        display: flex;
        align-items: center;
        border: 1px solid #f2f2f2;
        border-bottom: 0;
        padding: 8px 10px;
        user-select: none;
        cursor: pointer;

        svg,
        .material-symbols-outlined {
          display: block;
          margin-right: 8px;

          &.checked {
            color: $primary-color;
          }
          &.unchecked {
            color: #ccc;
          }
        }

        p {
          font-size: 0.875rem;
        }
      }

      ul {
        max-height: 236px;
        overflow: hidden;
        overflow-y: auto;

        li {
          display: flex;
          align-items: center;
          border: 1px solid #f2f2f2;
          border-bottom: 0;
          padding: 8px 10px;
          user-select: none;
          cursor: pointer;

          &:last-child {
            border-bottom: 1px solid #f2f2f2;
          }

          &:hover {
            background-color: #f2f2f2;
          }

          .checkboxes {
            margin-right: 8px;

            svg,
            .material-symbols-outlined {
              display: block;

              &.checked {
                color: $primary-color;
              }
              &.unchecked {
                color: #ccc;
              }
            }
          }

          .letter-circle,
          .letter-circle-v2 {
            margin-right: 8px;
          }

          p {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .button {
      margin-left: 15px;
    }
  }
}