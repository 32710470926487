@use '../abstracts/variables_new.scss' as *;

.widget {
  background-color: #fff;
  border: 1px solid $border-color-1;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &.no-overflow {
    overflow: unset;
  }

  @media (max-width: $break-md-max) {
    margin-right: 0;
  }
  
  &--half {
    width: calc(50% - 20px);

    @media (max-width: $break-md-max) {
      width: 100%;
    }
  }

  &__head {
    padding: 13px 20px 9px;
    border-bottom: 1px solid $border-color-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    h4 {
      cursor: move;
    }

    &_actions {
      display: flex;
      align-items: center;

      > a,
      .head-action-link {
        display: block;
        color: $primary-color;

        span {
          display: block;
        }

        &:hover {
          background-color: $bg-color-1;
        }
      }

      .icon-btn {
        margin-left: 10px;
      }
    }
  }

  &__body {

    &--placeholders {
      height: 300px;
      padding: 20px;
    }

    &_placeholder {
      width: 80%;
      height: 10px !important;
      border-radius: 6px;
      margin-bottom: 15px;

      &--2 {
        width: 60%;
      }

      &--3 {
        width: 40%;
      }

      &.MuiLinearProgress-colorPrimary {
        background-color: $bg-color-1;
      }
    
      .MuiLinearProgress-barColorPrimary {
        background-color: $bg-color-5;
      }
    }
  }

  &__foot {
    margin-top: auto;

    button {
      border-radius: 0 0 8px 8px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(#000, 0.6);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease;

    &.show {
      z-index: 1;
      opacity: 1;
    }
  }

  &__panel {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 90%;
    border: 1px solid $border-color-1;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 5;
    transition: top .3s ease;

    &.opened {
      top: 10%;
    }
    
    &_head {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 52px 14px 32px;
      border-bottom: 1px solid $border-color-1;
      border-radius: 6px 6px 0 0;
      position: relative;

      .close {
        color: $text-color-7;
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        transition: background-color .3s ease;

        &:hover {
          background-color: darken($bg-color-1, 5);
        }

        &:active {
          background-color: darken($bg-color-1, 10);
        }
      }
    }

    &_body {
      flex: 1;
      overflow-y: auto;
      padding: 19px 32px;
    }
  }

  &--placeholder {
    display: block;

    .widget__head {
      border: 0;
      height: 100px;
    }
  }

  &--dragging {
    background-color: $bg-color-4;
  }
}

.dragged-widget {
  background-color: #fff;
  border: 1px solid $primary-color;
  border-radius: 8px;
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    font-size: 1rem;
    font-weight: 500;
    color: $text-color-6;
  }
}