.not-authenticated-modal {

  &__head {
    margin-bottom: 30px;

    h4 {
      text-align: center;
      font-weight: 500;
    }
  }

  &__body {
    display: flex;
    justify-content: center;

    .button {
      margin: 0 5px;
    }
  }
}