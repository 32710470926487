@use '../abstracts/variables.scss' as *;

.guides-wrapper {

  .guides {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    z-index: 95;

    &__popup {
      position: fixed;
      background-color: #fff;
      border-radius: 5px;
      padding: 10px;
      width: 300px;

      &::before {
        position: absolute;
        top: 12px;
        left: -6px;
        width: 12px;
        height: 12px;
        transform: rotate(-45deg);
        background-color: #fff;
        content: '';
      }

      p {
        font-size: 0.875rem;
      }

      &_foot {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .button {
          margin-left: 10px;

          &.prev {
            margin-right: auto;
          }
        }
      }
    }

    &__side-menu {
      position: fixed;
      top: 0;
      left: 0;
      padding-top: 48px;
      width: 48px;
      display: flex;
      flex-direction: column;
    }

    &__guide {
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
    
    .guide-side-menu {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.375rem;
    }
  }
}