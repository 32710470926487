@use '../abstracts/variables_new.scss' as *;

.pagination-v2 {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;

  a {
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color-2;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-size: 0.875rem;
    position: relative;

    &.prev {
      margin-right: 5px;
    }

    &.next {
      margin-left: 5px;
    }

    &.active {
      color: $text-color-6;

      &::after {
        display: block;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary-color;
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }
  }
}