@use '../abstracts/variables_new.scss' as *;

.add-edit-approval {
  padding-top: 31px;
  max-width: 688px;

  section {
    margin-bottom: 44px;

    h3 {
      margin-bottom: 24px;
    }

    .add-edit-approval__details_fields {
      display: flex;

      @media (max-width: $break-md-max) {
        flex-direction: column;
      }

      .input-v2 {
        width: 322px;
        margin-bottom: 0;

        @media (max-width: $break-md-max) {
          margin-bottom: 15px;
          max-width: 322px;
          width: 100%;
        }

        &:first-child {
          margin-right: 44px;

          @media (max-width: $break-md-max) {
            margin-right: 0;
          }
        }

        &__label {
          font-size: 0.9375rem;
          letter-spacing: 0px;
        }

        &__field {
          height: 40px;
        }
      }
    }

    .approval-steps {

      &__head {
        padding-bottom: 11px;
        font-size: 0.8125rem;
        color: $text-color-6;

        @media (max-width: $break-md-max) {
          display: none;
        }
      }

      &__body {
        font-size: 0.875rem;
        border: 1px solid $border-color-5;
        background-color: $bg-color-8;
        border-radius: 6px;

        .approval-step-row {
          padding: 11px 0;

          .approval-step-col {

            &:nth-child(1) {
              font-size: 0.9375rem;
              font-weight: 500;
              color: $text-color-6;
            }
          }
        }
      }

      .approval-step-row {
        display: flex;
        align-items: center;

        @media (max-width: $break-md-max) {
          flex-direction: column;
          align-items: flex-start;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 15px;
        }
      }

      .approval-step-col {
        padding: 0 10px;
        display: flex;
        align-items: center;

        &:nth-child(1) {
          width: 100px;
          max-width: 100px;
          min-width: 50px;
        }
        &:nth-child(2) {
          flex: 1;
        }
        &:nth-child(3) {
          max-width: 322px;
          width: 322px;
          min-width: 322px;

          @media (max-width: $break-md-max) {
            min-width: auto;

            .select-v2 {
              margin: 4px 4px 4px 0;
            }
          }
        }
        &:nth-child(4) {
          width: 100px;
          max-width: 100px;
          min-width: 100px;
        }

        @media (max-width: $break-md-max) {
          width: 100% !important;
          max-width: 100% !important;
          clear: both;
          padding: 5px 10px;
          flex-wrap: wrap;

          &::before {
            content: attr(data-label);
            font-weight: 500;
            float: left;
            margin-right: 10px;
            font-size: 0.875rem;
          }
        }

        .members {
          display: flex;
          align-items: center;
          margin-right: 10px;
          
          .letter-circle:not(:first-child), 
          .letter-circle-v2:not(:first-child) {
            margin-left: -5px;
          }
        }

        .edit-members-btn {
          width: 34px;
        }

        &--with-dropdown {

          .select-v2 {
            margin-bottom: 0;
            margin-right: 10px;

            &__body_selected {
              padding-right: 24px;
              padding-left: 9px;
              color: $text-color-6;

              p {
                font-size: 0.8125rem;

              }
            }
          }

          // > div {
          //   width: 100%;

          //   @media (max-width: $break-md-max) {
          //     max-width: 200px;
          //   }
          // }
        }

        .remove-step-btn {
          border-radius: 4px;
          font-size: 0.8125rem;
        }
      }

      &__add {
        margin-top: 18px;

        .btn {
          padding-left: 0;

          @media (max-width: $break-md-max) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 13px;
    }
  }
}