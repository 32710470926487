@use '../abstracts/variables.scss' as *;

.views-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;

  a {
    color: $primary-color;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    font-size: 1.125rem;

    svg {
      display: block;
    }

    &.active {
      background-color: $primary-color;
      color: #fff;
    }
  }
}