@use '../abstracts/variables' as *;

.dashboard-inner {
  display: flex;
  flex-grow: 1;
  padding-left: 234px;
  transition: padding-left .3s ease;

  &.remove-pl {
    padding-left: 0;

    .dashboard-view__head {
      width: calc(100% - 48px);
      left: 48px;
    }

    .dashboard-inner__toggle-btn-wrapper {
      left: 48px;
    }
  }

  @media (max-width: $break-lg-max) {
    padding-left: 0;
  }

  &.blured {

    > * {
      filter: blur(4px);
    }
  }

  &__toggle-btn-wrapper {
    position: fixed;
    top: 0;
    left: 281px;
    height: 59px;
    width: 20px;
    z-index: 1002;
    transition: left .3s ease, opacity .3s ease;
  }

  &__toggle-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 14px;
    left: -12px;
    border-radius: 50%;
    background-color: #fff;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
    box-shadow: 0 0 5px rgba($secondary-bg-color, 0.2);
    cursor: pointer;
    transition: background-color .3s ease, color .3s ease, opacity .3s ease;
    opacity: 0;

    @media (max-width: $break-lg-max) {
      display: none;
    }
    
    svg {
      display: block;
      font-size: 1.25rem;
    }

    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }

  &__left {
    position: fixed;
    top: 0;
    left: 48px;
    width: 234px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left .3s ease;
    z-index: 7;

    &.closed {
      left: -186px;

      + .dashboard-inner__right .dashboard-view--documents .dashboard-view__body_table .dashboard-view__head_bottom .document-checked {
        max-width: calc(100vw - 48px);
      }
    }

    &_sidebar {
      background-color: $form-el-bg-color-1;
      padding: 11px 17px 11px 23px;
      border-right: 1px solid $primary-border-color;
      min-height: 100%;
      z-index: 1;
      position: relative;
    }

    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary-bg-color, 0.5);
      display: none;
    }

    h3 {
      margin-bottom: 30px;
      color: $color-20;
      padding-top: 5px;
    }

    .current {
      display: flex;
      align-items: center;
      margin: 30px 0;
      
      p {
        font-size: 0.875rem;
        font-weight: 700;
        color: $tertiary-text-color;
      }

      svg {
        display: block;
        margin-right: 8px;
      }
    }

    .documents {
      width: calc(100% + 23px);
      margin-left: -11px;
      margin-bottom: 49px;

      &__item {
        padding: 4px 10px 4px 11px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: $color-12;
        margin-bottom: 9px;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color .3s ease;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          display: block;
          margin-right: 10px;
        }

        i {
          display: block;
          margin-right: 10px;
          font-size: 1.5rem;
        }

        span {
          margin-left: auto;
          font-size: 0.75rem;
          font-weight: 500;
          letter-spacing: 0.53px;
          color: $nonary-text-color;
        }

        &.selected {
          background-color: $color-23;
          color: $primary-color;

          span {
            color: $primary-color;
          }
        }

        &:hover,
        &.hover {
          background-color: $color-23;
        }

        &.active {

          svg {
            color: $primary-color;
          }
        }
        &.archived {

          svg, i {
            color: $color-21;
          }
        }
        &.deleted {

          svg {
            color: $color-22;
          }
        }
      }
    }

    @media (max-width: $break-lg-max) {
      position: fixed;
      left: -234px;
      top: 0;
      height: 100%;
      z-index: 1004;

      &.opened {
        left: 0;

        .dashboard-inner__left_overlay {
          display: block;
        }
      }
    }
  }

  &__right {
    flex: 1;
  }
}