@use '../abstracts/variables' as *;

.v2-modal {
  min-height: 500px;

  &--auto-height {
    min-height: 1px;
  }

  &__head {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-top: -30px;
    margin-bottom: 26px;
    padding: 30px 55px 17px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 4px 0 rgba($shadow-color-4, 0.5);

    h5 {
      font-weight: 500;
      color: $secondary-text-color;
    }

    .icon-button {
      color: $nonary-text-color;
    }

    &_btn-wrap {
      position: relative;

      .more-dropdown {
        position: absolute;
        top: calc(100% + 4px);
        right: -25px;
        background-color: $primary-light-color;
        box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
        padding: 13px;
        border-radius: 3px;
        white-space: nowrap;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 10px;
          background-color: $primary-light-color;
          content: '';
          z-index: 1;
        }
    
        &::after {
          position: absolute;
          top: -5px;
          right: 30px;
          width: 10px;
          height: 10px;
          background-color: $primary-light-color;
          box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
          transform: rotate(-45deg);
          content: '';
        }

        ul {

          li {
            display: flex;
            align-items: center;
            font-size: 0.8125rem;
            color: $secondary-text-color;
            cursor: pointer;

            svg {
              display: block;
              margin-right: 6px;
            }

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  &__body {
    width: calc(100% + 60px);
    margin-left: -30px;
    padding: 0 24px 0 24px;

    &--no-padding {
      padding: 0;
    }
  }
}