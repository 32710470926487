@use '../abstracts/variables' as *;

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-80 {
  margin-top: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.iframe {
  width: 100%;
  height: calc(100vh - 170px);
  border: 0;
}

.buttons-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin: 0 15px;
  }
}

.loader-wrapper,
.preview-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  &--no-padding {
    padding: 0;
  }
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-hidden-no-height {
  visibility: hidden;
  height: 0;
}

.draggable-ghost-el {
  font-size: 1rem;
  padding: 10px 20px;
  display: block;
  position: absolute;
  top: -1000px;
  left: 0;
  background-color: rgba($primary-color, 1);
  color: $primary-light-color;
  box-shadow: 0 0 100px rgba($shadow-color, 0.9);
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 300px;
  width: auto;
  cursor: all-scroll;
}

.mb-10 {
  margin-bottom: 10px;
}

.no-data-to-show {
  text-align: center;
  padding: 50px 0;
}

.no-contacts-data {
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .button {
      margin: 0 5px 10px;
      height: 36px;
    }
  }
}

.back-to-top {
  font-size: 1.5rem;
  color: rgba($primary-dark-color, 0.2);
  cursor: pointer;
  transition: color .3s ease, opacity .3s ease;
  z-index: -1;
  position: relative;
  left: -9999px;
  opacity: 0;

  &.active {
    z-index: 1;
    opacity: 1;
    left: 0;
  }

  &--2 {
    margin-bottom: 20px;
  }

  &:hover {
    color: $primary-color;
  }
}

.u-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .checkbox {
    margin-right: 5px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.charts-container {

  &--pie {
    width: 250px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.u-position {

  &--relative {
    position: relative;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  p {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  .button {
    margin-left: 20px;
  }
}

.signature-positions {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &__page {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 1rem;
    padding: 0 12px;
    border-radius: 4px;
    margin-right: 11px;
    background-color: #F3F3F3;
    color: #767676;
    text-transform: capitalize;
  }

  .form-input {
    margin-right: 20px;
    margin-bottom: 0 !important;
    width: 100px;
    flex: 0 1 auto !important;
  }

  .button,
  .icon-button {
    align-self: flex-end;
  }
}

.fields-wrapper {
  margin: 20px 0 40px;

  &--2 {
    margin: 0;
  }

  > .button {
    margin-bottom: 20px;
  }
}

.sortable-wrapper {
  padding: 20px 0 0;
}

.sortable-list-item {
  background-color: $primary-light-color;
  border: 1px solid $primary-border-color;
  padding: 8px 13px;
  border-radius: 3px;
  cursor: grab;
  margin-bottom: 10px;
  font-size: 0.875rem;
  user-select: none;
}

.field-positions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  flex-wrap: wrap;

  &__page {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 1rem;
    padding: 0 12px;
    border-radius: 4px;
    margin-right: 11px;
    background-color: #F3F3F3;
    color: #767676;
    text-transform: capitalize;
  }

  .form-input {
    margin-bottom: 0 !important;
    flex: 0 1 auto !important;

    &.label-input {
      margin-right: 13px;
    }

    &.checkbox-page {
      margin-right: 11px;

      input {
        width: 56px;
        height: 36px;
      }
    }
  }

  &__divider {
    height: 22px;
    width: 1px;
    background-color: #ccc;
    margin-right: 17px;
  }

  .checkbox-wrapper {
    margin-bottom: 0;
    margin-right: 17px;
    
    &__field {
      background-color: #F3F3F3;
      border-radius: 4px;
      padding: 0 9px 0 4px;
      display: flex;
      align-items: center;
      height: 36px;
      margin-right: 13px;

      &_fix {
        position: relative;
        z-index: 1;

        &::before {
          content: '';
          background-color: #fff;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 16px;
          height: 16px;
          z-index: -1;
        }
      }

      p {
        color: #2E2F35;
        font-size: 0.875rem;
      }

      .checkbox {

        svg path{
          // background: #fff;
        }
      }
    }
  }

  .button {

    &.label-btn {
      margin-right: 10px;
      height: 36px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &__error {
    padding-top: 5px;
    font-size: 0.75rem;
    color: $secondary-color;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .checkbox-label {
    display: flex;

    .form-input {
      
      input {
        height: 36px;
        width: 169px;
        font-weight: 400;
      }

      &.error {

        input {
          border-width: 1px;
          border-style: solid;
        }
      }
    }

    .edit-btn {
      margin-left: 0 !important;
      margin-right: 13px;
    }
  }

  &__remove {
    margin-left: auto;
  }

  @media (max-width: $break-md-max) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &__page {
      margin-bottom: 13px;
    }

    &__divider {
      height: 1px;
      background-color: transparent;
      margin: 13px 0 0;
    }

    &__remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.u-cursor--pointer {
  cursor: pointer;
}

.u-cursor--default {
  cursor: default !important;
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.status-el {
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 0.5625rem;
  line-height: 1.22;
  font-weight: 500;
  letter-spacing: 0.4px;
  padding: 5px 8px;
  border-radius: 9px;
  text-transform: uppercase;
  background-color: #E6E2E2;
  color: #595858;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &.ready {
    background-color: $tertiary-color;
    color: #0E434D;
  }
  &.validated {
    background-color: $status-dot-color-5;
    color: #583F01;
  }
  &.to_validate {
    background-color: $quinary-color;
    color: $primary-light-color;
  }
  &.empty {
    min-width: 50px;
  }
}

.progress-el {
  width: 100px;
  height: 4px;
  border-radius: 3px;
  background-color: rgba($color-12, 0.2);
  position: relative;

  &__inner {
    background-color: $color-12;
    border-radius: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.u-error {
  font-size: 0.75rem;
  color: $secondary-color;
  padding: 5px 0;
}

.u-custom-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 rgba(0,0,0,0.06);
  // -webkit-transform: translate3d(0, 0, 0); // react-beautiful-dnd didn't work correctly when item was dragged and this was on

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    background: transparent;
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: rgba(0,0,0,0.06);
  }
  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 4px 4px 0 0;
  }
  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
  }
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    background: #c4c4c4;
    border-radius: 4px;
    display: block;
    height: 48px;
  }
}

.u-custom-scrollbar--2 {

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 5px 5px 0 0;
  }
  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 5px 5px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 5px 0 0 5px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 5px 5px 0;
  }
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 5px;
  }
}

.u-custom-scrollbar--3 {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 rgba(0,0,0,0.06);

  &::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    background: transparent;
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 4px 4px 0 0;
  }
  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
  }
  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
  }
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    display: block;
    height: 48px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #c4c4c4;
  }
}

.recharts-legend-item-text {
  font-size: 0.875rem !important;
}

.u-dropdown-list-wrapper {

  ul {

    li {

      a {
        
      }
    }
  }
}

.link-wrapper {
  margin-bottom: 20px;

  a {
    display: inline-flex;
    align-items: center;
    color: $primary-color;
    border-bottom: 1px solid transparent;
    flex-wrap: wrap;
    white-space: normal;

    svg {
      margin-right: 2px;
      font-size: 1rem;
    }
  
    &:hover {
      border-bottom: 1px solid $primary-color;
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  overflow-y: auto;
  height: auto !important;
  max-height: 354px !important;
}

.row {
  display: flex;
  flex-direction: row;

  &.v-centered {
    align-items: center;
  }

  &.centered {
    justify-content: center;
  }
}

.u-truncate-text-wrapper {
  min-width: 0;
  max-width: 100%;
}

.u-truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.u-text-linethrough {
  text-decoration: line-through;
}