@use '../abstracts/variables' as *;

.custom-popover {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: $primary-light-color;
  box-shadow: 0 3px 8px 0 rgba($shadow-color-5, 0.5);
  padding: 5px;
  transform: translateX(-50%);
}