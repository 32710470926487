@use '../abstracts/variables_new.scss' as *;

.dashboard-page-v2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  &__top {
    margin-bottom: 1px;

    .customize-btn-wrapper {
      padding: 15px 0 16px;
      display: flex;
      justify-content: flex-end;
    }

    .widgets-info {
      display: flex;
      width: calc(100% + 20px);

      @media (max-width: $break-md-max) {
        flex-wrap: wrap;
        width: calc(100% + 16px);
      }

      @media (max-width: $break-xs-max) {
        width: 100%;
      }

      &__box {
        width: 25%;
        height: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid $border-color-1;
        border-radius: 8px;
        background-color: #fff;
        padding: 15px 40px 14px 25px;
        position: relative;
        overflow: hidden;

        @media (max-width: $break-md-max) {
          width: calc(50% - 16px);
          margin-right: 16px;
        }

        @media (max-width: $break-xs-max) {
          width: 100%;
          margin-right: 0;
        }

        p {
          font-size: 1.625rem;
          line-height: 1.23;
          color: $text-color-1;
        }

        &_icon {
          position: absolute;
          right: -10px;
          bottom: -17px;
          color: $text-color-11;

          span.svg-icon {
            display: block;
          }
          
          .material-symbols-outlined {
            font-size: 4rem;
          }
        }

        &_placeholder {
          width: 80px;
          height: 10px !important;
          border-radius: 6px;
          margin-top: 14px;

          &.MuiLinearProgress-colorPrimary {
            background-color: $bg-color-1;
          }
        
          .MuiLinearProgress-barColorPrimary {
            background-color: $bg-color-5;
          }
        }

        &--1 {

          p {
            color: $widget-info-color-1;
          }
        }

        &--2 {

          p {
            color: $widget-info-color-2;
          }

          .widgets-info__box_icon {
            bottom: -20px;
          }
        }

        &--3 {

          p {
            color: $widget-info-color-3;
          }

          .widgets-info__box_icon {
            bottom: -8px;
            right: -7px;
          }
        }

        &--4 {

          p {
            color: $widget-info-color-4;
          }

          .widgets-info__box_icon {
            bottom: -23px;
            right: -6px;
          }
        }
      }
    }
  }

  &__widgets {
    flex: 1;
    width: calc(100% + 20px);

    &_inner {
      display: flex;
      flex-wrap: wrap;
    }

    &.sorting {
      // display: block;
      // width: 100%;
    }

    .no-widgets {
      padding: 100px 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color-1;
      border-radius: 8px;
      width: calc(100% - 20px);
      color: $text-color-6;
      font-size: 0.875rem;

      @media (max-width: $break-md-max) {
        width: calc(100% - 16px);
      }

      @media (max-width: $break-xs-max) {
        width: 100%;
      }
    }

    @media (max-width: $break-md-max) {
      width: 100%;
    }
  }
}