.folder-modal {

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form {

    .form-input {
      margin-bottom: 0;
    }

    &__actions {
      padding-top: 10px;
    }
  }
}