@use '../abstracts/variables_new.scss' as *;

.upload-or-drop-v2 {
  width: calc(100% + 30px);
  margin-left: -15px;

  .upload-or-drop__text-1 {
    color: $text-color-6;
  }

  p {
    color: $text-color-6;
  }

  .btn-wrapper {

    button {
      height: 40px;
      padding: 0 18px;
    }
  }
}