@use '../abstracts/variables_new.scss' as *;

.people-widget-content {
  padding: 41px 24px;
  
  &__inner {
    max-width: 100%;
    padding-bottom: 10px;
    overflow-x: auto;
  }

  .team-members {
    display: flex;
    white-space: nowrap;

    &__box {
      border: 1px solid $border-color-3;
      border-radius: 5px;
      width: 185px;
      height: 220px;
      display: flex;
      flex-direction: column;
      padding: 37px 26px 0;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      &_thumb {
        margin: 0 auto 11px;
      }

      &_info {

        p {
          margin-bottom: 26px;
          text-align: center;
        }
      }
    }
  }
}