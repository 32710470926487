@use '../abstracts/variables' as *;

.calendar-wrapper {
  padding-top: 42px;
  // max-width: 850px;

  &__head {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
      padding: 0 8px 0 6px;

      .icon {
        margin-right: 3px;
      }
    }
  }

  .fc {
    background-color: $primary-light-color;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba($shadow-color-7, 0.5);
    padding: 19px 7px;

    .fc-daygrid-body {
      z-index: auto;
    }

    .fc-toolbar-title {
      font-size: 1.125rem;
      color: $primary-dark-color;
      font-weight: 400;
    }

    .fc-header-toolbar {
      padding: 0 162px;
      position: relative;
      justify-content: center;
      min-height: 25px;
      margin-bottom: 26px;

      .fc-toolbar-chunk:last-child {
        position: absolute;
        top: 0;
        right: 0;
      }

      .fc-button {
        background-color: $primary-bg-color;
        height: 25px;
        font-size: 0.8125rem;
        line-height: 15px;
        font-weight: 500;
        color: $secondary-text-color;
        border: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        padding: 0 11px;
        margin-left: 7px;
        outline: 0;
        transition: background-color .3s ease;

        &:focus {
          box-shadow: 0 0 0;
        }

        &:hover {
          background-color: darken($primary-bg-color, 5);
        }

        &:active {
          background-color: darken($primary-bg-color, 10);
        }
      }

      .fc-prev-button,
      .fc-next-button {
        width: 29px;
        padding: 0;
      }
    }

    .fc-scrollgrid {
      border: 0;

      thead {
        
        td {
          border: 0;
        }
        
        .fc-col-header {
          border: 0;

          tbody {
            border: 0;

            tr {

              th,
              .fc-col-header-cell {
                border: 0;
                font-size: 0.75rem;
                font-weight: 400;
                text-transform: uppercase;
                color: $nonary-text-color;
              }
            }
          }
        }

        .fc-col-header-cell {

          > div {
            padding-bottom: 4px;
          }
        }
      }

      tbody {

        .fc-col-header {
          border: 0;

          tbody {
            border: 0;

            tr {

              th,
              .fc-col-header-cell {
                border: 0;
                font-size: 0.75rem;
                font-weight: 400;
                text-transform: uppercase;
                color: $nonary-text-color;
              }
            }
          }
        }

        td {
          border-color: transparent;
        }

        table {
          border-collapse: separate;

          tr {

            .fc-day {
              border: 0;
              padding-bottom: 6px;
              
              > div.fc-daygrid-day-frame {
                margin: 3px;
                background-color: $primary-bg-color;
                border-radius: 3px;
              }

              &.fc-day-today {
                background-color: transparent;

                > div {
                  background-color: $denary-color;
                }
              }

              .fc-daygrid-day-top {
                flex-direction: row;
                align-items: center;

                .fc-daygrid-day-number {
                  font-size: 0.9375rem;
                  font-weight: 500;
                  color: $secondary-text-color;
                  padding: 10px;
                  display: flex;
                  align-items: center;

                  .number-of-alerts {
                    font-size: 0.75rem;
                    font-weight: 400;
                    color: $secondary-text-color;
                    margin-left: 5px;
                  }
                }

              }

              .fc-daygrid-day-events {
                padding: 2px;

                .fc-daygrid-event {
                  background-color: $primary-light-color;
                  border: 0;
                  box-shadow: 0 1px 1px 0 rgba($shadow-color-7, 0.5);
                  font-size: 0.75rem;
                  padding: 4px 6px;
                  cursor: pointer;
                  
                  .fc-event-title {
                    color: $secondary-text-color !important;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
          
        }
      }
    }
  }
}