@use '../abstracts/variables' as *;
@use '../abstracts/mixins.scss' as *;

.actions-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primary-light-color;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 10px 0 rgba(#A7A6A6, 0.5);
  white-space: nowrap;
  width: 240px;
  z-index: 9999;

  &--hide {
    display: none;
  }

  &--2 {
    top: calc(100% + 5px);
  }

  &--wider {
    width: calc(100% + 20px);
    left: -10px;
  }

  &--3 {
    min-height: 100%;
  }

  &--no-padding {
    padding: 0;

    .actions-dropdown__body {
      padding: 0;
      margin-left: 0;
      width: 100%;
    }
  }

  &.fixed {
    position: fixed;
  }

  &__head {
    position: relative;
    text-align: center;
    padding: 0 18px 10px;
    border-bottom: 1px solid #E3F7E7;
    margin-bottom: 9px;

    p {
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1.2;
      color: #5D6C85;
      white-space: normal;
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.125rem;
      color: #767676;
      cursor: pointer;

      &.back {
        right: auto;
        left: 0;
        font-size: 1.25rem;
      }
    }
  }

  &__body {
    max-height: 500px;
    overflow-y: auto;
    width: calc(100% + 10px);
    margin-left: -5px;
    padding: 0 5px;

    ul {

      li {
        position: relative;
        margin-bottom: 8px;

        .divider {
          width: 100%;
          height: 1px;
          background-color: #f2f2f2;
        }
        
        .link {
          
          a,
          > span {
            display: flex;
            align-items: center;
            background-color: #F5F6F8;
            border-radius: 5px;
            padding: 8px 11px;
            min-height: 40px;
            font-size: 0.9375rem;
            font-weight: 400;
            color: #1B1D24;
            transition: background-color .3s ease, color .3s ease;
            white-space: normal;
  
            &:hover {
              background-color: darken(#F5F6F8, 5);
            }

            span.icon-custom {
              min-width: 32px;

              i {
                font-size: 1.25rem;
              }
            }
  
            svg,
            i {
              font-size: 1.5rem;
              display: block;
              margin-right: 8px;
              color: #000;
            }

            &.delete {

              &:hover {
                background-color: $bg-color-20;
                color: $text-color-22;
              }
            }
          }

          > span {
            background-color: #ddd;
            color: #fff;

            &:hover {
              background-color: #ddd;
              cursor: not-allowed;
            }
          }

          &--small {

            a, > span {
              font-size: 0.875rem;
              padding: 4px 8px;
              min-height: auto;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .attach-a-link {
          padding-top: 10px;

          p {
            font-size: 0.9375rem;
            font-weight: 500;
            line-height: 1.2;
            color: #45536A;
            margin-bottom: 8px;
          }

          .form-input {
            margin-bottom: 10px;
          }

          .button {
            font-size: 0.9375rem;
            color: #1B1D24;
            background-color: #F5F6F8;

            &:hover {
              background-color: darken(#F5F6F8, 5);
            }
          }
        }
      }
    }

    .document-lists {

      p {
        font-size: 0.875rem;
        cursor: pointer;
        width: calc(100% + 20px);
        margin-left: -10px;
        padding: 8px 10px;
        color: #162A4E;

        &.current {
          color: #5D6C85;
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

        &:hover {
          background-color: #F5F6F8;
        }
      }
    }

    .chart-type-list {

      li {
        cursor: pointer;
        color: #53617D;
        font-size: 0.875rem;
        padding: 5px;
        border-radius: 2px;
        margin-bottom: 4px;

        &:hover,
        &.active {
          background-color: #d7dbe3;
        }
      }
    }

    .btn-wrapper {
      margin-top: 10px;
    }

  }

  &.delete-list {

    .actions-dropdown__body {

      p {
        font-size: 0.875rem;
        white-space: normal;
        margin-bottom: 10px;
      }
    }

  }

  &.doc-row-statuses {
    padding-left: 0;
    padding-right: 0;

    ul {

      li {
        display: flex;
        justify-content: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 50px;
          cursor: pointer;

          &.empty {
            width: 100%;
            border-radius: 0;
            height: 21px;
            background-color: #f2f2f2;
          }
        }

        @include status;
      }
    }
  }

  &.add-documents-dropdown {
    padding: 10px;
    transform: translateX(-10px);

    ul:not(.add-documents-dropdown__menu) {

      li {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 5px;
        cursor: pointer;
        margin-bottom: 0;
        transition: background-color .3s ease, color .3s ease;
        white-space: normal;
        border-radius: 3px;

        &:hover {
          background-color: $primary-bg-color;
          color: $primary-color;

          svg, i {
            color: $primary-color;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg, i {
          display: block;
          font-size: 1.25rem;
          color: $quinary-text-color;
          transition: color .3s ease;
        }

        i {
          font-size: 0.875rem;
        }
      }
    }
  }

  &.more-actions {

    .dropdown {
      background-color: $primary-light-color;
      border-radius: 3px;
      border: 1px solid $senary-border-color;
      padding: 10px 0;
      z-index: 1;

      li {
        margin-bottom: 0;
        
        a {
          font-size: 0.8125rem;
          display: flex;
          align-items: center;
          color: $nonary-text-color;
          padding: 5px 10px;
          transition: background-color .3s ease, color .3s ease;
          max-width: 100%;
          white-space: normal;

          span.empty {
            width: 20px;
          }

          &:hover {
            background-color: $tertiary-bg-color;
            color: $primary-color;

            span {
              color: $primary-color;
            }

            &.delete {
              background-color: $bg-color-20;
              color: $text-color-22;
              
              span {
                color: $text-color-22;
              }
            }
          }
          
          span {
            font-weight: 700 !important;
            transition: color .3s ease;
            display: block;
            white-space: normal;
          }

          svg, i {
            font-size: 1.25rem;
            margin-right: 1px;
          }

          &.rotate-180 {

            svg, i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &--3 {

      .dropdown {

        li {

          a {

            svg,
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &.folders-popup-dropdown {

    .folders-popup {
      position: static;
      transform: translateX(0);
      box-shadow: none;

      li {
        margin-bottom: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  @include moreActionsDropdown;

  &__body {

    .more-actions,
    .more-folders {
      position: static;
    }
  }

  &.wrap-items {
    white-space: normal;
  }
}