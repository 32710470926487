@use '../abstracts/variables_new.scss' as *;

.letter-circle-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $bg-color-1;
  color: $primary-color;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;

  &.text-lg {
    font-size: 1.875rem;
  }

  &--1 {
    background-color: $lc-bg-color-1;
    color: $lc-text-color-1;
  }
  
  &--2 {
    background-color: $lc-bg-color-2;
    color: $lc-text-color-2;
  }
  
  &--3 {
    background-color: $lc-bg-color-3;
    color: $lc-text-color-3;
  }
  
  &--4 {
    background-color: $lc-bg-color-4;
    color: $lc-text-color-4;
  }
  
  &--5 {
    background-color: $lc-bg-color-5;
    color: $lc-text-color-5;
  }
  
  &--6 {
    background-color: $lc-bg-color-6;
    color: $lc-text-color-6;
  }
  
  &--7 {
    background-color: $lc-bg-color-7;
    color: $lc-text-color-7;
  }
  
  &--8 {
    background-color: $lc-bg-color-8;
    color: $lc-text-color-8;
  }

  &--9 {
    background-color: $bg-color-1;
    color: $primary-color;
  }
}