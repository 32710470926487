@use '../abstracts/variables' as *;

.filters {
  margin-bottom: 47px;

  &--contacts {

    h6 {
      padding-right: 30px;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
          display: block;
          color: #767676;
        }

        &:hover {

          svg {
            color: #333;
          }
        }
      }
    }
  }

  h6 {
    margin-bottom: 12px;
    color: $color-12;
    letter-spacing: 1px;
  }

  &__list {
    position: relative;

    &.dates .filters__list_item:hover, 
    &.dates .filters__list_item.f-active {
      // border-color: $tertiary-bg-color;
    }

    &_item {
      font-size: 0.8125rem;
      margin-bottom: 4px;
      color: $color-12;
      padding: 4px 7px 4px 12px;
      width: calc(100% + 19px);
      margin-left: -12px;
      border-radius: 3px;
      border: 1px solid transparent;
      transition: background-color .3s ease;

      &:hover,
      &.f-active {
        // background-color: $primary-light-color;
        background-color: #ebecf0;
        
        > span {
          color: $secondary-text-color;
        }
      }
      
      &.f-active {
        color: $primary-color;

        .icon {
          color: inherit;
        }
      }
      
      > div:not(.dropdown):not(.folders-popup):not(.dropdown-el):not(.item-inner):not(.dropdown-wrapper) {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
      
      &:last-child {
        margin-bottom: 0;
      }

      span.text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
      }
      
      span.icon {
        font-size: 1.5rem;
        color: $color-24;
        margin-right: 12px;
        transition: color .3s ease;

        svg {
          display: block;
        }
      }

      &-menu {
        position: absolute;
        // top: 100%;
        left: 18px;
        max-width: 175px;
        background-color: $primary-light-color;
        // box-shadow: 0 0 8px rgba($shadow-color, 0.5);
        border-radius: 3px;
        margin-top: 5px;

        li {
          padding: 8px;
          color: $nonary-text-color;
          font-size: 0.875rem;
          transition: color;
          cursor: pointer;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
    
          &:hover {
            color: $primary-color;
          }
        }
      }

      &--2 {
        padding-right: 30px;
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;

        .clickable {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
  
        &.active,
        &:hover {
          // background-color: #fff;
          // border-color: #f3f3f3;
          cursor: pointer;
          background-color: #ebecf0;
        }
        
        &.active {
          color: $primary-color;

          .icon svg,
          .icon svg:hover {
            color: inherit;
          }
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          z-index: 2;

          svg {
            display: block;
            color: #767676;
          }

          &:hover {

            svg {
              color: #333;
            }
          }
        }
      }
    }


    &.folders {

      > li > .dropdown-wrapper {

        .folders-popup {
          left: calc(100% + 18px) !important;
        }
      }

      li.filters__list_item {
        // display: flex;
        // align-items: center;
        // position: relative;
        // width: calc(100% + 36px);
        // margin-left: -18px;
        // padding: 4px 18px;
        margin-bottom: 4px;
        padding-top: 0;
        padding-bottom: 0;
        // transition: background-color .3s ease;

        &:hover {
          background-color: transparent !important;
  
          > span {
            color: inherit;
          }
        }

        &.active {

          > .item-inner {
            // background-color: $primary-light-color;
            // border-color: $tertiary-bg-color;
            min-height: 35px;
            background-color: #ebecf0;
            color: $primary-color;
            border-color: transparent;

            p {
              font-weight: 700;

              &.all {
                padding: 6px 0;
              }
            }

            .icon-right,
            .icon-left,
            .icon-arrow {
              color: $primary-color;
            }
          }
        }

        .dropdown-wrapper {
          position: relative;
          width: 100%;
          height: 1px;
        }

        .filters__list_item {
          margin-bottom: 0;
          padding: 0;
          margin-left: 0;
          width: 100%;

          .item-inner {
            width: calc(100% + 10px);
            margin-left: -5px;
            padding-left: 5px;
            padding-right: 5px;
            
            &--no-subfolders {
              padding-left: 29px;
            }
          }

          .dropdown {
            right: -10px;
          }

          .folders-dropdown {
            left: calc(100% - 42px);
          }
        }

        .item-inner {
          display: flex;
          align-items: center;
          position: relative;
          padding: 4px 7px 4px 12px;
          width: calc(100% + 19px);
          margin-left: -12px;
          // width: calc(100% + 36px);
          // margin-left: -18px;
          border: 1px solid transparent;
          border-radius: 3px;
          transition: background-color .3s ease;

          &--no-subfolders {
            padding-left: 42px;
          }

          &:hover {
            // background-color: $primary-light-color;
            // border-color: $tertiary-bg-color;
            background-color: #ebecf0;
          }
          
          &.hover {
            background-color: darken($tertiary-bg-color, 10);
          }
        }

        span.icon-arrow {
          color: $quinary-text-color;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          top: -2px;

          .right-icon {
            display: block;
          }
          
          .drop-down-icon {
            display: none;
          }

          &:hover {
            background-color: $tertiary-bg-color;
          }

          &.active {

            .drop-down-icon {
              display: block;
            }

            .right-icon {
              display: none;
            }
          }
        }

        .folders-dropdown {
          position: absolute;
          top: calc(100% + 10px);
          left: calc(100% - 54px);
        }

        .folders-popup {
          top: 0;
          left: 5px;
          bottom: auto;
          transform: translateX(0);
  
          &::after {
            top: auto;
            bottom: 100%;
            left: 24px;
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
        
            @media (max-width: $break-md-max) {
              left: 24px;
              right: auto;
            }
          }
        }
        
        .icon-left {
          color: $nonary-text-color;
          margin-right: 10px;
        }

        p {
          cursor: pointer;
          display: flex;
          align-items: center;
          min-width: 0;
          max-width: 100%;

          &.all {
            padding: 6px 0;
          }

          span.name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        
        .icon-right {
          margin-left: auto;
          color: $nonary-text-color;
          cursor: pointer;

          .edit-icon {
            font-size: 1.25rem;
          }
        }

        .dropdown {
          background-color: $primary-light-color;
          min-width: 122px;
          border-radius: 3px;
          padding: 10px 0;
          position: absolute;
          top: calc(100% + 10px);
          right: 4px;
          z-index: 10;
          box-shadow: 0 2px 4px rgba($shadow-color-6, 0.5);

          &::after {
            bottom: 100%;
            left: 80%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
            margin-left: -6px;
          }

          &.category-actions-dropdown {

            &::after {
              left: auto;
              right: 10px;
            }
          }

          p {
            display: flex;
            align-items: center;
            color: $nonary-text-color;
            cursor: pointer;
            font-weight: 700;
            padding: 5px 11px;
            transition: color .3s ease, background-color .3s ease;

            svg, i {
              font-size: 1.25rem;
              color: $nonary-text-color;
              transition: color .3s ease;
            }

            span {
              width: 24px;
              margin-right: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &:hover {
              background-color: $tertiary-bg-color;
              color: $primary-color;

              svg {
                color: $primary-color;
              }

              &.delete {
                background-color: $bg-color-20;
                color: $text-color-22;

                svg {
                  color: $text-color-22;
                }
              }
            }
          }
        }

        .subfolder {
          width: 100%;
          display: block;
          padding: 2px 0 2px 10px;
        }

        p.no-folders {
          padding: 5px 0;
          text-align: center;
          display: block;
        }
      }
    }
  }
}