@use '../abstracts/variables_new.scss' as *;

.task-widget-content {
  padding: 22px 0;

  &__progress {
    display: flex;
    align-items: center;
    padding: 0 22px;
    margin-bottom: 10px;

    .progress-num {
      font-size: 0.875rem;
      line-height: 1.285;
      letter-spacing: 0.2px;
      color: $primary-color;
      font-weight: 500;
      margin-right: 7px;
    }

    .progress {
      flex: 1;
      height: 8px;
      border-radius: 4px;
      background-color: $bg-color-5;
      position: relative;

      &__inner {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary-color;
        border-radius: 4px;
        height: 100%;
      }
    }
  }

  &__list {

    .task-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      padding: 0 22px;

      &__left {
        display: flex;
        align-items: center;

        &_status {
          display: block;
          width: 14px;
          min-width: 14px;
          height: 14px;
          border-radius: 4px;
          background-color: #fff;
          border: 2px solid rgba($text-color-3, 0.4);
          margin-right: 14px;
          overflow: hidden;
          position: relative;
          cursor: pointer;

          &.completed {
            background-color: $primary-color;
            border-color: $primary-color;
          }

          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        p {
          font-size: 0.875rem;
          letter-spacing: 0.2px;
          line-height: 1.285;
          color: $text-color-6;
          cursor: pointer;

          &.completed {
            text-decoration: line-through;
            color: $text-color-4;
          }
        }
      }

      &__right {

        &_default {
          display: flex;
          align-items: center;
        }

        &_assigned-to {

          .letter-circle {
            font-size: 0.875rem;
            font-weight: 500;
            background-color: $highlighted-color-5-light;
            color: $highlighted-color-5;
          }
        }

        &_date {
          margin-left: 9px;
          font-size: 0.8125rem;
          font-weight: 500;
          letter-spacing: 0.19px;
          line-height: 1.384;
          white-space: nowrap;

          &.already-passed {
            color: $danger-color;
          }

          &.done {
            color: $highlighted-color-1;
          }

          &.current-date {
            color: $highlighted-color-3;
          }
        }

        &_hover {
          display: flex;
          align-items: center;
          display: none;

          a {

            &.doc {
              display: flex;
              align-items: center;
              height: 25px;
              border: 1px solid $border-color-1;
              padding: 0 7px 0 2px;
              border-radius: 3px;
              color: $text-color-6;
  
              span.text {
                font-size: 0.75rem;
                margin-left: 2px;
                display: block;
              }
            }

            &.delete {
              margin-left: 6px;
              color: $danger-color;
            }
          }
        }
      }

      &:hover {
        background-color: $bg-color-1;

        .task-item__right_hover {
          display: flex;
        }

        .task-item__right_default {
          display: none;
        }
      }
    }
  }
}