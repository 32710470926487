@use '../abstracts/variables' as *;

.MuiToggleButton-root {
  color: $secondary-dark-color !important;
  font-size: 0.8rem;
}

.MuiToggleButton-root.Mui-selected {
  background-color: $primary-color-light !important;
  color: $primary-color !important;
}

.partner-note-container {
  padding: 15px 20px;
  background-color: $primary-color-light;
  border-radius: 4px;
  margin-bottom: 10px;
  display: inline-block;
  p {
    margin-top: 5px;
  }
}

.features-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.label-small {
  font-size: 0.8rem;
}

.checkout-dialog {
  padding: 0;
  position: relative;
}

.dialog-body {
  min-width: 450px;
  background-color: #FFF;
  border-radius: 4px;
}

.checkout-content {
  padding: 20px 20px 40px 20px;
}

.checkout-header {
  padding: 50px 20px 20px 20px;
  background-color: $primary-color;
  border-radius: 4px 4px 0 0;
  color: #FFF;
}

.cancel-header {
  padding: 20px 20px 20px 20px;
  background-color: $danger-color;
  border-radius: 4px 4px 0 0;
  color: #FFF;
}

.checkout-close-button {
  position: absolute !important;
  top: -14px;
  left: -14px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $octonary-bg-color !important;
  cursor: pointer;
}

.MuiPaper-root {
  overflow: visible !important;
}

.card-input {
  margin-top: 10px;
  max-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #DFE1E6;
  background-color: #FAFBFC;
  border-style: solid;
}

.card-input:hover {
  border: 1px solid $primary-color;
  // border-color: $primary-color;
}

.card-error-label {
  font-size: 13px;
  color: #eb1c26;
}

.hint {
  font-size: 13px;
  color: #767676;
}

.subscription-section-header {
  padding: 30px;
  color: $primary-light-color;
  border-radius: 4px;
  &.active {
    background-color: $quinary-color;
  }
  &.past_due {
    background-color: $secondary-color;
  }
  &.incomplete {
    background-color: $quaternary-color;
  }
}

.invoice-row {
  width: fit-content;
  // max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 4px;
  overflow: hidden;
  min-height: 50px;
  margin-top: 15px;
  flex-wrap: wrap;
  // row-gap: 15px;
  background-color: $senary-light-color;
  // display: grid;
  // grid-template-columns: 1fr minmax(100px, 3fr) minmax(100px, 2fr) minmax(100px, 2fr) minmax(100px, 1.5f) minmax(100px, 2fr);
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));;
}

.invoice-col {
  font-size: 0.85rem;
  min-height: 50px;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  &__status {
    color: $primary-light-color;
    // flex-grow: 0.5;
    width: 3.4vw;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
    min-width: 80px;
    max-width: 200px;
    font-size: small;

    &.paid {
      background-color: $quinary-color;
    }
    &.draft {
      background-color: $tertiary-color-light;      
    }
    &.open {
      background-color: $quaternary-color;
    }
    &.void {
      background-color: $nonary-dark-color;
    }
  }

  &__description {
    // background-color:blue;
    // flex-grow: 3;
    width: 15vw;
    justify-content: flex-start;
    padding-left: 20px;
    min-width: 150px;
    max-width: 300px;
  }

  &__number {
    // background-color:aqua;
    // flex-grow: 2;
    width: 10vw;
    justify-content: flex-start;
    min-width: 120px;
    max-width: 240px;
  }

  &__date {
    // background-color: #eb1c26;
    // flex-grow: 1;
    width: 8vw;
    justify-content: flex-end;
    min-width: 120px;
  }

  &__amount {
    // background-color:#DFE1E6;
    // flex-grow: 1;
    width: 6vw;
    justify-content: flex-end;
    min-width: 120px;
    max-width: 240px;
  }

  &__actions {
    // background-color: cadetblue;
    // flex-grow: 2;
    width: 20vw;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 10px;
    min-width: 250px;
    max-width: 400px;
  }

  .actions-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    row-gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: end;
  }
}

h6 {
  span {
    font-size: 0.95rem;
    font-weight: bold;
  }
}

.upcoming-invoice-header {
  padding: 30px 20px 20px 20px;
  background-color: $primary-color;
  border-radius: 4px 4px 0 0;
  color: #FFF;

  h4 {
    color: #FFF;
  }
}

.upcoming-invoice-content {
  padding: 15px 30px 30px 30px;
  h4 {
    margin-top: 10px;
  }
  h3 {
    margin-left: 30px;
    margin-top: 10px;
  }
  .description-item {
    margin-left: 30px;
    margin-top: 10px;
    line-height: 1.6rem;
  }
}

.card-block {
  border-radius: 4px;
  margin-top: 15px;
  border: 0.5px solid $primary-border-color;
  display: flex;
  flex-direction: column;
  width: 400px;
}

.card-row {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.card-logo {
  width: 100px;
  height: auto;
}

.card-info {
  padding-left: 25px;
  padding-top: 5px;
}

.card-title {
  font-size: 15px;
  font-weight: 500;
}

.card-expiry {
  font-size: 12px;
  color: #767676;
  margin-top: 7px;
}

.card-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  border-top: 0.5px solid $primary-border-color;
}