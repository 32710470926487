@use '../abstracts/variables' as *;

.react-datepicker {
  background-color: $primary-light-color;
  padding: 0 30px;
  box-shadow: 0 1px 3px 0 rgba($primary-dark-color, 0.23);
  border: 0;
  border-radius: 3px;
  z-index: 99;

  &__triangle {
    display: none;
  }

  &__navigation {
    outline: 0;
    fill: #34353B;

    &--previous {
      left: 44px;
      top: 26px;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/images/arrow_back_ios_new-24px.svg');
      background-size: 100% 100%;
      border: 0;
    }

    &--next {
      right: 44px !important;
      top: 26px;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/images/arrow_forward_ios_new-24px.svg');
      background-size: 100% 100%;
      border: 0;
    }
  }

  &__header {
    background-color: $primary-light-color;
    border: 0;
    padding-top: 30px;
    margin-bottom: 15px;
    border-radius: 3px 3px 0 0;

    &--time {
      padding: 0;
      margin-top: 10px;
      font-family: $ff-primary;
    }
  }

  &__current-month {
    text-transform: uppercase;
    font-weight: 500;
    font-family: $ff-primary;
    font-size: 1rem;
  }

  &__day-names {
    padding-top: 23px;
  }

  &__day-name {
    font-family: $ff-secondary;
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 1.273;
    color: $quaternary-light-color;
    opacity: 0.5;
    width: 34px;
    outline: 0;
    text-transform: capitalize;
  }

  &__month-container {
    float: none;
  }

  &__month {
    margin: 0;
    padding: 0 0 30px;
  }

  &__week {

  }

  &__day {
    font-size: 0.875rem;
    font-family: $ff-primary;
    font-weight: 400;
    color: $secondary-text-color;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    transition: background-color .3s ease;
    
    &:hover {
      border-radius: 100%;
      background-color: $primary-bg-color;
    }

    &--keyboard-selected {
      background-color: transparent;

      &:hover {
        background-color: $primary-bg-color;
      }
    }

    &--today {
      border-radius: 100%;
      background-color: $primary-bg-color !important;

      &:hover {
        background-color: $primary-color-light-2;
      }
    }

    &--selected {
      background-color: $primary-color !important;
      color: $primary-light-color !important;
      
      &:hover {
        background-color: $primary-color;
      }
    }

    &--outside-month {
      text-indent: -9999px;
      visibility: hidden;
    }

    &--in-range {
      background-color: rgba($primary-color, 0.5);
      color: $primary-light-color;
    }

    &--in-selecting-range {
      background-color: rgba($primary-color, 0.3);
      color: $primary-light-color;

      &:hover {
        color: $secondary-text-color;
      }
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__time-container {
    float: none;
    border: 0;
    width: 100%;
    clear: both;
    padding-bottom: 20px;
    margin-top: -20px;
    max-width: 274px;
  }

  &__time-box {
    width: 100% !important;
    overflow: visible !important;
  }

  &__time-list {
    height: 120px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $ff-primary;
      border-radius: 3px;
    }

    &-item--disabled {
      display: none !important;
    }

    &-item--selected {
      background-color: $primary-color !important;
      font-weight: 400 !important;
    }
  }

  &__input-time-container {

    input {
      width: 85px !important;
    }
  }

  &.calendar {
    padding: 0;
    box-shadow: none; 
    width: 100%;

    .react-datepicker__header {
      padding-top: 10px;
      margin-bottom: 5px;
    }

    .react-datepicker__current-month {
      font-size: 0.875rem;
    }

    .react-datepicker__day {
      font-size: 0.75rem;
      width: auto;
      height: auto;
      border-radius: 0;
      flex: 1;

      &-names {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 5px;
      }
    }

    .react-datepicker__month {
      padding-bottom: 5px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__week {
      display: flex;
      align-items: center;
    }

    .react-datepicker__navigation {

      &--next,
      &--previous {
        width: 16px;
        height: 16px;
        top: 10px;
      }

      &--next {
        right: 0 !important;
      }

      &--previous {
        left: 0 !important;
      }
    }
  }
}