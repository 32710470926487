@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.line-loader {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  overflow: hidden;
  z-index: 100;
  
  div {
    background-color: $primary-color;
    height: 100%;
    width: 30%;
    position: absolute;
    left: 0;
    top: 0;
    animation: moveRight 2s ease infinite;

    &:nth-child(2) {
      left: -120%;
      animation: moveRight2 2s ease infinite;
    }
  }
}