.detail-view-sidebar {

  .sidebar-section {
    margin-bottom: 52px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {

      h5 {
        font-size: 0.8125rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 14px;
        color: #162A4E;
        opacity: 0.67;
      }
    }

    &__list {

      .sidebar-section__dropdown-wrapper {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        height: 32px;
        border-radius: 3px;
        color: #162A4E;
        background-color: #EBEDF0;
        padding: 0 8px;
        transition: background-color .3s ease;
        margin-bottom: 8px;
        
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          background-color: darken(#EBEDF0, 10);
        }

        &:last-child {
          margin-bottom: 0;
        }

        svg, i {
          display: block;
          font-size: 1.5rem;
          color: #48494D;
          margin-right: 7px;
        }

        i.custom-icon-create-outlined {
          font-size: 1.25rem;
          width: 24px;
          position: relative;
          left: 3px;
        }
      }
    }
  }
}