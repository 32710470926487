@use '../abstracts/variables.scss' as *;

.pdf-share {

  &__body {
    padding: 15px 15px 85px;
    min-width: 930px;

    .close-comment-mode {
      display: none;
    }

    &--comments {
      position: relative;

      .top-border,
      .bottom-border,
      .left-border,
      .right-border {
        background-color: $secondary-color;
        position: fixed;
        z-index: 6;
      }

      .top-border,
      .bottom-border {
        width: 100%;
        height: 5px;
        left: 0;
        top: 0;
      }

      .bottom-border {
        bottom: 60px;
        top: auto;
      }

      .left-border,
      .right-border {
        width: 5px;
        height: 100%;
        left: 0;
        top: 0;
      }

      .right-border {
        right: 0;
        left: auto;
      }

      .close-comment-mode {
        position: fixed;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: $secondary-color;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 7;
        cursor: pointer;
        
        svg {
          font-size: 1.25rem;
        }
      }
    }

    .preview {
      padding: 15px;
      width: 900px;
      background-color: #ccc;
      margin: 0 auto;
      overflow: hidden;
      
      &__inner {
        position: relative;
        min-height: 1230px;
        background-color: #fff;

        .react-pdf__Document {

          .react-pdf__Page__annotations.annotationLayer {
            background-color: #fff;
          }
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        cursor: crosshair;
        user-select: none;

        .comment {
          position: absolute;
          width: 40px;
          height: 40px;
          transform: translate(-50%, -50%);

          &.active {

            .comment__box {
              background-color: rgba($primary-color, 0.75);
            }
          }
          
          &__box {
            width: 40px;
            height: 40px;
            background-color: rgba($primary-color, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 50%;
            cursor: move;

            &:hover {
              background-color: rgba($primary-color, 0.75);
            }

            .messages-badge {
              position: absolute;
              top: 0px;
              right: 0px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #FF3D65;
              color: #fff;
              font-size: 8px;
              font-weight: 500;
            }
          }


          &__dropdown {
            position: absolute;
            top: calc(100% + 15px);
            left: 0;
            width: 420px;
            box-shadow: 0 0 10px rgba($primary-dark-color, 0.2);
            border-radius: 4px;
            cursor: default;
            background-color: #fff;
            padding: 25px;

            .close-btn {
              position: absolute;
              top: 5px;
              right: 5px;
              width: 20px;
              height: 20px;

              svg {
                font-size: 0.875rem;
              }
            }

            h5 {
              margin-bottom: 10px;
              font-weight: 400;
              font-size: 0.875rem;
              color: #172b4d;
            }

            textarea {
              margin-bottom: 10px;
              padding: 8px;
              min-height: 80px;
              max-height: 80px;
              resize: none;
            }

            .btn-wrapper {
              display: flex;
              justify-content: flex-end;

              &--2 {
                display: flex;
                align-items: center;

                .button {
                  margin-right: 10px;
                }
              }
            }

            .messages-wrapper {
              max-height: 180px;
              overflow-y: auto;
              margin-bottom: 10px;

              .msg {
                position: relative;
                padding-left: 46px;
                margin-bottom: 20px;

                &__thumb {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 36px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #ebecf0;
                  font-size: 0.875rem;
                  border-radius: 50%;
                }

                &__info {

                  &_head {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 5px;

                    p {

                      &.author {
                        font-size: 1rem;
                        margin-right: 5px;
                      }

                      &.time {
                        font-size: 0.625rem;
                        color: #949ba7;
                      }
                    }

                    .actions {
                      margin-left: auto;
                      display: flex;
                      align-items: center;

                      button {
                        border: 0;
                        outline: 0;
                        background-color: transparent;
                        color: #949ba7;
                        cursor: pointer;

                        svg {
                          font-size: 1rem;
                          display: block;
                        }
                      }
                    }
                  }

                  &_body {

                    p {
                      font-size: 0.875rem;

                      span {
                        display: block;
                        min-height: 19px;
                      }
                    }

                    .textarea {
                      margin-right: 5px;
                    }
                  }
                }

                &__confirm {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(255,255,255,0.8);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  p {
                    font-size: 0.75rem;
                    margin-bottom: 5px;
                  }

                  .actions {

                    .button {
                      margin: 0 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__foot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $secondary-bg-color;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      border: 0;
      outline: 0;
      border-top: 5px solid transparent;
      height: 100%;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-light-color;
      margin: 0 10px;
      cursor: pointer;

      svg {
        display: block;
      }

      &.active {
        color: $primary-color;
        border-color: $primary-color;
      }

      &:hover:not(.active) {
        color: #fff;
      }
    }
  }

  &--2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    background-color: $primary-bg-color;
    padding: 15px;
  }

  &__not-found {
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba($primary-dark-color, 0.2);
    border-radius: 20px;
    padding: 50px;
    text-align: center;

    .icon {
      margin-bottom: 10px;
      
      svg {
        font-size: 2rem;
        color: $quaternary-text-color;
      }
    }

    h1 {
      color: $secondary-text-color;
      margin-bottom: 20px;
    }

    p {
      color: $quaternary-text-color;

      a {
        color: $primary-color;
      }
    }
  }

  &__made-in {
    position: fixed;
    z-index: 9999;
    left: 10px;
    bottom: 10px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 40px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #162A4E;
    box-shadow: 0 0 10px rgba(#000, 0.15);
    opacity: 0.7;
    transition: opacity .3s ease;

    img {
      max-width: 24px;
      display: block;
      height: auto;
      margin-right: 8px;
    }

    &:hover {
      opacity: 1;
    }
  }
}