@use '../abstracts/variables_new.scss' as *;

.collection-sidebar {
  width: 243px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 27px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-right: 1px solid $border-color-5;
  display: flex;
  flex-direction: column;

  h1 {
    padding: 0 26px;
    margin-bottom: 46px;
  }

  .documents {
    margin-bottom: 54px;

    &.settings {

      .documents__item {

        .material-symbols-outlined {
          font-size: 1.25rem;
          font-weight: 300;
          margin-right: 0;
        }

        .icon {
          display: flex;
          justify-content: center;
          width: 24px;
          min-width: 24px;
          margin-right: 11px;

          .thumb {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(#000, 0.1);
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }

          .letter-circle-v2 {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            font-size: 0.8125rem;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {

          .material-symbols-outlined {
            color: $text-color-2;
          }
        }
      }
    }

    &__item {
      padding: 0 21px 0 26px;
      display: flex;
      align-items: center;
      color: $text-color-4;
      position: relative;
      margin-bottom: 9px;
      cursor: pointer;
      height: 32px;

      &:last-child {
        margin-bottom: 0;
      }
      
      .material-symbols-outlined {
        font-size: 1.5rem;
        display: block;
        margin-right: 10px;
      }

      span.text {
        font-size: 0.875rem;
        line-height: 1.21;
        display: block;
      }

      span.num {
        font-size: 0.75rem;
        letter-spacing: 0.53px;
        display: block;
        font-weight: 500;
        margin-left: auto;
        padding-left: 5px;
      }

      &:hover {
        
        span.text {
          color: $text-color-1;
        }
      }

      &.selected {

        &::before {
          width: 2px;
          height: 21px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: $primary-color;
          content: '';
        }

        span.text {
          font-weight: 500;
          color: $text-color-1;
        }
      }

      &:nth-child(1) {

        .material-symbols-outlined {
          color: $primary-color;
        }
      }

      &:nth-child(2) {

        .material-symbols-outlined {
          color: $text-color-2;
          font-size: 1.875rem;
          margin-right: 4px;
          position: relative;
          left: -3px;
        }
      }

      &:nth-child(3) {

        .material-symbols-outlined {
          color: $widget-info-color-3;
        }
      }

      &:nth-child(4) {

        .material-symbols-outlined {
          color: $widget-info-color-4;
        }
      }

      &.hover {
        background-color: $bg-color-5;
      }
    }
  }

  .folders,
  .statuses,
  .workspaces {

    h5 {
      padding: 0 26px;

      &.with-action {
        position: relative;
        padding-right: 50px;

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 21px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }

  .statuses {

    ul {

      li {
        width: 100%;
        margin-left: 0;
        padding: 0 26px;
        height: 30px;
        border-radius: 0;

        span {
          background-color: darken($highlighted-color-2-light, 5);
          margin-right: 10px;
        }

        &.active,
        &:hover {
          background-color: $bg-color-2;
        }

        &.active {
          color: $primary-color;
          font-weight: 500;
        }

        &.COMPLETED {

          span {
            background-color: darken($highlighted-color-1-light, 5);
          }
        }

        &.SIGNING_PENDING {

          span {
            background-color: darken($highlighted-color-3-light, 5);
          }
        }
      }
    }
  }

  .credits {
    padding: 0 26px;

    &__num {
      padding: 0 10px;
      border: 1px solid $border-color-5;
      margin-bottom: 10px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;

      .loader-v2 {
        margin-left: 5px;
      }
    }
  }

  .workspaces {

    h5 {
      padding-right: 56px;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 26px;
        cursor: pointer;
      }

      a {
        color: inherit;
      }
    }

    .teams {

      &__item {

        .thumb {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: rgba(#000, 0.1);
          display: block;
          margin-right: 11px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .letter-circle-v2 {
          width: 24px;
          height: 24px;
          font-size: 0.8125rem;
          margin-right: 11px;
        }
      }
    }
  }

  &__foot {
    margin-top: auto;
    border-top: 1px solid $border-color-1;

    a {
      display: flex;
      align-items: center;
      padding: 0 26px;
      height: 46px;
      color: $text-color-4;
      font-size: 0.875rem;
      transition: background-color .3s ease;

      span {
        color: $text-color-2;
        margin-right: 11px;
        display: block;
      }

      &:hover {
        background-color: $bg-color-1;
      }
    }
  }
}