@use '../abstracts/variables' as *;

.custom-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  line-height: 1.285;
  padding: 5px 10px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba($shadow-color-12, 0.4);
  background-color: $secondary-text-color;
  color: $primary-light-color;
  // min-width: 120px;
  opacity: 0;
  transition-property: opacity;
  transform-origin: center center;
  text-align: center;
  backface-visibility: hidden;
  z-index: 9999;
  white-space: nowrap;

  &--multiline {
    white-space: pre-wrap;
  }

  svg.icon {
    font-size: 1rem;
    display: inline-block;
    color: $bg-color-29;
    vertical-align: middle;
  }

  p {
    position: relative;
    z-index: 1;

    span.multi-option {
      display: block;
    }
  }

  .arrow {
    width: 10px;
    height: 10px;
    background-color: $secondary-text-color;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
  }

  &::after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(255,0,0,0);
    border-width: 6px;
    display: none;
  }

  &--top {
    transform: translate(-50%, calc(-100% - 15px)) scale(1);

    &::after {
      top: 100%;
      left: 50%;
      border-top-color: $secondary-text-color;
      margin-left: -6px;
    }

    .arrow {
      top: 100%;
      left: calc(50%);
    }
  }
  &--top-input {
    transform: translate(-50%, calc(-100% - 10px)) scale(1);
  }
  &--bottom {
    transform: translate(-50%, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 50%;
      border-bottom-color: $secondary-text-color;
      margin-left: -6px;
    }

    .arrow {
      bottom: calc(100% - 10px);
      left: calc(50%);
    }
  }
  &--left {
    transform: translate(-15px, -50%) scale(1);

    &::after {
      top: 50%;
      left: 100%;
      border-left-color: $secondary-text-color;
      margin-top: -6px;
    }

    .arrow {
      top: 50%;
      left: 100%;
    }
  }
  &--right {
    transform: translate(15px, -50%) scale(1);

    &::after {
      top: 50%;
      right: 100%;
      border-right-color: $secondary-text-color;
      margin-top: -6px;
    }

    .arrow {
      top: 50%;
      right: calc(100% - 10px);
    }
  }
  &--top-left {
    transform: translate(0, calc(-100% - 15px)) scale(1);
    text-align: left;

    &::after {
      top: 100%;
      left: 0%;
      border-top-color: $secondary-text-color;
      margin-left: 10px
    }
  }
  &--top-right {
    transform: translate(-100%, calc(-100% - 15px)) scale(1);
    text-align: right;

    &::after {
      top: 100%;
      right: 0%;
      border-top-color: $secondary-text-color;
      margin-right: 10px
    }

    .arrow {
      top: 100%;
      right: 20px;
    }
  }
  &--bottom-left {
    transform: translate(0, 15px) scale(1);

    &::after {
      bottom: 100%;
      left: 0%;
      border-bottom-color: $secondary-text-color;
      margin-left: 10px
    }
  }
  &--bottom-right {
    transform: translate(-100%, 15px) scale(1);

    &::after {
      bottom: 100%;
      right: 0%;
      border-bottom-color: $secondary-text-color;
      margin-right: 10px
    }
  }
  &--left-top {
    transform: translate(-15px, 0) scale(1);

    &::after {
      top: 10px;
      left: 100%;
      border-left-color: $secondary-text-color;
    }
  }
  &--left-bottom {
    transform: translate(-15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      left: 100%;
      border-left-color: $secondary-text-color;
    }
  }
  &--right-top {
    transform: translate(15px, 0) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: $secondary-text-color;
    }
  }
  &--right-bottom {
    transform: translate(15px, -100%) scale(1);

    &::after {
      bottom: 10px;
      right: 100%;
      border-right-color: $secondary-text-color;
    }
  }
}