@use '../abstracts/variables.scss' as *;

.new-task-popup {
  position: fixed;
  bottom: 0;
  right: 20px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  border: 1px solid #eee;
  max-width: 500px;
  width: 100%;
  z-index: 199;

  @media (max-width: $break-sm-max) {
    max-width: 300px;
  }

  @media (max-width: $break-xxs-max) {
    max-width: 280px;
  }

  &--minimized {
    max-width: 300px;
    background-color: $primary-color;
    border: 0;
    cursor: pointer;

    @media (max-width: $break-xxs-max) {
      max-width: 240px;
    }

    &:hover {
      background-color: darken($primary-color, 10);
    }

    .new-task-popup__head {
      border: 0;

      p {
        color: #fff;
        max-width: 230px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &_actions {

        .icon-button {
          color: #fff;

          &:hover {
            background-color: rgba(#fff, 0.2);
            color: #fff;
          }
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 8px 16px;

    p {
      font-size: 0.875rem;
    }

    &_actions {
      display: flex;
      align-items: center;

      .icon-button {
        width: 28px;
        height: 28px;
        transition: none;

        svg {
          font-size: 1.25rem;
        }

        &:hover {
          color: #333;
        }
      }
    }
  }

  &__body {
    padding: 16px;
    min-height: 200px;

    .task-name-wrapper {
      margin-bottom: 20px;

      input {
        border: 0;
        font-size: 1.25rem;
        width: 100%;
        display: block;
        outline: 0;
        padding: 0;
      }
    }

    .assigned-to-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 0.875rem;
      position: relative;

      > p {
        margin-right: 6px;
        margin-bottom: 6px;
      }

      .choose {
        margin-right: 6px;
        margin-bottom: 6px;

        > button {
          border: 1px dashed #666;
          background-color: #fff;
          outline: 0;
          border-radius: 12px;
          height: 24px;
          padding: 0 24px;
          line-height: 22px;
          cursor: pointer;
  
          &:hover {
            background-color: #eee;
          }
        }

        &__input {

          &.hide {
            visibility: hidden;
            height: 0;
            width: 0;
            overflow: hidden;
          }

          input {
            width: 100px;
            display: block;
            padding: 3px 0 4px;
            border: 0;
            outline: 0;
          }

          &_suggestions {
            position: absolute;
            top: 26px;
            left: 0;
            background-color: #fff;
            border: 1px solid #eee;
            width: 100%;
            max-height: 150px;
            overflow-y: auto;
            padding: 10px 0;
            border-radius: 4px;

            ul {

              li {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding: 5px 10px;
                cursor: pointer;

                &:hover {
                  background-color: #eee;
                }
              }
            }

            p {
              padding: 0 10px;
            }
          }
        }

        .chosen {
          display: flex;
          align-items: center;
          height: 24px;
          position: relative;
          padding-right: 20px;
          padding-left: 10px;
          background-color: #eee;
          border-radius: 12px;
          font-size: 0.75rem;
          cursor: pointer;

          &:hover {
            background-color: darken(#eee, 5);
          }

          p {
            margin-right: 0;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              display: block;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    padding: 10px 16px;

    &_left {
      display: flex;
      align-items: center;

      button {

        &.icon-btn {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          margin-right: 4px;
          cursor: pointer;
          background-color: transparent;
          border: 0;
          outline: 0;
  
          svg {
            display: block;
            font-size: 1rem;
            color: #666;
          }
  
          &:hover {
            background-color: #eee;
            
            svg {
              color: #333;
            }
          }
        }

        &.text-btn {
          height: 28px;
          padding: 0 10px;
          font-size: 0.75rem;
          cursor: pointer;
          background-color: transparent;
          border: 0;
          outline: 0;
          border-radius: 2px;
          margin-right: 4px;

          &:hover {
            background-color: #eee;
            color: #333;
          }
        }
      }
    }

    &_right {
      margin-left: auto;

      .button {
        height: 36px;
      }
    }
  }
}