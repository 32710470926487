@use '../abstracts/variables.scss' as *;

.shared-templates-page {

  section {
    margin-bottom: 75px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .section-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: 500;
      font-size: 1.5rem;
      color: #030A4E;
    }

    .list {

      &__item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        border: 1px solid #D9E1E4;
        padding: 21px 30px;
        background-color: #fff;
        border-radius: 8px;

        &_name {

          h3 {
            font-weight: 400;
            font-size: 1.125rem;
            color: #030A4E;
            line-height: 1.22;
            margin-bottom: 4px;
          }

          p {
            font-size: 0.75rem;
            line-height: 1.5;
            color: #767676;
          }
        }

        &_actions-1,
        &_actions-2 {
          display: flex;
          align-items: center;
          margin-left: auto;

          @media (max-width: $break-md-max) {
            display: none;
          }

          .fix {
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 15px;
          }

          a {
            color: #bbb;
            font-size: 1.5rem;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 15px;
            transition: color .3s ease;

            &:hover {
              color: $primary-color;
            }

            &.delete {

              &:hover {
                color: $secondary-color;
              }
            }
          }
        }

        &_actions-2 {
          margin-left: 150px;

          @media (max-width: $break-lg-max) {
            margin-left: 50px;
          }
        }

        &_actions-mobile {
          margin-left: auto;
          display: none;

          @media (max-width: $break-md-max) {
            display: block;
          }

          .icon-button {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .no-data {

      p {
        font-size: 0.875rem;
      }
    }
  }
}