@use '../abstracts/variables.scss' as *;

.template-page {

  &__container {
    margin: 0 auto;
    max-width: 940px;
    padding: 0 20px;
  }

  &__edit {
    padding-top: 20px;
    margin-bottom: 30px;

    &_logo {
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
      
      img {
        display: block;
        margin-right: 7px;
      }

      p {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: $ff-tertiary-bold;
        line-height: 34px;
      }
    }

    &_embed {
      display: flex;
      margin-bottom: 30px;

      @media (max-width: $break-md-max) {
        display: block;
      }

      > .button {
        padding-left: 4px;
        align-self: flex-start;
      }

      .embed-wrapper {
        margin-left: auto;
        max-width: 500px;
        width: 100%;

        @media (max-width: $break-md-max) {
          margin-left: 0;
          margin-top: 20px;
          max-width: 100%;
        }

        &__top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;

          svg {
            display: block;
            font-size: 1.25rem;
          }

          p {
            display: flex;
            align-items: center;
            font-size: 0.875rem;

            svg {
              margin-right: 5px;
            }
          }

          .info {
            color: $primary-color;
          }
        }

        &__box {
          background-color: #333;
          color: #ccc;
          padding: 20px;
          border-radius: 4px;
          padding-bottom: 60px;
          position: relative;

          p {
            word-break: break-all;
          }

          .copy-to-clipboard {
            position: absolute;
            bottom: 20px;
            right: 20px;
            cursor: pointer;

            svg {
              display: block;
              color: $primary-color;
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    &_actions {
      display: flex;
      align-items: center;

      @media (max-width: $break-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        font-weight: 500;

        @media (max-width: $break-xs-max) {
          order: 2;
        }
      }

      .actions {
        margin-left: auto;
        display: flex;
        align-items: center;

        .button {
          margin-left: 15px;
        }

        @media (max-width: $break-xs-max) {
          margin-bottom: 20px;
          margin-left: 0;
          order: 1;
          
          .button {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
    }
  }

  &__content-wrapper {

    &--with-border {
      border: 2px solid #eee;
      padding: 10px 30px;
      border-radius: 4px;
      margin-bottom: 20px;

      .template-page__body {
        padding-bottom: 0;
      }
    }
  }

  &__head {
    padding: 20px 0;
    margin-bottom: 50px;

    &_inner {
      display: flex;
      margin-bottom: 20px;

      .logo {
        width: 150px;
        height: 150px;
        background-color: #f2f2f2;
        display: flex;
        align-self: center;
        justify-content: center;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          align-self: center;
        }
      }

      .actions {
        margin-left: auto;
        display: flex;
        align-items: center;

        .button {
          margin-left: 15px;
        }
      }

      @media (max-width: $break-xs-max) {
        flex-direction: column;

        .logo {
          order: 2;
        }

        .actions {
          order: 1;
          margin-bottom: 20px;
        }
      }
    }

    &_info {

      h1 {
        font-weight: 400;
        margin-bottom: 15px;
      }

      p {
        font-weight: 1.125rem;
      }
    }
  }

  &__body {
    padding-bottom: 50px;

    .list-of-templates {

      ul {

        li {
          margin-bottom: 30px;

          h2 {
            font-size: 1.75rem;
            font-weight: 400;
            
            a {
              color: #333;

              &:hover {
                color: $primary-color;
              }
            }
          }

          p {
            font-size: 1.125rem;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .go-back {
    padding-bottom: 20px;
  }
}