@use '../abstracts/variables.scss' as *;

.collection-grid-card {
  width: 270px;
  height: 270px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: background-color .3s ease;

  &:hover {
    background-color: #F9F8F8;

    .collection-grid-card__thumb {

      .more-wrapper {
        opacity: 1;
      }
    }
  }

  &.active {
    border-color: $primary-color;
  }

  &:hover,
  &.active {

    .collection-grid-card__thumb {

      .checkbox-wrapper {
        opacity: 1;
      }
    }
  }

  &__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 190px;
    border-bottom: 1px solid #f3f3f3;
    color: $primary-color;
    position: relative;

    svg {
      display: block;
      font-size: 3.5rem;
    }

    .checkbox-wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
      margin-bottom: 0;
      opacity: 0;
      transition: opacity .3s ease;

      .checkbox__btn {

        svg {
          font-size: 1.5rem;
        }
      }
    }

    .more-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      opacity: 0;
      transition: opacity .3s ease;

      .more {

        svg {
          font-size: 1.5rem;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    height: 80px;
    padding: 10px;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      font-size: 0.75rem;
      color: #767676;
    }
  }

  &__go-to {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    z-index: 5;
  }
}