@use '../abstracts/variables_new.scss' as *;

.list-view-droppable-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: rgba(#fff, 0.8);

  &__inner {
    flex: 1;
    border: 2px dashed $primary-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .material-symbols-outlined {
      display: block;
      font-size: 3rem;
      margin-bottom: 10px;
      color: $primary-color;
    }

    p {
      font-size: 1.25rem;
      font-weight: 700;
      color: $text-color-1;
    }
  }
}