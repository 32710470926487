@use '../abstracts/variables' as *;

.message {
  border: 1px solid transparent;
  padding: 20px;
  text-align: center;

  &--primary {
    border-color: $primary-color;
    color: $primary-color;
  }

  &--success {
    border-color: $quinary-color;
    color: $quinary-color;
  }
  
  &--danger {
    border-color: $secondary-color;
    color: $secondary-color;
  }
}