@use '../abstracts/variables_new.scss' as *;

.document-select {
  margin-bottom: 20px;

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 16px;
    background-color: #fff;
    border: 1px solid $border-color-2;
    border-radius: 6px;
    font-size: 0.75rem;
    color: $text-color-4;
    letter-spacing: 0.17px;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;

    p {
      display: flex;
      align-items: center;
    }
  }

  &.error {

    .document-select__selected {
      border-color: $danger-color;
    }
  }
}

.document-select-dropdown {

  .default-list {

    li {

      .material-symbols-outlined {
        margin-left: auto;
      }
    }
  }
}