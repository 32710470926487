@use '../abstracts/variables' as *;

.attachments-wrapper {
  margin-bottom: 30px;

  &__inner {

    &_checkbox-wrapper {
      display: flex;
      align-items: center;
      margin: 10px 0 0;

      .checkbox {
        margin-right: 5px;
      }
    }

    .no-data {
      font-size: 0.875rem;
      padding-top: 10px;
    }
  }

  .button {
    margin-top: 20px;
  }

  .attachments-disabled-msg {
    font-size: 0.875rem;
    padding: 15px 0;
    color: $secondary-color;
  }
}