@use '../abstracts/variables_new.scss' as *;

.notifications-v2 {

  .notifications__inner_head {
    background-color: $bg-color-2;
    color: $text-color-6;
    
    h5 {
      color: $text-color-6;
    }
  }

  .notifications__list {

    li {

      .text {

        p.info {
          color: $text-color-6;
          margin-bottom: 5px;
        }

        p.date {
          color: $text-color-4;
        }
      }

      &.document-action {

        .icon {

          svg {
            font-size: 1.25rem;
          }
        }
      }

      &.template-action {

        .icon {

          i {
            font-size: 1.125rem;
          }
        }
      }

      .icon {
        position: relative;

        .thumb {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #fff;
          background-color: $bg-color-3;
          position: absolute;
          right: -7px;
          bottom: -1px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;

          &--no-bg {
            background-color: transparent;
          }

          .letter-circle-v2 {
            position: absolute;
            width: 20px;
            height: 20px;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 0.625rem;
          }
        }
      }
    }
  }
}