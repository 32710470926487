@use '../abstracts/variables' as *;

// .dashboard-view--signatures {

//   .document-checked {

//     &__left {
//       padding-left: 10px;
//     }

//     &__right {
//       padding-right: 10px;
//     }
//   }
// }

.document-checked {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;

  &__left {
    display: flex;
    align-items: center;
    // padding-left: 10px;

    .checkbox {
      margin-right: 16px;

      &__btn {
        width: 18px;
        height: 18px;

        svg {
          font-size: 1.125rem;
        }
      }
    }

    p {
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    position: relative;

    .icon-button {
      margin-left: 24px;
      color: $nonary-text-color;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: 2px;
      font-size: 0.8125rem;
      font-weight: 700;
      color: $nonary-text-color;
      transition: background-color .3s ease;
      padding: 5px 11px;
      border-radius: 5px;

      &:hover {
        background-color: $tertiary-bg-color;
      }

      i, 
      svg {
        color: inherit;
        font-size: 1.125rem;
      }

      span:not(.path1):not(.path2) {
        margin-left: 5px;
      }

      &.rotate-180 {

        svg {
          transform: rotate(180deg);
        }
      }

      &.delete {

        &:hover {
          background-color: #FDEAE6;
          color: #ED5534;
        }
      }
    }

    &_dropdown {
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      background-color: $primary-light-color;
      box-shadow: 0 0 10px 0 rgba($shadow-color, 0.5);
      border-radius: 3px;
      width: 120px;
      padding: 5px 0;
      z-index: 10;

      li {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: $nonary-text-color;
        cursor: pointer;
        transition: background-color .3s ease, color .3s ease;
        padding: 5px 10px;

        &:hover {

          &.delete {
            background-color: $bg-color-20;
            color: $text-color-22;
          }
        }

        svg {
          display: block;
        }
      }
    }
  }
} 