@use '../abstracts/variables' as *;

.signature-templates-modal {

  h3 {
    margin-bottom: 20px;
  }

  ul {

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .name {

        .form-input {
          margin-bottom: 0;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        .icon-button {
          width: 36px;
          height: 36px;

          &.delete-btn {
            color: $nonary-text-color;
            transition: color 0.3s ease, background-color 0.3s ease;
            margin-left: 10px;

            &:hover {
              color: $secondary-color-light;
              background-color: rgba($secondary-color-light, 0.1);
            }
          }
        }
      }
    }
  }
}