@use '../abstracts/variables' as *;

.document-detail-sp-comments {
  // padding: 50px 38px 50px 24px;

  @media (max-width: $break-xs-max) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .form-input {
    margin-bottom: 0px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;

    .thumb {
      height: 30px;
      min-width: 30px;
      max-width: 30px;
      border-radius: 15px;
      background-color: $octonary-color;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
    }

    .thumb-wrapper {
      margin-right: 15px;
    }

    .right-wrapper {
      flex-grow: 1;
    }

    .info-label {
      font-size: 0.8rem;
    }

    .sender-name {
      font-weight: 800;
      color: $primary-text-color;
    }

    .sent-ago {
      font-size: 0.8rem;
      font-weight: 500;
      color: $octonary-text-color;
    }

    .message-box {
      margin-top: 5px;
      background-color: #FAFBFC;
      border: 2px solid #DFE1E6;
      color: #000;
      border-radius: 2px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 5px 10px;
      line-height: 1.1rem;
    }

    .info-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .button {
      height: 40px;
      font-size: 0.9rem;

      &:first-child {
        margin-right: 15px;
      }
    }
  }
}

.dropdown-actions {
  padding: 0px 10px;
}

.dropdown-action-row {
  color: $primary-text-color;
  font-size: 0.9rem;
  margin: 10px 0px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  .icon {
    margin-right: 5px;
    color: $octonary-text-color;
    font-size: 1rem;
  }
}

.dropdown-prompt {
  padding: 5px 15px;
  // min-width: 1rem;
  display: flex;
  flex-direction: column;
}

.dropdown-prompt-title {
  font-size: 0.9rem;
}

.dropdown-prompt-actions-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.dropdown-prompt-action {
  margin-left: 10px;
}
