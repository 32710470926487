@font-face {
  font-family: 'Jurisur-Custom';
  src:
    url('../../assets/fonts/Jurisur-Custom.ttf?pel1m9') format('truetype'),
    url('../../assets/fonts/Jurisur-Custom.woff?pel1m9') format('woff'),
    url('../../assets/fonts/Jurisur-Custom.svg?pel1m9#Jurisur-Custom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="custom-icon-"], [class*=" custom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Jurisur-Custom' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-icon-qr-code-scanner:before {
  content: "\e915";
}
.custom-icon-create-outlined:before {
  content: "\e914";
}
.custom-icon-signature:before {
  content: "\e913";
}
.custom-icon-google .path1:before {
  content: "\e90d";
  color: rgb(66, 133, 244);
}
.custom-icon-google .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.custom-icon-google .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.custom-icon-google .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.custom-icon-facebook .path1:before {
  content: "\e911";
  color: rgb(24, 119, 242);
}
.custom-icon-facebook .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.custom-icon-upload:before {
  content: "\e90c";
}
.custom-icon-dashboard:before {
  content: "\e906";
}
.custom-icon-upload-file:before {
  content: "\e917";
}
.custom-icon-contact-page:before {
  content: "\e90b";
}
.custom-icon-arrow-circle-up:before {
  content: "\e90a";
}
.custom-icon-content-copy:before {
  content: "\e909";
}
.custom-icon-article-outlined:before {
  content: "\e908";
}
.custom-icon-article:before {
  content: "\e907";
}
.custom-icon-folder-move .path1:before {
  content: "\e904";
}
.custom-icon-folder-move .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.custom-icon-template:before {
  content: "\e903";
}
.custom-icon-create:before {
  content: "\e901";
}
.custom-icon-mark-email-read:before {
  content: "\e902";
}
.custom-icon-close-panel:before {
  content: "\e900";
}
.custom-icon-inventory_2:before {
  content: "\e918";
}
.custom-icon-insights:before {
  content: "\e919";
}
.custom-icon-space-dashboard:before {
  content: "\e91a";
}
.custom-icon-attach-outline:before {
  content: "\e91b";
}
.custom-icon-message-square-outline:before {
  content: "\e91c";
}
.custom-icon-upload-file-2:before {
  content: "\e91d";
}
.custom-icon-east:before {
  content: "\e91e";
}
.custom-icon-download:before {
  content: "\e91f";
}
.custom-icon-task_alt:before {
  content: "\e920";
}
.custom-icon-tag:before {
  content: "\e921";
}
.custom-icon-news:before {
  content: "\e922";
}
.custom-icon-support:before {
  content: "\e923";
}
.custom-icon-file-download:before {
  content: "\e924";
}

.custom-icon-t {

  &::before {
    width: 13px;
    height: 3px;
    content: '';
    display: block;
    background-color: #fff;
  }
  &::after {
    width: 3px;
    height: 11px;
    content: '';
    display: block;
    background-color: #fff;
    margin: 0 auto;
  }
}