@use '../abstracts/variables_new.scss' as *;

.contact-box-v2 {
  border: 3px solid $border-color-1;
  border-radius: 3px;
  padding: 10px 10px 10px 40px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    .material-symbols-outlined {
      color: $text-color-2;
      display: block;
    }
  }

  p {
    font-size: 0.875rem;
    color: $text-color-6;
    margin-right: 10px;

    &::after {
      content: ','
    }

    &:last-child {

      &::after {
        display: none;
      }
    }
  }

  &.active {
    border-color: $primary-color;

    .icon {

      .material-symbols-outlined {
        color: $primary-color;
      }
    }
  }
}