@use '../abstracts/variables.scss' as *;

.new-approval {

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    @media (max-width: $break-md-max) {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-weight: 500;
    }

    .actions {
      margin-left: auto;

      .button {
        margin-left: 15px;
      }

      @media (max-width: $break-md-max) {
        margin-left: 0;
        margin-top: 15px;

        .button {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }
  }

  &__body {
    background-color: #fff;
    box-shadow: 0 0 10px rgba($shadow-color-7, 0.2);
    padding: 40px;
    border-radius: 4px;

    &--2 {
      padding-left: 0;
      padding-right: 0;
      // overflow-x: auto;
    }

    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 20px;

      p {
        font-size: 1.125rem;
        font-weight: 500;
      }
    }

    section {
      margin-bottom: 40px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f2f2f2;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      h3 {
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .approval-steps {

        &__head {
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 10px;
          margin-bottom: 10px;
          font-size: 0.875rem;
          color: #c1c1c1;

          @media (max-width: $break-md-max) {
            display: none;
          }
        }

        &__body {
          font-size: 0.875rem;

          .approval-step-row {
            margin-bottom: 20px;
          }
        }

        .approval-step-row {
          display: flex;
          align-items: center;

          @media (max-width: $break-md-max) {
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 15px;
          }
        }

        .approval-step-col {
          padding: 0 10px;
          display: flex;
          align-items: center;

          &:nth-child(1) {
            width: 100px;
            max-width: 100px;
            min-width: 50px;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            max-width: 200px;
            width: 200px;
            min-width: 200px;
          }
          &:nth-child(4) {
            width: 100px;
            max-width: 100px;
            min-width: 100px;
          }

          @media (max-width: $break-md-max) {
            width: 100% !important;
            max-width: 100% !important;
            clear: both;
            padding: 5px 0;
            flex-wrap: wrap;

            &::before {
              content: attr(data-label);
              font-weight: 500;
              float: left;
              margin-right: 10px;
              font-size: 0.875rem;
            }

            &:last-child::before {
              display: none;
            }
          }

          .members {
            display: flex;
            align-items: center;
            margin-right: 10px;
            
            .letter-circle:not(:first-child) {
              margin-left: -5px;
            }
          }

          .edit-members-btn {
            background-color: transparent;
            color: $primary-color;
            transition: opacity .3s ease;

            svg {
              font-size: 1.5rem;

              &.edit {
                font-size: 1.25rem;
              }
            }

            &:hover {
              opacity: 0.7;
            }
          }

          &--with-dropdown {

            > div {
              width: 100%;

              @media (max-width: $break-md-max) {
                max-width: 200px;
              }
            }
          }

          .from-trigger {
            display: flex;
            width: 100%;
            height: 30px;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 0 10px;
            background-color: #f2f2f2;
            border: 0;
            outline: 0;
            border-bottom: 2px solid #000;
            cursor: pointer;
          }

          .remove-step-btn {
            font-size: 0.75rem;
          }
        }

        &__add {
          margin-top: 30px;

          button {
            height: auto !important;
            padding: 2px 10px;

            .icon {
              margin-right: 5px;
            }

            svg {
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.approval-from-dropdown {

  ul {
    padding: 5px 0;

    li {
      font-size: 0.875rem;
      padding: 4px 10px;
      margin-bottom: 0;
      color: #333;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: darken(#f2f2f2, 10);
      }
    }
  }
}

.approval-actions-menu {
  white-space: normal;

  .approval-menu-content {
    padding: 5px 0;

    p {
      font-size: 0.875rem;

      a {
        color: $primary-color;
      }

      span {
        color: #ccc;
        display: flex;
        align-items: center;
        min-height: 20px;

        i {
          width: 20px;
          height: 20px;
          background-color: $primary-color;
          color: #fff;
          border-radius: 50%;
          margin-left: 10px;
          cursor: pointer;

          svg {
            font-size: 1.25rem;
          }
        }
      }
    }

    .already-approved {

      p {
        color: #ccc;
      }
    }

    .loader-wrapper {
      padding: 15px 0;
    }

    .steps-approved {
      font-size: 0.875rem;
    }

    .steps-approve-reject {
      display: flex;
      align-items: center;

      p {
        margin-right: 4px;
        color: $primary-color;
      }
    }

    .steps {
      padding: 10px 0;

      li {
        margin-bottom: 15px;

        .step-num {
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 5px;
          padding-bottom: 5px;
          font-weight: 500;
          position: relative;
          padding-right: 30px;

          .icon {
            position: absolute;
            top: 0;
            right: 0;

            svg {
              font-size: 1.25rem;
            }

            &.approved {

              svg {
                color: $bg-color-29;
              }
            }

            &.rejected {

              svg {
                color: $bg-color-28;
              }
            }
          }
        }

        .members {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 5px;

          .letter-circle:not(:first-child) {
            margin-left: -5px;
          }

          .letter-circle:nth-last-child(2) {
            margin-right: 10px;
          }
        }
      }
    }

    &__actions {

      .button {
        font-size: 0.75rem;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}