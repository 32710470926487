@use '../abstracts/variables.scss' as *;

.upload-atts-modal {

  &__head {
    margin-bottom: 20px;

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      color: $primary-text-color;
    }
  }

  &__body {
    position: relative;

    &_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 400px;
      border-radius: 3px;
      border: 2px dashed $senary-border-color;

      &.active {
        border-color: $primary-color;
      }

      .info {
        font-size: 1.1875rem;
        font-weight: 500;
        text-align: center;
        color: $quinary-dark-color;
        margin-bottom: 24px;
      }
  
      .or {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
    
        .line {
          height: 2px;
          width: 26px;
          background-color: $secondary-light-color;
        }
    
        p {
          font-size: 0.875rem;
          color: $quinary-dark-color;
          margin: 0 18px;
        }
      }

      .file-input {
        display: none;
      }
    }

    &_uploading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($primary-light-color, 0.9);
      padding: 15px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .progress {
        width: 200px;
        height: 10px;
        background-color: #ccc;
        border-radius: 4px;
        margin-bottom: 20px;
        position: relative;
    
        div {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: $primary-color;
          border-radius: 4px;
          transition: width .3s ease;
        }
    
        p {
          position: absolute;
          bottom: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
  }
}