@use '../abstracts/variables.scss' as *;

.choose-approval-modal {

  &__head {
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    border-bottom: 1px solid #f2f2f2;

    h2 {
      font-weight: 400;
    }
  }

  &__body {

    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 0;

      p {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }

    ul {

      li {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f2f2f2;

        .visible {
          display: flex;
          align-items: center;
          padding-right: 30px;
          position: relative;

          .radio {
            margin-right: 10px;
            cursor: pointer;

            svg {
              display: block;

              &.checked {
                color: $primary-color;
              }
              &.unchecked {
                color: #ccc;
              }
            }
          }

          .info {

            .name {
              font-weight: 500;
              color: #333;
            }

            .desc {
              font-size: 0.875rem;
              color: #ccc;
              margin-top: 2px;
            }
          }

          .expand-trigger {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        .expand {
          padding-left: 34px;
          padding-top: 10px;

          ul {

            li {
              display: flex;
              align-items: center;
              padding-bottom: 0;
              margin-bottom: 5px;
              border-bottom: 0;

              .step-num {
                margin-right: 10px;
                line-height: 30px;
                border-right: 1px solid #ccc;
                padding-right: 10px;
              }

              .members {
                display: flex;
                align-items: center;

                .letter-circle {
                  margin-left: -5px;

                  &:first-child {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    .button {
      margin-left: 15px;
    }
  }
}