@use '../abstracts/variables_new.scss' as *;

.docs-widget-content {
  padding: 20px 10px;

  .recharts-cartesian-axis-tick-value {    
    font-size: 0.8125rem;
    font-weight: 500;
    fill: rgba(110,131,163,0.5);
    letter-spacing: 0.19px;
  }

  .recharts-xAxis {

    .recharts-cartesian-axis-tick-value {
      text-transform: capitalize;
    }
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: $bg-color-1;
  }
}