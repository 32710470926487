@use '../abstracts/variables' as *;

.chart-tooltip {
  background-color: $primary-light-color;
  padding: 10px;
  border: 1px solid $primary-color;
  box-shadow: 0 1px 4px 0 rgba($shadow-color-4, 0.5);
  border-radius: 3px;

  &__text {
    font-size: 0.875rem;
    font-weight: 400;
    max-width: 300px;
  }
}