@use '../abstracts/variables' as *;

.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  height: 100%;

  h2 {
    color: $secondary-text-color;
  }

  .button {
    margin-top: 20px;
  }
}