@use '../abstracts/variables' as *;

.document-create-modal {
  display: flex;

  &__left {
    padding: 38px 18px 38px 18px;
    background-color: $primary-bg-color;
    width: 229px;
    border-right: 1px solid $primary-border-color;

    h3 {
      margin-bottom: 12px;
    }

    .form {
      margin-bottom: 28px;
    }

    .filters {
      margin-bottom: 0;
    }
  }

  &__right {
    flex: 1;

    .dashboard-view {

      &__head {
        padding-bottom: 0;

        &_top {
          padding-top: 40px;
          padding-bottom: 40px;
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: $break-sm-max) {
    display: block;

    &__left {
      width: 100%;
    }

    &__right {
      padding-bottom: 50px;
    }
  }
}