html,
body,
#root{
  height: 100%;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 16px;
}

.react-datepicker__triangle {
  left: 50px !important;
}

#modal-root {

  .react-datepicker-popper {
    z-index: 15;
  }
}