@use '../abstracts/variables.scss' as *;

.document-detail-sp-preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  max-height: 100%;
  overflow: hidden;

  &__pagination {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon-button {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    select {
      margin: 0 5px;
      height: 40px;
      width: 60px;
      border: 1px solid #333;
      outline: 0;
      padding: 0 10px;
    }
  }

  &__thumbs {
    min-height: 0;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .react-pdf__Page {
      width: 102px;
      height: auto;
      border: 1px solid $denary-border-color;
      border-radius: 3px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 0 5px rgba(#000, 0.05);
      cursor: pointer;

      &.active {
        border-color: $primary-color;
      }

      canvas {
        width: 100% !important;
        height: auto !important;
      }

      .react-pdf__Page__textContent {
        display: none !important;
      }
    }
  }
}