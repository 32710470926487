@use '../abstracts/variables.scss' as *;

.droppable-area {
  position: relative;

  &__hover {
    background-color: rgba(#fff, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 10px;
    display: flex;

    &_inner {
      border: 2px dashed $primary-color;
      height: 100%;
      flex: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $primary-color;

      svg {
        display: block;
        font-size: 3rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }
}