@use '../abstracts/variables_new.scss' as *;

.customize-side-panel {
  position: fixed;
  top: 0;
  right: -350px;
  background-color: #fff;
  padding: 0 20px;
  width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1010;
  box-shadow: 0 5px 10px $shadow-color-2;
  transition: right .3s ease;

  &.opened {
    right: 0;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 14px 0;
    margin-bottom: 15px;

    h2 {
      max-width: 258px;
    }

    .icon {
      margin-top: 5px;
      cursor: pointer;
      color: $text-color-11;
    }
  }

  &__body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 15px);
    padding-right: 15px;
    padding-bottom: 32px;

    section {
      margin-bottom: 34px;

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        margin-bottom: 19px;
      }

      .info {
        margin-bottom: 25px;
        margin-top: -10px;
      }

      .backgrounds {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 10px);

        &__box {
          width: 46px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 5px;
          margin-bottom: 5px;
          cursor: pointer;
        }
      }

      .widgets {

        .widget-box {
          margin-bottom: 15px;
        }
      }
    }
  }
}