@use '../abstracts/variables_new.scss' as *;

.status-v2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 4px;
  height: 20px;
  font-size: 0.5625rem;
  font-weight: 700;
  line-height: 1.22;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  &.ready {
    background-color: $highlighted-color-4-light;
    color: $highlighted-color-4;
  }

  &.to_validate {
    background-color: $highlighted-color-3-light;
    color: $highlighted-color-3;
  }

  &.validated {
    background-color: $highlighted-color-1-light;
    color: $highlighted-color-1;
  }

  &.draft {
    background-color: $highlighted-color-2-light;
    color: $highlighted-color-2;
  }
}