@use '../abstracts/variables.scss' as *;

.animated-search-bar {
  position: relative;
  width: 244px;
  transition: width .3s ease;

  @media (max-width: $break-sm-max) {
    margin-left: 10px;
  }

  &.active {
    width: 600px;

    input {
      outline: 2px solid $primary-color;
      padding-right: 105px;

      @media (max-width: $break-sm-max) {
        padding-right: 10px;
      }
    }

    @media (max-width: 1100px) {
      width: 400px;
    }

    @media (max-width: $break-md-max) {
      width: 244px;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 3px;
    width: 24px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      font-size: 1.125rem;
      color: #767676;
    }
  }

  &__category {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;

    button {
      background-color: rgba(0,110,255,0.1);
      display: flex;
      align-items: center;
      padding: 0 3px 0 9px;
      border-radius: 3px;
      border: 0;
      outline: 0;
      height: 26px;
      font-size: 0.8125rem;
      font-weight: 500;
      color: #1B1D24;
      cursor: pointer;

      svg {
        display: block;
        margin-left: 3px;
        font-size: 1.0625rem;
        color: #767676;
      }
    }
  }

  input {
    padding: 7px 10px 8px 30px;
    background-color: rgba(#EBECF0, 0.35);
    border-radius: 3px;
    border: 0;
    outline: 0;
    display: block;
    width: 100%;
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 2px;

    @media (max-width: $break-sm-max) {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 28px;
      color: #bbb;
      
      svg {
        font-size: 1.0625rem;
      }
    }

    button {
      height: 28px;
      min-height: auto;
      padding: 0 10px;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    min-width: 100%;
    border-radius: 3px;
    padding: 20px 10px;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    background-color: #fff;
    z-index: 99;
    white-space: nowrap;

    @media (max-width: 1100px) {
      white-space: normal;
    }

    p {
      color: #767676;
      font-size: 0.8125rem;
      margin-bottom: 8px;
    }

    &_categories {

      .dropdown-categories-body {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 1100px) {
          flex-wrap: wrap;
        }
        
        button {
          display: flex;
          align-items: center;
          font-size: 0.8125rem;
          color: #1B1D24;
          height: 33px;
          font-weight: 500;
          background-color: rgba(0,110,255,0.1);
          padding: 0 12px;
          border: 0;
          outline: 0;
          cursor: pointer;
          margin-right: 12px;
          margin-bottom: 12px;
          border-radius: 4px;

          &:last-child {
            margin-right: 0;
          }

          svg, i {
            font-size: 1rem;
            margin-right: 8px;
            display: block;
          }

          &:hover {
            background-color: rgba(0,110,255,0.2);
          }

          &:active {
            background-color: rgba(0,110,255,0.3);
          }
        }
      }
    }

    .search-history {

      p {
        margin-bottom: 19px;

        a {
          color: $primary-color;
          text-decoration: underline;
        }
      }

      &__list {
        padding: 5px 0;

        li {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          svg {
            font-size: 1.25rem;
            margin-right: 9px;
          }

          a {
            color: #1B1D24;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}