@use '../abstracts/variables' as *;

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: transparent;
  color: $quinary-text-color;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  transition: background-color .3s ease;

  svg {
    display: block;
  }

  &:hover {
    background-color: $primary-bg-color;
  }
  &:active {
    background-color: darken($primary-bg-color, 10%);
  }

  &--primary-light {
    background-color: $primary-color-light;
    color: $primary-color;

    &:hover {
      background-color: darken($primary-color-light, 5%);
    }
    &:active {
      background-color: darken($primary-color-light, 15%);
    }
    &:disabled {
      background-color: #f3f3f3;
      color: #bbb;
      cursor: not-allowed;
    }
  }
  
  &--light {
    background-color: $tertiary-bg-color;

    &:hover {
      background-color: darken($tertiary-bg-color, 5%);
    }
    &:active {
      background-color: darken($tertiary-bg-color, 15%);
    }
  }

  &--danger {
    background-color: $color-14;
    color: #fff;

    &:hover {
      background-color: darken($color-14, 5%);
    }
    &:active {
      background-color: darken($color-14, 15%);
    }
  }

  &--dark {
    color: $tertiary-text-color;
  }

  &--fixed-small {
    width: 50px;
    min-width: 50px;
    height: 30px;
    font-size: 1.5rem;
  }

  &--rotate-180 {

    svg {
      transform: rotate(180deg);
    }
  }

  &--round {
    border-radius: 50%;
  }

  &--medium {
    width: 40px;
    height: 40px;
  }

  &--small {
    height: 24px;
    width: 24px;
  }

  &--light-gray {
    background-color: $form-el-bg-color-1;
    border: 1px solid $form-el-border-color-1;
    color: $secondary-text-color;
  }

  &--light-blue {
    background-color: rgba(#EBECF0, 0.35);
    color: $primary-light-color;
    
    &:hover {
      background-color: rgba(#EBECF0, 0.5);
    }
  }
}