@use '../abstracts/variables' as *;

.documents-list {

  &__item {
    display: flex;
    align-items: center;
    padding: 14px 19px;
    border-bottom: 1px solid $primary-border-color;

    &:first-child {
      padding-top: 0;
    }

    svg {
      display: block;
      color: $quinary-text-color;
      margin-right: 11px;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.286;
      font-weight: 500;

      &.date {
        font-size: 0.75rem;
        font-weight: 400;
        color: $nonary-text-color;
        margin-left: auto;
      }
    }
  }

  &--tags {
    margin-top: -24px;

    .documents-list__item {
      position: relative;
      padding: 20px 80px 20px 19px;
      transition: background-color .3s ease;

      &:first-child {
        padding-top: 20px;
      }

      &:hover {
        background-color: $primary-bg-color;

        .delete-icon {
          opacity: 1;
        }
      }

      p.tag {
        font-weight: 400;
      }

      .delete-icon {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s ease;

        svg {
          display: block;
          color: $primary-color;
          margin: 0;
        }
      }
    }
  }
}