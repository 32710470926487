@use '../abstracts/variables_new.scss' as *;

.settings-layout {

  .main-header {

    &__right {

      @media (max-width: $break-md-max) {
        margin-top: 15px;
      }

      .icon-btn {

        @media (max-width: $break-md-max) {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
}

.main-header {
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $border-color-1;
  position: relative;
  z-index: 2;

  @media (max-width: $break-md-max) {
    height: auto;
    min-height: 80px;
    flex-wrap: wrap;
    padding: 27px 0 20px 0;
  }

  &__left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 33%;

    @media (max-width: $break-md-max) {
      max-width: 100%;
      width: 100%;
      position: static;
      transform: translateY(0);
    }
  }

  &__center {
    position: relative;
    z-index: 1;

    @media (max-width: $break-md-max) {
      width: 100%;
      margin: 15px 0;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media (max-width: $break-md-max) {
      max-width: 100%;
      position: static;
      transform: translateY(0);
      width: 100%;
      justify-content: flex-start;
      margin-left: 0;
    }

    .icon-btn {
      margin-left: 10px;
    }

    .add-doc-btn {

      @media (max-width: 1299px) {
        width: 44px;
        justify-content: center;
        padding: 0 5px;

        span.text {
          display: none;
        }
      }
    }

    .user-thumb-wrapper {
      margin-left: 10px;
      cursor: pointer;

      .user-thumb {
        width: 38px;
        height: 38px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        border: 1px solid $border-color-1;
      }

      .letter-circle-v2 {
        width: 38px;
        height: 38px;
        font-size: 1rem;
      }
    }
  }
}