@use '../abstracts/variables' as *;

.most-popular-articles {
  padding-bottom: 45px;
  border-bottom: 1px dashed #EBECF0;
  margin-bottom: 50px;

  &__head {
    margin-bottom: 30px;

    h2 {
      font-weight: 400;
      text-align: center;
    }
  }

  &__body {

    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 600px;
      margin: 0 auto;

      li {
        width: 50%;
        padding-right: 10px;
        margin-bottom: 5px;

        @media (max-width: $break-xs-max) {
          width: 100%;
          padding-right: 0;
        }

        a {
          display: inline-flex;
          align-items: center;
          color: #5D6C85;
  
          svg {
            display: block;
            margin-right: 5px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}