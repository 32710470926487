@use '../abstracts/variables' as *;

.synthesis {

  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 33px;

    @media (max-width: $break-sm-max) {
      flex-direction: column;

      li {
        margin-right: 0 !important;
        margin-bottom: 10px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex !important;
        }
      }
    }

    li {
      margin-right: 30px;

      a {
        font-size: 0.875rem;
        color: $nonary-text-color;

        &.active {
          color: $primary-color;
        }
      }

      &.all,
      &.empty,
      &.completed {
        margin-right: 7px;

        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          border-radius: 3px;
          padding: 0 14px;
          border: 2px solid transparent;
          box-sizing: initial;
        }
      }

      &.all {

        a {
          color: #1B1D24;
          background-color: #EFEFF0;

          &.active {
            border-color: #454855;
          }
        }
      }

      &.empty {

        a {
          color: #6C5B3D;
          background-color: #FFECCA;

          &.active {
            border-color: #C5891D;
          }
        }
      }

      &.completed {

        a {
          color: #5561FE;
          background-color: #E4E7FC;

          &.active {
            border-color: #5867D0;
          }
        }
      }
    }
  }

  &__section {
    margin-bottom: 44px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      background-color: rgba($secondary-bg-color, 0.07);
      color: $text-color-28;
      padding: 10px 12px;
      font-size: 1.0625rem;
      font-weight: 400;
      line-height: 1.294;
      margin-bottom: 26px;
    }

    &_fields {
      
      .inputs-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% + 15px);
      }

      .input-wrapper {
        flex: 1;
        margin-right: 15px;
        min-width: calc(100% / 3 - 15px);

        @media (max-width: $break-sm-max) {
          min-width: calc(100% / 2 - 15px);
        }
        @media (max-width: $break-xxs-max) {
          min-width: calc(100% - 15px);
        }

        .form-input {
          width: 100%;

          &__label {
            margin-bottom: 8px;
            font-size: 0.875rem;
            color: $tertiary-text-color;
          }

          &__field {
            background-color: $bg-color-24;
            padding: 4px 6px 3px;
            border: 1px solid transparent;
            border-radius: 3px;

            &:focus {
              border-color: $bg-color-25 !important;
            }
          }

          &.not-empty {

            .form-input__field {
              background-color: rgba($bg-color-27, 0.08);
              border-color: $bg-color-26;
              color: $bg-color-26;
              
              &:focus {
                border-color: $bg-color-26 !important;
              }
            }
          }
        }

        .date_picker {
          margin-bottom: 15px;

          .label {
            margin-bottom: 8px;
            font-size: 0.875rem;
            color: $tertiary-text-color;
          }

          .react-datepicker-wrapper {
            display: block;
          }

          input {
            width: 100%;
            display: block;
            background-color: $bg-color-24 !important;
            padding: 4px 6px 3px 27px !important;
            border: 1px solid transparent !important;
            border-radius: 3px !important;

            &:focus {
              border-color: $bg-color-25 !important;
            }
          }

          &.not-empty {

            input {
              background-color: rgba($bg-color-27, 0.08) !important;
              border-color: $bg-color-26 !important;
              color: $bg-color-26 !important;
              
              &:focus {
                border-color: $bg-color-26 !important;
              }
            }
          }
        }

        .select_input_wrapper {
          margin-bottom: 15px;

          .label {
            margin-bottom: 8px;
            font-size: 0.875rem;
            color: $tertiary-text-color;
          }

          select {
            width: 100%;
            background-color: $bg-color-24;
            padding: 3px 6px 2px;
            border: 1px solid transparent;
            border-radius: 3px;
            outline: 0;
            font-family: $ff-primary;
            font-weight: 700;
            font-size: 0.875rem;

            &:focus {
              border-color: $bg-color-25 !important;
            }
          }

          &.not-empty {

            select {
              background-color: rgba($bg-color-27, 0.08);
              border-color: $bg-color-26;
              color: $bg-color-26;
              
              &:focus {
                border-color: $bg-color-26 !important;
              }
            }
          }
        }
      }

      .textarea-wrapper {
        width: calc(100% - 15px);
        margin-bottom: 15px;

        .label {
          margin-bottom: 8px;
          font-size: 0.875rem;
          color: $tertiary-text-color;
        }

        textarea {
          background-color: $bg-color-24;
          padding: 4px 6px 3px;
          border: 1px solid transparent;
          border-radius: 3px;
          width: 100%;
          height: 100px;
          resize: none;
          outline: 0;
          font-family: $ff-primary;
          font-weight: 700;
          font-size: 0.875rem;

          &:focus {
            border-color: $bg-color-25 !important;
          }

          &.not-empty {
            background-color: rgba($bg-color-27, 0.08);
            border-color: $bg-color-26;
            color: $bg-color-26;
            
            &:focus {
              border-color: $bg-color-26 !important;
            }
          }
        }
      }

      .question-container {
        width: calc(100% - 15px);
      }

      .question-wrapper {
        padding-bottom: 5px;
        border-bottom: 1px solid $octonary-color;
        margin-bottom: 25px;
        width: 100%;

        &.hide {
          display: none;
        }

        p {
          font-size: 0.9375rem;
          line-height: 1.467;
          color: $senary-color;

          span {
            color: $secondary-bg-color;
            margin-left: 6px;
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  .form {
    padding-bottom: 32px;

    // h4 {
    //   margin-top: 30px;
    //   margin-bottom: 20px;
    //   padding-bottom: 5px;
    //   border-bottom: 1px solid $septenary-text-color;
    //   color: $septenary-text-color;
    //   text-transform: uppercase;
    //   font-size: 1rem;
    // }

    // .form-input {
    //   margin-bottom: 0;
    //   padding: 0 5px;

    //   &__inner {

    //     &--align-horiz {
    //       padding-top: 7px;

    //       .form-input {

    //         &__label {
    //           width: 220px;
    //           margin-right: 15px;
    //         }

    //         &__field {
    //           padding-top: 7px;
    //           padding-bottom: 6px;
    //         }
    //       }
    //     }
    //   }
    // }

    &__actions {

      .button {

        &--transparent {
          margin-right: 23px;
          color: $nonary-text-color;
        }

        &--primary {
          text-transform: uppercase;
          font-size: 0.75rem;
          letter-spacing: 0.3px;
          padding: 0 28px;
        }
      }
    }
  }
}