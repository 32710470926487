@use '../abstracts/variables_new.scss' as *;

.textarea-v2 {
  margin-bottom: 23px;

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.642;
    margin-bottom: 11px;
    display: block;
    color: $text-color-6;
  }

  &__field {
    display: block;
    width: 100%;
    height: 131px;
    border: 1px solid $border-color-2;
    border-radius: 6px;
    background-color: #fff;
    padding: 10px;
    font-size: 0.875rem;
    color: $text-color-6;
    outline: 0;
    resize: none;

    &:focus {
      border-color: $primary-color;
    }
  }

  &__error {

  }

  &.label-normal {

    .textarea-v2__label {
      font-weight: 500;
      line-height: 1.285;
      letter-spacing: 0.2px;
      margin-bottom: 10px;
    }
  }
}