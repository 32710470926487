@use '../abstracts/variables' as *;

.status {
  font-size: 11px;
  font-weight: 700;
  padding: 4px 10px;
  display: inline-flex;
  text-transform: uppercase;
  background-color: $primary-bg-color;

  &--signed {
    background-color: $status-color-2-bg;
    color: $status-color-2;
  }

  &--not-signed {
    background-color: $status-color-3-bg;
    color: $status-color-3;
  }
}