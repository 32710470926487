@use '../../abstracts/variables' as *;

.synthesis-modal {

  .modal__content {
    max-width: 830px;
    background-color: #F8FAFC;
    padding: 0;
  }
}

.synthesis-view {
  padding: 43px 46px 43px 39px;

  @media (max-width: $break-sm-max) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .history {

    &__section {

      h3.today {
        background-color: #0ACB93;
        border-radius: 3px;
        padding: 0 15px;
        text-transform: none;
        font-weight: 700;
        font-size: 0.875rem;
        height: 32px;
        display: inline-flex;
        align-items: center;
      }
    }

    .history-box {
      background-color: transparent;
      box-shadow: none;

      &.opened {
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.21);
      }

      

      .button {
        background-color: #EBEDF0;
        color: #162A4E;

        &:hover {
          background-color: darken(#EBEDF0, 10);
        }
      }
    }
  }
}