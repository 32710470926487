@use '../abstracts/variables.scss' as *;

.custom-editor-wrapper {
  display: flex;
  flex-direction: column;

  .rdw-link-modal {
    height: auto;
  }
}

.custom-editor {
  order: 1;
  height: 230px;
  font-family: $ff-primary;
  font-size: 0.9375rem;

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

.custom-editor-toolbar {
  order: 2;
  border: 0;
  padding: 0;
  margin-top: 20px;

  .editor-btn {
    border: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin: 0 6px 0 0;

    img {
      display: block;
      max-width: 20px;
    }

    &:hover {
      box-shadow: none;
      background-color: #eee;
    }
  }
}