@use '../abstracts/variables_new.scss' as *;

.member-select {
  margin-bottom: 20px;

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 16px;
    background-color: #fff;
    border: 1px solid $border-color-2;
    border-radius: 6px;
    font-size: 0.75rem;
    color: $text-color-4;
    letter-spacing: 0.17px;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;

    &_member {
      display: flex;
      align-items: center;

      .user-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin-right: 10px;

        .letter-circle {
          width: 20px;
          height: 20px;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
}

.member-select-dropdown {

  .default-list {

    li {

      .user-thumb {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 15px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .material-symbols-outlined {
        margin-left: auto;
      }
    }
  }
}