@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('../../assets/fonts/HelveticaNeue-Medium.eot');
  src: url('../../assets/fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/HelveticaNeue-Medium.ttf') format('truetype'),
      url('../../assets/fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg'),
      url('../../assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
      url('../../assets/fonts/HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../assets/fonts/HelveticaNeue.eot');
  src: url('../../assets/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/HelveticaNeue.ttf') format('truetype'),
      url('../../assets/fonts/HelveticaNeue.svg#HelveticaNeue') format('svg'),
      url('../../assets/fonts/HelveticaNeue.woff2') format('woff2'),
      url('../../assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('../../assets/fonts/HelveticaNeue-Bold.eot');
  src: url('../../assets/fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
      url('../../assets/fonts/HelveticaNeue-Bold.woff') format('woff'),
      url('../../assets/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
      url('../../assets/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}