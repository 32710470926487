@use '../abstracts/variables.scss' as *;

.template-share-modal {

  &__head {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    h2 {
      font-weight: 400;
      font-size: 1.75rem;
    }
  }

  &__body {

    &_actions {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .share-btn {
        margin-right: 15px;
      }

      .save-btn {
        margin-left: auto;
      }

      .delete-btn {
        margin-right: auto;
      }

      @media (max-width: $break-sm-max) {
        flex-direction: column;

        > div {
          margin: 15px auto 15px 0;
        }

        .button {
          margin-right: auto;
        }
      }
    }

    &_fields {
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 40px;

      .upload-file {
        margin-bottom: 15px;

        &__label {
          font-size: 0.9375rem;
          font-weight: 400;
          color: #34353B;
          margin-bottom: 10px;
        }

        &__dropzone {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 2px dashed #DFE1E6;
          background-color: #FAFBFC;
          height: 200px;
          width: 100%;
          border-radius: 2px;
          padding: 10px;
          user-select: none;

          .preview-box {
            width: 100px;
            height: 100px;
            display: flex;
            align-self: center;
            justify-content: center;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              align-self: center;
            }
          }

          p {
            font-size: 0.875rem;

            &:last-child {
              font-size: 0.75rem;
            }

            a {
              color: $primary-color;
            }
          }
        }
      }
    }

    &_list-of-templates {
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 40px;

      h2 {
        font-size: 1.75rem;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .list {

        &__item {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #f2f2f2;
          position: relative;

          &_draggable {
            position: absolute;
            top: 8px;
            left: -30px;
            color: $primary-color;
            cursor: pointer;
            z-index: 1;
          }

          &_name {
            position: relative;
            padding-right: 60px;
            margin-bottom: 15px;
            min-height: 40px;

            h3 {
              font-weight: 400;

              input {
                font-size: 1.25rem;
                padding: 6px 0;
                border: 0;
                outline: 0;
                width: 100%;
                display: block;
              }
            }

            .delete-item {
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          &_description {

            .textarea {

              &__label {
                margin-bottom: 10px;
                font-size: 0.9375rem;
                display: block;
              }

              &__field {
                padding: 10px;
                resize: none;
              }
            }
          }
        }
      }

      .add-new-template {
        padding-top: 20px;
      }
    }
  }

  &__foot {
    // display: flex;
    // align-items: center;
    // margin-top: 20px;
    // padding-top: 20px;
    // border-top: 1px solid #f2f2f2;
  }
}

.template-share-actions-dropdown {

  &__body {

    .generated-link {
      margin-bottom: 15px;

      input {
        padding: 5px;
      }
    }

    .share-actions {
      display: flex;
      flex-wrap: wrap;

      .button {
        margin-bottom: 10px;

        &:first-child {
          margin-right: 10px;
        }
      }

      > div {
        width: 100%;
      }
    }
  }
}