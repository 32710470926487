@use '../abstracts/variables' as *;

.form {

  &__group {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &--auth {
      margin-bottom: 15px;

      .form-input,
      .select,
      .button {
        width: calc(50% - 5px);
        align-self: flex-start;
  
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    &--profile {
      max-width: 685px;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0;

      .form-input,
      .input-wrapper {
        flex: 0 1 auto !important;
        margin-bottom: 34px !important;

        &:first-child {
          margin-right: 45px;
        }
      }

      .input-wrapper {

        .form-input {
          margin-right: 0;
          margin-bottom: 0 !important;
        }
      }

      .language-input {
        margin-bottom: 34px;

        .language-selector {
          text-transform: uppercase;

          svg {
            margin-left: 10px;
          }
        }
      }

      .form__group_label {
        font-size: 0.9375rem;
        font-weight: 400;
        color: #34353B;
        margin-bottom: 10px;
      }
    }

    &--mb-20 {
      margin-bottom: 20px;
    }

    .form-input,
    &_inputs {
      flex: 1;
      margin-bottom: 0;
    }

    &_inputs {

      .form-input {
        margin-bottom: 20px;
      }
    }

    .icon {
      margin-left: 16px;
      color: $primary-color;
      align-self: center;
      position: relative;

      &--flex-start {
        align-self: flex-start;
        margin-top: 15px;
      }

      svg {
        display: block;
      }
    }

    &--2-cols {
      align-items: flex-start;
      margin-bottom: 0;

      > :first-child {
        width: 50%;
        max-width: calc(50% - 12px);
        margin-right: 12px;
      }

      > :nth-child(2) {
        width: 50%;
        max-width: calc(50% - 12px);
        margin-left: 12px;
      }

      @media (max-width: $break-sm-max) {
        flex-wrap: wrap;

        > :first-child {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }
  
        > :nth-child(2) {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
      }
    }

    &--image {
      align-items: center;
      
      .image-box {
        background-color: $form-el-bg-color-1;
        border: 1px solid $form-el-border-color-1;
        border-radius: 2px;
        width: 90px;
        height: 90px;
        margin-right: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;

        &--sm {
          width: 60px;
          height: 60px;
        }

        &--transparent-border {
          border-color: transparent;
        }

        .letter-circle {
          border-radius: 2px;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 1.5rem;
        }
      }

      .actions {
        display: flex;
        flex-direction: column;

        .button {
          font-size: 0.8125rem;
          height: auto;
          padding: 5px 8px;
          margin-bottom: 7px;
        }

        a {
          color: $primary-color;
          font-size: 0.8125rem;
        }
      }
    }
  }

  &__group-2 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &--choices {
      padding: 5px 0 10px;

      p.label {
        align-self: flex-start;
      }
    }

    &_inner {

      .form-input {
        padding-top: 15px !important;

        &__label {
          font-size: 0.875rem;
        }
      }
    }
    
    &--hover-state {
      padding: 0 5px;

      .form-input {
        width: 100%;
      }

      &:hover {
        background-color: $primary-bg-color;

        .form__group-2 {

          &_input-wrap {
            border-color: transparent;

            .form-input {
              display: block;
            }

            p {
              display: none;
            }
          }
        }
      }
    }

    .label {
      width: 220px;
      min-width: 220px;
      max-width: 220px;
      margin-right: 15px;
      font-weight: 400;
      font-size: 0.875rem;
      color: $septenary-text-color;
    }

    &_input-wrap {
      border-bottom: 1px solid $senary-border-color;
      flex: 1;
      padding: 8px 0;

      p {
        font-size: 1rem;
        font-weight: 700;
        color: $septenary-text-color;
        text-transform: uppercase;
        padding: 2px 0 1px;
      }

      .form-input {
        margin-bottom: 0;
        width: 100%;
        display: none;
      }
    }

    .form-radio {

      &__choices {

        > div {
          margin-bottom: 5px;
        }
      }
    }

    .select_input_wrapper,
    .date_picker {
      width: 100%;
      padding: 0 5px;

      .react-datepicker-wrapper,
      .react-datepicker__input-container {
        width: 100%;
      }

      select:not(.react-datepicker__year-select):not(.react-datepicker__month-select),
      input {
        background-color: rgba($primary-color, 0.12);
        border-radius: 0;
        padding: 5px 10px 4px;
        width: 100%;
        border: 0;
        font-weight: 700;
        border-bottom: 1px solid $primary-dark-color;
        outline: 0;
      }
    }

    @media (max-width: $break-sm-max) {
      display: block;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    .button {
      margin: 0 10px;
    }
  }

  &--search {

    .form-input input {
      background-color: $septenary-bg-color;
    }
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__subtitle-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    > div {
      position: relative;
    }

    a {
      color: $primary-color;
      position: relative;
      display: block;
    }

    .users-dropdown {
      min-width: 280px;
      max-height: 185px;
      overflow-y: auto;
      // padding-top: 50px !important;

      .form-input {
        position: sticky;
        top: -10px;
        width: 100%;
        margin-bottom: 0;
        margin-left: -10px;
        margin-top: -10px;
        width: calc(100% + 20px);
        padding: 10px;
        background-color: $primary-light-color;
      }

      p {
        font-size: 0.875rem;
        color: $secondary-text-color;
        font-weight: 500;
        padding: 5px;
        border-radius: 2px;
        cursor: pointer;
        transition: background-color .3s ease;

        &:hover {
          background-color: $primary-bg-color;
        }

        &.no-data {
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__label {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 10px;
  }
}