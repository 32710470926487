@use '../abstracts/variables_new.scss' as *;

.select-v2--new {
  margin-bottom: 20px;

  .select-v2__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  }

  .select-v2__body {

    &_selected {
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 16px;
      background-color: #fff;
      border: 1px solid $border-color-2;
      outline: 0;
      border-radius: 6px;
      font-size: 0.75rem;
      color: $text-color-4;
      letter-spacing: 0.17px;
      line-height: 1.5;
      width: 100%;

      svg {
        color: $primary-color;
      }

      .drop-icon {
        color: $text-color-6;
        font-weight: 500;
        right: 2px;
      }
    }

    &_dropdown {
      border: 1px solid $border-color-1;
      border-radius: 4px;
      background-color: #fff;

      .dropdown-item {
        border-color: $border-color-5;

        p {
          color: $text-color-6;
        }

        &:hover {
          background-color: $bg-color-1;
        }
      }
    }
  }

  &-light {

    .select-v2__body {

      &_selected {
        background-color: #fff;
        border: 1px solid $border-color-5;
      }
    }
  }

  &-small {
    
    .select-v2__body {
      
      &_selected {
        height: 28px;
      }
    }
  }
}