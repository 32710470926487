@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.dashboard-row {
  display: flex;
  align-items: center;
  // padding: 9px 11px;
  border-bottom: 1px solid #DDE1E3;
  // min-height: 65px;
  position: relative;

  @media (max-width: $break-sm-max) {
    padding-left: 12px;
    padding-right: 12px;
  }

  &:hover {
    // background-color: $primary-bg-color;
    background-color: #F9F8F8;

    .status-el {
      // color: $primary-color;
      // background-color: rgba($primary-color, 0.1);
    }

    .progress-el {
      background-color: rgba($primary-color, 0.2);

      &__inner {
        background-color: $primary-color;
      }
    }

    .dashboard-column__show-on-hover {
      display: flex;
    }
  }

  &.template-row {
      
    .dashboard-column__show-on-hover {
      // display: flex;
      width: auto;
      padding-right: 2px;

      .button--white {
        min-height: 28px;
      }

      .dashboard-column__template-more-wrapper {
        margin-left: 5px;

        .dashboard-column__dropdown {
          right: 0;
        }
      }
    }
  }

  .more {
    display: none;
    
    @media (max-width: $break-md-max) {
      position: absolute;
      right: 15px;
      top: 10px;
      display: block;

      .horiz {
        display: none;
      }
    }

    .dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 5px);
      min-width: 120px;
      background-color: $primary-light-color;
      border-radius: 3px;
      // box-shadow: 0 4px 8px rgba($shadow-color, 0.5);
      z-index: 1;
      white-space: nowrap;
      border: 1px solid $senary-border-color;
      padding: 10px 0;
      z-index: 1;

      &::after, &::before {
        bottom: 100%;
        left: 60%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

        @media (max-width: $break-md-max) {
          left: 90%;
        }
      }
      
      &::after {
        border-color: rgba($primary-light-color, 0);
        border-bottom-color: $primary-light-color;
        border-width: 6px;
        margin-left: -6px;
      }
      &::before {
        border-color: rgba($senary-border-color, 0);
        border-bottom-color: $senary-border-color;
        border-width: 7px;
        margin-left: -7px;
      }

      li {
        
        a {
          font-size: 0.8125rem;
          display: flex;
          align-items: center;
          color: $nonary-text-color;
          padding: 5px 10px;
          transition: background-color .3s ease, color .3s ease;

          &:hover {
            background-color: $tertiary-bg-color;
            color: $primary-color;

            span {
              color: $primary-color;
            }

            &.delete {
              background-color: $bg-color-20;
              color: $text-color-22;
              
              span {
                color: $text-color-22;
              }
            }
          }
          
          span {
            font-weight: 700 !important;
            transition: color .3s ease;
          }

          svg, i {
            font-size: 1.25rem;
            margin-right: 1px;
          }

          &.rotate-180 {

            svg, i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .dashboard-column {
    border-right: 1px solid #f3f3f3;

    &:last-child {
      border-right: 0;
    }

    @media (max-width: $break-md-max) {
      border-right: 0;
    }
  }

  &.document-row {

    .dashboard-column {

      &.col-1 {
        // min-width: 430px;
        // max-width: 430px;
        // width: 430px !important;
        // white-space: normal;

        // @media (max-width: $break-md-max) {
        //   min-width: auto;
        // }

        // .dashboard-column__inner {
          
        //   p.title {
            
        //   }
        // }
      }

      &.col-2 {
        justify-content: center;
        color: $color-12;
      }

      &.col-3 {
        font-size: 0.75rem;
        font-weight: 400;
        justify-content: center;

        span {
          margin-left: 4px;
        }
      }

      &.col-4 {
        justify-content: center;

        .dashboard-column__hide-on-hover {
          font-size: 0.75rem;
          font-weight: 400;
          color: $color-12;
  
          span {
            color: $color-13;
            margin-left: 4px;
          }
        }

        @media (max-width: $break-md-max) {
          position: static;
        }
      }
    }

    @media(max-width: $break-md-max) {
      flex-wrap: wrap;
      padding-top: 10px;

      .dashboard-column {

        &.col-1 {
          width: 100%;
          flex: 0 auto;
          margin-bottom: 10px;
          padding-right: 30px;
          padding-left: 33px;
        }
        &.col-2,
        &.col-3,
        &.col-4,
        &.custom-col {
          display: block;
          width: 100%;
          padding-left: 33px;
          margin-right: 0;
          margin-bottom: 10px;
          text-align: left;
          font-size: 0.875rem;

          &::before {
            content: attr(data-label);
            font-weight: 700;
            float: left;
            margin-right: 10px;
            font-size: 0.875rem;
          }
        }
        // &.col-2 {
        //   width: 50%;
        //   margin-right: 0;
        //   padding-right: 20px;
        //   padding-left: 33px;
        //   justify-content: flex-start;
        // }
        // &.col-3 {
        //   width: 50%;
        //   justify-content: flex-end;
        // }
      }
    }

    &:hover {
      .dashboard-column__hide-on-hover {
        display: none;

        @media (max-width: $break-md-min) {
          display: block;
        }
      }
      .more {
        display: block;

        .vert {
          display: none;

        }

        @media (max-width: $break-md-min) {
          .vert {
            display: block;
          }
          .horiz {
            display: none;
          }
        }
      }
    }
  }

  &.template-row {

    .dashboard-column {

      &.col-1 {
        width: 1px;
        
        .dashboard-column__main {
          display: flex;
          flex: 1;
          min-width: 0;
        }
        
        p.title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;

          @media (max-width: $break-md-max) {
            white-space: normal;
          }
        }
      }
    }

    .show-on-hover {
      display: none;
      margin-right: 14px !important;
    }

    &:hover {

      .show-on-hover {
        display: flex;
        align-items: center;

        > .button {
          margin-right: 11px;
        }

        .dashboard-column__template-more-wrapper {
          height: 24px;
        }
      }

      // .col-2, .col-3 {
      //   display: none;
      // }
    }

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;
      padding-top: 10px;
      padding-bottom: 10px;

      .dashboard-column {
        
        &.col-1 {
          width: 100%;
          flex: 0 auto;
          margin-bottom: 10px;
          padding-right: 30px;
        }
        &.col-2,
        &.col-3,
        &.col-with-label {
          display: block;
          text-align-last: left;
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          padding-left: 33px;
          display: block !important;

          &::before {
            content: attr(data-label);
            float: left;
            font-weight: 700;
            margin-right: 10px;
            font-size: 0.875rem;
            color: #172B4D;
          }
        }
        &.show-on-hover {
          display: flex !important;
          width: 100%;
          padding-left: 33px;

          .button {
            margin-right: 11px;
          }

          .dashboard-column__template-more-wrapper {
            margin-left: auto;
          }

          .icon-button {
            margin-left: auto;
          }
        }
      }
    }

    // @media (max-width: $break-sm-max) {
    //   .dashboard-column {
    //     &.show-on-hover {
          
    //       .icon-button {
    //         margin-right: 3px;
    //       }
    //     }
    //   }
    // }

    // @media (max-width: $break-xs-max) {
    //   .dashboard-column {
    //     &.col-2 {
    //       width: 100%;
    //       margin-bottom: 20px;
    //     }
    //     &.show-on-hover {
    //       width: 100%;
    //       justify-content: space-between;
    //       padding-left: 33px;
    //     }
    //   }
    // }
  }

  .dashboard-column {

    @media (max-width: $break-md-max) {
      &.col-1 {

        .checkbox {
          display: none !important;
        }
        .icon {
          display: block !important;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.signatures-row,
  &.shared-docs-row {
    min-width: 0;

    .more {
      display: block;
      height: 24px;

      .icon-button {
        height: 24px;
      }

      .dropdown {
        top: 100% !important;

        &::before,
        &::after {
          left: 80%;
        }
      }
    }

    .dashboard-column {

      &.col-1 {
        width: 1px;
        
        .dashboard-column__main {
          display: flex;
          flex: 1;
          min-width: 0;
        }
        
        p.title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;

          @media (max-width: $break-md-max) {
            white-space: normal;
          }
        }
      }
    }

    .signatures-col-2,
    .signatures-col-3,
    .signatures-col-4,
    .signatures-col-5 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .signatures-col-2 {
      // flex-direction: row;

      p:not(:last-child) {
        margin-bottom: 5px;
      }

      .tooltip-wrapper:not(:last-child) {
        margin-right: 5px;
      }

      .letter-circle {
        margin: 0 2px;
      }
    }

    .signatures-col-4 {
      text-align: center;
      font-size: 12px;
      color: $color-12;
      width: 145px;
    }

    .ellipsis-wrapper {
      min-width: 0;
      max-width: 100%;
      flex: 1;
    }

    .status-wrapper,
    .recipients-wrapper {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .status {
        height: 23px;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }

      .refresh-icon {
        min-width: 20px;
        vertical-align: middle;
      }

      .letter-circle {
        min-width: 20px;
        display: inline-flex;
      }
    }

    .signatures-col-5 {
      margin-right: 0;
      // justify-content: flex-end;

      .text-overflow {
        min-width: 0;
        max-width: 100%;
      }

      .status {
        cursor: pointer;
      }

      .refresh-icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $primary-color;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 5px;

        svg {
          font-size: 1rem;
          display: block;
        }

        &.refreshing {
          animation: spin .7s linear infinite;
        }

        &.refreshed {
          background-color: $senary-bg-color;
        }
      }
    }

    @media (max-width: $break-md-max) {
      display: block;
      padding-top: 10px;

      .dashboard-column {
        display: block;
        width: auto !important;

        &::before {
          content: attr(data-label);
          font-weight: 700;
          margin-right: 10px;
          float: left;
        }
        
        &.col-1 {
          margin-bottom: 10px;
          padding-right: 30px;
          padding-left: 16px;

          p.title {
            overflow: auto !important;
            white-space: normal !important;
          }

          &::before {
            display: none;
          }
        }
        &.col-2 {
          flex-direction: row;
        }
        &.col-2,
        &.col-3,
        &.col-4,
        &.col-5 {
          max-width: 100%;
          margin-right: 0;
          padding-right: 30px;
          padding-left: 16px;
          margin-bottom: 10px;
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start!important;
        }
        &.col-4 {
          font-size: 0.875rem;
          color: $primary-dark-color;
        }
        // &.col-3 {
        //   width: 100%;
        //   justify-content: flex-end;
        //   margin-right: 0;
        //   margin-bottom: 20px;
        // }
        // &.col-4 {
        //   width: 50%;
        //   margin-right: 0;
        //   padding-right: 20px;
        //   padding-left: 33px;
        //   justify-content: flex-start;
        // }
        // &.col-5 {
        //   width: 50%;
        //   justify-content: flex-end;
        //   margin-right: 0;
        // }
      }
    }
  }

  &.models-row {

    .show-on-hover {
      display: none;

      @media (max-width: $break-lg-max) {
        display: block;
      }
    }

    .dashboard-column {
      padding: 13px 11px;

      .icon {
        position: static;
        transform: translateY(0);
        margin-right: 5px;
      }

      &.show-on-hover {
        padding: 4px 11px;
      }
    }

    &:hover {

      .dashboard-column {

        &.col-1 {

          .icon {
            display: block;
          }
        }
      }

      .show-on-hover {
        display: flex;
        align-items: center;

        .button {
          margin-right: 0;
        }
      }
    }

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;

      .show-on-hover {
        width: 100%; 
        padding-left: 33px;
        margin-top: 20px;
      }
    }
  }

  &.attachment-row {

    .col-1 {

      .checkbox {
        display: none;
      }
    }

    &:hover,
    &.active {

      .col-1 {

        .icon {
          display: none;
        }
        .checkbox {
          display: block;
        }
      }
    }

    &.active {

      p.title {
        color: $primary-color;
      }
    }
  }

  &.folder-row {

    &.hover {
      background-color: darken($tertiary-bg-color, 10);
    }

    @media (max-width: $break-md-max) {
      flex-wrap: wrap;
      padding-top: 10px;

      .col-1 {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 30px;
        padding-left: 33px;

        .icon {
          left: 10px;
        }
      }

      .col-2,
      .col-3,
      .date-col,
      .custom-col,
      .col-with-label {
        width: 100%;
        padding-left: 33px;
        margin-bottom: 10px;
        font-size: 0.875rem;
        justify-content: flex-start;

        &::before {
          color: #172B4D;
          content: attr(data-label);
          font-weight: 700;
          float: left;
          margin-right: 10px;
          font-size: 0.875rem;
        }
      }

      .col-actions,
      .col-helper,
      .col-progress,
      .col-sort_template, 
      .col-status_label, 
      .col-sort_attachments, 
      .col-sort_tasks, 
      .col-sort_alerts, 
      .col-sort_dates, 
      .col-sort_tags,
      .custom-col {
        display: none !important;
      }
    }
  }

  &.contact-row {

    .col-2 {
      flex-direction: column;
    }

    .col-3 {
      word-break: break-all;
    }

    .contact-actions {
      display: none;
    }

    .custom-col {

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.8125rem;
      }
    }

    @media(max-width: $break-md-max) { 
      flex-wrap: wrap;
      padding-top: 10px;

      .col-1 {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 30px;
        padding-left: 33px;
        font-size: 0.875rem !important;

        &::before {
          content: attr(data-label);
          font-weight: 700;
          float: left;
          margin-right: 10px;
        }
      }
      .col-2,
      .col-3,
      .col-4 {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 33px;
        margin-bottom: 10px;
        font-size: 0.875rem !important;

        &::before {
          content: attr(data-label);
          font-weight: 700;
          float: left;
          margin-right: 10px;
        }
      }
      .col-2 {
        flex-direction: row;

        div {

          p {
            font-size: 0.75rem;
            text-align: left;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .col-3 {
        align-items: center;
      }
      .contact-actions {
        display: block;
        position: absolute;
        top: 10px;
        right: 15px;

        .dropdown {
          position: absolute;
          right: 0;
          top: calc(100% + 5px);
          min-width: 120px;
          background-color: $primary-light-color;
          border-radius: 3px;
          z-index: 1;
          white-space: nowrap;
          border: 1px solid $senary-border-color;
          padding: 5px 0;
          z-index: 1;

          &::after, &::before {
            bottom: 100%;
            left: 90%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &::after {
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
            margin-left: -6px;
          }
          &::before {
            border-color: rgba($senary-border-color, 0);
            border-bottom-color: $senary-border-color;
            border-width: 7px;
            margin-left: -7px;
          }

          li {

            a {
              font-size: 0.8125rem;
              display: flex;
              align-items: center;
              color: $nonary-text-color;
              padding: 5px 10px;
              transition: background-color .3s ease, color .3s ease;
              cursor: pointer;
  
              &:hover {
                background-color: $tertiary-bg-color;
                color: $primary-color;
  
                &.delete {
                  background-color: $bg-color-20;
                  color: $text-color-22;
                  
                  span {
                    color: $text-color-22;
                  }
                }
              }
            }
          }
        }
      }
    }

    &:hover {

      .more {
        display: block;

        .vert {
          display: none;

        }

        @media (max-width: $break-md-min) {
          .vert {
            display: block;
          }
          .horiz {
            display: none;
          }
        }
      }
    }
  }

  .date-col {
    font-size: 0.75rem !important;
    font-weight: 400;
    color: $color-12;
    justify-content: center;

    span:not(.normal) {
      color: $color-13;
      margin-left: 4px;
    }
  }

  &.active {

    .dashboard-column.template-col {

      .hover {
        display: block;
      }
      .template {
        display: none;
      }
    }

    // .dashboard-column.tags-col.multiple {

    //   .tags--normal {
    //     display: flex;
    //   }
    //   .tags--inline {
    //     display: none;
    //   }
    // }
  }
}