@use '../abstracts/variables' as *;

.signatures-not-available {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;

  h2 {
    font-weight: 500;
    margin-bottom: 17px;
    color: $text-color-11;
  }

  p {
    color: $nonary-text-color;
    font-size: 0.875rem;
    line-height: 1.43;
    margin-bottom: 20px;
    max-width: 392px;
    text-align: center;
  }

  &__link {
    background-color: $primary-color;
    color: $primary-light-color;
    display: inline-flex;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.714;
    letter-spacing: 0.35px;
    padding: 8px 20px;
    text-decoration: none;
    border-radius: 3px;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken($primary-color, 10);
    }
  }
}

.signatures-success-top {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  h2 {
    font-weight: 500;
    margin-bottom: 17px;
    color: $text-color-11;
  }
}