// Fade
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

// Fade scale
.fade-scale-enter {
  opacity: 0;
  transform: scale(0.7);
}
.fade-scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-scale-exit {
  opacity: 1;
}
.fade-scale-exit-active {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 300ms, transform 300ms;
}

// Fade scale horiz 50px
.fade-scale--horiz-50px-enter {
  opacity: 0;
  transform: scale(0.7) translateX(-50px);
  transform-origin: left;
}
.fade-scale--horiz-50px-enter-active {
  opacity: 1;
  transform: translateX(-50px);
  transform-origin: left;
  transition: opacity 300ms, transform 300ms;
}
.fade-scale--horiz-50px-enter-done {
  transform: translateX(-50px);
  transform-origin: left; 
}
.fade-scale--horiz-50px-exit {
  opacity: 1;
  transform: translateX(-50px);
  transform-origin: left;                   
}
.fade-scale--horiz-50px-exit-active {
  opacity: 0;
  transform: scale(0.7) translateX(-50px);
  transform-origin: left;        
  transition: opacity 300ms, transform 300ms;
}