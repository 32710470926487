@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 34px 14px 34px;
    // box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.5);
    border-bottom: 1px solid $nonary-border-color;
    position: relative;
    
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2;

      @media (max-width: $break-xs-max) {
        font-size: 1.125rem;
      }
    }

    .line-loader {
      top: 100%;
    }

    .search-wrap {
      width: calc(100% - 24px);
      left: 12px;
    }

    .search-trigger-wrapper {
      position: relative;

      .close {
        position: absolute;
        left: 100%;
        top: 0;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      }
    }

    .search-trigger {
      width: 244px;
      height: 32px;
      background-color: rgba(#EBECF0, 0.35);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 1.125rem;
        color: #767676;
      }

      p {
        font-size: 0.875rem;
        margin-left: 2px;
        color: #767676;
      }

      @media (max-width: $break-md-max) {
        width: 36px;

        p {
          display: none;
        }
      }
    }

    &_right {
      display: flex;
      align-items: center;

      .dashboard-btn-wrapper {
        margin-left: 8px;

        .icon-button,
        .username {
          margin-left: 0;
        }

        button:hover {
          color: $primary-color;
          background-color: darken(rgba(#EBECF0, 0.5), 10) !important;
        }
      }

      .icon-button {
        margin-left: 8px;
        width: 36px;
        color: $nonary-text-color;
        background-color: rgba(#EBECF0, 0.35);

        &:hover {
          background-color: darken(rgba(#EBECF0, 0.35), 5);
        }
      }

      @include documentsDropdownWrapper;
      @include username {
        margin-left: 8px;
      }

      @media (max-width: $break-xs-max) {
        .add-documents-dropdown {
          right: 0;
          left: auto;
        }

        .button {
          padding: 0 7px; 

          .text {
            display: none;
          }
        }
      }
    }

    @media (max-width: $break-sm-max) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  
  &__body {
    background-color: $dashboard-bg;
    display: flex;
    flex: 1;
    flex-direction: column; // v2
    padding: 30px 34px; // v2
    overflow-x: hidden;

    &_top-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      padding-right: 34px;

      @media (max-width: $break-sm-max) {
        padding-right: 17px;
      }

      button {

        .icon {
          font-size: 1.25rem;
        }
      }
    }

    &_dnd-wrapper {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      width: 100%;
      flex: 1;

      @media (max-width: $break-xl-max) {
        width: 100%;
        padding-right: 34px;
      }

      @media (max-width: $break-sm-max) {
        padding: 0 17px 0 17px;
      }

      .widgets-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .no-widgets {
        border: 1px solid $primary-color;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125rem;
        width: calc(100% - 34px);
        color: #34353B;
        align-self: flex-start;
        // margin: 0 17px;

        @media (max-width: $break-xl-max) {
          width: 100%;
        }
      }

      .dashboard-box-container {
        // max-width: calc(100% - 34px);
        width: calc(100% - 34px);
        margin: 0 34px 34px 0;
        // height: 400px !important;

        @media (max-width: $break-xl-max) {
          margin: 0 0 34px;
          width: 100%;
          height: auto !important;
        }

        &.active-item {
          background-color: #eee;
          box-shadow: none;

          &::before,
          .drag-handle,
          .more-actions,
          .dashboard-box-container__head,
          .dashboard-box-container__body,
          .dashboard-small-box {
            visibility: hidden;
          }
        }
      }

      .dashboard-box-container--half-width {
        width: calc(50% - 34px);
        margin-right: 34px;

        // &:nth-child(2n+1) {
        //   margin-right: 17px;
        // }
        // &:nth-child(2n+2) {
        //   margin-left: 17px;
        // }

        @media (max-width: $break-xl-max) {
          width: 100%;
          margin-right: 0;

          // &:nth-child(2n+1) {
          //   margin-right: 0;
          // }
          // &:nth-child(2n+2) {
          //   margin-left: 0;
          // }
        }
      }
    }

    &--white-bg {
      background-color: #fff;
    }

    @media (max-width: $break-sm-max) {
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;
    }

    &.filter {
      flex-direction: column;
      padding: 0;

      .dashboard-view {
        flex: 1;
      }
    }
    
    h5 {
      font-size: 0.875rem;
      font-weight: 400;
      color: $nonary-text-color;
      margin-bottom: 20px;
    }

    &_left {
      flex: 1;
      padding: 30px 19px 30px 0;
    }

    &_right {
      width: 298px;
      padding: 30px 0 30px 19px;
      border-left: 1px solid rgba($quaternary-border-color, 0.42);

      @media (max-width: $break-sm-max) {
        border-left: 0;
        border-top: 1px solid rgba($quaternary-border-color, 0.42);
        width: 100%;
        padding-left: 0;
      }
    }

    &_row {
      display: flex;

      @media (max-width: $break-lg-max) {
        display: block;
      }

      .dashboard-box-container {
        flex: 1;
        height: 390px;

        @media (max-width: $break-md-max) {
          height: auto;
        }

        &.tasks-container {
          // min-width: 330px;
          // max-width: 38.82%;
          margin-left: 17px;

          @media (max-width: $break-lg-max) {
            min-width: 1px;
            max-width: 100%;
            margin-left: 0;
          }
        }
      }

      &.two-cols {

        .dashboard-box-container {

          &:first-child {
            margin-right: 8px;
          }

          &:last-child {
            margin-left: 8px;
          }

          @media (max-width: $break-lg-max) {
            &:first-child {
              margin-right: 0;
            }
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .dashboard-view {

      &__head {

        &_top,
        &_bottom {
          padding-left: 34px;
          padding-right: 34px;
        }

        &_top {

          h4 span {
            display: none;
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;
            
            .search-wrap {
              width: calc(100% - 24px);
              left: 12px;
            }
          }
        }
      }

      &__body {

        .dashboard-row {
          padding-left: 34px;
          padding-right: 34px;

          @media (max-width: $break-md-max) {
            .more {
              right: 30px;
            }
          }

          @media (max-width: $break-sm-max) {
            padding-left: 12px;
            padding-right: 12px;

            .more {
              right: 15px;
            }
          }
        }
      }
    }
  }

  &__side-panel {
    position: fixed;
    top: 0;
    right: -360px;
    width: 350px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0,0,0,0.1);
    padding: 15px;
    transition: right .3s ease;
    z-index: 99;

    &--active {
      right: 0;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #34353B;

      .icon {
        display: block;
        cursor: pointer;
        
        svg {
          font-size: 1.25rem;
          display: block;
        }
      }
    }

    section {
      margin-bottom: 30px;

      h3 {
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .info {
        margin-bottom: 20px;

        p {
          font-size: 0.875rem;
        }
      }

      .backgrounds {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__box {
          width: 48px;
          height: 48px;
          margin-right: 5px;
          border-radius: 50%;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .widgets {

        .widget-box {
          border: 1px solid #eee;
          border-radius: 4px;
          padding: 20px;
          margin-bottom: 20px;
          user-select: none;
          cursor: move;

          h4 {
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 10px;
          }

          &__tasks {

            .t-row {
              display: flex;
              align-items: center;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }

              svg {
                font-size: 1.25rem;
                margin-right: 10px;
                color: #aaa;
              }

              .line {
                background-color: #eee;
                height: 5px;
                border-radius: 2px;
              }

              &:nth-child(1) {
                .line {
                  width: calc(80% - 30px);
                }
              }
              &:nth-child(2) {
                .line {
                  width: calc(60% - 30px);
                }
              }
              &:nth-child(3) {
                .line {
                  width: calc(70% - 30px);
                }
              }
              &:nth-child(4) {
                .line {
                  width: calc(50% - 30px);
                }
              }

              &--notepad {

                svg {
                  opacity: 0;
                }

                &:first-child {

                  svg {
                    opacity: 1;
                  }
                }
              }
            }
          }

          &__people {
            display: flex;

            .person-placeholder {
              height: 95px;
              width: 30%;
              border: 1px solid #eee;
              border-radius: 4px;
              padding: 10px;
              margin-right: 10px;

              &__icon {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #eee;
                border-radius: 50%;
                margin: 0 auto 10px;

                svg {
                  display: block;
                  color: #ccc;
                }
              }

              &__lines {

                .line {
                  height: 5px;
                  border-radius: 4px;
                  background-color: #eee;
                  margin: 0 auto 5px;

                  &--1 {
                    width: 80%;
                  }

                  &--2 {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}