@mixin editAddState {
  .textarea {

    &__field {
      height: 54px;
      resize: none;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 10px;

    a.close {
      margin-right: auto;
      color: #162A4E;
      margin-left: 10px;

      svg {
        display: block;
      }
    }

    a:not(.close) {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #162A4E;
      border-radius: 2px;
      margin-left: 5px;
      transition: background-color .3s ease;
      padding: 0 5px;
      font-size: 0.875rem;
      text-decoration: underline;

      svg, i {
        font-size: 1rem;
        margin-right: 5px;
      }

      &:hover {
        background-color: darken(#f3f3f3, 10);
      }

      &.more {

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

.detail-view__section_tasks {

  .progress-wrap {
    position: relative;
    margin-bottom: 20px;

    p {
      position: absolute;
      left: -34px;
      top: -2px;
      font-size: 0.6875rem;
    }

    .progress {
      width: 100%;
      height: 8px;
      background-color: #EBEDF0;
      border-radius: 8px;
      position: relative;

      div {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        height: 100%;
        width: 25%;
        background-color: #2663EB;
      }
    }
  }

  .list-tasks {
    margin-bottom: 15px;

    &__item {
      position: relative;

      &.completed {
        .list-tasks__item {

          &_body {

            p {
              text-decoration: line-through;
            }
          }
        }
      }

      &.show-actions {
        .list-tasks__item {

          &_body {

            .actions {
              display: flex;
            }
          }
        }
      }

      .normal-state {
        padding: 5px 0;
        position: relative;
        
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
        }

        &:hover {
          background-color: #f3f3f3;

          .list-tasks__item {

            &_complete {
              background-color: #f3f3f3;
            }

            &_body {

              .actions {
                display: flex;
              }
            }
          }
        }
      }

      .edit-state {
        padding-bottom: 10px;

        @include editAddState()
      }

      &_complete {
        position: absolute;
        left: -34px;
        top: 0;
        width: 34px;
        height: 100%;
        padding-top: 8px;
        padding-left: 3px;
      }

      &_body {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 0.875rem;
          padding: 4px 0;
        }

        .actions {
          display: none;
          align-items: center;

          a {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            color: #162A4E;
            border-radius: 2px;
            margin-left: 4px;
            transition: background-color .3s ease;
            position: relative;
            padding: 0 4px;
            z-index: 5;

            &.current-date {
              background-color: #F2D600;
              color: #fff;
              
              &:hover {
                background-color: #F2D600;
              }
            }

            &.already-passed {
              background-color: #EB5A46;
              color: #fff;

              &:hover {
                background-color: #EB5A46;
              }
            }

            &.done {
              background-color: #61BD4F;
              color: #fff;
              
              &:hover {
                background-color: #61BD4F;
              }
            }

            svg, i {
              font-size: 1rem;
            }

            span {
              font-size: 0.75rem;
              margin-left: 2px;
            }

            &:hover {
              background-color: darken(#f3f3f3, 10);
            }
          }
        }
      }
    }
  }

  .tasks-foot {

    > .button {
      color: #162A4E;
    }

    .add-state {

      @include editAddState()
    }
  }
}