@use '../abstracts/variables' as *;

.move-to-folder {

  &__head {
    border-bottom: 1px solid $primary-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__body {
    padding: 20px 0;
    color: $quaternary-text-color;

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .breadcrumbs {
        font-size: 0.875rem;

        &__item {

          &.active {
            color: $tertiary-text-color;
          }
        }
      }
    }

    &_main {
      padding: 10px 0;

      .folder-inner {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        padding: 5px;
        transition: background-color .3s ease;
        cursor: pointer;

        &:hover {
          background-color: $primary-bg-color;
        }

        svg {
          display: block;
          margin-right: 10px;
        }
      }

      .no-folders {
        text-align: center;
        padding: 50px 0;
      }

      &-pagination {
        padding-top: 20px;
        display: flex;
        align-items: center;

        .button:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  &__foot {
    padding-top: 20px;
    border-top: 1px solid $primary-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}