.help-search {
  border-bottom: 1px solid #EBECF0;
  padding-bottom: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    position: relative;
    width: 100%;
    max-width: 600px;

    input {
      width: 100%;
      display: block;
      padding: 13px 120px 14px 20px;
      font-size: 1.125rem;
      border: 1px solid #EBECF0;
      border-radius: 4px;
      outline: 0;
      color: #5D6C85;

      &:focus {
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
      }
    }

    button {
      height: 100%;
      position: absolute;
      width: 110px;
      top: 0;
      right: 0;
      border-radius: 0 4px 4px 0;
      font-size: 1rem;
      font-weight: 400;
    }

    .search-suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      padding: 10px 0;
      border-radius: 0 0 4px 4px;
      border: 1px solid #EBECF0;
      border-top: 0;
      background-color: #fff;
      z-index: 99;
      max-height: 280px;
      overflow-y: auto;

      ul {

        li {
          margin-bottom: 0;

          a {
            color: #5D6C85;
            font-size: 1rem;
            display: block;
            padding: 7px 15px;

            &:hover {
              background-color: #f4f4f4;
            }
          }
        }
      }

      p {
        color: #5D6C85;
        font-size: 1rem;
        padding: 7px 15px;
      }
    }

    &--small {
      margin-bottom: 30px;
      
      input {
        padding: 11px 30px 11px 10px;
        font-size: 0.875rem;
      }
      button {
        width: 24px;
        background-color: #fff;
        color: #5D6C85;
        height: calc(100% - 2px);
        top: 1px;
        right: 1px;
        
        svg {
          font-size: 1.125rem;
        }

        &:hover {
          background-color: #fff;
          color: inherit;
        }
      }

      .search-suggestions {
        padding: 5px 0;

        ul {

          li {
  
            a {
              font-size: 0.875rem;
              padding: 5px 10px;
            }
          }
        }

        p {
          padding: 5px 15px;
          font-size: 0.875rem;
        }
      }
    }
  }
}