@use '../abstracts/variables' as *;

.response-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
  background-color: rgba($primary-light-color, 0.5);

  &__text {
    color: $primary-light-color;
    background-color: $primary-color;
    padding: 15px;
    min-width: 300px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 3px;
  }

  &__progress {
    width: 200px;
    height: 10px;
    background-color: #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;

    div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary-color;
      border-radius: 4px;
      transition: width .3s ease;
    }

    p {
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
}