@use '../abstracts/variables' as *;

.cookies-section-wrapper {
  background-color: $primary-light-color;
  box-shadow: 0 5px 10px 0 rgba($shadow-color-11, 0.5);
  padding: 25px 0 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  // max-height: 500px;
  // overflow-y: auto;

  .container {
    display: block;
  }

  .cookies-section {
    position: relative;
    padding-left: 88px;

    @media (max-width: $break-sm-max) {
      padding-left: 50px;
    }

    p {
      font-family: $ff-tertiary;
    }

    &__cookie {
      position: absolute;
      top: 23px;
      left: 0;
      max-width: 100%;
      display: block;

      @media (max-width: $break-sm-max) {
        max-width: 40px;
        top: 10px;
      }
    }

    &__top {
      margin-bottom: 17px;

      p {
        font-size: 0.9375rem;
        line-height: 1.2;
        color: $text-color-25;

        @media (max-width: $break-xs-max) {
          font-size: 0.875rem;
        }
      }
    }

    &__actions {
      margin-bottom: 5px;

      .button {
        margin-right: 15px;
        margin-bottom: 15px;
        font-family: $ff-tertiary-medium;

        @media (max-width: $break-xs-max) {
          font-size: 0.8125rem;
        }
        
        &:not(&--primary) {
          background-color: #f6f6f6;
          color: #656565;

          &:hover {
            background-color: darken(#f6f6f6, 5);
          }
          &:active {
            background-color: darken(#f6f6f6, 10);
          }
        }

        &--primary {
          font-family: $ff-tertiary-bold;
        }
      }
    }

    &__bottom {

      p {
        font-size: 0.8125rem;
        color: #8e8e8e;

        @media (max-width: $break-xs-max) {
          font-size: 0.75rem;
        }

        a {
          color: $primary-color;
        }
      }
    }
  }
}

.cookies-modal {

  .modal__content {
    max-width: 475px;
    padding: 26px;
  }

  &__body {

    &_section {
      margin-bottom: 26px;

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        p {
          font-size: 1rem;
          font-family: $ff-tertiary-medium;
          color: $denary-dark-color;
        }
      }

      &-text {
        font-family: $ff-tertiary;
        font-size: 0.875rem;
        line-height: 1.214;
        color: #656565;
      }
    }

    .button {
      font-family: $ff-tertiary-bold;
      border-radius: 6px;
    }
  }
}