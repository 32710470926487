@use '../abstracts/variables' as *;

.recent-documents-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 20px 0;

  &__cell {
    display: table-cell;
    text-align: center;
    padding: 10px 5px;
    width: 140px;
    
    @media (max-width: $break-md-max) {
      width: auto;
      display: block;
      text-align: left;
      padding-left: 0;
      border-bottom: 1px solid #F8F9FA;

      &:last-child {
        border-bottom: 0;
      }
      
      &::before {
        content: attr(data-label);
        font-weight: 700;
        float: left;
        margin-right: 10px;
        font-size: 0.875rem;
        display: block;
      }
    }

    &:first-child {
      text-align: left;
      width: auto;
      padding-left: 0;
      font-size: 0.875rem;
      line-height: 1.28;
      color: #1B1D24;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.title-cell {
      cursor: pointer;
    }

    &.date-cell {
      font-size: 0.75rem;
      line-height: 1.167;
      color: #34353B;

      span {
        color: #767676;
      }
    }

    .status-el.empty {
      opacity: 0;
    }
  }

  &__row {
    display: table-row;

    @media (max-width: $break-md-max) {
      display: block;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    &--head {

      .recent-documents-table__cell {
        font-size: 0.8125rem;
        line-height: 1.15;
        color: #9B9B9B;
      }

      @media (max-width: $break-md-max) {
        display: none;
      }
    }
  }
}