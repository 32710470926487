@use '../abstracts/variables_new.scss' as *;

.recent-docs-widget-content {

  .recent-docs-table {

    &__row {
      display: flex;
      align-items: center;
      height: 58px;
      border-bottom: 1px solid $border-color-1;

      &--head {
        height: 48px;
        background-color: $bg-color-1;

        .recent-docs-table__cell {
          color: $text-color-2;

          span {
            margin-right: 8px;
          }

          p {
            font-size: 0.625rem;
            letter-spacing: 0.91px;
            line-height: 1.8;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }

    &__cell {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        flex: 1;
        padding-left: 25px;
        padding-right: 10px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 140px;
        justify-content: center;
        padding: 0 5px;
      }

      &:nth-child(2) {
        
        @media (max-width: $break-xs-max) {
          width: 120px;
        }
      }

      &:nth-child(3) {
        
        @media (max-width: $break-sm-max) {
          display: none;
        }
      }

      &:nth-child(4) {
        
        @media (max-width: $break-md-max) {
          display: none;
        }
      }

      &.title-cell {
        cursor: pointer;

        &:hover {

          p {
            color: $primary-color;
          }
        }
      }
    }
  }
}