@use '../abstracts/variables.scss' as *; 

.inject-modal {
  min-height: 500px;

  &__head {
    margin-bottom: 30px;

    h2 {
      display: flex;
      align-items: center;
      font-size: 1.4375rem;
      font-weight: 500;
      letter-spacing: 0.29px;
      color: $secondary-text-color;

      svg {
        display: block;
        margin-right: 10px;
      }
    }
  }

  &__search {
    padding: 10px;
    background-color: $primary-bg-color;
    margin-bottom: 30px;

    .form-input {
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;

    &_left {
      width: 170px;
      margin-right: 20px;

      h6 {
        margin-bottom: 5px;
        padding: 10px 0;
      }

      ul {

        li {
          font-size: 0.875rem;
          margin-bottom: 4px;
          
          .main {
            padding: 5px;
            border-radius: 4px;
            color: $secondary-text-color;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-right: 20px;
            position: relative;

            .num {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 4px;
              height: 100%;
              font-size: 0.75rem;
              font-weight: 700;
            }
          }

          svg {
            display: block;
            margin-right: 5px;
            color: $quinary-text-color;
          }

          .main {

            &:hover,
            &.active {
              background-color: $primary-bg-color;
            }
          }

          .subfolder {
            margin-left: 10px;
            margin-top: 4px;
          }
        }
      }
    }

    &_right {
      flex: 1;

      ul {

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px;
          border-radius: 4px;
          color: $secondary-text-color;

          .icon {
            display: block;

            &.hover {
              display: none;
            }
          }

          svg {
            display: block;
            margin-right: 5px;
            color: $quinary-text-color;
          }

          &:hover {
            background-color: $primary-bg-color;

            .icon {
              display: none;

              &.hover {
                display: block;
              }
            }
          }
        }
      }

      p {

        &.no-data {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 4px;
          background-color: $primary-bg-color;
          color: $secondary-text-color;
        }
      }
    }
  }
}