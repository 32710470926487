@use '../abstracts/variables.scss' as *;

.notaries-modal {

  &__head {
    margin-bottom: 10px;

    &--2 {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 1.4375rem;
      font-weight: 500;
      letter-spacing: 0.29px;
      color: $secondary-text-color;
    }
  }

  &__search {
    padding: 10px 56px 10px 10px;
    background-color: rgba($primary-color, 0.1);
    margin-bottom: 30px;
    border-radius: 50px;
    height: 56px;
    position: relative;

    &--2 {
      padding-right: 10px;
    }

    .form-input {
      margin-bottom: 0;

      input {
        border-radius: 50px;
      }
    }

    .icon-button {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $primary-color;
      color: #fff;

      &:hover {
        background-color: darken($primary-color, 10);
      }
    }
  }

  &__body {

    &_boxes {
      min-height: 300px;
    }

    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 20px 0;
    }

    .form {

      &__group {
        justify-content: space-between;

        .form-input {
          width: 30%;
          max-width: 30%;
        }

        .custom-select {
          margin-bottom: 0;
          width: 100%;
          
          &__selected {
            border-radius: 4px;
            background-color: #fff;
            color: #bbb;
            font-size: 0.875rem;

            .icon {
              color: #bbb;
            }
          }

          .select-dropdown {

            li {
              color: #aaa;
            }
          }
        }
      }

      &__actions {
        justify-content: flex-start;
        padding-top: 10px;

        .button {
          margin: 0 10px 0 0;
        }

        .icon-button {
          margin-left: auto;
        }
      }
    }
  }
}