@use '../abstracts/variables_new.scss' as *;

.report-a-bug-v2 {

  &__body {

    > p {
      font-size: 0.875rem;
      line-height: 1.642;
      color: $text-color-6;
      margin-bottom: 11px;
    }

    p.already-voted {
      padding: 20px;
      text-align: center;
      border: 1px solid $border-color-1;
      margin-top: 10px;
    }

    .upload-section {

      &__dnd {
        margin-top: 13px;
        border: 1px dashed $border-color-2;
        border-radius: 6px;
        padding: 25px 50px;

        p {
          font-size: 0.875rem;
          line-height: 1.214;
          letter-spacing: 0.4px;
          color: $text-color-4;
          text-align: center;

          a {
            color: $primary-color;
          }
        }
      }
    }

    .emojis {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 0 20px;

      a {
        text-align: center;
        width: 60px;
        color: $text-color-7;

        span.material-symbols-outlined {
          font-size: 2.5rem;
        }

        span {
          display: block;
          font-size: 0.8125rem;
        }

        &.active {
          color: $primary-color;
        }
      }
    }

    .features {
      margin: 20px 0;
      overflow-y: auto;
      max-height: 214px;

      &__item {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .checkbox-v2 {
          margin-right: 5px;

          label {
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid $border-color-1;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;

            .checkbox-v2__label {
              font-weight: 400;
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
}