@use '../abstracts/variables' as *;

.custom-popup {
  position: fixed;
  z-index: 1010;

  .folders-popup {
    top: 0;
    bottom: auto;
    left: 0;
    transform: translateX(0);
  }

  &--bottom-left {

    .folders-popup {

      &::after {
        top: auto;
        bottom: 100%;
        left: 24px;
        border-color: rgba($primary-light-color, 0);
        border-bottom-color: $primary-light-color;
        border-width: 6px;
    
        @media (max-width: $break-md-max) {
          left: 24px;
          right: auto;
        }
      }
    }
  }
}