@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.document-detail-appendices {

  &__head {
    padding: 19px 15px 17px;
    border-bottom: 1px solid $primary-bg-color;
    
    h2 {  
      color: $tertiary-text-color;
      font-size: 1.125rem;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }

  &__body {

    .dashboard-row { 
      padding-right: 45px;
      border-color: $primary-light-color;
      padding-left: 15px;

      .dashboard-column {

        &.col-1 {

          p.title {
            font-weight: 400;
          }
        }

        &--actions {
          opacity: 0;
          transition: opacity .3s ease;;
        }
      }

      &:hover {
  
        .dashboard-column--actions {
          opacity: 1;
        }
      }
    }
  }
}