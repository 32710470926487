@use '../abstracts/variables' as *;

.signature-modal {

  &__template {

    .checkbox-wrapper {
      margin-bottom: 16px;
    }

    &_save {
      
      .form-input {
        margin-bottom: 14px !important;
        max-width: 340px;
      }
    }

    &_choose {
      display: flex;
      align-items: center;

      .custom-select {
        max-width: 340px;
        width: 100%;
        margin-right: 15px;
        margin-bottom: 0;
      }

      a {
        color: $primary-color;
        font-size: 0.875rem;
      }
    }

    &_update {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .button {
        
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}