@use '../abstracts/variables' as *;

.create-variable {

  &__head {
    margin-bottom: 20px;
    text-align: center;
  }

  &__body {
    margin-bottom: 20px;

    .form-input {

      &__label {
        margin-bottom: 8px !important;
      }
    }

    .custom-select {
      margin-bottom: 15px;

      &__label {
        font-weight: 400;
      }
    }

    .import-textarea {

      .textarea__field,
      .textarea-v2__field {
        height: 520px;
        resize: none;
      }
    }

    .select-options {
      margin-bottom: 20px;

      > .button {
        padding-left: 5px;

        .icon {
          margin-right: 5px;
        }
      }

      &__group {
        display: flex;
        align-items: center;

        .form-input {
          margin-right: 5px;
        }

        .danger {
          width: 32px;
          height: 32px;
          color: $danger-color;
          transition: background-color .3s ease, color .3s ease;

          svg {
            font-size: 1.25rem;
          }

          &:hover {
            background-color: $danger-color;
            color: #fff;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .button {
      margin-right: 20px;
    }

    .delete {
      margin-left: auto;
      margin-right: 0;
    }
  }
}