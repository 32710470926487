@use '../abstracts/variables_new.scss' as *;

.main-layout {
  background-color: $bg-color-1;
  width: 100%;
  min-height: 100%;
  padding-left: 52px;
  display: flex;
  flex-direction: column;

  &__sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $border-color-5;
    box-shadow: 0 1px 1px 0 $shadow-color-3;
    position: fixed;
    top: 25px;
    left: 276px;
    cursor: pointer;
    z-index: 1;
    color: $text-color-1;
    overflow: hidden;
    text-indent: 8px;
    transition: background-color .3s ease, color .3s ease, left .3s ease, opacity .3s ease;
    opacity: 0;
    z-index: 1000;
    
    span {
      font-size: 1rem;
    }

    &:hover {
      background-color: $primary-color;
      color: #fff;
      opacity: 1;
    }

    &.position-2 {
      left: 41px;
      text-indent: 2px;
      opacity: 1;
    }

    &.show {
      opacity: 1;
    }

    @media (max-width: $break-lg-max) {
      display: none;
    }
  }

  &__sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background-color: rgba(#000, 0.3);

    &.show {
      z-index: 1000;
      opacity: 1;

      @media (min-width: $break-lg-min) {
        display: none;
      }
    }
  }

  &__sidebar-trigger {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 57px;
    top: 29px;
    z-index: 10;
    cursor: pointer;

    span {
      display: block;
      color: $text-color-2;
      transform: rotate(180deg);
    }

    @media (min-width: $break-lg-min) {
      display: none;
    }
  }

  &__content {
    padding: 0 34px;
    display: flex;
    flex-direction: column;
    // height: 100%;
    flex: 1;
    min-height: 0;
    transition: padding .3s ease;

    @media (max-width: $break-sm-max) {
      padding: 0 16px;
    }

    &.with-sidebar {
      padding-left: 277px;

      @media (max-width: $break-lg-max) {
        padding-left: 34px;
      }
    }

    &.sidebar-closed {
      padding-left: 34px;

      .main-layout__content_sidebar {
        left: -191px;

        &.sidebar-mobile {
          left: 0;

          @media (min-width: $break-lg-min) {
            left: -191px;
          }
        }
      }
    }

    &_sidebar {
      position: fixed;
      top: 0;
      left: 52px;
      width: 277px;
      height: 100%;
      transition: left .3s ease;
      z-index: 2;

      @media (max-width: $break-lg-max) {
        left: -191px;
      }

      &.sidebar-mobile {
        left: 0;
        z-index: 1001;

        @media (min-width: $break-lg-min) {
          left: 52px;
          z-index: 2;
        }
      }
    }

    &_body {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      // height: 100%;
      // max-height: 100%;
      // overflow-y: auto;
      // overflow-x: hidden;
      padding-bottom: 20px;
      position: relative;

      .settings-pages-wrapper {
        padding-top: 23px;
      }

      .shared-templates-page {
        padding-top: 23px;
      }
    }
  }
}