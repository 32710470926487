@use '../abstracts/variables_new.scss' as *;

.approvals-steps {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    display: flex;
    align-items: center;
    margin-right: 15px;

    p {
      border-right: 1px solid $border-color-5;
      margin-right: 10px;
      padding-right: 10px;
    }

    .letter-circle-v2:not(:nth-child(2)) {
      margin-left: -5px;
    }
  }
}