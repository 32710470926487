@use '../abstracts/variables_new.scss' as *;

.select-list {
  
  li {
    padding-bottom: 14px;
    border-bottom: 1px solid $border-color-1;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;

    &.folder-item {
      justify-content: flex-start;
      cursor: pointer;

      span {
        display: block;
        margin-right: 9px;
        color: $text-color-10;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      font-size: 0.875rem;
      color: $text-color-6;
      letter-spacing: 0.2px;
      line-height: 1.285;
    }

    button {
      white-space: nowrap;
      display: none;
    }

    .date {
      font-size: 0.8125rem;
      font-weight: 500;
      letter-spacing: 0.19px;
      line-height: 1.384;
      min-width: 90px;
      text-align: right;

      &--small {
        min-width: 45px;
      }

      &.already-passed {
        color: $danger-color;
      }

      &.done {
        color: $highlighted-color-1;
      }

      &.current-date {
        color: $highlighted-color-3;
      }
    }

    .on-hover {
      display: none;
      min-width: 45px;
      align-items: center;
      justify-content: flex-end;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: $text-color-6;
        transition: color .3s ease;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &:hover {

      button {
        display: flex;
      }

      .on-hover {
        display: flex;
      }

      .hide-on-hover {
        display: none;
      }
    }
  }
}