@use '../abstracts/variables.scss' as *;

.approvals-table {
  display: table;
  width: 100%;

  @media (max-width: $break-md-max) {
    display: block;
  }

  &__head {
    display: table-header-group;

    .approvals-col {
      padding-top: 0;
      color: #949BA7;
      user-select: none;

      p.select-all {
        display: none;
      }

      .sort {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #949BA7;

        &:hover {
          color: #172B4D;
        }

        &:active {
          color: #c1c1c1;
        }

        &.active {
          color: #172B4D;
          font-weight: 700;

          &:active {
            color: #c1c1c1;
          }
        }

        svg {
          font-size: 1rem;
          display: block;
          margin-left: 5px;
        }
      }
    }
  }
  
  .approvals-table__head {

    @media (max-width: $break-md-max) {
      display: block;
  
      .approvals-row {
        display: block;
      }
  
      .approvals-col:not(:first-child) {
        display: none;
      }
  
      .approvals-col:first-child {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 8px;

        p.select-all {
          margin-left: 8px;
          display: block;
        }
      }
    }
  }

  &__body {
    display: table-row-group;
  }

  .approvals-table__body {

    @media (max-width: $break-md-max) {
      display: block;
    }

    .approvals-row {

      @media (max-width: $break-md-max) {
        display: block;
        border-bottom: 3px solid #f2f2f2;
        margin-bottom: 20px;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .approvals-col {

      @media (max-width: $break-md-max) {
        display: block;
        padding: 15px 15px 15px 40px;
        width: 100%;
  
        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          padding: 0;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;

          &::before {
            display: none;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        &::before {
          display: block;
          content: attr(data-label);
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }

      &--actions {

        .icon-button {
          
          svg {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .approvals-row {
    display: table-row;
    position: relative;

    .checked-approvals {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 1px);
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 10px 5px 10px 20px;

      @media (max-width: $break-md-max) {
        padding-left: 8px;
      }

      .check-icon {
        color: $primary-color;
        cursor: pointer;
      }

      svg {
        display: block;
      }

      p {
        margin-left: 20px;
        font-size: 0.875rem;

        @media (max-width: $break-md-max) {
          margin-left: 8px;
        }
      }

      .actions {
        margin-left: auto;
        padding-right: 20px;

        a {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          color: #767676;
          padding: 3px 6px;
          border-radius: 5px;
          transition: color .3s ease, background-color .3s ease;

          &:hover {
            background-color: #f2f2f2;
          }

          &.delete-btn {

            &:hover {
              background-color: #FDEAE6;
              color: #ED5534;
            }
          }
        }
      }
    }
  }
  
  .approvals-col {
    display: table-cell;
    padding: 10px 5px;
    vertical-align: middle;
    border-bottom: 1px solid #f2f2f2;
    font-size: 0.875rem;

    &:first-child {
      width: 60px;
      padding-left: 20px;

      svg {
        display: block;
        color: #c3c2c2;
        cursor: pointer;

        &.checked {
          color: $primary-color;
        }
      }
    }

    &:last-child {
      padding-right: 20px;
    }

    // .text-overflow-wrapper {
    //   max-width: 100%;
    //   display: flex;
    //   min-width: 0;
    // }

    // .text-overflow {
    //   overflow: hidden;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   flex: 1;
    // }

    &.selected-col {
      color: $primary-color;
    }

    .steps {
      display: flex;
      align-items: center;

      .step {
        display: flex;
        align-items: center;
        margin-right: 15px;

        p {
          border-right: 1px solid #f2f2f2;
          margin-right: 10px;
          padding-right: 10px;
        }

        .letter-circle:not(:nth-child(2)) {
          margin-left: -5px;
        }
      }
    }
  }
}