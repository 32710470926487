@use '../abstracts/variables_new.scss' as *;

.collection-columns-filter {

  &__list {

    &_item {
      display: flex;
      align-items: center;
      padding: 2px 18px;

      .checkbox-v2 {
        margin-right: 12px;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.142;
        color: $text-color-6;
      }

      .handle {
        cursor:  move;
        margin-left: auto;

        span {
          display: block;
          color: $text-color-6;
        }
      }
    }
  }

  &__actions {
    padding: 20px 42px 0;

    .btn {
      margin-bottom: 8px;
      height: 25px;
      border-color: $border-color-5;
      font-size: 0.75rem;
      justify-content: flex-start;
      padding: 0 4px;
      border-radius: 4px;

      .material-symbols-outlined {
        margin-right: 2px;
        color: $text-color-2;
        font-size: 1.25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}