@use '../abstracts/variables' as *;

.breadcrumbs {
  display: flex;
  align-items: center;

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    span.name {
      display: block;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--last {

      .icon-button {
        margin-left: 5px;
      }

      .more-actions {
        position: absolute;
        left: calc(100% - 24px);
        top: calc(100% + 10px);
        white-space: nowrap;
        background-color: $primary-light-color;
        min-width: 122px;
        border-radius: 3px;
        padding: 10px 0;
        z-index: 10;
        box-shadow: 0 2px 4px rgba($shadow-color-6, 0.5);

        p {
          display: flex;
          align-items: center;
          color: $nonary-text-color;
          cursor: pointer;
          font-size: 0.8125rem;
          font-weight: 700;
          padding: 5px 11px;
          transition: background-color .3s ease, color .3s ease;

          &:hover {
            color: $primary-color;
            background-color: $tertiary-bg-color;

            svg {
              color: $primary-color;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            width: 24px;
            margin-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg {
            font-size: 1.25rem;
            color: $nonary-text-color;
            transition: color .3s ease;
          }

          &.delete {

            &:hover {
              background-color: $bg-color-20;
              color: $text-color-22;

              svg {
                color: $text-color-22;
              }
            }
          }

        }
      }
    }

    p {
      cursor: pointer;
    }

    .more-folders {
      position: absolute;
      top: calc(100% + 10px);
      left: 24px;
    }

    &_more {
      background-color: $primary-light-color;
      box-shadow: 0 0 10px 0 rgba($shadow-color, 0.5);
      border-radius: 3px;
      // width: 120px;
      padding: 10px;
      z-index: 10;
      max-width: 300px;
      min-width: 120px;

      li {
        display: flex;
        align-items: center;
        font-size: 0.8125rem;
        color: $nonary-text-color;
        transition: color .3s ease;
        cursor: pointer;
        font-weight: 700;
        min-width: 0;
        max-width: 100%;

        span.name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover {
          color: $tertiary-text-color;
        }
      }
    }
  }
}