@use '../abstracts/variables_new.scss' as *;

.side-menu-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 52px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid $border-color-1;
  border-right: 1px solid $border-color-1;
  z-index: 999;
  transition: left .3s ease;

  &__overlay {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.3);
    opacity: 0;
    transition: opacity .3s ease;

    &.active {
      opacity: 1;
      z-index: 998;
    }
  }

  &--long {
    left: -196px;
    z-index: 1000;
    width: 196px;

    &.opened {
      left: 0;
    }

    .side-menu-v2__list {

      li {

        a {
          justify-content: flex-start;
        }

        &.divider {
          margin-left: 7px;
        }

        &.menu {

          &::after {
            left: 9px;
            transform: translateX(0);
          }

          .team-menu-wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            .icon-left {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              cursor: pointer;
            }

            .team-menu {
              display: flex;
              align-items: center;
              position: relative;
              user-select: none;
              
              > span {
                cursor: pointer;

                &.text {
                  color: $text-color-6;
                }
              }
            }
          }
        }
      }
    }
  }

  &__list {

    li {
      margin-bottom: 19px;

      a,
      span.menu-span {
        color: $text-color-4;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        transition: color .3s ease;

        svg,
        span.material-symbols-outlined {
          fill: $text-color-4;
          color: $text-color-4;
          display: block;
          transition: fill .3s ease, color .3s ease;
        }

        .icon {
          display: flex;
          width: 50px;
          align-items: center;
          justify-content: center;
        }

        .text {
          color: $text-color-4;
          font-size: 0.875rem;
          font-weight: 500;
          transition: color .3s ease;
        }

        &:hover,
        &.active {
          color: $text-color-1;

          svg {
            fill: $text-color-1;
          }

          span.material-symbols-outlined {
            color: $text-color-1;
          }

          .text {
            color: $text-color-6;
          }
        }

        &.active {

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -1px;
            transform: translateY(-50%);
            width: 2px;
            height: 21px;
            background-color: $primary-color;
          }
        }
      }

      &.menu {
        height: 81px;
        position: relative;
        margin-bottom: 22px;

        &::after {
          content: '';
          width: 31px;
          height: 1px;
          background-color: $border-color-1;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.divider {
        width: 35px;
        height: 1px;
        margin: 0 auto 19px;
        background-color: $border-color-1;
      }
    }
  }
}