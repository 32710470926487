@use '../abstracts/variables.scss' as *;

.select-v2 {

  &__label {
    line-height: 1.33;
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  &__body {
    position: relative;

    &.opened {
      background-color: $primary-bg-color;
      border-radius: 12px 12px 0 0;
      box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
    }

    &_selected {
      border: 1px solid $primary-border-color;
      border-radius: 3px;
      display: flex;
      align-items: center;
      height: 25px;
      padding: 0 26px 0 10px;
      line-height: 1.33;
      position: relative;
      cursor: pointer;
      user-select: none;

      // @media (max-width: $break-md-max) {
      //   height: 30px;
      //   padding: 0 32px 0 12px;
      //   font-size: 0.9375rem;
      //   border-radius: 15px;
      // }

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.875rem;
      }

      svg,
      .drop-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: $primary-color;
      }

      &--colorpicker {
        border: 0;
        padding: 0;

        .color-box {
          width: 23px;
          height: 23px;
          border-radius: 12px;
          border: 1px solid $primary-color;
          margin-right: 7px;

          @media (max-width: $break-md-max) {
            width: 31px;
            height: 31px;
            margin-right: 9px;
            border-radius: 16px;
          }
        }

        .color-text {
          border: 1px solid $primary-color;
          border-radius: 12px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          height: 23px;

          @media (max-width: $break-md-max) {
            height: 31px;
            padding: 0 18px;
            border-radius: 16px;
          }
        }
      }

      &--status {
        border: 0;
        padding: 0;

        p.selected-status {
          display: flex;
          align-items: center;

          span {
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 6px;

            &.draft {
              // background-color: $bg-color-4;
            }
            &.ready {
              // background-color: $highlighted-color-2;
            }
          }
        }
      }
    }

    &_dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      z-index: 5;
      background-color: $primary-bg-color;
      box-shadow: 0 3px 6px 0 rgba(#000, 0.16);
      border-radius: 0 0 3px 3px;
      padding-top: 3px;
      overflow-y: auto;
      max-height: 219px;

      &.align-right {
        right: 0;
        left: auto;
      }

      .dropdown-item {
        padding: 0 10px;
        display: flex;
        align-items: center;
        min-width: 0;
        cursor: pointer;
        border-bottom: 0.5px solid $primary-border-color;
        transition: background-color .3s ease;

        &--empty {
          display: none;
        }

        p {
          padding: 3px 0 4px;
          font-size: 0.875rem;

          // @media (max-width: $break-md-max) {
          //   font-size: 0.9375rem;
          // }
        }

        &:hover {
          background-color: darken($primary-bg-color, 5);
        }

        &:last-child {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }

  &--colorpicker {

    .select-v2__body {

      &.opened {
        background-color: #fff;
        box-shadow: none;
      }

      &_dropdown {
        background-color: #fff;
        border: 0;
        border-radius: 12px;

        .dropdown-item {

          &:first-child {
            border-radius: 12px 12px 0 0;
          }

          p {
            display: flex;
            align-items: center;
          }

          span {
            width: 23px;
            height: 23px;
            border-radius: 12px;
            border: 1px solid $primary-color;
            margin-right: 7px;
            display: block;
          }
        }
      }
    }
  }

  &--status {

    .select-v2__body {

      &.opened {
        background-color: transparent;
        box-shadow: none;
      }

      &_dropdown {
        background-color: #fff;
        border: 0;
        border-radius: 12px;
        padding-top: 0;

        .dropdown-item {

          &:first-child {
            border-radius: 12px 12px 0 0;
          }

          p {
            display: flex;
            align-items: center;
          }

          span.status-box {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 6px;

            &.draft {
              // background-color: $bg-color-4;
            }
            &.ready {
              // background-color: $highlighted-color-2;
            }
          }
        }
      }
    }
  }
}