@use '../abstracts/variables' as *;

.activity-box {
  background-color: $primary-light-color;
  box-shadow: 0 4px 8px 0 rgba($primary-dark-color, 0.1);
  border-radius: 3px;
  padding: 17px 14px 0 14px;
  transition: box-shadow .3s ease;
  cursor: pointer;
  margin-bottom: 22px;
  
  &:hover {
    box-shadow: 0 0 0 0 rgba($primary-dark-color, 0);
  }

  &.ready {

    &:hover {

      .activity-box__title {
        color: $primary-color;
      }
    }

    .activity-box__status {
      color: $primary-color;
      background-color: rgba($primary-color, 0.08);
  
      span {
        background-color: $primary-color;
      }
    }
  }

  &.draft {

    &:hover {

      .activity-box__title {
        color: $tertiary-color;
      }
    }

    .activity-box__status {
      color: $tertiary-color;
      background-color: rgba($tertiary-color-light, 0.54);
  
      span {
        background-color: $tertiary-color;
      }
    }
  }

  &.in-progress {

    &:hover {

      .activity-box__title {
        color: $quaternary-color;
      }
    }

    .activity-box__status {
      color: $quaternary-color;
      background-color: rgba($quaternary-color-light, 0.38);
  
      span {
        background-color: $quaternary-color;
      }
    }
  }

  &.closed {

    &:hover {

      .activity-box__title {
        color: $quinary-color;
      }
    }

    .activity-box__status {
      color: $quinary-color;
      background-color: $quinary-color-light;
  
      span {
        background-color: $quinary-color;
      }
    }
  }

  &.expired {

    &:hover {

      .activity-box__title {
        color: $senary-color;
      }
    }

    .activity-box__status {
      color: $senary-color;
      background-color: $tertiary-bg-color;
  
      span {
        background-color: $senary-color;
      }
    }
  }

  &.canceled {

    &:hover {

      .activity-box__title {
        color: $secondary-color;
      }
    }

    .activity-box__status {
      color: $secondary-color;
      background-color: rgba($secondary-color, 0.12);
  
      span {
        background-color: $secondary-color;
      }
    }
  }

  &__title {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 11px;
    transition: color .3s ease;
  }

  &__status {
    display: inline-flex;
    padding: 2px 7px;
    align-items: center;
    font-size: 0.6875rem;
    margin-bottom: 12px;
    border-radius: 14px;

    span {
      width: 7px;
      height: 7px;
      display: block;
      border-radius: 50%;
      margin-right: 3px;
    }
  }

  &__list {
    width: calc(100% + 28px);
    margin-left: -14px;
    max-height: 340px;
    overflow-y: auto;

    li {
      padding: 8px 14px 6px;
      background-color: $octonary-bg-color;
      border-bottom: 1px solid $quinary-border-color;

      &:last-child {
        border-bottom: 0;
      }

      p {
        font-size: 0.875rem;
        color: $nonary-text-color;
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }

        .name {
          color: $secondary-text-color;
          font-weight: 500;
          margin-right: 4px;
        }

        .ac-status {
          padding-right: 0;
        }
      }
    }
  }
}