@use '../abstracts/variables' as *;

.detail-view-modal {

  .modal__content {
    max-width: 830px;
    background-color: #F8FAFC;
    padding: 30px 30px 30px 22px;
  }
}

.detail-view {
  
  &__content {
    display: flex;

    @media (max-width: $break-md-max) {
      flex-direction: column;

      .detail-view__content_left {
        margin-right: 0;
      } 
      .detail-view__content_right {
        width: auto;
      } 
    }

    &_left {
      flex: 1;
      margin-right: 33px;
    }

    &_right {
      width: 169px;
      min-width: 169px;
    }
  }

  &__section {
    position: relative;
    padding-left: 34px;
    color: #162A4E;

    &--title {
      margin-bottom: 26px;

      .detail-view__section_icon {
        top: 6px;
      }
    }

    &--members {
      margin-bottom: 46px;
    }

    &--description {
      margin-bottom: 36px;
    }

    &--attachments {
      margin-bottom: 80px;

      .detail-view__section_title {
        margin-bottom: 17px;
      }
    }

    &--variables {
      margin-bottom: 70px;

      .detail-view__section_title {
        margin-bottom: 20px;
      }
    }

    &--task-list {
      margin-bottom: 36px;

      .detail-view__section_title {

        h4 {
          cursor: pointer;
        }
      }
    }

    &--comments {

      .detail-view__section_title {
        margin-bottom: 26px;
      }
    }

    &--preview {
      margin-bottom: 60px;
    }
  }

  &--with-preview {

    .detail-view__content_right {
      padding-top: 52px;
    }
  }
}

.detail-view-modal-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.16;
  color: #1B1D24;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  svg {
    display: block;
    margin-right: 10px;
  }
}