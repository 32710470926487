@use '../abstracts/variables_new.scss' as *;

.customize-panel-widget-placeholder {
  border: 1px solid $border-color-4;
  border-radius: 7px;
  padding: 20px;
  cursor:  move;
  height: 140px;
  position: relative;
  overflow: hidden;

  &__head {
    margin-bottom: 17px;

    p {
      font-size: 0.875rem;
      line-height: 1.21;
      color: $text-color-6;
    }
  }

  &__body {

    .alerts-placeholder {
      display: flex;

      &__col {
        width: 50px;

        p {
          color: $text-color-16;
          font-size: 0.8125rem;
          font-weight: 500;
          line-height: 1.23;
          margin-bottom: 12px;

          &.num {
            font-weight: 400;
            color: $text-color-15;
            display: flex;
            align-items: center;
            height: 24px;
            
            &.active {
              justify-content: center;
              width: 24px;
              border-radius: 50%;
              background-color: $bg-color-6;
              color: $text-color-17;
              font-weight: 700;
            }
          }
        }
      }
    }

    .list {

      li {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        span {
          margin-right: 13px;
          color: $text-color-16;
          font-size: 1.25rem;
        }

        .line {
          background-color: $bg-color-6;
          height: 5px;
          border-radius: 2px;

          &--1 {
            width: 94px;
          }

          &--2 {
            width: 184px;
          }

          &--3 {
            width: 152px;
          }

          &--4 {
            width: 194px;
          }

          &--5 {
            width: 117px;
          }
        }
      }
    }

    .docs-placeholder {
      position: absolute;
      bottom: 0;
      left: 20px;
      display: flex;
      align-items: flex-end;

      .bar {
        background-color: $bg-color-6;
        width: 9px;
        border-radius: 5px 5px 0 0;
        margin-right: 17px;

        &--1 {
          height: 59px;
          margin-right: 21px;
        }

        &--2 {
          height: 45px;
        }

        &--3 {
          height: 68px;
        }

        &--4 {
          height: 34px;
          margin-right: 21px;
        }

        &--5 {
          height: 45px;
        }

        &--6 {
          height: 20px;
        }

        &--7 {
          height: 85px;
        }

        &--8 {
          height: 66px;
        }
      }
    }

    .people-placeholder {
      display: flex;
      margin-top: -3px;

      li {
        width: 75px;
        height: 75px;
        border: 1px solid $border-color-4;
        border-radius: 5px;
        background-color: #fff;
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        .material-symbols-outlined {
          font-size: 2.5rem;
          color: $text-color-18;
        }

        .line {
          width: 24px;
          height: 4px;
          border-radius: 2px;
          background-color: $bg-color-6;
          margin-top: 2px;
        }
      }
    }

    .notepad-placeholder {
      position: absolute;
      left: 24px;
      bottom: -140px;
      width: 240px;
      height: 240px;

      svg {
        width: 100%;
        height: auto;
        fill: $text-color-19;
      }
    }
  }
}