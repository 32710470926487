@use '../../abstracts/variables' as *;

.detail-view__section_variables {
  display: flex;

  @media (max-width: $break-xs-max) {
    flex-direction: column;

    .variables-progress {
      margin-bottom: 20px;
    }
  }

  .variables-progress {
    width: 63px;
    height: 63px;
    margin-right: 20px;
    position: relative;
    background-color: #fff;
    border-radius: 50%;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .variables-info {
    position: relative;
    z-index: 1;

    &__num {
      margin-bottom: 5px;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-bottom: 9px;

      p {
        font-size: 0.875rem;
        font-weight: 500;
        margin-right: 5px;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    &__date {

      p {
        font-size: 0.875rem;
        color: #63728A;
      }
    }
  }
}