@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.animated-search-bar--v2 {

  // @media (max-width: $break-md-max) {
  //   width: 150px;
  // }

  // @media (max-width: $break-sm-max) {
  //   width: 44px;
  // }

  @media (max-width: $break-md-max) {
    width: 100%;
    margin-left: 0;
  }

  form {
    background-color: #fff;
    border-radius: 6px;
  }

  .animated-search-bar__icon {
    left: 0;
    width: 39px;
    
    svg {
      color: $text-color-3;
      font-size: 1.25rem;
    }
  }

  input {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid $border-color-1;
    padding: 10px 16px 9px 39px;
    color: $text-color-2;
    font-size: 0.8125rem;
    display: block;
    z-index: 1;
    position: relative;
    
    @include placeholder {
      color: $text-color-2;
    }

    @media (max-width: $break-sm-max) {
      padding-right: 10px;
    }
  }

  &.active {

    input {
      border: 1px solid $primary-color;
      outline: 2px solid rgba($primary-color, 0.34);
      padding-right: 110px;
    }

    @media (max-width: 1100px) {
      width: 600px;
    }

    @media (max-width: $break-md-max) {
      width: 400px;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  form {
    position: relative;
    z-index: 100;
  }

  .animated-search-bar__dropdown {
    top: -7px;
    min-width: calc(100% + 22px);
    left: -11px;
    padding-top: 60px;
    border-radius: 6px;
    box-shadow: 0 5px 10px $shadow-color-2;

    .search-history {

      p {
        margin-bottom: 6px;

        a {
          color: $primary-color;
        }
      }

      &__list {

        li {

          svg {
            display: none;
          }

          a {
            color: $text-color-1;
          }
        }
      }
    }

    &_categories {

      .dropdown-categories-body {
        margin-bottom: 6px;
      }

      button {
        background-color: #fff;
        border: 1px solid $border-color-1;
        height: 31px;
        padding: 0 11px;

        &:hover {
          background-color: $bg-color-1;
        }
    
        &:active {
          background-color: darken($bg-color-1, 5);
        }
      }
    }
  }

  .animated-search-bar__actions {
    padding: 5px;
    z-index: 5;
  }

  p {
    color: $text-color-4;
  }

  .animated-search-bar__category {
    padding: 0 0 0 9px;
    height: 38px;
    display: flex;
    align-items: center;

    .icon {
      display: block;
      margin-right: 7px;

      svg {
        color: $text-color-3;
        display: block;
      }
    }

    button {
      background-color: $bg-color-1;
      border: 1px solid $border-color-1;
      height: 26px;
      padding: 0 3px 0 9px;
      color: $text-color-6;

      &:hover {
        background-color: darken($bg-color-1, 5);
      }
  
      &:active {
        background-color: darken($bg-color-1, 10);
      }
    }
  }
}