@use '../abstracts/variables' as *;

.settings-sidebar {
  position: relative;
  height: 100%;
  background-color: $form-el-bg-color-1;
  border-right: 1px solid $primary-border-color;
  padding: 11px 18px 0;
  display: flex;
  flex-direction: column;

  h3 {
    color: $color-20;
    margin-bottom: 30px;
  }

  section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    h6 {
      margin-bottom: 9px;
      letter-spacing: 1px;
      color: $color-12;
    }

    ul {
      width: calc(100% + 36px);
      margin-left: -18px;

      li {

        a {
          display: flex;
          align-items: center;
          color: $color-12;
          font-size: 0.875rem;
          padding: 8px 18px;
          transition: color .3s ease, background-color .3s;
          
          &.active,
          &:hover {
            background-color: $color-23;
          }

          svg, .thumb {
            display: block;
            margin-right: 15px;
            color: $color-24;
          }

          .thumb {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: $octonary-color;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
          }

          .letter-circle {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            margin-right: 15px;
            font-size: 0.75rem;
          }

          &.active ~ .add {
            color: $tertiary-text-color;
          }
        }

        &.team {
          display: flex;
          flex-wrap: wrap;

          .team__nav {
            width: 100%;
            position: relative;
          }
          
          a {
            display: flex;
            flex: 1;
            padding: 15px 18px;
          }

          .add {
            display: flex;
            align-items: center;
            justify-content: center;
            // padding-right: 21px;
            cursor: pointer;
            color: $color-12;
            margin-left: auto;
            position: absolute;
            top: 50%;
            right: 18px;
            height: 24px;
            transform: translateY(-50%);
            transition: color .3s ease;
           
            svg {
              margin-right: 0;
            }
          }

          ul.team-list {
            flex: 1;
            margin-left: 0px;
            width: 100%;
            padding: 8px 16px;

            li {
              font-size: 0.6875rem;
              text-transform: uppercase;
              color: $color-12;
              padding: 8px 19px;
              border-radius: 3px;
              margin-bottom: 9px;
              cursor: pointer;
              transition: background-color .3s ease, color .3s ease;
              display: flex;
              align-items: center;

              .thumb {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: 700;
              }

              .letter-circle {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                font-size: 0.625rem;
              }

              &.active {
                background-color: $color-23;
              }

              &:hover {
                background-color: darken($color-23, 5);
              }
            }
          }
        }
      }
    }
  }

  &__foot {
    margin-top: auto;
    width: calc(100% + 36px);
    margin-left: -18px;
    border-top: 1px solid $primary-border-color;

    a {
      padding: 13px 19px;
      display: flex;
      align-items: center;
      color: $color-12;
      font-size: 0.875rem;
      transition: background-color .3s ease;

      i {
        font-size: 1.25rem;
        margin-right: 13px;
        transform: rotate(90deg);
        color: $color-24;
      }

      &:hover {
        background-color: $color-23;
      }
    }
  }
}