// Colors
$primary-color: #2C7BE5;
$danger-color: #FF3D65;

$highlighted-color-1: #037645;
$highlighted-color-1-light: #CCF6E4;
$highlighted-color-2: #778395;
$highlighted-color-2-light: #E3E6EA;
$highlighted-color-3: #99522B;
$highlighted-color-3-light: #FDE6D8;
$highlighted-color-4: #146C92;
$highlighted-color-4-light: #D4F2FF;
$highlighted-color-5: #FF3900;
$highlighted-color-5-light: #FEECE8;

$bg-color-1: #F9FBFD;
$bg-color-2: #F6FAFE;
$bg-color-3: #c3c2c2;
$bg-color-4: #EBEDF0;
$bg-color-5: #E2F0FD;
$bg-color-6: #EDEAE9;
$bg-color-7: #fafafa;
$bg-color-8: #f8f9fa;
$bg-color-9: #fafbfc;

$border-color-1: #D4DCE9;
$border-color-2: #D2DDEC;
$border-color-3: #EDF2F9;
$border-color-4: #E7E7E7;
$border-color-5: #E3EBF7;
$border-color-6: #DFE1E6;

$text-color-1: #172B4D;
$text-color-2: #95A9C9;
$text-color-3: #99ACCB;
$text-color-4: #6E83A3;
$text-color-5: #A4B7CF;
$text-color-6: #162A4E;
$text-color-7: #6C757D;
$text-color-8: #212529;
$text-color-9: #727477;
$text-color-10: #6F85A2;
$text-color-11: #A3B6D0;
$text-color-12: #5D6C85;
$text-color-13: #5F6E82;
$text-color-14: #34353B;
$text-color-15: #bbbbbb;
$text-color-16: #AFAFAF;
$text-color-17: #A4A4A4;
$text-color-18: #e4e3e3;
$text-color-19: #EDEAE9;

$shadow-color-1: #2C3F58;
$shadow-color-2: #D4DAE1;
$shadow-color-3: #CDD0D3;

// Letter circle colors
$lc-bg-color-1: #E5FAFF;
$lc-text-color-1: #01CBF2;
$lc-bg-color-2: #F1EBF9;
$lc-text-color-2: #AA6FFB;
$lc-bg-color-3: rgba(255,83,33,0.1);
$lc-text-color-3: #FF3900;
$lc-bg-color-4: rgba(0,214,255,0.1);
$lc-text-color-4: #01CBF2;
$lc-bg-color-5: rgba(107,120,155,0.1);
$lc-text-color-5: #6B789B;
$lc-bg-color-6: rgba(56,182,72,0.1);
$lc-text-color-6: #38B648;
$lc-bg-color-7: rgba(0,110,255,0.1);
$lc-text-color-7: #006EFF;
$lc-bg-color-8: rgba(169,110,251,0.1);
$lc-text-color-8: #A96EFB;

// Widgets info colors
$widget-info-color-1: #19BFFF;
$widget-info-color-2: #20DAD2;
$widget-info-color-3: #FCB400;
$widget-info-color-4: #FF3D65;

// Breakpoints
$break-xxs-max: 375px;
$break-xxs-min: 376px;
$break-xs-max: 480px;
$break-xs-min: 481px;
$break-sm-max: 575px;
$break-sm-min: 576px;
$break-md-max: 767px;
$break-md-min: 768px;
$break-lg-max: 991px;
$break-lg-min: 992px;
$break-xl-max: 1199px;
$break-xl-min: 1200px;