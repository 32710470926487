@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.dashboard-v3 {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .line-loader {
    top: 48px;

    div {
      background-color: #53617D;
    }
  }

  &__head {
    background-color: #2663EB;
    padding: 7px 23px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    z-index: 10;
    position: relative;

    @media (max-width: $break-xs-max) {
      padding: 7px 10px 9px;
    }

    .icon-button {
      width: 36px;
      font-size: 1.5rem;
      margin-right: 5px;

      i.custom-icon-create-outlined {
        font-size: 1.2rem;
      }
    }

    &_left {
      display: flex;
      align-items: center;

      .team-dropdown {
        margin-right: 8px;
        position: relative;

        &__selected {
          display: flex;
          align-items: center;
          background-color: rgba(#EBECF0, 0.35);
          color: $primary-light-color;
          height: 32px;
          padding: 0 9px;
          border-radius: 3px;
          cursor: pointer;
          transition: background-color .3s ease;

          &:hover {
            background-color: rgba(#EBECF0, 0.5);
          }

          p {
            margin-left: 10px;
            margin-right: 9px;
            font-size: 0.9375rem;
            font-weight: 700;

            @media (max-width: $break-xs-max) {
              font-size: 0.8125em;
            }
          }
        }

        &__list {
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          min-width: 100%;
          white-space: nowrap;
          background-color: $primary-light-color;
          box-shadow: 0 2px 4px rgba($shadow-color-7, 0.5);
          border-radius: 3px;
          white-space: nowrap;

          li {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 0 9px;
            cursor: pointer;
            transition: background-color .3s ease;

            p {
              margin-left: 10px;
              font-size: 0.9375rem;
            }

            &:hover {
              background-color: rgba(#EBECF0, 0.35);
            }
          }
        }
      }

      .search-trigger {
        width: 178px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5px;
        background-color: rgba(#EBECF0, 0.35);
        border-radius: 3px;
        cursor: pointer;
        margin-left: 3px;
        margin-right: 10px;

        @media (max-width: $break-md-max) {
          width: 36px;
          padding-right: 0;
          justify-content: center;
        }

        svg {
          display: block;
          color: #fff;
        }
      }

      .icon-button.menu-btn {
        display: none;
      }

      @media (max-width: $break-md-max) {
        .icon-button,
        .dashboard-head-meta {
          display: none;
        }
        .icon-button.menu-btn {
          display: inline-flex;
        }
      }
    }
    
    &_right {
      display: flex;
      align-items: center;

      .icon-button {
        margin-right: 0px;
        margin-left: 8px;
      }

      .user-thumb {
        background-color: rgba(#EBECF0, 0.35);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 8px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }

      @include documentsDropdownWrapper;

      .documents-dropdown-wrapper {

        .add-documents-dropdown {
          right: 0;
          left: auto;
          white-space: nowrap;
          min-width: 120px;
        }
      }

      @media (max-width: $break-xs-max) {
        .icon-button {
          display: none;
        }
      }
    }
  }

  &__body {
    flex: 1;
  }
}