@use '../abstracts/variables_new.scss' as *;

.popup-v2 {

  &__foot {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      min-width: 120px;
      margin: 0 5px;
    }
  }
}