@use '../abstracts/variables' as *;

.popup {
  background-color: $primary-bg-color;
  border-radius: 3px;

  &__head {
    background-color: $primary-light-color;
    padding: 19px 32px;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 1px 3px rgba($shadow-color-4, 0.2);

    h3 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__body {
    padding: 44px 32px 0;
  }

  &__foot {
    padding: 34px 32px 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 3px 3px;

    &--align-right {
      justify-content: flex-end;
    }

    .button {

      &--primary {
        font-size: 0.9375rem;
        font-weight: 700;
      }

      &--transparent {
        color: $primary-color;
        font-weight: 400;
        margin-right: 28px;
        font-size: 0.9375rem;
        letter-spacing: 0.19px;
      }
    }
  }

  &.invite-popup {

    .popup__body {
      padding-top: 14px;
    }

    .popup__foot {
      padding-top: 14px;
      padding-bottom: 20px;

      .button {
        text-transform: uppercase;
        font-size: 0.75rem;
        min-width: 135px;
      }
    }
  }
}