@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.skeleton {
  position: relative;
  background-color: $primary-bg-color;
  overflow: hidden;

  &--title {
    width: 100%;
    max-width: 100%;
    height: 34px;
    margin-bottom: 34px;
    border-radius: 17px;
  }

  &--text {
    width: 75%;
    max-width: 100%;
    height: 24px;
    margin-bottom: 16px;
    border-radius: 12px;
  }

  &--icon {
    width: 100%;
    height: 24px;
  }

  &--small {
    width: 30%;
    height: 20px;
  }

  &--xs {
    width: 30%;
    height: 14px;
  }

  &--form {
    width: 100%;
    height: 33px;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba($senary-bg-color, 0.5), transparent);
    animation: loading 1s infinite;
  }
}