@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.textarea {

  &--edit-mode {

    .textarea__field {
      background-color: rgba($primary-color, 0.12);
      border-radius: 3px;
      padding: 5px 10px 4px;
      height: 94px;
      min-height: 46px;
      width: 100%;
      // resize: none;
    }
  }

  &__field {
    border: 0;
    outline: 0;
    border-radius: 3px;
    background-color: $primary-bg-color;
    font-family: $ff-primary;
    font-size: 0.875rem;
    font-weight: 700;
    color: $nonary-text-color;
    padding: 8px 14px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;
    transition: border .3s ease;

    @include placeholder {
      color: $nonary-text-color;
    }

    &:focus {
      border-color: $primary-color !important;
    }
  }

  &--dashboard-modal {

    .textarea__field {
      background-color: transparent;
      border: 1px solid $tertiary-border-color;
      font-weight: 300;
      color: $primary-text-color;
      padding: 5px;
      min-height: 450px;
      max-height: 450px;

      @include placeholder {
        font-size: 0.75rem;
        color: $quinary-text-color;
      }
    }
  }

  &--form-el {

    .textarea__field {
      background-color: $form-el-bg-color-1;
      border: 1px solid $form-el-border-color-1;
      color: $primary-dark-color;
      border-radius: 2px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 7px 14px;
      height: 177px;
      
      @include placeholder {
        color: $form-el-text-color-1;
      }
    }

    .textarea__label {
      font-size: 0.9375rem;
      font-weight: 400;
      color: $secondary-text-color;
      margin-bottom: 8px;
      display: block;
    }
  }

  &--form-el-optional {

    .textarea__field {
      background-color: $primary-light-color;
      border: 1px solid $tertiary-bg-color;
      color: $primary-dark-color;
      border-radius: 2px;
      font-size: 0.9375rem;
      font-weight: 400;
      padding: 7px 14px;
      height: 110px;
      
      @include placeholder {
        color: $form-el-text-color-1;
      }
    }

    .textarea__label {
      font-size: 0.9375rem;
      font-weight: 400;
      color: $senary-color;
      margin-bottom: 8px;
      display: block;
    }
  }

  &--thick-border {

    .textarea__field {
      border-width: 2px;
    }
  }
}