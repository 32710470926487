@use '../abstracts/variables' as *;

.team {

  .form {
    margin-bottom: 45px;

    &__group {
      margin-bottom: 0;
    }

    .letter-circle-v2 {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
  }

  &__head {
    position: relative;
    margin-bottom: 25px;

    h3.team__subtitle {
      margin-bottom: 0;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    &_dropdown {
      min-width: auto !important;
      
      &::after {
        left: 20px !important;
      }

      ul {
        
        li {
          padding: 5px 10px;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          color: $secondary-text-color;
          cursor: pointer;
          transition: background-color .3s ease, color .3s ease;

          &:hover {
            background-color: $primary-bg-color;
            color: $primary-color;
          }
          
          &.delete:hover {
            background-color: $bg-color-20;
            color: $text-color-22;
            
            svg {
              color: $text-color-22;
            }
          }

          svg {
            font-size: 1.25rem;
            margin-right: 5px;
            transition: color .3s ease;
          }
        }
      }
    }
  }

  &__subtitle {
    font-size: 1.0625rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 10px;

    &--mb-lg {
      margin-bottom: 30px;
    }
  }

  &__add {
    display: flex;
    margin-bottom: 53px;

    @media (max-width: $break-lg-max) {
      display: block;
    }

    &_left {
      margin-right: 50px;
      max-width: 454px;

      @media (max-width: $break-lg-max) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      p {
        font-size: 0.9375rem;
        line-height: 1.333;
      }
    }

    &_right {
      white-space: nowrap;

      .button {
        text-transform: uppercase;
        letter-spacing: 0.3px;
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }

  &__table {
    max-width: 560px;
    
    &_head {
      display: flex;
      margin-bottom: 23px;

      @media (max-width: $break-xs-max) {
        display: none;
      }

      .col {

        &.col-1 {
          width: 260px;

          @media (max-width: $break-lg-max) {
            max-width: 260px;
            width: 100%;
          }
        }
        &.col-2 {
          width: 100px;

          .sort {
            justify-content: center;
          }
        }
        &.col-3 {
          width: 100px;

          .sort {
            justify-content: center;
          }
        }
        &.col-4 {
          width: 100px;
          margin-left: auto;

          .sort {
            justify-content: center;
          }
        }

        .sort {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          color: $quaternary-text-color;
          user-select: none;
          cursor: pointer;

          svg {
            font-size: 1rem;
            display: block;
            margin-left: 2px;
          }

          &.active {
            color: $secondary-bg-color;
            font-weight: 500;
          }

          &:hover {
            color: $secondary-bg-color;
          }

          &:active {
            color: $senary-text-color;
          }
        }
      }
    }

    &_body {

      .row {
        display: flex;
        margin-bottom: 20px;

        @media (max-width: $break-xs-max) {
          display: block;
          padding-right: 30px;
          position: relative;
        }

        .col {

          &.col-1 {
            width: 260px;
            display: flex;

            @media (max-width: $break-lg-max) {
              max-width: 260px;
              width: 100%;
            }
            @media (max-width: $break-xs-max) {
              max-width: 100%;
              margin-bottom: 10px;
            }
          }
          &.col-2,
          &.col-3 {
            width: 100px;
            text-align: center;
            font-size: 0.8125rem;
            line-height: 1.385;
            color: $secondary-bg-color;

            @media (max-width: $break-xs-max) {
              width: 100%;
              padding-left: 54px;
              text-align: left;
            }
          }
          &.col-4 {
            width: 100px;
            margin-left: auto;
            text-align: center;

            @media (max-width: $break-xs-max) {
              width: auto;
              position: absolute;
              top: 0;
              right: 0;
            }

            .wrapper {
              display: inline;
              position: relative;

              .dropdown-el {
                width: 120px;
                margin-left: -60px;
                left: 50%;

                @media (max-width: $break-xs-max) {
                  margin-left: 0;
                  left: auto;
                  right: 0;

                  &::after {
                    left: calc(100% - 20px);
                  }
                }

                li {
                  font-size: 0.8125rem;
                  text-align: left;
                  padding: 5px 10px;
                  cursor: pointer;
                  transition: background-color .3s ease, color .3s ease;

                  &:hover {
                    background-color: $primary-bg-color;
                    color: $primary-color;

                    &.delete {
                      background-color: $bg-color-20;
                      color: $text-color-22;
                    }
                  }
                }
              }
            }

            svg {
              color: $nonary-text-color;
              cursor: pointer;
              border-radius: 3px;
              transition: background-color .3s ease;

              &:hover {
                background-color: $primary-bg-color;
              }
              &:active {
                background-color: darken($primary-bg-color, 5);
              }
            }
          }
        }

        .user {
          display: flex;
          
          &__img {
            width: 36px;
            height: 36px;
            margin-right: 18px;
            border-radius: 3px;
            background-color: $senary-bg-color;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }

          .letter-circle,
          .letter-circle-v2 {
            width: 36px;
            height: 36px;
            border-radius: 3px;
            margin-right: 18px;
          }

          &__info {

            h4 {
              font-size: 0.9375rem;
              font-weight: 500;
              line-height: 1.2;
              color: $primary-dark-color;
              margin-bottom: 1px;
            }

            p {
              font-size: 0.8125rem;
              line-height: 1.385;
              color: $nonary-text-color;
            }
          }
        }
      }
    }
  }

  &__ls-credentials {
    padding: 15px 15px;
    background-color: $primary-bg-color;
    border-radius: 5px;
    margin-top: 10px;
    color: $tertiary-dark-color;
    display: inline-block;
    position: relative;
    .note {
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
    }
    h4, h5 {
      font-weight: bold;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
    }
    .value.visible {
      font-weight: bold;
    }
    .show-button {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }
}

.invite-users {

  p {
    font-size: 1rem;
    line-height: 1.813;
    margin-bottom: 6px;
  }

  .textarea {

    &__field {
      font-size: 0.875rem !important;
      line-height: 1.714;
      letter-spacing: 0.18px;
      height: 71px !important;
      padding: 10px 13px !important;
      color: $secondary-text-color !important;
    }
  }
}

.team-add {

  &--step-1 {
    max-width: 340px;

    .submit-btn {
      min-width: 209px;
    }
  }

  &--step-2 {

    .team__subtitle {
      margin-bottom: 30px;
    }

    .form {
      max-width: 340px;

      .form-input {
        margin-bottom: 26px;

        &__field {
          transition: border .3s ease, background-color .3s ease, box-shadow .3s ease;

          &:focus {
            background-color: $primary-light-color;
            box-shadow: 0 0 0 1px $primary-color;
          }
        }
      }

      .actions {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .button {
          padding: 0 17px;
          min-width: 170px;
          margin-bottom: 15px;
        }

        a {
          min-width: 170px;
          text-decoration: underline;
          text-align: center;
          color: $primary-color;
          font-size: 0.8125rem;
          letter-spacing: 0.33px;
        }
      }
    }
  }
}