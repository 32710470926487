@use '../abstracts/variables' as *;

.add-new-task {
  margin-bottom: 15px;

  &__body {
    border: 1px solid $primary-color;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;

    &_btn-wrap {
      position: relative;
    }
  }

  &__documents-dropdown {
    background-color: $primary-light-color;
    box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
    border-radius: 3px;
    position: absolute;
    bottom: calc(100% + 15px);
    left: -16px;
    width: 330px;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: $primary-light-color;
      content: '';
      z-index: 1;
    }

    &::after {
      position: absolute;
      bottom: -5px;
      left: 50px;
      width: 10px;
      height: 10px;
      background-color: $primary-light-color;
      box-shadow: 0 1px 4px 0 rgba($shadow-color-3, 0.5);
      transform: rotate(-45deg);
      content: '';
    }

    &_head {
      border-bottom: 1px solid $primary-bg-color;
      padding: 7px 11px 6px;

      .form-input {
        margin-bottom: 0;
      }
    }

    &_body {
      height: 150px;
      overflow-y: auto;
      padding: 3px 0;

      ul {

        li {
          font-size: 0.8125rem;
          font-weight: 300;
          color: $primary-dark-color;
          padding: 10px 11px 9px 15px;
          cursor: pointer;
          position: relative;
          z-index: 2;

          &:hover {
            color: $primary-color;
            background-color: $primary-bg-color;
          }

          &.selected {
            background-color: $primary-bg-color;
            padding-right: 36px;

            &:hover {
              color: inherit;
            }
          }

          svg {
            position: absolute;
            top: 5px;
            right: 12px;
            color: $primary-color;
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  &__member-and-date {

    .assign-btn {
      margin-bottom: 10px;
      min-width: 0;
      max-width: 100%;
  
      svg {
        display: block;
        margin-left: 10px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        display: block;
      }
    }

    .date-btn {
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #162a4e;
      border-radius: 2px;
      transition: background-color .3s ease;
      padding: 0 5px;
      font-size: 0.75rem;
      text-decoration: underline;
      margin-bottom: 10px;
      min-width: 0;
      max-width: 100%;

      span {
        display: block;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg {
        margin-right: 5px;
        font-size: 1.25rem;
      }

      &:hover {
        background-color: #dadada;
      }
    }
  }

  &__foot {

    .button {

      &:last-child {
        margin-left: 14px;
        font-weight: 400;
      }
    }
  }
}