@use '../abstracts/variables' as *;

.date_picker {
  
  .react-datepicker-wrapper {
    max-width: 140px;
    
    .react-datepicker__input-container {

      &:focus {
        outline: 0;
      }
      
      input {
        background-color: rgba($primary-color, 0.12);
        padding: 5px 10px 4px 27px;
        border: 1px solid transparent;
        border-radius: 3px;
        font-family: $ff-primary;
        font-size: 0.875rem;
        font-weight: 700;
        color: $nonary-text-color;
        background-image: url('../../assets/images/today-icon.svg');
        background-repeat: no-repeat;
        background-position: 2px center;
        background-size: 22px 22px;
        width: 100%;

        &:focus {
          border-color: $primary-color;
          outline: 0;
        }
      }
    }
  }

  &--2 {

    .date_picker__label {
      font-size: 0.9375rem;
      color: $secondary-text-color;
      margin-bottom: 8px;
    }

    .react-datepicker-wrapper {
      max-width: 100%;
      width: 100%;

      .react-datepicker__input-container {

        input {
          height: 36px;
          border: 1px solid $form-el-border-color-1;
          background-color: $form-el-bg-color-1;
          border-radius: 2px;
          width: 100%;
          background-image: url('../../assets/images/calendar_today.png');
          font-size: 0.875rem;
          font-weight: 400;
          color: $primary-dark-color;
          background-position: 5px center;
          padding-left: 30px;
          transition: border .3s ease;

          &:focus {
            border-color: $primary-color;
          }
        }
      }
    }
  }

  &--thick-border {

    .react-datepicker-wrapper {

      .react-datepicker__input-container {

        input {
          border-width: 2px;
        }
      }
    }
  }

  &--error {

    .react-datepicker-wrapper {

      .react-datepicker__input-container {

        input {
          border-color: $secondary-color;
        }
      }
    }
  }
}

.synthesis {

  .date_picker {

    .react-datepicker-wrapper {
      max-width: 100%;

      .react-datepicker__input-container {

        input {
          color: $primary-dark-color;
        }
      }
    }
  }
}

.react-datepicker__close-icon::after {
  background-color: $primary-color;
}