.detail-view__section_comments {

  .comment {
    position: relative;
    padding-left: 14px;
    margin-bottom: 25px;
    min-height: 36px;

    &__user-thumb {
      position: absolute;
      top: 0;
      left: -36px;
      width: 36px;
      height: 36px;
      background-color: #EBEDF0;
      border-radius: 50%;
    }

    textarea {
      height: 36px;
      resize: none;
      background-color: #fff;
    }

    &__info {

      p {
        font-size: 0.875rem;
        color: #1B1D24;
        margin-bottom: 4px;

        span {
          font-weight: 500;
        }

        a {
          color: inherit;
          text-decoration: underline;
        }

        &.date {
          font-size: 0.8125rem;
          color: #767676;

          span {
            font-weight: 400;

            &.divider {
              margin: 0 5px;
            }
          }

          a.edit {
            margin-left: 10px;
          }
        }
      }
    }

    &__text,
    &__edit {
      background-color: #fff;
      padding: 10px;
      border: 1px solid #DFE1E6;
      border-radius: 3px;
      margin: 5px 0;
      font-size: 0.875rem;
    }

    &__edit {
      position: relative;

      p.hidden-el {
        font-size: 0.9375rem;
        word-break: break-all;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        visibility: hidden;

        span {
          display: block;
        }
      }

      .textarea__field {
        border: 0;
        padding: 0;
        height: auto;
      }

      .actions {
        display: flex;
        align-items: center;

        a {
          margin-left: 10px;
          color: #162A4E;

          svg {
            display: block;
          }
        }
      }
    }
  }
}