@use '../abstracts/variables_new.scss' as *;

.add-contacts-modal-v2 {

  &__body {

    .heading-2 {
      margin-bottom: 20px;
    }

    .input-group-custom-fields,
    .input-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: calc(100% + 15px);

      > div {
        flex: 1;
        min-width: calc(50% - 15px);
        margin-right: 15px;
      }
    }
  }
}