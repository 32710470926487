@use '../abstracts/variables_new.scss' as *;

.folders-popup-v2 {
  position: absolute;
  top: calc(100% + 9px);
  left: 50%;
  width: 253px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 10px $shadow-color-2;
  z-index: 150;
  user-select: none;

  @media (max-width: $break-md-max) {
    left: auto;
    transform: translateY(0);
    right: 0;
  }

  &::after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(#fff, 0);
    border-bottom-color: #fff;
    border-width: 6px;
    margin-left: -6px;

    @media (max-width: $break-md-max) {
      left: auto;
      right: 26px;
    }
  }

  &__head {
    padding: 11px 18px;
    border-bottom: 2px solid $bg-color-1;

    &.root {

      .back {
        display: none;
      }
    }

    &.inner {

      .main {
        display: none;
      }
    }

    .main,
    .back {
      display: flex;
      align-items: center;

      span.icon {
        margin-right: 12px;
        display: block;

        span {
          color: $primary-color;
          display: block;
        }
      }
    }

    .back {

      h5 {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      
      span.icon {
        cursor: pointer;
        position: relative;
        transition: background-color .3s ease;

        &:hover {
          background-color: $bg-color-1;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }

      &__actions {
        margin-left: auto;
        height: 24px;
        display: flex;
        align-items: center;

        .tooltip-wrapper {
          height: 24px;
        }
      }
    }

    svg {
      display: block;
      color: $primary-color;
    }

    h5 {
      font-size: 0.8125rem;
      margin-bottom: 0;
      padding-top: 4px;
      color: $text-color-1;
      text-transform: uppercase;
    }
  }

  &__body {
    height: 153px;
    overflow-y: auto;
    position: relative;

    ul {
      padding-top: 1px;

      &.root {

        li.inner {
          display: none;
        }
      }

      &.inner {

        li.root {
          display: none;
        }
      }

      li {

        .root {
          display: flex;
          align-items: center;
          padding: 7px 18px;
          cursor: pointer;
          transition: background-color .3s ease;

          &:hover {
            background-color: $bg-color-1;
          }
        }

        &.hover {
          background-color: darken($bg-color-1, 10);
        }

        svg {
          display: block;
          color: $primary-color;
        }

        p {
          font-size: 0.8125rem;
          font-weight: 700;
          color: $text-color-1;
          margin-left: 12px;
        }
  
        span.material-symbols-outlined {
          display: block;
          color: $primary-color;
        }

        span.right {
          margin-left: auto;
        }

        .sublevel {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;

          ul {
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    padding: 11px 11px 11px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 -1px 4px 0 $shadow-color-2;
    z-index: 1;
    position: relative;

    .new {
      margin-right: auto;
      color: $primary-color;
      cursor: pointer;
      transition: background-color .3s ease;
      padding: 0 4px;
      margin-left: -4px;

      &:hover {
        background-color: $bg-color-1;
      }

      svg {
        display: block;
      }
    }
  }
}

.draggable-ghost-el-v2 {
  font-size: 1rem;
  padding: 10px 20px;
  display: block;
  position: absolute;
  top: -1000px;
  left: 0;
  background-color: rgba($primary-color, 1);
  color: #fff;
  box-shadow: 0 0 100px rgba($shadow-color-1, 0.9);
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 300px;
  width: auto;
  cursor: all-scroll;
}