@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/animations.scss' as *;

.list-view-v2 {
  margin-top: 23px;
  background-color: #fff;
  border: 1px solid $border-color-5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // height: 100%;
  max-height: 100%;
  flex: 1;
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 46px);

  &__actions {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    border-bottom: 1px solid $border-color-5;
    position: absolute;
    top: 53px;
    left: 0;
    background-color: $bg-color-1;
    z-index: 10;

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      min-width: 48px;
      height: 100%;
      color: $text-color-2;
      user-select: none;

      span.filled {
        color: $primary-color;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

    > p {

      @media (max-width: $break-sm-max) {
        display: none;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      padding-right: 16px;

      a {
        margin-left: 4px;

        @media (max-width: $break-lg-max) {

          .text {
            display: none;
          }
        }

        @media (max-width: $break-xs-max) {
          padding: 0 4px;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 53px;
    border-bottom: 1px solid $border-color-5;

    .search {
      position: relative;
      flex: 1;

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: $text-color-2;

        span {
          display: block;
        }
      }

      input {
        padding: 5px 26px;
        border: 0;
        outline: 0;
        color: $text-color-6;
        font-size: 0.875rem;
        display: block;
        width: 100%;
      }
    }

    .per-page {

      a {
        display: flex;
        align-items: center;

        span {
          color: $text-color-2;
        }
      }

      @media (max-width: $break-xs-max) {
        display: none;
      }

      &--tasks {
        margin-left: auto;
      }
    }

    .actions {
      margin-left: 15px;
      display: flex;
      align-items: center;

      &--tasks {
        // margin-left: auto;

        .icon-btn {
          width: 30px;
          border-radius: 4px;
          color: $text-color-6;
        }

        @media (max-width: $break-xs-max) {
          margin-left: auto;
        }
      }

      .icon-btn {
        margin-left: 5px;
        
        span.material-symbols-outlined {
          font-size: 1.5rem;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .filter {
      margin-left: 15px;

      .btn {
        line-height: 20px;
        text-transform: capitalize;
      }

      @media (max-width: $break-xs-max) {
        display: none;
      }
    }
  }

  &__body {
    flex: 1;
    // height: 100%;
    // min-height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    // display: flex;
    // flex-direction: column;

    .list-view-table-wrapper {
      overflow-y: auto;
      overflow-x: auto;
      max-width: 100%;
      height: 100%;
    }
    
    .list-view-table {
      display: table;
      table-layout: fixed;
      width: 100%;
      padding-left: 47px;

      &--tasks {
        padding-left: 0;

        .list-view-table__head {
          
          .list-view-table__cell {

            &.title-col {

              .sort {
                padding-left: 26px;
              }
            }

            &.delete-col {
              width: 50px;
              min-width: 50px;
              max-width: 50px;
            }

            &.small-task-col {
              width: 100px;
              min-width: 100px;
              max-width: 100px;
            }
          }
        }

        .list-view-table__body {

          .list-view-table__cell {

            &.title-col {
              padding-left: 26px;

              .task-name-wrapper {
                display: flex;
                align-items: center;

                &__check {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 14px;
                  height: 14px;
                  border: 2px solid rgba($text-color-4, 0.4);
                  border-radius: 4px;
                  margin-right: 9px;
                  position: relative;
                  top: 1px;
                  cursor: pointer;
                }
              }
            }

            &.date-col {
              color: $text-color-6;

              span.time {
                color: $text-color-4;
                margin-left: 4px;
              }

              .already-passed {

                span.normal {
                  color: $danger-color;
                }
              }

              .done {

                span.normal {
                  color: $lc-text-color-6;
                }
              }

              .current-date {

                span.normal {
                  color: $widget-info-color-3;
                }
              }
            }

            &.user-col {

              .letter-circle-v2 {
                margin: 0 auto;
              }
            }

            &.delete-col {

              a {
                color: $text-color-2;
                transition: color .3s ease;

                svg {
                  font-size: 1.25rem;
                }

                &:hover {
                  color: $danger-color;
                }
              }
            }
          }

          .completed {

            .list-view-table__cell {

              &.title-col {

                .task-name-wrapper {

                  &__check {
                    border-color: $primary-color;
                    background-color: $primary-color;
                    color: #fff;

                    span {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }

          .text-smaller {
            font-size: 0.75rem;
          }
        }
      }

      &__head {
        display: table-header-group;
        position: sticky;
        top: 0;
        z-index: 1;
        height: 48px;
        
        .list-view-table__row {
          border-top: 1px solid $border-color-5;
          border-bottom: 1px solid $border-color-5;
          height: 48px;
          position: relative;
        }

        .sortable-row-items {
          display: table-row;
          height: 48px;
        }
        
        .list-view-table__cell {
          background-color: $bg-color-1;
          height: 48px;
          display: flex;
          align-items: center;
          position: relative;
          display: table-cell;
          user-select: none;
          border-bottom: 1px solid $border-color-5;

          &.contact-cell {
            padding-right: 40px;

            .sort {
              padding: 0 30px;
            }

            .link-column-to-variable {

              button {
                background-color: $bg-color-5;
                color: $text-color-6;
              }
            }
          }

          .sort {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex: 1;
            cursor: pointer;
            transition: background-color .1s ease;
            min-width: 0;
            max-width: 100%;

            &:hover {
              background-color: darken($bg-color-1, 2);
            }

            span {
              display: block;
              color: $text-color-2;

              &.icon {
                font-size: 1.5rem;
              }

              &.text {
                font-size: 0.625rem;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.91px;
                margin-left: 9px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              &.arrow {
                margin-left: 5px;
              }
            }

            &.active-sort {
              
              span {
                color: $text-color-1;
              }
            }
          }

          .resize-handle {
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 100%;
            z-index: 5;
            cursor: col-resize;

            &--before {
              right: auto;
              left: -10px;
            }

            &::after {
              width: 1px;
              height: 70%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%);
              background-color: $primary-color;
              content: '';
              display: none;
            }

            &:hover {

              &::after {
                display: block;
              }
            }
          }

          &.title-col {
            
            p {
              justify-content: flex-start;
            }
          }

          &.checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            position: absolute;
            top: 0;
            left: -47px;

            span {
              cursor: pointer;
              color: $text-color-2;

              &.filled {
                color: $primary-color;
                font-size: 1.5rem;
              }
            }
          }
        }
      }

      &__body {
        flex: 1;
        overflow-y: auto;
        display: table-row-group;

        .loader-wrapper {
          padding: 20px 0;
          width: 100px;
        }

        .list-view-table__cell {
          padding-right: 10px;
          text-align: center;
          border-bottom: 1px solid $border-color-3;
          vertical-align: middle;

          &.date-col {
            font-size: 0.75rem;
            color: $text-color-2;
          }

          &.title-col {
            text-align: left;
          }

          &.checkbox {
            padding-right: 0;
            user-select: none;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: $text-color-2;
              
              &.checkbox-icon {
                cursor: pointer;
                display: none;
              }
            }

            .filled {
              color: $primary-color;
              font-size: 1.5rem;

              &.row-icon {
                color: $text-color-2;
              }
            }
          }

          .num {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 0.75rem;
            font-weight: 500;
            margin: 0 auto;

            &--1 {
              background-color: $widget-info-color-1;
            }
            &--2 {
              background-color: $widget-info-color-2;
            }
            &--3 {
              background-color: $widget-info-color-3;
            }
            &--4 {
              background-color: $widget-info-color-4;
            }
          }
        }

        .no-data {
          padding: 20px 0;
        }
      }

      &__row {
        display: flex;
        align-items: center;
        height: 58px;
        border-bottom: 1px solid $border-color-3;
        white-space: nowrap;
        flex-wrap: nowrap;
        position: relative;
        display: table-row;

        .checkbox {
          position: absolute;
          top: 0;
          left: -47px;
          width: 47px;
          height: 100%;
          display: flex;
        }

        &.selected {
          background-color: $bg-color-1;

          .template {
            display: none;
          }

          .checkbox {
            background-color: $bg-color-1;

            span {

              &.row-icon {
                display: none;
              }

              &.checkbox-icon {
                display: block;
              }
            }
          }

          .hover {
            display: block;

            .on {
              color: $widget-info-color-2 !important;
            }

            .off {
              color: $widget-info-color-4 !important;
            }
          }
        }

        &:hover {

          .checkbox:not(.default-template) {

            span {

              &.row-icon {
                display: none;
              }

              &.checkbox-icon {
                display: block;
              }
            }
          }

          .list-view-table__cell {
  
            .show-on-hover {
              display: flex;
            }
          }
        }
      }

      &__cell {
        min-width: 200px;
        width: 200px;
        display: table-cell;

        &.title-col {
          position: relative;
        }

        &.checkbox {
          min-width: 47px;
          width: 47px;
        }

        .text-smaller {
          font-size: 0.8125rem;
          color: $text-color-1;
        }

        .hover {
          display: none;
        }

        p.title {
          cursor: pointer;
          user-select: none;
        }

        &.user-col {

          .letter-circle-v2 {
            margin: 0 auto;
          }
        }

        .tags {

          .tag {
            display: inline-flex;
            align-items: center;
            height: 20px;
            padding: 0 8px;
            font-size: 0.675rem;
            color: #fff;
            font-weight: 500;
            border-radius: 8px;
            margin: 0 2px;
          }
        }

        .show-on-hover {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 100%;
          background-color: #fff;

          .btn {
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &.default-template {

          .show-on-hover {

            .btn {
              right: 0;
            }
          }
        }

        .letter-circle-wrapper {
          text-align: center;

          .letter-circle-v2,
          .status-v2,
          .refresh-wrapper {
            margin: 0 2px;
            display: inline-flex;
            vertical-align: middle;
          }

          .refresh-wrapper {
            width: 20px;
            height: 20px;
            background-color: $highlighted-color-4-light;
            color: $highlighted-color-4;
            border-radius: 50%;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.refreshing {
              animation: spin .7s linear infinite;
            }

            &.refreshed {
              background-color: $highlighted-color-2-light;
            }
          }
        }
      }
    }
  }

  &__foot {

  }
}