@use '../abstracts/variables' as *;

.document-detail-variables {
  padding: 39px 36px;
  // padding-bottom: 800px;

  @media (max-width: $break-sm-max) {
    padding: 30px 15px;
  }

  &__head {
    margin-top: 32px;
    flex: 1;

    &_top {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-input {
        width: 140px;
        margin-bottom: 0;
      }

      .doc-variable-el--with-icon {
        display: inline-block;

        .icon {
          right: 0px !important;
        }
      }

      @media (max-width: $break-sm-max) {
        display: block;

        h2 {
          margin-bottom: 10px;
        }
      }
    }

    h3 {
      color: $primary-text-color;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  &__body {

    > .variables:first-child {
      
      .document-detail-variables__head {
        margin-top: 0;
      }
    }

    section {
      margin-bottom: 50px;
    }

    h4 {
      color: $primary-text-color;
      font-weight: 700;
      margin-bottom: 16px;

      span {
        border-bottom: 2px solid $primary-dark-color;
      }
    }
  }
  .select_input_wrapper {
    margin-left: 10px;
    margin-right: 10px;

    &.multiselect {

      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .date_picker {
    margin-left: 10px;
    margin-right: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid #41444d;
  }

  thead {
    background-color: #41444d;
  }

  thead td {
    color: white;
  }
  
  td {
    padding: 8px;
  }

  td .form-input {
    margin: 0;
  }

  td .tooltip-wrapper {
    max-width: 80%;
  }

  .row-count-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    justify-content: flex-end;
  }
  .row-count-input-label {
    margin-right: 10px;
    color: #41444d;
  }
  .row-count-button.button {
    background-color: #41444d;
  }
  .row-count-input {
    width: 20px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    color: #41444d;
    font-weight: bold;
  }

  &__foot {
    margin-top: 37px;

    h4 {
      width: calc(100% + 72px);
      margin-left: -36px;
      border-bottom: 1px solid #98BDFF;
      padding-left: 60px;
      margin-bottom: 20px;

      span {
        padding: 5px 7px 5px 11px;
        display: inline-block;
        background-color: #D9E8F5;
        border: 1px solid #98BDFF;
        border-radius: 5px 5px 0 0;
        font-size: 0.75rem;
        color: #204B91;
        line-height: 1;
        border-bottom: 0;
      }
    }
  }
}