@use '../abstracts/variables_new.scss' as *;

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
  background-color: #fff;
  border: 1px solid $border-color-1;
  border-radius: 6px;
  outline: 0;
  color: $text-color-1;
  cursor: pointer;
  transition: background-color .3s ease;

  svg {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: lighten($border-color-1, 5);
  }

  &:active {
    background-color: lighten($border-color-1, 2);
  }

  &--small {
    height: 28px;
    width: 36px;
  }

  &--light {
    background-color: #fff;
    border: 1px solid $border-color-5;
  }
}