@use '../abstracts/variables' as *;

.edit-task-modal {

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .task-status {
      width: 16px;
      height: 16px;
      border: 1px solid $senary-color;
      border-radius: 50%;
      position: relative;
      margin-right: 8px;
      cursor: pointer;

      svg {
        font-size: 1.2rem;
        position: absolute;
        top: -2px;
        left: -2px;
        color: $senary-color;
      }
    }

    .name,
    .form-input--transparent .form-input__field {
      font-size: 1.1875rem;
      font-weight: 500;
      color: $secondary-text-color;
      flex: 1;
    }

    .form-input--transparent .form-input__field {
      border-radius: 3px;
      border: 1px solid transparent;
      padding: 5px;

      &:focus {
        border: 1px solid $primary-color;
      }
    }

    .form-input--transparent {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .task-member-and-date {
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    > div {
      min-width: 0;

      &:first-child {
        max-width: 80%;
      }
    }

    .assign-btn {
      min-width: 0;
      max-width: 100%;
  
      svg {
        display: block;
        margin-left: 10px;
      }

      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }

    .date-btn {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #162a4e;
      border-radius: 2px;
      margin-left: 5px;
      transition: background-color .3s ease;
      padding: 0 5px;
      font-size: 0.75rem;
      text-decoration: underline;
      min-width: 0;

      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }

      svg {
        margin-right: 5px;
        font-size: 1.25rem;
      }

      &:hover {
        background-color: #dadada;
      }
    }

    @media (max-width: $break-sm-max) {
      display: block;

      .assign-btn {
        margin-bottom: 20px;
      }

      .date-btn {
        margin-left: 0;
        width: auto;
        display: inline-flex;
      }
    }
  }

  &__doc {
    display: flex;
    align-items: center;

    p {
      color: $nonary-text-color;
      font-size: 0.8125rem;
      margin-right: 15px;
      white-space: nowrap;
    }

    .contract-picker {
      margin-bottom: 0;
      min-width: 0;

      &__label {
        display: none;
      }
    }
  }
}