@use '../abstracts/variables' as *;

.documents-view {
  height: calc(100vh - 48px);

  &__change {
    padding: 12px 23px 10px;
    position: relative;
    z-index: 8;
    display: flex;
    align-items: center;

    @media (max-width: $break-xs-max) {
      padding: 12px 10px 10px;
    }

    .space {
      height: 32px;
      width: 1px;
      margin-left: -1px;
    }

    &_title {
      min-height: 32px;
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
    }

    .view-dropdown {
      display: inline-flex;
      position: relative;
      user-select: none;

      &__selected {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 9px;
        background-color: #EBEDF0;
        color: #162A4E;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color .3s ease;

        &:hover {
          background-color: darken(#EBEDF0, 10);
        }

        i {
          font-size: 1.5rem;
        }

        p {
          font-size: 0.875rem;
          font-weight: 400;
          margin: 0 1px 0 5px;
        }

        svg:not(.arrow),
        i {
          color: #2663EB;
        }
      }

      &__list {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        white-space: nowrap;
        background-color: $primary-light-color;
        box-shadow: 0 2px 4px rgba($shadow-color-7, 0.5);
        border-radius: 3px;
        min-width: 100%;
        z-index: 5;

        li {
          display: flex;
          align-items: center;
          padding: 5px 9px;
          cursor: pointer;
          white-space: nowrap;
          color: #53617D;

          i {
            font-size: 1.5rem;
          }
  
          p {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0 1px 0 5px;
          }

          &:hover {
            background-color: #d7dbe3;
          }
        }
      }
    }

    &--variables {

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &--docs {

      @media (max-width: $break-xs-max) {
        flex-direction: column;
        align-items: flex-start;

        .view-filters {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  &__body {
    padding: 0 23px 13px;
    height: calc(100% - 54px);
    overflow-y: auto;

    @media (max-width: $break-xs-max) {
      padding: 0px 10px 13px;
    }

    &--list {
      padding-left: 0;
      padding-right: 0;
    }
  }
}