@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.tags-input {
  margin-bottom: 22px;

  &.active {

    .tags-input__wrapper {
      // border-color: $primary-text-color;
    }
  }

  label {
    display: block;
    font-size: 0.9375rem;
    font-weight: 500;
    color: $secondary-text-color;
    margin-bottom: 8px;
  }

  &__label-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    label {
      margin-bottom: 0;
    }

    a {
      color: $primary-color;
      font-size: 0.8375rem;
      font-weight: 300;
      margin-left: 9px;
    }
  }

  &__wrapper {
    background-color: $form-el-bg-color-1;
    border: 1px solid $form-el-border-color-1;
    border-radius: 2px;
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 3px 6px 3px 6px;
    position: relative;
    transition: border .3s ease;

    &.active {
      border-color: $primary-color;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    .tag {
      background-color: $tags-bg-color;
      color: $primary-color;
      border: 1px solid $tags-border-color;
      border-radius: 3px;
      padding: 3px 26px 3px 8px;
      font-size: 0.875rem;
      margin-right: 5px;
      margin-bottom: 2px;
      margin-top: 2px;
      transition: background-color .3s ease;
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        color: $primary-color;
      }

      &:hover {
        background-color: darken($tags-bg-color, 2);
      }
    }

    input {
      border: 0;
      outline: 0;
      margin-bottom: 2px;
      margin-top: 2px;
      padding: 3px 0;
      flex: 1;
      min-width: 80px;
      font-size: 0.9375rem;
      color: $primary-dark-color;
      background-color: transparent;
      font-family: $ff-primary;
      font-weight: 400;

      @include placeholder {
        color: $form-el-text-color-1;
      }
    }
  }

  &--large {

    .tags-input__tags {

      .tag {
        line-height: 24px;
      }

    }

    input {
      height: 32px;
    }
  }

  &--thick-border {

    .tags-input__wrapper {
      border-width: 2px;
    }
  }

  &--error {

    .tags-input__wrapper {
      border-color: $secondary-color;
    }
  }
}