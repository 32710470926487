.add-contacts-modal {

  &__head {
    padding: 10px 0;
    border-bottom: 2px solid rgba(0,0,0,0.05);
    margin-bottom: 30px;

    h2 {
      font-weight: 500;
      color: #333;
    }
  }

  &__body {

    .form {

      &__group {
        margin-bottom: 15px;

        .form-input {

          label {
            margin-bottom: 5px;
            font-size: 0.875rem;
            font-weight: 400;
          }

          &:first-child {
            margin-right: 15px;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .date_picker,
        .custom-select {
          flex: 1;
          margin-bottom: 0;

          &__label {
            margin-bottom: 5px;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }

      &__custom-fields-title {
        margin-bottom: 10px;
        font-size: 1.25rem;
        font-weight: 500;
      }

      &__custom-fields {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% + 15px);

        > div {
          flex: 1;
          min-width: calc(50% - 15px);
          margin-right: 15px;

          label {
            font-size: 0.875rem;
            margin-bottom: 5px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid rgba(0,0,0,0.05);
    margin-top: 30px;
    padding-top: 30px;

    .button {
      margin: 0 10px;
    }
  }
}