@use '../abstracts/variables' as *;

.switch-el {

  label {
    cursor: pointer;

    input {
      display: none;
    }

    input:checked ~ .switch-el__btn {
      background-color: $primary-color;

      &::after {
        left: 18px;
      }
    }
  }

  &__btn {
    width: 36px;
    height: 20px;
    border-radius: 16px;
    background-color: $switch-bg;
    position: relative;
    transition: background-color .3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $primary-light-color;
      transition: left .3s ease;
    }
  }

  &--small {

    .switch-el__btn {
      width: 20px;
      height: 12px;
      border-radius: 8px;

      &::after {
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }

    label {

      input:checked ~ .switch-el__btn {
  
        &::after {
          left: 10px;
        }
      }
    }
  }
}