@use '../abstracts/variables_new.scss' as *;

.modal-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.6);
  }

  &__inner {
    max-height: 100%;
    position: relative;
    width: 100%;
  }

  &__content-wrapper {
    padding: 50px 0;
    width: 100%;
  }

  &__content {
    max-width: 500px;
    width: 90%;
    margin: 50px auto;
    background-color: #fff;
    border-radius: 6px;
    z-index: 10;
    position: relative;
    box-shadow: 0 4px 15px 0 rgba($shadow-color-1, 0.35);

    .modal-v2-header {
      position: relative;
      padding: 22px 52px 22px 32px;
      border-radius: 6px 6px 0 0;
      border: 1px solid rgba($text-color-6, 0.12);

      h5 {
        font-weight: 500;
        font-size: 1.125rem;
        color: $text-color-6;
        line-height: 1.3333;
        display: flex;
        align-items: center;

        @media (max-width: $break-xs-max) {
          font-size: 1rem;
        }

        strong {
          margin: 0 5px;
          font-weight: 700;
          display: inline-flex;
          align-items: center;
        }

        svg {
          font-size: 1.125rem;
          display: block;
          margin-right: 10px;
        }

        span.material-symbols-outlined {
          margin-right: 10px;
          display: block;
        }
      }

      .modal-v2-close {
        color: $text-color-7;
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        transition: background-color .3s ease;

        svg {
          display: block;
          font-size: 1.25rem;
        }

        &:hover {
          background-color: darken($bg-color-1, 5);
        }

        &:active {
          background-color: darken($bg-color-1, 10);
        }
      }
    }

    .modal-v2-body {
      border: 1px solid rgba($text-color-6, 0.12);
      padding: 19px 32px;

      @media (max-width: $break-xs-max) {
        padding: 19px 16px;
      }
    }
    
    .modal-v2-foot {
      padding: 16px 32px;
      border: 1px solid rgba($text-color-6, 0.12);
      border-radius: 0 0 6px 6px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        margin-left: 13px;
      }
    }
  }

  &__out-of-box {
    width: calc(100% + 64px);
    margin-left: -32px;
    padding: 0 32px;
  }

  &__section-with-border {
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid $border-color-1;
  }

  &.no-footer {

    .modal-v2__content {

      .modal-v2-body {
        border-radius: 0 0 6px 6px;
      }
    }
  }

  &--medium {

    .modal-v2__content {
      max-width: 606px;
    }
  }

  &--large {

    .modal-v2__content {
      max-width: 900px;
    }
  }

  &.centered-head {

    .modal-v2__content {

      .modal-v2-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid $border-color-3;

        h5 {
          font-size: 1rem;
          line-height: 1.1875;
        }
      }

      .modal-v2-body {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
}