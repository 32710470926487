@use '../abstracts/variables_new.scss' as *;
@use '../abstracts/mixins.scss' as *;

.field-mapping-v2 {
  display: flex;
  border-bottom: 1px solid $border-color-1;
  padding-bottom: 10px;
  margin-bottom: 15px;
  align-items: flex-start;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }

  &__main {
    width: 150px;
    margin-right: 5px;

    &_body {
      display: flex;
      align-items: center;
      background-color: $bg-color-2;
      border-radius: 3px;
      min-height: 32px;
      padding: 5px 10px;
      cursor: pointer;
      user-select: none;
      position: relative;

      p {
        margin-left: 8px;
        font-size: 0.8125rem;
        color: $text-color-6;
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &:hover {
        opacity: 0.8;
      }

      .switch-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      .edit-custom-field {
        position: absolute;
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        z-index: 11;

        button {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          border-radius: 3px;
          outline: 0;
          background-color: $primary-color;
          color: #fff;

          span {
            display: block;
            font-size: 0.75rem;
          }
        }
      }
    }

    &_link-variable {
      padding-top: 5px;

      a {
        color: $primary-color;
        text-decoration: none;
        font-size: 0.8125rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__csv {
    flex: 1;
    display: flex;
    align-items: center;

    .arrow {
      margin-right: 5px;

      span {
        display: block;
        color: $text-color-2;
        transform: rotate(180deg);
      }
    }

    > div:not(.arrow) {
      flex: 1;
    }
  }
}