@use '../../abstracts/variables' as *;

.add-custom-fields {

  &__head {
    margin-bottom: 20px;
    text-align: center;

    h2 {
      font-weight: 400;
    }
  }

  &__body {

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_link {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid transparent;
      
      svg {
        margin-right: 2px;
        font-size: 1rem;
      }

      &.add-field {
        color: $primary-color;
  
        &:hover {
          border-bottom: 1px solid $primary-color;
        }
      }

      &.remove-field {
        color: #EB5A46;
        font-size: 0.875rem;

        svg {
          position: relative;
          top: -1px;
        }

        &:hover {
          border-bottom: 1px solid #EB5A46;
        }
      }
    }


    .new-field {
      border: 1px solid #EBEDF0;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 4px;
      position: relative;

      &.error {
        border-color: #EB5A46;
      }

      .drag-handle,
      .toggle-content {
        cursor: grab;
        position: absolute;
        top: 5px;
        right: calc(100% + 1px);
        width: 20px;
        height: 20px;
        background-color: #ebedf0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 1rem;
          color: #5D6C85;
        }
      }

      .toggle-content {
        top: 30px;
        cursor: pointer;
      }

      &__name {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #5D6C85;

        svg, i {
          font-size: 1.25rem;
          margin-right: 10px;
        }
      }

      &__other-fields {
        margin-bottom: 20px;

        .options-sort {
          margin-bottom: 5px;
          display: flex;
          justify-content: flex-end;

          a {
            font-size: 0.75rem;
            display: inline-flex;
            align-items: center;
            background-color: #5D6C85;
            padding: 4px 10px;
            color: #fff;
            border-radius: 4px;
            transition: background-color .3s ease;

            &:hover {
              background-color: darken(#5D6C85, 10);
            }

            svg {
              font-size: 1rem;
              margin-right: 2px;
            }
          }
        }

        .options {

          .select-option {
            margin-bottom: 10px;

            .view-mode {
              padding: 10px 10px 10px 5px;
              display: flex;
              align-items: center;
              border: 1px solid #EBEDF0;
              border-radius: 4px;

              p {
                font-size: 0.875rem;
              }

              a.add {
                margin-left: auto;

                &:hover {
                  border-bottom: 0;
                }
              }

              svg.option-handle {
                margin-right: 5px;
                cursor: grab;
              }
            }

            .edit-mode {
              background-color: #EBEDF0;
              padding: 10px;
              border-radius: 4px;

              .inputs {
                display: flex;
                justify-content: space-between;

                .form-input {
                  width: 100%;
                }

                // .form-input:first-child {
                //   margin-right: 10px;
                // }

                .form-input__label {
                  font-size: 0.75rem;
                }
              }

              .actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .button {
                  margin-left: 10px;
                }

                .icon-button {

                  svg {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }

        .add,
        .remove {
          font-size: 0.8125rem;
          display: inline-flex;
          align-items: center;
          color: #5D6C85;
          border-bottom: 1px solid transparent;
          
          &:hover {
            border-bottom: 1px solid #5D6C85;
          }

          svg {
            display: block;
            margin-right: 2px;
            font-size: 1rem;
          }
        }

        .remove {
          color: #EB5A46;
          margin-left: 5px;
          border: 0;

          &:hover {
            border: 0;
          }

          svg {
            font-size: 1.25rem;
            margin-right: 0;
          }
        }
      }

      &__actions {
        display: flex;
        
        .icon-button {
          height: 30px;
          width: 30px;

          svg {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

.user-custom-fields-wrapper {
  
  &--section {
    border-top: 1px solid #EBEDF0;
    padding-top: 15px;
    margin-top: 20px;
  }

  > p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #5D6C85;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .custom-fields {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;
      }

      &.textarea {
        margin-bottom: 15px;

        .textarea__field {
          height: 80px;
          resize: none;
        }
      }

      &.date-picker-field,
      &.custom-select {
        margin-bottom: 15px;
      }

      .form-input__label,
      .textarea__label,
      .date-picker-field__label,
      .custom-select__label {
        font-size: 0.875rem;
        font-weight: 700;
        color: #5D6C85;
        margin-bottom: 6px;
        white-space: normal;
      }

      .date-picker-field__input {
        position: relative;

        .date-icon {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          color: $primary-color;
          z-index: 10;
        }

        .react-datepicker-wrapper {

          input {
            padding-left: 40px;
          }
        }
      }
    }
  }
}

.dragged-field {
  background-color: #fff !important;
  color: #34353B !important;
  
  p {
    color: #34353B !important;
  }

  &--2 {
    background-color: #fff;
    color: #172b4d !important;
    
    p {
      color: #172b4d !important;
    }

    p.handle {
      display: none;
    }
  }
}
.selected-field {
  background-color: lighten(#EBEDF0, 5);

  &--2 {
    background-color: red;
    color: #172b4d;
  }
}