@use '../abstracts/variables.scss' as *;

.template-shared-modal {

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    svg {
      display: block;
      font-size: 2rem;
      color: $primary-color;
    }
  }

  &__message {
    margin-bottom: 30px;

    p {
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    align-self: center;
    justify-content: center;

    .button {
      margin-right: 15px;
      height: 36px;
    }

    .link-wrapper {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}