@use '../abstracts/variables' as *;

.selected-resources {
  position: fixed;
  bottom: 0;
  left: 281px;
  width: calc(100% - 281px);
  background-color: $primary-light-color;
  padding: 0 0 0 33px;
  height: 74px;
  box-shadow: 0 -1px 10px 0 rgba($shadow-color-2,0.5);
  transform: translateY(100%);
  transition: transform .3s ease, left .3s ease, width .3s ease;
  z-index: 1;

  @media (max-width: $break-lg-max) {
    width: 100%;
    left: 0;
    bottom: 60px;
    transform: translateY(calc(100% + 60px));
  }

  &.opened {
    transform: translateY(0);
  }
  
  &__inner {
    display: flex;
    align-items: center;
    max-width: 555px;
    height: 100%;
  }

  &__remove {
    margin-right: 17px;

    svg {
      font-size: 1.25rem;
      display: block;
      color: $primary-border-color;
      cursor: pointer;
    }
  }

  &__text {

    p {
      font-size: 0.8125rem;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $primary-color;

    > svg {
      cursor: pointer;
    }

    &_popup-wrap {
      position: relative;
    }

    &_btn {
      height: 35px;
      padding: 0 10px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10px;
      transition: background-color .3s ease;
      
      &:hover {
        background-color: $primary-bg-color;
      }

      svg {
        display: block;
      }
    }
  }
}