.detail-view__section_preview {
  padding-top: 7px;

  .preview-box {
    max-width: 325px;
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(#646464, 0.5);

    .react-pdf__Document {

      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 20px;
      }
    }
  }

  .preview-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.375rem;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.8125rem;
      color: rgba(#162A4E, 0.69);
    }
  }
}