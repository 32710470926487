@use '../abstracts/variables_new.scss' as *;

.alerts-calendar {
  padding-top: 23px;
  position: relative;

  .fc {
    background-color: #fff;
    border: 1px solid $border-color-5;
    border-radius: 8px;
    padding: 15px;

    .fc-header-toolbar {
      margin-bottom: 19px;

      @media (max-width: $break-sm-max) {
        flex-direction: column;

        .fc-toolbar-chunk:first-child {
          margin-bottom: 10px;
        }
      }

      .fc-toolbar-chunk {
        display: flex;
        align-items: center;

        @media (max-width: $break-sm-max) {
          flex-wrap: wrap;
          justify-content: flex-end;
          justify-content: center;

          > * {
            margin-top: 2px;
            margin-bottom: 2px;
          }
        }
      }

      .fc-toolbar-title {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.125;
        color: $text-color-4;
      }

      .fc-today-button,
      .fc-prev-button,
      .fc-next-button,
      .fc-listBtn-button,
      .fc-addBtn-button {
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid $border-color-5;
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 0.8125rem;
        color: $text-color-6;
        font-weight: 400;
        outline: 0;
        box-shadow: none !important;
        transition: background-color .3s ease;

        &:hover {
          background-color: $border-color-5;
        }

        &:active {
          background-color: darken($border-color-5, 5);
        }
      }

      .fc-prev-button,
      .fc-next-button {
        width: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 1rem;
        }
      }

      .fc-prev-button {
        margin-left: 5px;
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }

      .fc-next-button {
        margin-left: 0;
        border-radius: 0 4px 4px 0;
      }

      .fc-listBtn-button {
        padding: 0 10px 0 4px;
        margin-left: 5px;

        span {
          font-size: 1.25rem;
          margin-right: 4px;

          &.close-icon {
            margin-right: 0;
          }
        }
      }

      .fc-addBtn-button {
        padding: 0;
        width: 30px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 1.25rem;
          color: $text-color-6;
        }
      }
    }

    .fc-alerts-table-wrapper {

      table.fc-scrollgrid {
        border: 0;

        th {
          border: 0;
        }

        .fc-col-header {

          th {
            border: 0;

            > div {
              text-align: left;
              padding-left: 4px;
              padding-bottom: 9px;

              a {
                display: block;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.25;
                color: $text-color-6;
                padding: 0;
              }
            }
          }
        }

        tbody {

          tr.fc-scrollgrid-section {

            > td {
              border: 1px solid $border-color-3;

              > div.fc-scroller-harness {
                
                .fc-scroller {
                  -webkit-overflow-scrolling: touch;
                  scrollbar-width: thin;
                  scrollbar-color: #c4c4c4 rgba(0,0,0,0.06);

                  &::-webkit-scrollbar {
                    height: 14px;
                    width: 14px;
                  }
                  &::-webkit-scrollbar-button:start:decrement,
                  &::-webkit-scrollbar-button:end:increment {
                    background: transparent;
                    display: none;
                  }
                  &::-webkit-scrollbar-track-piece {
                    background: transparent;
                  }
                  &::-webkit-scrollbar-track-piece:vertical:start {
                    border-radius: 4px 4px 0 0;
                  }
                  &::-webkit-scrollbar-track-piece:vertical:end {
                    border-radius: 0 0 4px 4px;
                  }
                  &::-webkit-scrollbar-track-piece:horizontal:start {
                    border-radius: 4px 0 0 4px;
                  }
                  &::-webkit-scrollbar-track-piece:horizontal:end {
                    border-radius: 0 4px 4px 0;
                  }
                  &::-webkit-scrollbar-thumb:vertical,
                  &::-webkit-scrollbar-thumb:horizontal {
                    display: block;
                    height: 48px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    border-radius: 9999px;
                    background-color: #c4c4c4;
                  }
                }
              }
            }
          }

          td {

            &.fc-daygrid-day {
              border: 1px solid $border-color-3;

              &:hover {

                .fc-daygrid-day-top {

                  a {

                    span.plus {
                      opacity: 1;
                    }
                  }
                }
              }

              .fc-daygrid-day-top {
                flex-direction: row;

                a {
                  padding: 4px 9px;
                  font-size: 0.8125rem;
                  font-weight: 500;
                  line-height: 1.23;
                  color: $text-color-6;
                  display: flex;
                  align-items: center;
                  width: 100%;
                  justify-content: space-between;

                  span {
                    display: block;

                    &.plus {
                      cursor: pointer;
                      opacity: 0;
                      transition: opacity .3s ease;

                      @media (max-width: $break-xs-max) {
                        display: none;
                      }

                      span {
                        color: $text-color-6;
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 20;
                      }
                    }
                  }
                }
              }

              &.fc-day-sat,
              &.fc-day-sun {
                background-color: $bg-color-7;
              }

              &.fc-day-today {
                background-color: $bg-color-1;
              }

              .fc-daygrid-day-events {
                padding: 4px 6px 0;

                .fc-daygrid-event {
                  font-size: 0.6875rem;
                  line-height: 13px;
                  letter-spacing: 0.16px;
                  font-weight: 500;
                  margin: 0 0 4px;
                  white-space: normal;
                  cursor: pointer;

                  &.fc-event:focus {
                    box-shadow: none !important;

                    &::after,
                    &::before {
                      display: none;
                    }
                  }
                  
                  &.fc-h-event {
                    background-color: #fff;
                    border: 1px solid $border-color-6;
                    border-radius: 4px;
                    padding: 6px 8px;
                    
                    .fc-event-main {
                      color: $text-color-6;
                    }
                  }

                  .fc-event-title {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical; 
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.with-view {

    .fc-daygrid.fc-view {
      padding-right: 256px;

      @media (max-width: $break-md-max) {
        padding-right: 0;
      }
    }

    .fc-header-toolbar {

      .fc-listBtn-button {
        background-color: $bg-color-8;
        padding-right: 4px;
        
        &:hover {
          background-color: $bg-color-8;
        }
        
        &:active {
          background-color: darken($bg-color-8, 5);
        }
      }
    }
  }

  &__view {
    position: absolute;
    top: 110px;
    right: 15px;
    width: 248px;
    height: calc(100% - 125px);
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: $break-md-max) {
      position: static;
      width: 100%;
      height: auto;
      margin-top: 20px;
      background-color: #fff;
      border: 1px solid $border-color-5;
      border-radius: 8px;
      padding: 15px;
    }

    &_item {
      border: 1px solid $border-color-5;
      border-radius: 4px;
      padding: 10px 13px 8px;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 4px;
        word-break: break-word;
      }

      .date {

        span {
          margin-right: 4px;
        }

        &.already-passed {

          span {
            color: $danger-color;
          }
        }
      }
    }
  }
}