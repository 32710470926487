@use '../abstracts/variables_new.scss' as *;

.input-v2 {
  margin-bottom: 20px;

  &__label-wrapper {
    position: relative;

    &.with-dropdown {
      padding-right: 30px;

      .label-dropdown-wrapper {
        position: absolute;
        top: 0;
        right: 0;

        .link-column-to-variable {
          position: static;
          transform: translateY(0);
        }
      }
    }

    .link-column-to-variable__btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: $text-color-6;
      border: 1px solid $border-color-5;

      span {
        font-weight: 300;
        font-size: 1.5rem;
      }

      &:hover {
        background-color: $bg-color-1;
      }
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 10px;
    color: $text-color-6;
  }

  &__field-wrapper {
    position: relative;

    .input-v2__icon,
    .input-v2__eye-icon {
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      cursor: pointer;
      user-select: none;
      
      span {
        font-weight: 300;
        font-size: 1.5rem;
        display: block;
        color: $primary-color;
      }
    }

    .input-v2__eye-icon {
      left: auto;
      right: 6px;
    }
  }

  &.with-icon {

    .input-v2__field {
      padding-left: 36px;
    }
  }

  &.with-eye-icon {

    .input-v2__field {
      padding-right: 36px;
    }
  }
  
  &__field {
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 16px;
    background-color: #fff;
    border: 1px solid $border-color-2;
    outline: 0;
    border-radius: 6px;
    font-size: 0.75rem;
    color: $text-color-4;
    letter-spacing: 0.17px;
    line-height: 1.5;
    width: 100%;

    &:focus {
      border-color: $primary-color;
    }
  }

  &__info {
    padding-top: 5px;
    font-size: 0.75rem;
    color: $text-color-6;
  }

  &__error {
    font-size: 0.75rem;
    color: $danger-color;
    padding-top: 4px;
  }

  &.error {

    .input-v2__field {
      border-color: $danger-color !important;
    }
  }

  &.transparent {
    
    .input-v2__field {
      height: 22px;
      font-size: 1rem;
      line-height: 1.1875;
      border: 0;
      text-align: center;
      padding: 0;
      color: $text-color-6;
      font-weight: 500;
    }
  }

  &.no-mb {
    margin-bottom: 0;
  }

  &.disabled {

    .input-v2__field {
      background-color: $bg-color-6;
      cursor: not-allowed;
    }
  }
}