@use '../abstracts/variables' as *;

.auth {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__box {
    max-width: 385px;
    width: 100%;

    &_top {
      margin-bottom: 30px;

      h1 {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: $tertiary-text-color;
      }
    }

    &_main {
      background-color: $primary-bg-color;
      padding: 35px 32px 31px;
      border-radius: 6px;

      .form {

        &__group--auth {

          a {
            color: inherit;
          }
        }
      }
    }

    &_main-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 17px;

      p {
        font-size: 1rem;
        font-weight: 500;
        color: $septenary-text-color;
      }

      .text-link {
        font-size: 0.6875rem;
        color: rgba($primary-color, 0.8);
      }

      .icon {
        color: $tertiary-text-color;
        
        svg {
          font-size: 1.25rem;
          display: block;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding-top: 15px;
    margin-bottom: 17px;

    .button {
      flex: 1;
      padding: 0 10px;
      height: 50px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 1.125rem;

        i {
          display: block;
        }
      }

      // &:first-child {
      //   margin-right: 12px;
      // }
    }

    @media (max-width: $break-xs-max) {
      display: block;

      .button {
        width: 100%;

        &:first-child {
          margin-bottom: 12px;
          margin-right: 0;
        }
      }
    }
  }

  &__back {
    margin-top: 16px;
    
    a {
      display: inline-flex;
      align-items: center;
      color: $primary-color;
      font-size: 0.8125rem;

      svg {
        font-size: 1.125rem;
      }
    }
  }

  &__note {
    text-align: center;
    padding-top: 15px;
    
    p {
      color: $quaternary-text-color;
      font-weight: 500;
      font-size: 0.875rem;

      .text-link {
        margin-left: 8px;
      }

      &.small {
        font-size: 0.75rem;
        font-weight: 400;
        margin-bottom: 16px;
      }
    }
  }

  .hint {
    font-size: 12px;
    line-height: 22px;
    &.error {
      color: $text-color-22;
    }
  }

  .recaptcha-container {
    margin-top: 10px;
  }
}