.search-results {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px;
    border-bottom-color: lightgrey;
    border-bottom-style: solid;
  }

  .tabs-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-top: 20px;
    // padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px;
    border-bottom-color: lightgrey;
    border-bottom-style: solid;
  }

  .tab-button {
    cursor: pointer;
    font-size: 16px;
    margin-right: 30px;
    padding-bottom: 15px;
    p {
      color: #767676;
      font-weight: 400;
    }
  }
  .tab-button.selected { 
    border-bottom: 3px;
    border-bottom-color: #006EFF;
    border-bottom-style: solid;
    p {
      color: #2E2F35 !important;
      font-weight: bold !important;
    }
  }
  .result-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 60px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px;
    border-bottom-color: lightgrey;
    border-bottom-style: solid;
    

    .content {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }

    .icon {
      color: #bbb;
      font-size: 18px;
    }

    .label {
      margin-left: 15px;
      color: #1B1D24;
      
      .highlight {
        background-color: #FFE89F;
      }
    }
    .label.name {
      font-weight: 800;
    }
  }
}