@use '../abstracts/variables' as *;

.letter-circle {
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $primary-light-color;
  position: relative;
  background-color: $senary-color;

  &--1 {
    background-color: $primary-color;
  }

  &--2 {
    background-color: $secondary-color;
  }

  &--3 {
    background-color: $tertiary-color;
  }

  &--4 {
    background-color: $quaternary-color;
  }

  &--5 {
    background-color: #2C80FA;
  }
  &--6 {
    background-color: #17BFFF;
  }
  &--7 {
    background-color: #1FDAD2;
  }
  &--8 {
    background-color: #FF07C3;
  }
  &--9 {
    background-color: #F92A60;
  }
  &--10 {
    background-color: #FF702B;
  }
  &--11 {
    background-color: #FDB400;
  }
  &--12 {
    background-color: #1FC933;
  }
  &--13 {
    background-color: #8C46FF;
  }
}