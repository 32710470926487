.preview-modal {

  .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  &--v2 {

    .icon-button--primary-light {
      color: #2C7BE5;
      background-color: rgba(#2C7BE5, 0.1);
    }
  }
}