@use '../abstracts/mixins' as *;

.search-box {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .button {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  // form .suggestions {
  //   width: auto;
  // }
  .sb-label {
    margin-bottom: 10px;
    font-size: 12px;
    color: darkgray;
  }
  
  &--premium {

    .wider {

      .close {
        right: 90px;
      }
  
      .form-input__field {
        padding-right: 110px;
      }
    }
  }
}

.dashboard-v3 {

  .search-box {
    margin-left: 3px;

    .search-form-el {
      width: 178px;

      &.wider {
        width: 400px;

        .form-input__icon {
          display: none;
        }
      }
    }

    .form-input {

      &__icon {
        right: 5px;
        left: auto;
        color: #fff;
      }

      &__field {
        background-color: rgba(#EBECF0, 0.35);
        padding-bottom: 7px;
        padding-left: 10px !important;
        padding-right: 36px !important;
        
        @include placeholder {
          color: transparent;
        }
      }
    }

    .search-small {
      width: 36px;
      height: 32px;
      background-color: rgba(#EBECF0, 0.35);
      color: #fff;
      border-radius: 3px;
    }
  }
}