@use '../../abstracts/variables' as *;

.detail-view__section_attachments {

  .attachments {

    .attachment {
      margin-bottom: 18px;
      display: flex;

      @media (max-width: $break-xs-max) {
        flex-direction: column;

        .attachment__thumb {
          margin-right: 0;
          margin-bottom: 17px;
        }
      }

      &__thumb {
        margin-right: 17px;

        .placeholder {
          background-color: #EBEDF0;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 113px;
          height: 89px;
          font-weight: 1.125rem;
          font-weight: 500;
          color: #49576D;
          text-transform: uppercase;
        }
      } 

      &__info {

        &_title {
          font-weight: 500;
          margin-bottom: 10px;
        }

        &_meta {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          p {
            font-size: 0.875rem;
            color: #63728A;
            margin-right: 5px;

            a {
              color: inherit;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .add-attachment {
    position: relative;

    .button {
      padding: 0 15px;
      background-color: #EBEDF0;
      color: #162A4E;
      border-radius: 2px;

      &:hover {
        background-color: darken(#EBEDF0, 10);
      }
    }

    &__dropdown {
      max-width: 240px;
    }
  }

}

.delete-attachment {

  p {
    font-size: 0.875rem;
    white-space: normal;
    margin-bottom: 10px;
  }
}

.actions-dropdown {
  .edit-attachment {

    .form-input__field {
      padding: 0 7px;
    }
  }
}