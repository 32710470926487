.document-popup {

  &--alerts {

    .document-popup__group {
      display: block;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .date-picker-field,
    .date_picker,
    .form-input,
    .custom-select,
    .textarea,
    .tags-input {
      width: 48%;
      min-width: 48%;
      max-width: 48%;
      margin-bottom: 0;

      &__label,
      label {
        color: #5D6C85 !important;
        font-size: 0.875rem !important;
        font-weight: 700 !important;
        margin-bottom: 6px !important;
        white-space: normal;
      }

      &.hidden-label {

        .custom-select__label,
        label {
          visibility: hidden;
        }
      }
    }

    .tags-input__label-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      label {
        margin-bottom: 0 !important;
      }
    }

    &--2 {
      display: block;

      .date_picker,
      .form-input,
      .custom-select,
      .textarea,
      .tags-input {
        width: 100%;
        min-width: auto;
        max-width: 100%;
      }
    }
  }

  .save,
  .delete {
    width: 100%;
    height: 36px;
    display: flex;
    margin-bottom: 8px;
    border-radius: 5px;
    font-size: 0.9375rem;
  }

  .delete {
    background-color: #F5F6F8;
    color: #34353B;

    &:hover {
      background-color: darken(#F5F6F8, 10);
    }
  }
}