@use '../abstracts/variables_new.scss' as *;

.chart-tooltip-v2 {
  background-color: #fff;
  padding: 10px;
  border: 1px solid $border-color-1;
  // box-shadow: 0 1px 4px 0 rgba($shadow-color-1, 0.5);
  border-radius: 3px;

  &__text {
    font-size: 0.8125rem;
    font-weight: 400;
    max-width: 300px;
    color: $text-color-6;

    &.label {
      text-transform: capitalize;
    }
  }
}