@use '../abstracts/variables' as *;

.custom-datepicker {
  position: relative;

  &__selected {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-right: 10px;
      font-size: 0.75rem;
      font-weight: 500;
      color: $secondary-text-color;
    }

    svg {
      display: block;
      color: $primary-color;
    }
  }

  &__dropdown {
    padding: 14px 11px;
    background-color: $primary-light-color;
    box-shadow: 0 1px 3px 0 rgba($primary-dark-color, 0.23);
    border-radius: 3px;
    position: absolute;
    top: calc(100% + 5px);
    right: 0;

    &_head {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @media (max-width: $break-sm-max) {
        display: block;
      }

      .react-datepicker__input-container {

        input {
          font-size: 0.875rem;
          font-weight: 400;
          font-family: $ff-primary;
          border: 1px solid $primary-bg-color;
          border-radius: 4px;
          outline: 0;
          padding: 7px 30px 6px 8px;
          margin-right: 10px;
          width: 137px;
          background-image: url('../../assets/images/date_range-icon.svg');
          background-repeat: no-repeat;
          background-position: right 8px center;
          background-size: 24px 24px;
          transition: border .3s ease;

          @media (max-width: $break-sm-max) {
            margin-right: 0;
            margin-bottom: 10px;
          }

          &:focus {
            border-color: $primary-color;
          }

          &.not-empty {
            background-image: none;

          }
        }

        .react-datepicker__close-icon {
          right: 10px;
        }
      }
    }

    &_body {

      ul {

        li {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.785;
          cursor: pointer;
          position: relative;

          &::after {
            content: '';
            width: 3px;
            height: 20px;
            background-color: $primary-color;
            position: absolute;
            left: -11px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
          }

          &.active {

            &::after {
              opacity: 1;
            }
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}