@use '../abstracts/variables.scss' as *;

.list-of-templates-modal {

  &__head {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    h2 {
      font-weight: 400;
    }
  }

  &__body {

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .checkbox-all {
        display: flex;
        align-items: center;
        font-size: 0.9375rem;
        cursor: pointer;

        svg {
          color: #f2f2f2;
        }

        p {
          margin-left: 6px;
        }

        &.checked {

          svg {
            color: $primary-color;
          }
        }
      }
    }

    .list {
      max-height: 400px;
      overflow-y: auto;
      padding-right: 5px;

      li {
        padding: 8px 0 8px 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f5f5f5;
        font-size: 0.875rem;
        position: relative;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }

        .checkboxes {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          svg {
            color: #f2f2f2;
          }

          .checked {
            display: none;
          }
          .unchecked {
            display: block;
          }
        }

        &.active {

          .checkboxes {

            svg {
              color: $primary-color;
            }

            .checked {
              display: block;
            }
            .unchecked {
              display: none;
            }
          }
        }
      }
    }
  }

  &__foot {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      margin: 0 10px;
    }
  }
}