@use '../abstracts/variables' as *;

.upload-or-drop {
  border: 2px dashed $senary-border-color;
  border-radius: 3px;
  padding: 130px 15px 18px;
  position: relative;
  transition: border .3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: $primary-light-color;
    opacity: 0;
    transition: opacity .3s ease;
    // z-index: -1;
    height: 0;
  }

  &.active {
    border-color: $primary-color;

    &::after {
      // z-index: 10;
      opacity: 0.8;
      height: 100%;
    }
  }

  &__text-1 {
    font-size: 1.1875rem;
    font-weight: 500;
    text-align: center;
    color: $quinary-dark-color;
    margin-bottom: 24px;
  }

  &__or {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;

    .line {
      height: 2px;
      width: 26px;
      background-color: $secondary-light-color;
    }

    p {
      font-size: 0.875rem;
      color: $quinary-dark-color;
      margin: 0 18px;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 93px;
  }

  .button {
    margin: 0 auto;

    i {
      font-size: 1.25rem;
    }
  }

  &__note {
    font-size: 0.875rem;
    line-height: 1.43;
    color: $nonary-text-color;
    text-align: center;
  }
}