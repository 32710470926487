@use '../abstracts/variables_new.scss' as *;

.team-dropdown-v2 {
  width: 280px;
  padding: 0;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  border-radius: 3px;
  border: 1px solid $border-color-1;
  box-shadow: 0 4px 10px 0 rgba(#000, 0.2);

  @media (max-width: $break-xxs-max) {
    width: 240px;
  }

  &::after {
    left: 20px;
    width: 8px;
    height: 8px;
    top: -4px;
    display: none;
  }

  &__head {
    padding: 18px;
    display: flex;
    align-items: center;

    &_image-box {
      width: 44px;
      height: 44px;
      background-color: $border-color-1;
      border-radius: 3px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 15px;
    }

    .letter-circle-v2 {
      width: 44px;
      height: 44px;
      border-radius: 3px;
      margin-right: 15px;
      font-size: 1.25rem;
    }

    &_info-box {
      padding-top: 3px;

      h5 {
        font-size: 0.9375rem;
        font-weight: 500;
        margin-bottom: 3px;
      }

      p {
        font-size: 0.8125rem;
        font-weight: 400;
        color: $text-color-6;
      }
    }
  }

  &__body {
    border-top: 1px solid $border-color-1;
    border-bottom: 1px solid $border-color-1;
    padding: 16px 0;

    &--no-border-top {
      border-top: 0;
    }
  }

  &__foot {
    padding: 16px 0;
  }

  &__link-item {
    display: flex;
    align-items: center;
    padding: 4px 20px;
    margin-bottom: 6px;
    cursor: pointer;
    color: $text-color-1;
    transition: background-color 0.3s ease;
    position: relative;

    &:hover {
      background-color: $bg-color-1;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .material-symbols-outlined {
      display: block;
      margin-right: 5px;
    }

    i {
      font-size: 1.125rem;
      transform: rotate(90deg);
      position: relative;
      left: 3px;
      margin-right: 20px;
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
      color: $text-color-1;
    }

    .icon-right {
      margin-left: auto;
    }

    .inner-dropdown {
      position: absolute;
      left: 100%;
      top: -16px;
      width: 240px;
      padding: 22px 29px;
      background-color: #fff;
      cursor: default;
      border-radius: 3px;
      box-shadow: 0 4px 10px 0 rgba(#000, 0.2);

      @media (max-width: $break-sm-max) {
        top: 100%;
        left: 20px;
        z-index: 1;
      }

      &__title {
        color: $text-color-6;
        font-size: 0.875rem;
        line-height: 1.142;
      }

      &__list {
        margin-top: 13px;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            background-color: $bg-color-1;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .logo-box {
            width: 24px;
            height: 24px;
            border-radius: 3px;
            background-color: $border-color-1;
            margin-right: 10px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          p {
            font-size: 0.875rem;
            color: $text-color-6;
          }
        }
      }
    }

    &.disabled {
      p,
      svg {
        color: $text-color-15;
        user-select: none;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}
