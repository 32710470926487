@use '../abstracts/animations' as *;
@use '../abstracts/variables_new.scss' as *;

.loader-v2 {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  border: 2px solid rgba($primary-color, 0.2); 
  border-top: 2px solid $primary-color; 
  border-radius: 50%;
  animation: spin 1s linear infinite;

  &--mini {
    width: 16px;
    height: 16px;
    border-width: 1px;
  }
}